import MockData from '../../utils/mockData';
import Utils from '../../utils/utils';
import { handleError, isMocKData } from './utils';

export const getChangeIrisportalviewUserData = async (customerId: string) => {
  let token_express = Utils.getObjectItem('token_express');

  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/change-iris-portal-view?customerId=${customerId}`, {
    headers: new Headers({
      Authorization: `Bearer ${token_express}`
    })
  });
  handleError(response.status);

  const body = await response.json();
  if (response.status === 200) {
    return body;
  } else {
    return {};
  }
};

export const getFirstTimeSetupIrisPortal = async () => {
  let user = Utils.getObjectItem('user');
  let token_express = Utils.getObjectItem('token_express');

  if (isMocKData) {
    Utils.saveObjectItem('user', MockData.getUserMockData());
    return { firstTimeSetupComplete: true };
  }

  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/first-time-setup?customerId=${user.customerId}`, {
    headers: new Headers({
      Authorization: `Bearer ${token_express}`
    })
  });
  handleError(response.status);

  const body = await response.json();
  if (response.status !== 200) throw Error(body.message);
  return body;
};

export const saveIrisPortalUser = async (email: any) => {
  let user = Utils.getObjectItem('user');
  let token_express = Utils.getObjectItem('token_express');
  let data: any = {};

  data.userEmailToAdd = email;

  // Use email and customerId to uniquely identify the user who is adding the email
  data.userEmailWhoIsAdding = user.email;
  data.customerId = user.customerId;

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token_express}`
    },
    body: JSON.stringify(data)
  };
  if (isMocKData) {
    return MockData.getSaveIrisPortalUserMockData();
  }
  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/save/iris/portal/user`, requestOptions);
  handleError(response.status);

  const body = await response.json();
  if (response.status !== 200) throw Error(body.message);
  return body;
};

export const getIrisPortalInvitedUsers = async () => {
  let user = Utils.getObjectItem('user');
  let token_express = Utils.getObjectItem('token_express');
  if (isMocKData) {
    return MockData.getIrisPortalInvitedUsersMockData();
  }
  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/users/getAllIrisPortalUsers?customerId=${user.customerId}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token_express}`
    }
  });
  handleError(response.status);
  const body = await response.json();
  if (response.status !== 200) throw Error(body.message);
  return body;
};

export const getAgentAnalyticsData = async (startTime: any, endTime: any, currentTeamName: any, currentCategory: any) => {
  let token_express = Utils.getObjectItem('token_express');
  const startTimeUTC = Utils.formatDateToUTC(startTime);
  const endTimeUTC = Utils.formatDateToUTC(endTime);

  if (isMocKData) {
    return MockData.getAgentAnalyticsMockData();
  }

  const params = {
    startTime: startTimeUTC,
    endTime: endTimeUTC,
    ...(currentTeamName
      ? {
          currentTeamName: currentTeamName
        }
      : undefined),
    ...(currentCategory
      ? {
          currentCategory: currentCategory
        }
      : undefined)
  };

  const endpoint = `${process.env.REACT_APP_APISERVER_URL}/v1/agents/summary?${new URLSearchParams(params)}`;

  const response = await fetch(endpoint, {
    method: 'GET',
    headers: new Headers({
      Authorization: `Bearer ${token_express}`,
      'Content-Type': 'application/json'
    })
  });
  handleError(response.status);
  if (response.status === 403) {
    return;
  }
  const body = await response.json();
  if (response.status !== 200) {
    // return;
    throw Error(body.message);
  }
  return body;
};

export const getSingleAgentAnalytics = async (agentId: any) => {
  let token_express = Utils.getObjectItem('token_express');

  if (isMocKData) {
    return MockData.getSingleAgentAnalyticsMockData();
  }

  let endpoint = `${process.env.REACT_APP_APISERVER_URL}/v1/agents/${agentId}`;

  const response = await fetch(endpoint, {
    method: 'GET',
    headers: new Headers({
      Authorization: `Bearer ${token_express}`
    })
  });

  handleError(response.status);
  if (response.status === 403) {
    return;
  }
  if (!response.ok) {
    throw new Error('Unhandled status code');
  }
  const body = await response.json();
  if (response.status !== 200) throw Error(body.message);
  return body;
};
