import React, { forwardRef, useCallback, useImperativeHandle, useState } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

export interface IModalConfirmationRef {
  openModal: () => void;
  closeModal: () => void;
  toggleModal: () => void;
}

interface IModalConfirmationProps {
  data?: any;
  title: string;
  message?: string;
  open?: boolean;
  acceptButtonLabel?: string;
  cancelButtonLabel?: string;
  onConfirm?: (data: any) => void;
  onCancel?: (data: any) => void;
}

const ModalConfirmationComponent: React.ForwardRefRenderFunction<IModalConfirmationRef, IModalConfirmationProps> = (
  {
    data = {},
    title,
    message,
    open = false,
    onConfirm = () => undefined,
    onCancel = () => undefined,
    acceptButtonLabel = 'Agree',
    cancelButtonLabel = 'Cancel'
  },
  ref
) => {
  const [isOpen, setIsOpen] = useState(open);

  const openModal = useCallback(() => {
    setIsOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  const toggleModal = useCallback(() => {
    setIsOpen((oldState) => !oldState);
  }, []);

  useImperativeHandle(
    ref,
    () => ({
      openModal,
      closeModal,
      toggleModal
    }),
    [closeModal, openModal, toggleModal]
  );

  const handleConfirm = useCallback(() => {
    onConfirm(data);
    closeModal();
  }, [closeModal, data, onConfirm]);

  const handleCancel = useCallback(() => {
    onCancel(data);
    closeModal();
  }, [closeModal, data, onCancel]);

  return (
    <div>
      <Dialog open={isOpen} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>{message && <DialogContentText id="alert-dialog-description">{message}</DialogContentText>}</DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            {cancelButtonLabel}
          </Button>
          <Button onClick={handleConfirm} color="primary" autoFocus>
            {acceptButtonLabel}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export const ModalConfirmation = forwardRef(ModalConfirmationComponent);
