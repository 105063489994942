import React, { FC, useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router';
import { StickyContainer } from 'react-sticky';
import Utils from '../../../utils/utils';
import { Stack, Text } from '../../../components/common';
import { CaseCategoriesType } from '../types';
import { CategoriesTabs } from './CategoriesTabs';
import { logEventWithUserInfo } from '../../../utils/amplitude';
import { PageContainer } from '../../../components/common/blocks/PageContainer';
import { Loader } from '../../../components/common/blocks/Loader';
import { useLocation } from 'react-router-dom';
import { useDateRangeQuery, useNavigateToCategory } from '../utils';
import { Paper } from '../../../components/home/Paper';
import AccountSearch from '../../customerHealth/components/AccountSearch';
import { useResetPage } from '../../../hooks/useResetPage';
import _ from 'lodash';
import { TablePaper } from '../../../components/common/blocks/TablePaper';
import Constants, { DEFAULT_END_DATE, DEFAULT_START_DATE } from '../../../utils/constants';

interface DetailedViewState {
  goBackText: string;
}

export const DetailedView: FC<RouteComponentProps> = (props) => {
  const history = useHistory();

  const { state } = useLocation<DetailedViewState>();
  let goBackText = state?.goBackText;

  const dateRangeCategoriesOverview = Utils.getObjectItem(Constants.STORAGE_KEY.DATE_RANGE_CATEGORIES_OVERVIEW);
  const dateRange = dateRangeCategoriesOverview.current ? dateRangeCategoriesOverview.current : [DEFAULT_START_DATE, DEFAULT_END_DATE];

  const currentFiltersCategories = Utils.getObjectItem(Constants.FILTERS_CATEGORIES).current ? Utils.getObjectItem(Constants.FILTERS_CATEGORIES).current : {};

  const attributeFilter = currentFiltersCategories;

  const { data: categoriesDataWithFilter } = useDateRangeQuery(dateRange, attributeFilter);

  const [categoriesData, setCategoriesData] = useState<any[]>([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  // let categoriesData: any[] = [];

  const { hash } = useLocation();
  const navigateToCategory = useNavigateToCategory();
  const handleClickCardMetrics = (optionSelected: string) => {
    Utils.removeObjectItem('categoryDetailedView');
    navigateToCategory(optionSelected);
  };

  useResetPage();

  // Auto selects the first category when the page loads
  // This will not auto select the first category when the date range is changed
  // This will also preserve the hash already present in the URL
  // to bring the user back to the same category when she reloads the page
  useEffect(() => {
    setCategoriesData(categoriesDataWithFilter ? categoriesDataWithFilter.caseCategoriesData : []);
    if (!hash && categoriesDataWithFilter?.caseCategoriesData[0]) {
      console.log({ hash });
      history.replace({
        hash: categoriesDataWithFilter?.caseCategoriesData[0].name
      });
      logEventWithUserInfo('Detailed View', {
        view: categoriesDataWithFilter?.caseCategoriesData[0].name
      });
    }

    if (Utils.getObjectItem('categoryDetailedView').current) {
      history.replace({
        hash: Utils.getObjectItem('categoryDetailedView').current
      });
    }
  }, [categoriesDataWithFilter, hash, history]);

  if (!categoriesData) {
    return <Loader center />;
  }

  const categoriesSort = categoriesData.sort((cat1, cat2) => (cat1.count - cat2.count > 0 ? -1 : 1));
  return (
    <div style={{ display: 'flex', width: '100%' }}>
      <div style={{ width: '80%' }}>
        <PageContainer>
          {/* Categories Table */}
          {categoriesData.length !== 0 ? (
            <StickyContainer>
              {/* Categories Tabs List + Details */}
              <CategoryDetails caseCategories={categoriesData} goBackText={goBackText} setSelectedCategory={setSelectedCategory} />
            </StickyContainer>
          ) : (
            <Paper>
              <Text variant="p3">No Data</Text>
            </Paper>
          )}
        </PageContainer>
      </div>
      <div style={{ width: '20%' }}>
        <div
          style={{
            marginTop: '10%',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <AccountSearch
            defaultSelection={selectedCategory}
            isCustom={true}
            deactivateDropDown={true}
            placeholder="Search"
            deactivateTitle={true}
            withoutBorder={true}
            dataListSearch={categoriesSort}
            showListSearch={true}
            getClickCardMetrics={handleClickCardMetrics}
          />
        </div>
      </div>
    </div>
  );
};

const CategoryDetails: FC<{
  caseCategories: CaseCategoriesType[];
  goBackText: string;
  setSelectedCategory: (category: string) => void;
}> = (props) => {
  const history = useHistory();

  const { caseCategories, goBackText, setSelectedCategory } = props;
  const navigateToCategory = useNavigateToCategory();

  const filterData = caseCategories?.map((category: any) => {
    return category.name;
  });
  const handleSelectTextSearch = (category: string) => {
    Utils.removeObjectItem('categoryDetailedView');
    setSelectedCategory(category);
    navigateToCategory(category);
  };
  useEffect(() => {
    setSelectedCategory(window.location.hash.replace('#', ''));
  });

  const goBack = () => {
    if (_.isEmpty(goBackText)) {
      history.push('/overview');
    } else {
      history.goBack();
    }
  };

  return (
    <Stack direction="vertical" gap={20}>
      {/* Header */}
      <Stack gap={10} align="baseline">
        <TablePaper style={{ width: '100%' }}>
          <div style={{ textAlign: 'left', paddingTop: 16 }}>
            <div
              style={{
                marginBottom: '3%',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <img src="/images/arrow_left.png" alt="users" style={{ cursor: 'pointer' }} onClick={goBack} />
              <div
                style={{
                  fontFamily: 'DM Sans',
                  fontStyle: 'normal',
                  fontWeight: 'bold',
                  fontSize: 24,
                  marginLeft: '2%'
                }}
              >
                {goBackText || 'Back to all category overview'}
              </div>
            </div>
            <AccountSearch
              withoutBorder={true}
              dataList={filterData}
              value={window.location.hash.replace('#', '')}
              deactivateTitle={true}
              withoutBorderInput={true}
              getOptionSelected={handleSelectTextSearch}
            />
          </div>
          {/* Category Metrics */}
          <div style={{ width: '100%' }}>
            <CategoriesTabs caseCategories={caseCategories} />
          </div>
        </TablePaper>
      </Stack>
    </Stack>
  );
};
