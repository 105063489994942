import 'react-datepicker/dist/react-datepicker.css';
import React, { FC } from 'react';
import './SortSelectionDisplay.scss';
import { MenuListComposition, MenuListCompositionValue } from '../../../customerHealth/components/MenuListComposition';

type SortSelectionProps = {
  onChange: (sortField: MenuListCompositionValue) => void;
  allMenuListValues: MenuListCompositionValue[];
};

export const SortSelection: FC<SortSelectionProps> = (props) => {
  const { onChange, allMenuListValues } = props;

  const handleCloseMenuListComposition = (field: string) => {
    let matchItem = null;
    allMenuListValues.map((item) => {
      matchItem = item;
      if (matchItem.formattedLabel === field) {
        onChange(matchItem);
      } else {
        item.icon = item.sortDownIcon;
      }
      return item;
    });
    //console.log(allMenuListValues);
  };
  const currentMenuListValue = 'Sort A-Z';

  return (
    <div className="sort-class">
      <MenuListComposition
        handleClose={handleCloseMenuListComposition}
        currentFormattedLabel={currentMenuListValue}
        allValues={allMenuListValues}
        showIcons={true}
      ></MenuListComposition>
    </div>
  );
};
