import { Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, Button } from '@material-ui/core';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { Stack, Text, useTableSorters } from '../../../components/common';
import { logEventWithUserInfo } from '../../../utils/amplitude';
import { CaseCategoriesType } from '../types';
import TableHeadSorting from '../../../components/table-head-sorting/TableHeadSorting';
import { NoCategoriesFoundCmp } from '../../../components/home/CategoriesByMostCases';
import Utils from '../../../utils/utils';
import { useHistory } from 'react-router';
import { MenuListCompositionValue } from '../../customerHealth/components/MenuListComposition';
import _ from 'lodash';
import Constants from '../../../utils/constants';
import { ReactComponent as BlueTickSvg } from '../../../assets/BlueTick.svg';

export enum DEFAULT_SORT {
  column = 'casesCount',
  orderAsc = 'asc'
}

export const CategoriesTable: FC<{
  sortField?: MenuListCompositionValue;
  caseCategories: CaseCategoriesType[];
  dateRange: [Date, Date];
  sortFlag?: boolean;
  caseCountWithoutFilter: number;
  searchValue?: string;
}> = (props) => {
  const history = useHistory();

  const goToActionsTrigger = useCallback(
    (categoryVal) => {
      history.push(Constants.routes.TRIGGERS_DETAILS + `?templateId=categoryAutoRespond&categoryVal=` + categoryVal);
    },
    [history]
  );

  const { caseCategories, sortField, sortFlag } = props;

  const formattedTableData = useMemo(() => {
    return caseCategories.map((category) => {
      return {
        name: category?.name,
        casesCount: category?.count,
        averageNumberOfComments: category?.averageNumberOfComments,
        mttr: category?.mttr?.mttr || 0,
        csat: category?.csat?.score,
        surveyCount: category?.csat?.totalSurveyCount || 0,
        casesChangePercent: category?.casesChangePercent,
        mttrChangePercent: category?.mttrChangePercent,
        csatChangePercent: category?.csatChangePercent,
        // Rounding up, since these values could be as low as 0.22
        // which in the UI should anyways be shown as 0 (toFixed(0))
        averageSentimentScorePercent: category.averageSentimentScore === null ? '-' : Math.round(category?.averageSentimentScore * 100),
        sentimentScoreChangePercent: category?.sentimentScoreChangePercent
          ? Math.round(category?.sentimentScoreChangePercent)
          : category?.sentimentScoreChangePercent,
        revenueImpacted: category.revenueImpacted,
        automationTriggerDetails: category.automationTriggerDetails
      };
    });
  }, [caseCategories]);

  const navigateToCategory = (categoryName: string) => {
    Utils.saveObjectItem('categoryDetailedView', { current: categoryName });
    history.replace(`/detailed-view#${categoryName}`);
  };
  const {
    onSort,
    filterData,
    sortColumn,
    sortOrder,
    onPageChange,
    currentPage,
    handleChangeRowsPerPage,
    labelDisplayedRows,
    pageSize,
    ROWS_PER_PAGE_OPTIONS
  } = useTableSorters({
    turnOffPagination: true,
    defaultSortColumn: DEFAULT_SORT.column,
    defaultSortOrder: DEFAULT_SORT.orderAsc
  });
  useEffect(() => {
    onSort(sortField?.field ? sortField?.field : 'casesCount');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortFlag]);

  return (
    <React.Fragment>
      <TableContainer style={{ maxHeight: 1000 }}>
        <Table
          size="small"
          style={{
            minWidth: '1000px'
          }}
        >
          <TableHeadSorting order={sortOrder} orderByColumn={sortColumn} onRequestSort={(_, column) => onSort(column)} headCells={TableHeaderCells} />
          <TableBody>
            {props.caseCountWithoutFilter === 0 ? (
              <TableRow>
                <TableCell colSpan={100} align="center">
                  <NoCategoriesFoundCmp />;
                </TableCell>
              </TableRow>
            ) : (
              filterData(formattedTableData).length === 0 && (
                <TableRow>
                  <TableCell colSpan={100} align="center" style={{ paddingTop: 40 }}>
                    <Text style={{ textAlign: 'center' }}>Your search - {props.searchValue} - did not match any categories.</Text>
                    <br />
                    <Text style={{ textAlign: 'center' }}>Suggestions: Search again or remove filters.</Text>
                  </TableCell>
                </TableRow>
              )
            )}

            {filterData(formattedTableData)
              .slice(currentPage * pageSize, currentPage * pageSize + pageSize)
              .map((category) => (
                <TableRow
                  onClick={() => {
                    logEventWithUserInfo('Category clicked', {
                      categoryName: category.name
                    });
                    navigateToCategory(category.name);
                  }}
                  key={category.name}
                >
                  <TableCell variant="body">
                    <Text style={{ color: 'rgb(94, 129, 244)' }} lineClamp={2} variant="p1" weight="semi-bolder">
                      {category.name}
                    </Text>
                  </TableCell>

                  <TableCell variant="body">
                    <Stack direction="horizontal" gap={30} align="center" justify="space-between">
                      <Text weight="semi-bold" variant="p2" style={{ color: '#58508D' }}>
                        {category.casesCount}
                      </Text>
                    </Stack>
                  </TableCell>

                  <TableCell variant="body">
                    {category.mttr ? (
                      <Stack direction="horizontal" gap={30} align="center" justify="space-between">
                        <Text weight="semi-bold" variant="p2" style={{ color: '#58508D' }}>
                          {category.mttr ? category.mttr.toFixed(0) : '-'}
                        </Text>
                      </Stack>
                    ) : (
                      <Text weight="semi-bold" variant="p2" style={{ color: '#58508D' }}>
                        {'-'}
                      </Text>
                    )}
                  </TableCell>

                  {
                    <TableCell variant="body">
                      <Stack direction="horizontal" gap={30} align="center" justify="space-between">
                        <Text
                          weight="semi-bold"
                          variant="p2"
                          style={{
                            textAlign: 'center',
                            flex: 1,
                            color: '#58508D'
                          }}
                        >
                          {`${category.averageSentimentScorePercent}%`}
                        </Text>
                      </Stack>
                    </TableCell>
                  }

                  <TableCell variant="body" align="center">
                    <Stack direction="horizontal" gap={30} align="center" justify="space-between">
                      <Text
                        weight="semi-bold"
                        variant="p2"
                        color="dark"
                        style={{
                          textAlign: 'center',
                          flex: 1,
                          color: '#58508D'
                        }}
                      >
                        {category.averageNumberOfComments}
                      </Text>
                    </Stack>
                  </TableCell>

                  <TableCell variant="body" align="center">
                    <Stack direction="horizontal" gap={30} align="center" justify="space-between">
                      <Text variant="p2" weight="semi-bold" color="dark">
                        {!_.isNil(category.csat) ? category.csat + '%' : '-'}
                      </Text>
                      <Text variant="p0" weight="regular" color="green" style={{ marginInlineStart: '2px' }}>
                        {category.surveyCount ? category.surveyCount + ' Surveys' : ' '}
                      </Text>
                    </Stack>
                  </TableCell>

                  <TableCell variant="body" align="center">
                    <Stack direction="horizontal" gap={30} align="center" justify="space-between">
                      <Text
                        weight="semi-bold"
                        variant="p2"
                        color="dark"
                        style={{
                          textAlign: 'center',
                          flex: 1,
                          color: '#58508D'
                        }}
                      >
                        {Utils.getCurrencyValue(category.revenueImpacted)}
                      </Text>
                    </Stack>
                  </TableCell>
                  <TableCell variant="body" align="center">
                    <Stack direction="horizontal" gap={30} align="center" justify="space-between">
                      {category?.automationTriggerDetails?.exists ? (
                        <div style={{ display: 'flex' }}>
                          <BlueTickSvg
                            style={{
                              alignSelf: 'center'
                            }}
                          />
                          <Text
                            style={{
                              color: 'rgb(94, 129, 244)',
                              paddingLeft: '5px'
                            }}
                            lineClamp={2}
                            variant="p1"
                            weight="semi-bolder"
                          >
                            Automated
                          </Text>
                        </div>
                      ) : (
                        <Button
                          fullWidth
                          onClick={(e) => {
                            e.stopPropagation();
                            goToActionsTrigger(category.name);
                          }}
                          className="cancel-button"
                        >
                          Automate
                        </Button>
                      )}
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
        component="div"
        count={caseCategories.length}
        rowsPerPage={pageSize}
        page={currentPage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelDisplayedRows={labelDisplayedRows}
        onChangePage={(_, page) => {
          onPageChange(page);
        }}
      />
    </React.Fragment>
  );
};

export const TableHeaderCells = [
  {
    id: 'name',
    label: 'Category Name',
    width: '25%',
    hasFilter: true,
    type: 'string'
  },
  {
    id: 'casesCount',
    label: 'No. of Cases',
    width: '15%',
    hasFilter: true,
    type: 'number'
  },
  {
    id: 'mttr',
    label: 'Mean Time to Resolve(In Hrs)',
    width: '15%',
    hasFilter: true,
    type: 'number'
  },
  {
    id: 'averageSentimentScorePercent',
    label: 'Sentiment Score',
    width: '10%',
    hasFilter: true,
    type: 'number'
  },
  {
    id: 'averageNumberOfComments',
    label: 'Avg no. of comments',
    width: '10%',
    hasFilter: true,
    type: 'number'
  },
  {
    id: 'csat',
    label: 'CSAT Score',
    width: '15%',
    hasFilter: true,
    type: 'number'
  },
  {
    id: 'revenueImpacted',
    label: 'Revenue Impacted',
    width: '10%',
    hasFilter: true,
    type: 'number'
  },
  {
    id: 'automation',
    label: 'Automation',
    width: '15%',
    hasFilter: false,
    type: 'string'
  }
];
