import React, { ReactElement } from 'react';

import { RulesRowManager } from '../../../utils/RulesRowManager';

import { HandleRowComponentType, Option } from '../../../types';
import { ReactComponent as AssignTeam } from '../../../../../assets/AssignTeam.svg';
import '../styles.scss';
import { formatPrerequisitesData, useTriggersPrerequisites } from '../../../utils';
import SearchWidget from '../../../../categories/components/SearchWidget';

/**
 * Row manager for Assign Agent
 */
export class AssignTeamRowManager extends RulesRowManager {
  constructor(options: Option[], actionValue: any, actionOperator: any, type: string) {
    super(options, actionValue, actionOperator, type);
    this.action.type = 'change_case_assignee_team';
  }
  setDefaultActionValue(): void {
    this.action.value = '';
  }
  createItems(handleRowComponent: HandleRowComponentType): ReactElement {
    const prerequesitesData = useTriggersPrerequisites();
    const teamOptions = formatPrerequisitesData(prerequesitesData.triggersPrerequesites?.supportTeams || []).map((team) => {
      return { name: team.name.id, label: team.name.name };
    });
    let searchVal = '';
    if (this.action.value) {
      const teamObj = teamOptions.filter((opt) => opt.name === this.action.value);
      if (teamObj && teamObj.length > 0) {
        searchVal = teamObj[0]['label'];
      }
    }
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <AssignTeam />
          <p className="title">Assign Team</p>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 8 }}>
          <p className="operator-text">Select Team</p>
          <div style={{ width: '20em' }}>
            <SearchWidget
              isCustom={false}
              searchValue={searchVal}
              dataList={teamOptions.map((cat) => {
                return cat.label;
              })}
              onInputChange={(input: string) => {
                const teamObj = teamOptions.filter((opt) => opt.label === input);
                if (teamObj && teamObj.length > 0) {
                  this.action.value = teamObj[0]['name'];
                } else {
                  this.action.value = '';
                }
                searchVal = input;
                handleRowComponent(this.action);
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
