import { Accordion, AccordionDetails, AccordionSummary, Button, List, ListItem, Typography } from '@material-ui/core';
import React, { ReactNode } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import './Tool.scss';
import { ToolItem } from './ToolItem';
import { Option } from '../../types';

type ToolProps = {
  headerColor: string;
  heading: string;
  toolItems: Option[];
  getIcon: (iconName: string) => ReactNode;
  headerIcon: () => ReactNode;
  conditionToolClicked: (condition: string) => void;
};

export const Tool: React.FC<ToolProps> = (props) => {
  const headerColor = props.headerColor;
  return (
    <Accordion
      style={{ borderRadius: '8px' }}
      defaultExpanded={true}
      classes={{
        root: 'tool-root'
      }}
    >
      <AccordionSummary
        className="accordian"
        expandIcon={<ExpandMoreIcon style={{ backgroundColor: '#FFFFFF', borderRadius: '2px' }} />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        style={{
          backgroundColor: headerColor,
          borderRadius: '8px'
        }}
      >
        <Button className="accordian-icon" variant="text" startIcon={props.headerIcon()} />

        <Typography
          style={{
            color: '#ffffff',
            fontFamily: 'DM Sans',
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '20px',
            lineHeight: '27px'
          }}
        >
          {props.heading}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <List style={{ width: '100%' }}>
          {props.toolItems.map((tool) => {
            return (
              <ListItem className="tool-item" style={{ width: '100%' }} disabled={tool?.disabled}>
                <ToolItem
                  type={props.heading.substring(0, props.heading.length - 1)}
                  toolItem={tool}
                  getIcon={props.getIcon}
                  borderColor={props.headerColor}
                  conditionToolClicked={props.conditionToolClicked}
                />
              </ListItem>
            );
          })}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};
