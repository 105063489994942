import React, { FC } from 'react';
import { Stack, Text } from '../../../components/common';
import { Line, LineChart, ReferenceLine, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis } from 'recharts';
import { TablePaper } from '../../../components/common/blocks/TablePaper';
import { SentimentEntry } from '../../../services/IrisPortalService/customer';
import { format, parseISO } from 'date-fns';

export const SentimentDistribution: FC<{
  style?: React.CSSProperties;
  data: SentimentEntry[];
  classes?: {
    root?: string;
  };
}> = (props) => {
  const { data, classes: { root = '' } = {} } = props;

  return (
    <TablePaper className={root}>
      <Stack direction="vertical" gap={20} style={{ marginTop: 16 }}>
        <Text variant="p4" weight="bold" color="dark">
          Sentiment analysis
        </Text>
        <ResponsiveContainer height={250} width="100%">
          <LineChart data={transformData(data)}>
            <XAxis dataKey="title" tickSize={0} stroke="white" interval="preserveStartEnd" />
            <ReferenceLine y={0} stroke="#000" />
            <YAxis domain={[-100, 100]} tick={{ fontSize: 14, fontWeight: 600 }} tickSize={0} tickMargin={20} unit="%" />
            <Tooltip content={<CustomTooltip />} />
            <Line dot={false} type="monotone" dataKey="sentiment" stroke="#58508D" strokeWidth="1.5px" />
          </LineChart>
        </ResponsiveContainer>
      </Stack>
    </TablePaper>
  );
};

const CustomTooltip: FC<TooltipProps<string, number>> = (props) => {
  const { active, payload, label } = props;
  if (active && payload && payload.length) {
    const date = label;
    const sentimentScore = Number(payload[0].value) || 0;
    const color = sentimentScore >= 0 ? '#37E9A3' : '#FF6361';
    return (
      <Stack
        direction="vertical"
        gap={10}
        style={{
          backgroundColor: '#fff',
          boxShadow: '0px 4px 4px rgba(216, 216, 216, 0.25)',
          borderRadius: 1,
          padding: '15px 20px'
        }}
      >
        <Text variant="p1" weight="semi-bold">
          {date}
        </Text>
        <Stack
          style={{
            backgroundColor: `${color}1A`,
            borderRadius: 100,
            padding: '5px 15px'
          }}
          gap={5}
          align="center"
        >
          <Text variant="p1" weight="semi-bold" style={{ color }}>
            {sentimentScore.toFixed(0)}%
          </Text>
          <Text variant="p0" style={{ color, fontWeight: 300, fontStyle: 'italic' }}>
            {sentimentScore >= 0 ? 'POSITIVE' : 'NEGATIVE'}
          </Text>
        </Stack>
      </Stack>
    );
  }
  return null;
};

function transformData(distro: SentimentEntry[]) {
  const transformedData: { title: string; sentiment: number }[] = [];

  for (let i = 0; i < distro.length; i++) {
    const entry = distro[i];
    if (entry.count == 0) {
      continue;
    }
    transformedData.push({
      title: format(parseISO(entry.id), 'MMM dd, yyyy'),
      sentiment: (entry.avgSentiment / entry.count) * 100
    });
  }

  return transformedData;
}
