import { Paper } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import IrisPortalService from '../../../../services/IrisPortalService';
import { CACHE_KEYS, CACHE_TIME } from '../../../../utils/cacheUtils';
import Utils from '../../../../utils/utils';
import { Stack } from '../../../common';
import { Loader } from '../../../common/blocks/Loader';
import { BarGraph } from '../Graphs/BarGraph';
import { CustomPieChart } from '../Graphs/PieChart';
import './RevenueImpacted.scss';
import '../Graphs/Graph.scss';
import { RevenueImpactGraphItem } from '../../../../services/IrisPortalService/home';

export const OverallImpact: FC<any> = (props) => {
  const [overallCumulativeBin, setOverallCumulativeBin] = useState<any[]>([]);
  const [graphOverallBin, setGraphOverallBin] = useState<any[]>([]);

  const { data: revenueImpactData, status: statusRevenue } = useQuery(
    [CACHE_KEYS.REVENUE_IMPACT_DATA, ...props.dateRange],
    () => IrisPortalService.getOverallRevenueImpact(props.dateRange[0], props.dateRange[1]),
    {
      ...CACHE_TIME.get(CACHE_KEYS.REVENUE_IMPACT_DATA),
      refetchOnWindowFocus: false,
      onError: (e: any) => {
        if (e.message === 'no_revenue_impact') {
          props.dataAvailable(false);
        } else {
          Utils.showError(String(e));
        }
      },
      onSuccess: (OverallImpactData) => {
        if (OverallImpactData?.impact.graph.length === 0) {
          props.dataAvailable(false);
        }
      }
    }
  );

  useEffect(() => {
    if (revenueImpactData && statusRevenue === 'success') {
      revenueImpactData.impact.graph.sort(
        (a: RevenueImpactGraphItem, b: RevenueImpactGraphItem) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime()
      );

      const newOverallCumulativeBin = [
        {
          name: 'total',
          value: revenueImpactData?.impact.cumulative.totalAcv,
          hide: false
        }
      ];

      const newGraphOverallBin: any = [];
      revenueImpactData.impact.graph.map((imp: RevenueImpactGraphItem) => {
        newGraphOverallBin.push({
          date: imp.startTime,
          accounts: imp.accounts,
          acv: imp.acv
        });
        return imp;
      });
      setOverallCumulativeBin(newOverallCumulativeBin);
      setGraphOverallBin(newGraphOverallBin);
    }
  }, [revenueImpactData, statusRevenue]);

  return (
    <Stack style={{ width: '100%' }} direction="horizontal">
      {statusRevenue === 'loading' ? (
        <Paper style={{ flex: 1, marginBottom: '40px' }}>
          <Loader center />
        </Paper>
      ) : (
        <>
          <Stack>
            <CustomPieChart
              distribution={overallCumulativeBin}
              title=""
              pies={[
                {
                  name: 'total',
                  label: 'Total impact',
                  color: '#5E81F4'
                }
              ]}
            />
          </Stack>
          {graphOverallBin.length > 0 && (
            <Stack
              direction="vertical"
              gap={30}
              align="flex-start"
              // className="line-graph-container"
              style={{ width: '100%', marginLeft: '100px', marginTop: '50px' }}
            >
              <BarGraph
                customTooltip={true}
                title=""
                distribution={graphOverallBin}
                bars={[
                  {
                    name: 'acv',
                    label: 'Total Revenue Impact',
                    color: '#5E81F4',
                    type: 'currency'
                  },
                  {
                    name: 'accounts',
                    label: 'Total Customers Impacted',
                    color: '#8181A5',
                    hide: true
                  }
                ]}
              />
            </Stack>
          )}
        </>
      )}
    </Stack>
  );
};
