import 'react-datepicker/dist/react-datepicker.css';
import React from 'react';

import './DetectedIncidentsSettingsHolder.scss';
import { Button } from '../../../../../components/home/Button';
import { Badge, Box, IconButton } from '@material-ui/core';
import { Text } from '../../../../../components/common';

import CloseIcon from '@material-ui/icons/Close';
import { DetectedIncidentsManagementTable } from '../index';
import { DetectedIncidentsSettingsTableDrawerWithHeaderFooter } from './DetectedIncidentsSettingsTableDrawerWithHeaderFooter';
import { ReactComponent as Settings } from '../../../../../assets/settings.svg';

export interface DetectedIncidentsSettingsHolderProps {}

export const DetectedIncidentsSettingsHolder: React.FC<DetectedIncidentsSettingsHolderProps> = (props) => {
  const [state, setState] = React.useState({
    showDrawer: false
  });

  return (
    <React.Fragment key="right">
      <div
        onClick={() => {
          console.log('Button set state true');
          setState({ showDrawer: true });
        }}
        style={{
          width: 'fit-content'
        }}
      >
        <Badge
          badgeContent={0} // filters Count
          color="primary"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
        >
          <Button variant="outlined" classes={{ root: 'configure-button' }}>
            <Settings />
            {'Configure'}
          </Button>
        </Badge>
      </div>
      <DetectedIncidentsSettingsTableDrawerWithHeaderFooter
        headerComponent={
          <Box
            style={{
              width: '100%',
              alignSelf: 'flex-end',
              marginBottom: '64px'
            }}
            role="presentation"
          >
            <div className="advanced-filter ">
              <Text className="heading">Configure Trending Incidents</Text>
              <IconButton
                style={{ top: 0, position: 'fixed', right: 0 }}
                onClick={() => {
                  setState({ showDrawer: false });
                }}
              >
                <CloseIcon style={{ color: '#F6F6F6' }} />
              </IconButton>
            </div>
          </Box>
        }
        footerComponent={
          <div className="footer-di">
            <Button
              className="cancel-button"
              onClick={() => {
                setState({ showDrawer: false });
              }}
            >
              Cancel
            </Button>
          </div>
        }
        showDrawer={state.showDrawer}
      >
        <div className={'trending-incidents-settings-drawer-bottom'}>
          <DetectedIncidentsManagementTable />
        </div>
      </DetectedIncidentsSettingsTableDrawerWithHeaderFooter>
    </React.Fragment>
  );
};
