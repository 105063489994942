import MockData from '../../utils/mockData';
import { doRequest } from './utils';

export interface JiraIssueCase {
  caseId: string;
  caseNumber: string;
  priority: string;
  sourceCaseId: string;
  accountName: string;
  subject: string;
  status: string;
  description: string;
  timeOfCreation: Date;
  url: string;
}

export interface JiraIssue {
  cases: JiraIssueCase[];
  issue: {
    issueKey: string;
    summary: string;
    url: string;
    createdAt: Date;
    totalRevenue: number;
  };
}

export const getJIRAIssueDetails = async (issueKey: string): Promise<JiraIssue> => {
  const url = `/v1/customer/escalations/incidents/jira?issueKey=${issueKey}`;
  return await doRequest(url, MockData.getJiraIssuesMockData);
};
