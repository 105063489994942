import Utils from '../../utils/utils';
import MockData from '../../utils/mockData';
import { doRequest, isMocKData } from './utils';
import qs from 'query-string';
import { IncidentSourcesData, VisualBucket } from '../types';
import { PriorityScoreParameter } from '../../routes/customerHealth/types';

// TODO: Has been deprecated from the Node.js, has not been migrate to the new API.
export const getIncidentSourcesData = async () => {
  if (isMocKData) {
    return MockData.getIncidentSourcesMockData() as IncidentSourcesData[];
  }

  const body: IncidentSourcesData[] = [];
  return body;
};

export interface AIRecommendationItem {
  case: {
    caseId: string;
    caseNumber: string;
    priority: string;
    sourceCaseId: string;
    subject: string;
    timeOfCreation: string;
    url: string;
  };
  issue: {
    issueKey: string;
    summary: string;
    url: string;
  };
  // TODO: Not migrated and only present in the mock data.
  priorityScore?: CasePriorityScore;
}

export type CasePriorityScore = {
  score: number;
  caseSentimentBucket: {
    id: number | string;
    title: string;
  };
  openTimeSecs: number;
  commentsLength: number;
  annualContractValue: number;
  parameters: ParameterCasePriorityScore[];
};

export type ParameterCasePriorityScore = {
  score: number;
  type: PriorityScoreParameter;
  scoreBucket: VisualBucket;
};

export interface AIRecommendationResponse {
  data: AIRecommendationItem[];
}

export const getAiRecommendedCases = async (startTime: Date, endTime: Date): Promise<AIRecommendationResponse> => {
  const params = qs.stringify({
    startTime: Utils.formatDateToUTC(startTime),
    endTime: Utils.formatDateToUTC(endTime)
  });
  const url = `/v1/customer/escalations/incidents?${params}`;

  return await doRequest(url, MockData.getAiRecommendedCases);
};
