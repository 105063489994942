import React from 'react';
import './Footer.scss';

const Footer = () => {
  return (
    <footer className="login-footer">
      <span>
        <a className="text-white" target="_blank" rel="noopener noreferrer" href="https://www.irisagent.com/privacy-policy">
          Privacy Policy
        </a>
      </span>
      <span className="text-white"> | ©{new Date().getFullYear()} Iris Agent Inc. All Rights Reserved.</span>
    </footer>
  );
};
export default Footer;
