import { formatDistance } from 'date-fns';
import _ from 'lodash';
import { useQuery } from 'react-query';
import IrisPortalService from '../../services/IrisPortalService';
import { CACHE_KEYS, CACHE_TIME } from '../../utils/cacheUtils';
import Utils from '../../utils/utils';
import { AgentCSATScore, AgentDistributionData } from './types';

export const formatOpenCloseCases = (numOpenCases: number | undefined, numClosedCases: number | undefined) => {
  return `${numOpenCases !== undefined ? numOpenCases : 0}/${numClosedCases !== undefined ? numClosedCases : '-'}`;
};

export const formatAvgTime = (mttrInSecs: number | undefined | null): string => {
  if (mttrInSecs === undefined || mttrInSecs === null || mttrInSecs === 0) return '-';
  return formatDistance(0, mttrInSecs * 1000);
};

export const formatCSATScore = (agentCSATScore: AgentCSATScore | undefined): [string, string] => {
  if (agentCSATScore === undefined) return ['-', ''];

  const averageScore = agentCSATScore.averageScore !== null ? `${agentCSATScore.averageScore}%` : '-';
  const surveyCount = agentCSATScore.surveyCount !== null && agentCSATScore.surveyCount !== 0 ? `${agentCSATScore.surveyCount} surveys` : '';
  return [averageScore, surveyCount];
};

export const formatAgentDistributionData = (resolvedCases: any): AgentDistributionData[] => {
  if (resolvedCases === undefined) return [];
  let formattedDistributionData: AgentDistributionData[] = [];
  formattedDistributionData = Object.keys(resolvedCases).map((dateKey) => ({
    date: dateKey,
    resolvedCases: resolvedCases[dateKey]
  }));
  return formattedDistributionData;
};

export const useDateRangeQueryForAgentData = (dateRange: Date[], currentTeamName: String = '', currentCategory = {}) => {
  return useQuery(
    [dateRange, currentTeamName, currentCategory, 'Agent'],
    () =>
      IrisPortalService.getAgentAnalyticsData(
        dateRange[0],
        dateRange[1],
        _.isEmpty(currentTeamName) ? undefined : currentTeamName,
        _.isEmpty(currentCategory) ? undefined : currentCategory
      ),
    {
      refetchOnWindowFocus: false,
      onError: (e) => {
        // Utils.showNotify(String(e));
      },
      onSuccess: () => {},
      ...CACHE_TIME.get(CACHE_KEYS.AGENT_ANALYTICS_DATA)
    }
  );
};

export const useSingleAgentQuery = (agentId: string) =>
  useQuery([CACHE_KEYS.AGENT_SINGLE_ANALYTICS_DATA, agentId], () => IrisPortalService.getSingleAgentAnalytics(agentId), {
    enabled: !!agentId,
    refetchOnWindowFocus: false,
    onError: (e) => {
      Utils.showError(String(e));
    },
    onSuccess: () => {},
    ...CACHE_TIME.get(CACHE_KEYS.AGENT_SINGLE_ANALYTICS_DATA)
  });
