import React, { FC } from 'react';
import Button from '@material-ui/core/Button';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import './MenuListComposition.scss';

export type MenuListCompositionValue = {
  label: String;
  formattedLabel: String;
  sortUpIcon?: string;
  sortDownIcon?: string;
  icon?: string;
  field?: string;
};

type MenuListCompositionProps = {
  handleClose: (newFormattedLabel: string, menuItem?: MenuListCompositionValue) => void;
  currentFormattedLabel: String | undefined;
  allValues: MenuListCompositionValue[];
  showIcons?: Boolean;
  size?: string;
};

export const MenuListComposition: FC<MenuListCompositionProps> = (props) => {
  const { handleClose, currentFormattedLabel, allValues, showIcons, size } = props;

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleCloseOut = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  const handleCloseWithOpenToggle = (newFormattedLabel: string, menuItem?: MenuListCompositionValue) => () => {
    handleClose(newFormattedLabel, menuItem);
    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }
    prevOpen.current = open;
  }, [open]);

  return (
    <div className="filter-box">
      <Button
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        style={{ textTransform: 'none', fontSize: size }}
      >
        {currentFormattedLabel}
        <KeyboardArrowDownRoundedIcon
          style={{
            color: '#5E81F4',
            marginLeft: 20
          }}
        ></KeyboardArrowDownRoundedIcon>
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{
          zIndex: 1
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              height: allValues.length < 8 ? 'auto' : '45vh',
              overflowY: 'auto'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleCloseOut}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  {allValues.map((menuItem, index) =>
                    menuItem ? (
                      <MenuItem
                        key={index}
                        onClick={handleCloseWithOpenToggle(menuItem.formattedLabel?.toString(), menuItem)}
                        className="filter-menu"
                        selected={false}
                      >
                        {showIcons && (
                          <ListItemIcon className="list-item-icon">
                            <img src={menuItem.icon} alt={menuItem.label.toString()} />
                          </ListItemIcon>
                        )}
                        <ListItemText>{menuItem.formattedLabel}</ListItemText>
                      </MenuItem>
                    ) : null
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};
