import Utils from '../../utils/utils';
import MockData from '../../utils/mockData';
import { handleError, isMocKData } from './utils';

export enum Provider {
  Intercom = 'intercom',
  Salesforce = 'salesforce',
  Zendesk = 'zendesk',
  Zoho = 'zoho'
}

interface setupOAuthParams {
  clientId?: string | '';
  clientSecret?: string | '';
  isNew: boolean;
  provider: Provider;
  subdomain?: string | '';
  isCRM?: boolean;
}

export const setupOAuth = async ({ isNew, subdomain, provider, clientId, clientSecret, isCRM }: setupOAuthParams) => {
  const userData = Utils.getUserItem();
  const tokenExpress = Utils.getObjectItem('token_express');

  const reqBody = {
    customerId: userData.customerId,
    email: Utils.getUserEmail(),
    sourceOrganizationId: userData.sourceOrganizationId,
    userId: userData.userId,
    organizationId: subdomain,
    sourceName: Utils.getOAuthSource() || '',
    hd: userData?.data?.hd || '',
    name: userData?.data?.name || '',
    isCRM: isCRM || false,
    customProvider: {
      clientID: clientId,
      clientSecret: clientSecret
    }
  };

  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${tokenExpress}`,
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(reqBody)
  } as RequestInit;

  const endpoint = isNew ? `/v1/oauth/${provider}/new` : `/v1/oauth/${provider}`;
  const response = await fetch(`${process.env.REACT_APP_APISERVER_URL}${endpoint}`, requestOptions);
  const body = await response.json();
  if (response.status !== 200) {
    const baseMessage = `${provider} token check failed with error code ${response.status}. Check subdomain and API Key values.`;
    Utils.showError(baseMessage);
  } else {
    window.open(body.redirect_url, '_self');
  }
};

export const signupIrisGpt = async (helpCenterUrl: string) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ helpCenterUrl }),
    credentials: 'include'
  } as RequestInit;
  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/signup/irisGpt`, requestOptions);

  handleError(response.status);

  if (!response.ok) {
    throw new Error(`Something went wrong while signing up`);
  }

  const body = await response.json();

  window.location.href = body.uri;
};

interface FreshdeskTokenParams {
  isNew: boolean;
  token: string;
  subdomain: string;

  adminEmail: string;
  adminName: string;
  hd: string;
  source: string;
}

export const createFreshdeskToken = async ({ isNew, token, subdomain, adminName, adminEmail, hd, source }: FreshdeskTokenParams) => {
  const tokenExpress = isNew ? Utils.getNewCustomerToken() : Utils.getObjectItem('token_express');

  const reqBody = {
    adminEmail: adminEmail,
    adminName: adminName,
    hd: hd,
    source: source,
    subdomain: subdomain,
    token: token
  };

  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${tokenExpress}`,
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(reqBody)
  } as RequestInit;

  const endpoint = isNew ? '/v1/oauth/freshdesk/token/new' : '/v1/oauth/freshdesk/token';
  const response = await fetch(`${process.env.REACT_APP_APISERVER_URL}${endpoint}`, requestOptions);
  const body = await response.json();
  if (response.status !== 200) {
    Utils.showError('Failed to validate Freshdesk credentials. Please verify your token and try again.');
  } else {
    Utils.showNotify("Freshdesk token validated successfully. You're all set!");
    window.open(body.redirect_url, '_self');
  }
};

export const createJiraToken = async (onPrem: boolean, token: string, email: string, subdomain: string) => {
  const tokenExpress = Utils.getObjectItem('token_express');

  const reqBody = {
    email: email,
    subdomain: subdomain,
    token: token,
    isOnPrem: onPrem
  };

  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${tokenExpress}`,
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(reqBody)
  } as RequestInit;

  const response = await fetch(`${process.env.REACT_APP_APISERVER_URL}/v1/oauth/jira/token`, requestOptions);
  const body = await response.json();
  if (response.status !== 200) {
    Utils.showError('Failed to validate Jira credentials. Please verify your token and try again.');
  } else {
    Utils.showNotify("Jira token validated successfully. You're all set!");
    window.open(body.redirect_url, '_self');
  }
};

export const createNewJiraToken = async (
  token: string,
  adminName: string,
  adminEmail: string,
  source: string,
  email: string,
  hd: string,
  subdomain: string
) => {
  const tokenExpress = Utils.getNewCustomerToken();

  const reqBody = {
    adminEmail: adminEmail,
    adminName: adminName,
    email: email,
    hd: hd,
    isOnPrem: false,
    source: source,
    subdomain: subdomain,
    token: token
  };

  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${tokenExpress}`,
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(reqBody)
  } as RequestInit;

  const response = await fetch(`${process.env.REACT_APP_APISERVER_URL}/v1/oauth/jira/token/new`, requestOptions);
  const body = await response.json();
  if (response.status !== 200) {
    Utils.showError('Failed to validate Jira credentials. Please verify your token and try again.');
  } else {
    Utils.showNotify("Jira token validated successfully. You're all set!");
    const jwtToken = document.cookie
      .split('; ')
      .find((row) => row.startsWith('jwt='))
      ?.split('=')[1];

    const redirectUrl = new URL(body.redirect_url);
    if (jwtToken) redirectUrl.searchParams.append('jwt', jwtToken);

    window.open(redirectUrl, '_self');
  }
};

export const verifySalesforceCrmToken = async () => {
  if (Utils.isDemo()) {
    return MockData.isGenericTokenAvailableMockData();
  }

  const tokenExpress = Utils.getObjectItem('token_express');
  const user = Utils.getObjectItem('user');

  const params = new URLSearchParams({
    customerId: user.customerId
  });
  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/validate/salesforce_crm/token?${params}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${tokenExpress}`
    }
  });

  handleError(response.status);

  if (!response.ok) {
    throw new Error('Some error occurred');
  }

  const body: { isToken: boolean } = await response.json();
  return body;
};

export const isJiraTokenAvailable = async () => {
  let user = Utils.getObjectItem('user');
  let token_express = Utils.getObjectItem('token_express');
  if (isMocKData) {
    return MockData.getSaveTokenMockData();
  }
  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/validate/jira/token?customerId=${user.customerId}`, {
    headers: new Headers({
      Authorization: `Bearer ${token_express}`
    })
  });
  handleError(response.status);
  const body = await response.json();
  if (response.status !== 200) throw Error(body.message);
  return body;
};
