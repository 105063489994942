/** @jsx jsx */
import { ClassNames, jsx } from '@emotion/react';
import React, { forwardRef } from 'react';

type FontVariant = 'p0' | 'p1' | 'p2' | 'p3' | 'p4' | 'p5';

type FontWeight = 'regular' | 'medium' | 'semi-bold' | 'bold' | 'semi-bolder';

type FontAlign = 'left' | 'right' | 'center';

type FontColor = 'lighter' | 'light' | 'gray' | 'light-dark' | 'dark' | 'green' | 'red';

export interface TextProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {
  variant?: FontVariant;
  weight?: FontWeight;
  color?: FontColor;
  align?: FontAlign;
  maxWidth?: string;
  lineClamp?: number;
  textOverflow?: boolean;
}

const Text = forwardRef<HTMLSpanElement, TextProps>(({ lineClamp, textOverflow = false, ...spanProps }, ref) => {
  const { variant = 'p1', weight = 'regular', color = 'light', align = 'left', maxWidth, className } = spanProps;

  return (
    <ClassNames>
      {({ css, cx }) => (
        <span
          ref={ref}
          {...spanProps}
          className={cx(
            className,
            css`
              --p0: 12px;
              --p1: 14px;
              --p2: 16px;
              --p3: 18px;
              --p4: 24px;
              --p5: 32px;

              --p0-line-height: 14px;
              --p1-line-height: 21px;
              --p2-line-height: 24px;
              --p3-line-height: 27px;
              --p4-line-height: 31px;
              --p5-line-height: 38px;

              --regular-font-weight: 400;
              --medium-font-weight: 500;
              --semi-bold-font-weight: 600;
              --semi-bolder-font-weight: 700;
              --bold-font-weight: 800;

              --lighter-font-color: rgba(56, 56, 56, 0.53);
              --light-font-color: rgba(56, 56, 56, 0.83);
              --gray-font-color: #8181a5;
              --light-dark-font-color: #4f4f4f;
              --dark-font-color: #383838;
              --green-font-color: #6fcf97;
              --red-font-color: #ff808b;

              font-size: var(--${variant});
              font-weight: var(--${weight}-font-weight);
              line-height: var(--${variant}-line-height);
              color: var(--${color}-font-color);
              text-align: ${align};
            `,
            maxWidth
              ? css`
                  max-width: ${maxWidth};
                  text-overflow: ellipsis;
                  overflow-x: hidden;
                  white-space: nowrap;
                  display: inline-block;
                `
              : '',
            lineClamp
              ? css`
                  display: block;
                  line-clamp: ${lineClamp};
                  text-overflow: ellipsis;
                  overflow: hidden;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: ${lineClamp};
                `
              : '',
            textOverflow
              ? css`
                  text-overflow: ellipsis;
                  flex-wrap: nowrap;
                  white-space: nowrap;
                  overflow-x: hidden;
                `
              : ''
          )}
        />
      )}
    </ClassNames>
  );
});

export { Text };
