import React, { useEffect, useState } from 'react';
import './IncidentsDetails.scss';
import IrisPortalService from '../../../services/IrisPortalService';
import BadgeInformation from '../badge-information/BadgeInformation';
import ReportingService from '../reporting-service/ReportingService';
import NegativeScoreTable from '../negative-score-table/NegativeScoreTable';
import { Paper } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { PageContainer } from '../../../components/common/blocks/PageContainer';
import { Stack } from '../../../components/common';
import { scrollToTop } from '../../../utils/scrollToTop';
import { JiraIssue, JiraIssueCase } from '../../../services/IrisPortalService/jira';

const IncidentsDetails: React.FC = () => {
  const [detailsData, setDetailsData] = useState<JiraIssue | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const { incidentId } = useParams<{ incidentId: string }>();
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      scrollToTop();
      setIsLoading(true);

      try {
        const response = await IrisPortalService.getJIRAIssueDetails(incidentId);
        setDetailsData(response);
      } catch (e) {
        console.log('Incident Details Page Error: ', e);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [incidentId]);

  const goBackToIncidents = () => {
    history.goBack();
  };

  let cases = detailsData?.cases || [];
  cases = cases.map((obj: JiraIssueCase) => ({ ...obj }));
  const revenueData = detailsData?.issue?.totalRevenue || 0;

  return (
    <div className="incidents-details-container">
      {isLoading && (
        <div>
          <img className="loading" alt="" width="55px" src="/images/spinner.gif" />
        </div>
      )}
      {detailsData && !isLoading ? (
        <>
          <Paper style={{ padding: '40px' }}>
            <Stack direction="vertical">
              <Stack align="center" justify="space-between">
                <ReportingService data={detailsData} goBack={goBackToIncidents} />
              </Stack>
              <BadgeInformation data={cases} revenueData={revenueData} />
            </Stack>
          </Paper>
          <PageContainer className="tab-container">
            <Stack direction="vertical" gap={20}>
              <NegativeScoreTable data={cases} />
            </Stack>
          </PageContainer>
        </>
      ) : null}
    </div>
  );
};

export default IncidentsDetails;
