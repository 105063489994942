import Utils from '../../utils/utils';
import _ from 'lodash';
import moment from 'moment';

export const isMocKData = process.env.REACT_APP_MOCKDATA === 'true';

export const handleError = (codeStatus: number) => {
  let errorMessage;
  switch (codeStatus) {
    case 401:
    case 403:
      logout();
      break;
    case 404:
      errorMessage = 'Not found!';
      Utils.showError(errorMessage);
      break;
    default:
      break;
  }
};

export const logout = _.debounce(
  (showMessage = true) => {
    if (showMessage) {
      let errorMessage = 'Not authorized!';
      Utils.showNotify(errorMessage);
    }

    window.location.href = '/';
    Utils.clearStorage();
    // Saving the current path in the local storage
    // to maintain the user context after login
    Utils.saveObjectItem('meta', {
      next: window.location.pathname + window.location.search
    });
  },
  moment.duration(1, 'second').asMilliseconds(),
  {
    maxWait: 0
  }
);

export const doRequest = async (
  endpoint: string,
  mockDataFn: () => any,
  action: string = 'GET',
  body: any = undefined,
  additionalHeaders?: Record<string, string>
) => {
  const tokenExpress = Utils.getObjectItem('token_express');
  if (isMocKData) {
    return mockDataFn();
  }

  let request = {
    method: action,
    headers: new Headers({
      Authorization: `Bearer ${tokenExpress}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...additionalHeaders
    }),
    credentials: 'include',
    ...(body && { body: JSON.stringify(body) })
  } as RequestInit;

  const response = await fetch(`${process.env.REACT_APP_APISERVER_URL}${endpoint}`, request);

  handleError(response.status);
  if (response.status === 403) {
    return;
  }
  if (!response.ok) {
    throw new Error(`Unhandled error status code!: ${response.status} for ${endpoint}`);
  }

  return response.json();
};

export const doMultipartRequest = async (endpoint: string, formData: FormData): Promise<Response | undefined> => {
  const tokenExpress = Utils.getObjectItem('token_express');
  let request = {
    method: 'POST',
    headers: new Headers({
      Authorization: `Bearer ${tokenExpress}`,
      Accept: 'application/json'
    }),
    credentials: 'include',
    body: formData
  } as RequestInit;

  return await fetch(`${process.env.REACT_APP_APISERVER_URL}${endpoint}`, request);
};

interface CacheEntry {
  data: any;
  timestamp: number;
}

interface CacheConfig {
  maxSize: number;
  timeoutMs: number;
}

export class CacheManager {
  private cache: Map<string, CacheEntry>;
  private config: CacheConfig;

  constructor(config: CacheConfig) {
    this.cache = new Map();
    this.config = config;
  }

  get(key: string): any | null {
    const entry = this.cache.get(key);
    if (!entry) return null;

    const now = Date.now();
    if (now - entry.timestamp > this.config.timeoutMs) {
      this.cache.delete(key);
      return null;
    }
    return entry.data;
  }

  set(key: string, data: any): void {
    if (this.cache.size >= this.config.maxSize) {
      const oldestKey = this.cache.keys().next().value;
      if (oldestKey !== undefined) {
        this.cache.delete(oldestKey);
      }
    }
    this.cache.set(key, { data, timestamp: Date.now() });
  }
}

const requestCache = new CacheManager({
  maxSize: 100,
  timeoutMs: 5 * 60 * 1000 // 5 minutes
});

export async function doRequestWithCache(endpoint: string, mockDataFn: () => any, body?: any): Promise<any> {
  const cached = requestCache.get(endpoint);
  if (cached) return cached;

  const response = await doRequest(endpoint, mockDataFn, 'GET', body);
  requestCache.set(endpoint, response);
  return response;
}
