import React, { ReactElement, useEffect, useState } from 'react';
import { Checkbox, FormControl, FormControlLabel } from '@material-ui/core';
import { TextArea } from '../../../../../components/TextArea';
import { RulesRowManager } from '../../../utils';
import { ActionValue, HandleRowComponentType, Option } from '../../../types';
import { ReactComponent as UserPlus } from '../../../../../assets/userPlus.svg';
import { ActionApplyMacro } from '../../../constants';
import '../styles.scss';
import { GetMacroNameByID, MacroDetails } from '../../../../../services/IrisPortalService/triggers';
import { debounce } from 'lodash';

export const useMacroDetails = (macroId: string | null) => {
  const [macroDetails, setMacroDetails] = useState<MacroDetails | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchMacroDetails = debounce(async (id: string) => {
    try {
      setIsLoading(true);
      setError(null);
      const result = await GetMacroNameByID(id);
      setMacroDetails(result);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to fetch macro details');
    } finally {
      setIsLoading(false);
    }
  }, 300);

  useEffect(() => {
    if (macroId) {
      fetchMacroDetails(macroId);
    } else {
      setMacroDetails(null);
    }

    return () => {
      fetchMacroDetails.cancel();
    };
  }, [macroId]);

  return { macroDetails, isLoading, error };
};

export class ApplyMacroRowManager extends RulesRowManager {
  constructor(options: Option[], actionValue: any, actionOperator: any, type: string) {
    actionValue =
      typeof actionValue === 'string'
        ? {
            content: actionValue,
            deferIfAgentReplied: true
          }
        : actionValue || { content: '', deferIfAgentReplied: true };
    super(options, actionValue, actionOperator, type);
    this.action.type = ActionApplyMacro;
  }

  setDefaultActionValue(): void {
    this.action.value = {
      content: '',
      deferIfAgentReplied: true
    } as ActionValue;
  }

  createItems(handleRowComponent: HandleRowComponentType): ReactElement {
    if (this.action.value === undefined) this.setDefaultActionValue();

    const { macroDetails, isLoading, error } = useMacroDetails(this.action.value?.content || null);

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }} className={'align-items-center relative'}>
          <UserPlus />
          <p className="title">Apply Macro</p>
          <FormControlLabel
            className={'absolute right-0 title'}
            control={
              <Checkbox
                color="default"
                name={'Do not apply macro in case a reply is present'}
                size="small"
                onChange={(e, checked) => {
                  this.action.value.deferIfAgentReplied = checked;
                  handleRowComponent(this.action);
                }}
                defaultChecked={this.action.value.deferIfAgentReplied}
              />
            }
            label={'Do not apply macro in case a reply is present'}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 8, gap: 16, alignItems: 'center' }}>
          <p className="operator-text">Enter Macro ID</p>
          <div className={'input-selector'}>
            <FormControl>
              <TextArea
                onChange={(e) => {
                  if (this.action.value) {
                    this.action.value.content = e.target.value;
                  }
                  handleRowComponent(this.action);
                }}
                placeholder="Enter Macro ID"
                defaultValue={this.action.value?.content}
                rowsMin={1}
                className="text-area"
              />
            </FormControl>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', gap: 24, alignItems: 'center' }}>
            {isLoading ? (
              <p>Loading...</p>
            ) : error ? (
              <p>Error: {error}</p>
            ) : (
              <>
                <div>
                  <span className="operator-text">Label: </span>
                  <span>{macroDetails?.name || 'N/A'}</span>
                </div>
                <div>
                  <span className="operator-text">URL: </span>
                  <a href={macroDetails?.url || '#'} target="_blank" rel="noopener noreferrer" style={{ color: '#0066cc', textDecoration: 'underline' }}>
                    {macroDetails?.url || 'N/A'}
                  </a>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}
