/** @jsx jsx */
import { ClassNames, jsx } from '@emotion/react';
import React from 'react';

type SidebarProps = {
  sideWidth?: string | null;
  contentMin?: string;
  space?: string;
  noStretch?: boolean;
  side?: 'right' | 'left';
} & { children: React.ReactNode } & React.HTMLAttributes<HTMLDivElement>;

const Sidebar = React.forwardRef<HTMLDivElement, SidebarProps>((props, ref) => {
  const { sideWidth, contentMin, space, noStretch, side, children, className, ...htmlProps } = props;
  if (React.Children.count(children) !== 2) {
    throw new Error('Sidebar: Should have exactly two children ' + React.Children.count(children));
  }
  const adjustedSpace = props.space === '0' ? '0px' : props.space;
  return (
    <ClassNames>
      {({ css, cx }) => (
        <div
          {...htmlProps}
          ref={ref}
          className={cx(
            css`
              overflow: hidden;

              & > * {
                display: flex;
                flex-wrap: wrap;
                margin: calc(${adjustedSpace} / 2 * -1);
                height: 100%;
                ${props.noStretch ? 'align-items: flex-start;' : 'align-items: stretch;'}
              }

              & > * > * {
                flex-grow: 1;
                margin: calc(${adjustedSpace} / 2);
                ${props.sideWidth ? `flex-basis: ${props.sideWidth};` : ''}
              }

              & > * > ${props.side === 'left' ? ':last-child' : ':first-child'} {
                flex-basis: 0;
                flex-grow: 999;
                min-width: calc(${props.contentMin || '50%'} - ${adjustedSpace});
              }
            `,
            className
          )}
        >
          <div>{children}</div>
        </div>
      )}
    </ClassNames>
  );
});

Sidebar.defaultProps = {
  sideWidth: null,
  contentMin: '50%',
  space: '0',
  noStretch: false,
  side: 'left'
};

export { Sidebar };
