import { RouteComponentProps } from 'react-router';
import React from 'react';
import { HighPriorityScoreCases } from '../customerHealth/components/HighPriorityScoreCases';

import { CustomerAccountHealthScoreTable } from '../customerHealth/components/CustomerAccountHealthScoreTable';
import { PrioritizedEngineeringEscalations } from '../../components/PrioritizedEngineeringEscalations';
import { Page } from '../Page';
import { useCustomerHealthData } from '../customerHealth/hooks/useCustomerHealthData';

export const NeedsAttention: React.FC<RouteComponentProps> = () => {
  const { dataCustomerHealth } = useCustomerHealthData();

  return (
    <React.Fragment>
      <Page>
        <HighPriorityScoreCases />

        {dataCustomerHealth && <CustomerAccountHealthScoreTable data={dataCustomerHealth.customerHealth.customerLowestHealthScores} />}

        <PrioritizedEngineeringEscalations title={'Engineering escalations that need attention'} />
      </Page>
    </React.Fragment>
  );
};
