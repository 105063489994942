import React, { Component } from 'react';
import './ManageUsers.scss';
import IrisPortalService from '../../services/IrisPortalService';
import AlertComponent from '../../components/alert-component/AlertComponent';
import Utils from '../../utils/utils';
import { Button, Input } from '@material-ui/core';
import { RouteComponentProps } from 'react-router';
import { PageContainer } from '../../components/common/blocks/PageContainer';
import { Paper } from '../../components/home/Paper';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import { ManageUsersTable } from '../categories/components/ManageUsersTable';
import { Loader } from '../../components/common/blocks/Loader';
import { CACHE_KEYS, CACHE_TIME } from '../../utils/cacheUtils';
import { queryClient } from '../../utils/queryCache';
import { scrollToTop } from '../../utils/scrollToTop';

class ManageUsers extends Component<RouteComponentProps, any> {
  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      showNotification: false,
      inserted: false,
      notificationMessage: '',
      emailError: '',
      openModal: false,
      dataInvitedUsers: [],
      isLoadingList: true
    };
  }

  async addUser() {
    this.closeAlert(); // To hide previous informative message
    let emailError = Utils.validateEmail(this.state.email);
    if (emailError) {
      this.setState({
        showNotification: true,
        inserted: false,
        notificationMessage: emailError
      });
      return;
    }

    let result = await IrisPortalService.saveIrisPortalUser(this.state.email);
    if (result.inserted) {
      this.setState({ openModal: true });
    } else {
      setTimeout(() => {
        this.setState({
          showNotification: true,
          inserted: result.inserted,
          notificationMessage: Utils.notificationUserMessage(result.messageCode)
        });
      }, 300); // to show informative message with a little delay
    }
  }

  async getSourcesDataInvitedUsers() {
    const invitedUsersList = await queryClient.fetchQuery([CACHE_KEYS.MANAGE_USERS], () => IrisPortalService.getIrisPortalInvitedUsers(), {
      ...CACHE_TIME[CACHE_KEYS.MANAGE_USERS]
    });
    const { irisPortalUsers = [] } = invitedUsersList;
    const userCurrent = Utils.getObjectItem('user');
    const irisPortalUsersFilter = irisPortalUsers.filter((user: any) => user.email !== userCurrent.email);
    this.setState({
      isLoadingList: false,
      dataInvitedUsers: irisPortalUsersFilter
    });
  }

  async componentDidMount() {
    scrollToTop();
    await this.getSourcesDataInvitedUsers();
  }

  closeAlert = () => {
    this.setState({
      showNotification: false,
      inserted: false,
      notificationMessage: ''
    });
  };

  handleChange = (e: any) => {
    this.setState({ email: e.target.value, emailError: '' });
  };

  onEnterPress = (e: any) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      this.addUser();
    }
  };

  handleClose = () => {
    this.setState({ openModal: false });
  };

  render() {
    const { showNotification, inserted, notificationMessage } = this.state;

    return (
      <PageContainer className="manage-user-container">
        {showNotification && <AlertComponent alertType={inserted ? 'success' : 'error'} message={notificationMessage} onClick={this.closeAlert} />}
        <Paper className="manage-user-img">
          <div style={{ display: 'inline-grid', width: '100%' }}>
            <div className="manage-user-title">Enter email and Invite users</div>
            <div className="manage-user-subtitle">Add user to this dashboard</div>
            <div className="manage-user-actions">
              <div className="manage-user-input-container">
                <div style={{ backgroundColor: 'white' }}>
                  <Input className="manage-user-input" placeholder="Enter email address" onKeyDown={this.onEnterPress} onChange={this.handleChange} />
                </div>
              </div>
              <div className="manage-user-button-container">
                <Button
                  className="manage-user-input"
                  onClick={async () => {
                    this.addUser();
                  }}
                >
                  Invite User
                </Button>
              </div>
            </div>
          </div>
        </Paper>
        {/* ----------------------------Table Container----------------------------- */}
        <Paper>
          {this.state.isLoadingList ? (
            <Loader center />
          ) : (
            <>
              <ManageUsersTable title="Users added to the Dashboard" cases={this.state.dataInvitedUsers} />
            </>
          )}
        </Paper>
        <div>
          <Modal open={this.state.openModal} onClose={this.handleClose} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
            <div
              style={{
                marginTop: '15%',
                marginLeft: '35%',
                width: '28.5%',
                borderRadius: 12,
                backgroundColor: 'white'
              }}
            >
              <CloseIcon
                style={{
                  position: 'absolute',
                  marginTop: '1%',
                  marginLeft: '26%',
                  color: 'white'
                }}
                onClick={this.handleClose}
              />
              <img alt="loader" src="/images/messageManageUser.png" style={{ width: '100%' }} />
              <div
                style={{
                  textAlign: 'center',
                  fontFamily: 'DM Sans',
                  fontWeight: 700,
                  fontStyle: 'normal',
                  fontSize: 20,
                  lineHeight: 1.5,
                  paddingTop: 5,
                  paddingBottom: 5
                }}
              >
                <div>
                  You have invited "<span style={{ color: '#5575DE' }}>{this.state.email}</span>"
                </div>
                <div>to the platform.</div>
              </div>
            </div>
          </Modal>
        </div>
      </PageContainer>
    );
  }
}

export default ManageUsers;
