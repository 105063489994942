import React from 'react';
import { DetectedIncidentsSettingsTableDrawer } from './DetectedIncidentsSettingsTableDrawer';
import './DetectedIncidentsSettingsTableDrawerWithHeaderFooter.css';
import classNames from 'classnames';

export interface DetectedIncidentsSettingsTableDrawerWithHeaderFooterClasses {
  root?: string;
}

export interface DetectedIncidentsSettingsTableDrawerWithHeaderFooterProps {
  headerComponent: React.ReactElement;
  footerComponent: React.ReactElement;
  showDrawer: boolean;
  classes?: DetectedIncidentsSettingsTableDrawerWithHeaderFooterClasses;
}

export const DetectedIncidentsSettingsTableDrawerWithHeaderFooter: React.FC<DetectedIncidentsSettingsTableDrawerWithHeaderFooterProps> = (props) => {
  const { classes: { root: rootClasses } = {} } = props;

  const rootClassName = classNames('header-footer-container', rootClasses);

  return (
    <React.Fragment>
      <DetectedIncidentsSettingsTableDrawer showDrawer={props.showDrawer}>
        <div className={rootClassName}>
          {props.headerComponent}
          {props.children}
          {props.footerComponent}
        </div>
      </DetectedIncidentsSettingsTableDrawer>
    </React.Fragment>
  );
};
