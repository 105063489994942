import React from 'react';

import type { TextareaAutosizeProps } from '@material-ui/core';
import { TextareaAutosize } from '@material-ui/core';

import './styles.scss';

interface ITextAreaProps extends TextareaAutosizeProps {
  placeholder: string;
  size?: 'sm' | 'md' | 'lg';
}

const textAreaSizes = {
  sm: 128,
  md: 288,
  lg: 512
};

export const TextArea: React.FC<ITextAreaProps> = ({ placeholder, rowsMin = 4, rowsMax = 5, size = 'md', style = {}, ...rest }) => {
  return (
    <TextareaAutosize
      className="app-textarea-component"
      placeholder={placeholder}
      rowsMin={rowsMin}
      rowsMax={rowsMax}
      style={{ width: textAreaSizes[size], ...style }}
      {...rest}
    ></TextareaAutosize>
  );
};
