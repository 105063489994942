import MockData from '../../utils/mockData';
import Utils from '../../utils/utils';
import { doRequest } from './utils';

export type ImportantSupportMetrics = {
  metrics: ImportantSupportMetric[];
};

export type ImportantSupportMetric = {
  startTime: Date;
  score: number;
  totalCasesWithScore: number;
  totalScore: number;
};

export const getSentimentMetrics = async (startTime: Date, endTime: Date): Promise<ImportantSupportMetrics> => {
  const url = `/v1/customer/health/sentiment?startTime=${Utils.formatDateToUTC(startTime)}&endTime=${Utils.formatDateToUTC(endTime)}`;
  return await doRequest(url, MockData.getSentimentMetricData);
};

export const getCSATMetrics = async (startTime: Date, endTime: Date): Promise<ImportantSupportMetrics> => {
  const url = `/v1/customer/health/csat?startTime=${Utils.formatDateToUTC(startTime)}&endTime=${Utils.formatDateToUTC(endTime)}`;
  return await doRequest(url, MockData.getCSATMetrics);
};

export interface RevenueImpactGraphItem {
  startTime: string;
  accounts: number;
  acv: number;
}

export interface RevenueImpactCumulative {
  accounts: number;
  totalAcv: number;
}

export interface RevenueImpact2 {
  impact: {
    cumulative: RevenueImpactCumulative;
    graph: RevenueImpactGraphItem[];
  };
}

export const getOverallRevenueImpact = async (startTime: Date, endTime: Date): Promise<RevenueImpact2> => {
  const url = `/v1/customer/health/impact/revenue/overall?startTime=${Utils.formatDateToUTC(startTime)}&endTime=${Utils.formatDateToUTC(endTime)}`;
  return await doRequest(url, MockData.getRevenueImpactOverall);
};

export interface CategoryItem {
  item: string;
  acv: number;
}

export interface CategoryItemRange {
  startTime: string;
  endTime: string;
  categories: CategoryItem[];
}

export interface RevenueImpactCategories {
  impact: {
    cumulative: CategoryItem[];
    graph: CategoryItemRange[];
  };
}

export const getCategoryRevenueImpact = async (startTime: Date, endTime: Date): Promise<RevenueImpactCategories> => {
  const url = `/v1/customer/health/impact/revenue/category?startTime=${Utils.formatDateToUTC(startTime)}&endTime=${Utils.formatDateToUTC(endTime)}`;
  return await doRequest(url, MockData.getRevenueImpactMockData);
};
