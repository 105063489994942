import { createStyles, TableCell as MUITableCell, TableRow as MUITableRow, withStyles } from '@material-ui/core';

export const TableCell = withStyles(
  createStyles({
    root: {
      borderBottom: 'none'
    }
  })
)(MUITableCell);
export const TableRow = withStyles(
  createStyles({
    root: {
      '&:hover': {
        background: 'rgba(48, 170, 188, 0.05)'
      }
    }
  })
)(MUITableRow);
