import React from 'react';

import { Text } from '../../../../../components/common/blocks/Text';

import './styles.scss';

interface IRuleTriggersTableHeadButtonProps {
  onClick(): void;
  isSelected: boolean;
  subTitle: string;
  title: string;
}

export const RuleTriggersTableHeadButton: React.FC<IRuleTriggersTableHeadButtonProps> = ({ onClick, isSelected, subTitle, title }) => (
  <div className={['rule-triggers-table-head-button-container', isSelected && 'active'].filter(Boolean).join(' ')}>
    <button disabled={isSelected} onClick={onClick}>
      <Text className="rule-triggers-table-head-button-title" variant="p4" weight="semi-bolder">
        {title}
      </Text>
      <Text className="rule-triggers-table-head-button-subtitle">{subTitle}</Text>
    </button>
  </div>
);
