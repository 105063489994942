import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';

import './styles.scss';

export interface IContextMenuRef {
  openContextMenu: (x: number, y: number) => void;
}

interface IContextMenuProps {
  children: JSX.Element | Array<JSX.Element>;
  itemRef: React.RefObject<HTMLElement>;
  className?: string;
}

const ContextMenuComponent: React.ForwardRefRenderFunction<IContextMenuRef, IContextMenuProps> = ({ itemRef, children, className = '' }, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [clickCoordinates, setClickCoordinates] = useState({ x: 0, y: 0 });

  const openContextMenu = useCallback((x: number, y: number) => {
    setClickCoordinates({ x, y });

    setIsOpen(true);
  }, []);

  const handleContextMenu = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();

      openContextMenu(event.clientX, event.clientY);
    },
    [openContextMenu]
  );

  const handleOnClick = useCallback(() => {
    if (isOpen) {
      setIsOpen(false);

      setClickCoordinates({ x: 0, y: 0 });
    }
  }, [isOpen]);

  useImperativeHandle(
    ref,
    () => ({
      openContextMenu
    }),
    [openContextMenu]
  );

  useEffect(() => {
    document.addEventListener('click', handleOnClick);
    itemRef.current?.addEventListener('contextmenu', handleContextMenu);

    return () => {
      document.removeEventListener('click', handleOnClick);
    };
  }, [handleContextMenu, handleOnClick, itemRef]);

  return (
    <>
      {isOpen && (
        <span
          className={['app-context-menu', className].filter(Boolean).join(' ')}
          style={{
            left: clickCoordinates.x,
            top: clickCoordinates.y,
            zIndex: 9999
          }}
        >
          {children}
        </span>
      )}
    </>
  );
};

export const ContextMenu = forwardRef(ContextMenuComponent);
export * from './ContextMenuItem';
// export * from './ContextMenuItemSeparator'
