import React, { FC } from 'react';
import { Stack, Text, useTableSorters } from '../../../components/common';
import { Table, TableBody, TableCell, TableContainer, TableRow, Tooltip } from '@material-ui/core';
import TableHeadSorting from '../../../components/table-head-sorting/TableHeadSorting';

export const TasksTable: FC<{ tasks: any[] }> = (props) => {
  const tasks = props.tasks;
  const { onSort, filterData, sortColumn, sortOrder } = useTableSorters({
    turnOffPagination: true
  });

  return (
    // <TablePaper>
    <Stack direction="vertical" gap={20} style={{ marginTop: '16px' }}>
      <TableContainer style={{ maxHeight: 400 }}>
        <Table stickyHeader>
          <TableHeadSorting order={sortOrder} orderByColumn={sortColumn} onRequestSort={(_, column) => onSort(column)} headCells={TableHeaderCells} />
          <TableBody>
            {tasks.length === 0 && (
              <TableRow>
                <TableCell colSpan={100} align="center">
                  <Text>No Data</Text>
                </TableCell>
              </TableRow>
            )}
            {filterData(tasks).map((task) => {
              return (
                <TableRow>
                  {/* Issue ID */}
                  <TableCell>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          marginLeft: '24px',
                          width: '-webkit-fill-available'
                        }}
                      >
                        <p
                          style={{
                            color: 'rgb(94, 129, 244)',
                            fontSize: '16px',
                            fontWeight: 700
                          }}
                        >
                          {task.taskId}
                        </p>

                        <Tooltip title={task.description} placement="top">
                          <Text color="dark" variant="p1" lineClamp={2}>
                            {task.description}
                          </Text>
                        </Tooltip>
                      </div>
                    </div>
                  </TableCell>

                  {/* Subject */}
                  <TableCell variant="body">
                    <Text variant="p2" weight="regular" color="dark" lineClamp={4}>
                      {task.status}
                    </Text>
                  </TableCell>

                  {/* Sentiment */}
                  <TableCell variant="body">
                    <Text variant="p2" weight="regular" color="dark" lineClamp={4}>
                      {task.timeOfCreation}
                      {/* {format(new Date(task.timeOfCreation), 'MMM dd, yyyy')} */}
                    </Text>
                  </TableCell>

                  <TableCell variant="body">
                    <Text variant="p2" weight="regular" color="dark" lineClamp={4}>
                      {task.owner}
                    </Text>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
    // </TablePaper>
  );
};
const TableHeaderCells = [
  {
    id: 'taskId',
    label: 'Task ID & Description',
    width: '45%',
    hasFilter: true
  },
  { id: 'status', label: 'status', width: '15%', hasFilter: true },
  {
    id: 'timeOfCreation',
    label: 'Created Date',
    width: '15%',
    hasFilter: true
  },
  { id: 'owner', label: 'Owner', width: '25%', hasFilter: true }
];
