import React, { FC, useCallback, useState } from 'react';
import './AgentDistributionChart.scss';
import { Heading, Stack, Text } from '../../../components/common';
import { Cell, Pie, PieChart, ResponsiveContainer, Sector, Tooltip, TooltipProps } from 'recharts';
import { makeStyles } from '@material-ui/core';
import { useSingleAgentQuery } from '../utils';
import { Loader } from '../../../components/common/blocks/Loader';

export const AgentDistributionChart: FC<{
  agentId: string;
  waitFor: any;
}> = ({ agentId, waitFor }) => {
  const useStyles = makeStyles(() => ({
    box: {
      width: 147,
      height: 42,
      color: '#8181A5',
      fontWeight: 'bold',
      fontSize: '14px',
      lineHeight: '17px',
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center',
      flexDirection: 'column'
    }
  }));
  const classes = useStyles();

  const { data: agentSingleAnalyticsData } = useSingleAgentQuery(agentId);

  const data = agentSingleAnalyticsData !== undefined ? agentSingleAnalyticsData.topCategoriesToFrequencyMap : [];

  const [activeIndex, setActiveIndex] = useState(0);
  let totalCases = 0;

  const dataFormat = Object.keys(data)
    .map((distributionData) => {
      totalCases = totalCases + data[distributionData];
      return {
        name: distributionData,
        value: data[distributionData]
      };
    })
    .sort((caseItem1, caseItem2) => caseItem2.value - caseItem1.value);

  const COLORS = ['#EF5DA8', '#F2C94C', '#9F72FE', '#F2994A', '#219653', '#2E5BFF'];

  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );

  const RenderRows = () => {
    return (
      <div className="grid-container">
        {dataFormat.map((agent, index) => (
          <div className="grid-item" onMouseEnter={() => setActiveIndex(index)}>
            <div
              style={{
                width: 12,
                height: 12,
                borderRadius: '50%',
                backgroundColor: COLORS[index % COLORS.length]
              }}
            />
            <span style={{ marginLeft: 5 }}>
              {agent.name} ({agent.value})
            </span>
          </div>
        ))}
      </div>
    );
  };

  const renderActiveShape = (props: any) => {
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } = props;

    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill="#1C1D21" fontSize="32px" fontWeight={900} fontFamily="DM Sans">
          {totalCases}
        </text>
        <text x={cx} y={cy} dy={36} textAnchor="middle" fill="rgba(160, 160, 160, 1)" fontSize="20px" fontWeight={400}>
          Cases
        </text>
        <Sector cx={cx} cy={cy} innerRadius={innerRadius} outerRadius={outerRadius + 5} startAngle={startAngle} endAngle={endAngle} fill={fill} />
      </g>
    );
  };
  return (
    <div style={{ padding: '30px 0' }}>
      {agentSingleAnalyticsData === undefined || waitFor === undefined ? (
        <Loader center />
      ) : (
        Object.keys(dataFormat).length > 0 && (
          <Stack direction="vertical">
            <Stack
              direction="horizontal"
              style={{
                justifyContent: 'space-between'
              }}
            >
              <Heading> Case resolved by category </Heading>
              <div className={classes.box}>Last 1 month Data</div>
            </Stack>

            <Stack direction="horizontal">
              <ResponsiveContainer height={350} width="35%">
                <PieChart>
                  <Pie
                    activeIndex={activeIndex}
                    activeShape={renderActiveShape}
                    data={dataFormat}
                    cx="50%"
                    cy="50%"
                    innerRadius={80}
                    outerRadius={120}
                    fill="#8884d8"
                    dataKey="value"
                    onMouseEnter={onPieEnter}
                  >
                    {dataFormat.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip content={<CustomTooltip />} />
                </PieChart>
              </ResponsiveContainer>

              <Stack direction="vertical" gap={2} style={{ width: '65%', marginTop: '8%' }}>
                <div className="title-chart-items">CATEGORY DISTRIBUTION</div>
                <div style={{ display: 'flex', marginTop: '3%' }}>
                  <RenderRows />
                </div>
              </Stack>
            </Stack>
          </Stack>
        )
      )}
    </div>
  );
};

const CustomTooltip: FC<TooltipProps<number, string>> = (props) => {
  const { active, payload } = props;
  if (active && payload && payload.length) {
    const categoryName = payload[0].name;
    const casesCount = payload[0].value;
    const color = payload[0].payload.fill;
    return (
      <Stack
        style={{
          backgroundColor: `white`,
          padding: '5px 10px',
          borderRadius: 100,
          border: `1px solid ${color}`
        }}
      >
        <Text variant="p0" weight="semi-bold" style={{ color }}>
          {categoryName}: {casesCount}
        </Text>
      </Stack>
    );
  }
  return null;
};
