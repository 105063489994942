import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Page } from '../Page';
import Constants from '../../utils/constants';
import { DetectedIncidentsTable } from '../incidents/incident-list/DetectedIncidentsTable';
import { HighPriorityScoreCases } from '../customerHealth/components/HighPriorityScoreCases';
import { PrioritizedEngineeringEscalations } from '../../components/PrioritizedEngineeringEscalations';
import '../incidents/incident-list/RecentIncidents.scss';
import '../incidents/max-support-impact/MaxSupportImpactIncidents.scss';
import { isMocKData } from '../../services/IrisPortalService/utils';

export const Escalations: React.FC<RouteComponentProps> = () => {
  return (
    <React.Fragment>
      <Page
        classes={{
          root: 'tab-container'
        }}
      >
        <HighPriorityScoreCases />

        <PrioritizedEngineeringEscalations />

        {isMocKData && (
          <div style={{ marginTop: '2%' }} id={Constants.incidents_sections.trending_incidents}>
            <DetectedIncidentsTable />
          </div>
        )}
      </Page>
    </React.Fragment>
  );
};
