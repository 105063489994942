import { Paper } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import IrisPortalService from '../../../../services/IrisPortalService';
import { CACHE_KEYS, CACHE_TIME } from '../../../../utils/cacheUtils';
import Utils from '../../../../utils/utils';
import { Stack } from '../../../common';
import { Loader } from '../../../common/blocks/Loader';
import './RevenueImpacted.scss';
import '../Graphs/Graph.scss';
import LegendItem from '../Graphs/LegendItem';
import { StackedBarGraph } from '../Graphs/StackedBarGraph';
import { CategoryItem, CategoryItemRange } from '../../../../services/IrisPortalService/home';

export const Categories: FC<any> = (props) => {
  const [categoriesBin, setCategoriesBin] = useState<any[]>([]);
  const [graphOverallBin, setGraphOverallBin] = useState<any[]>([]);

  const { data: revenueImpactData, status: statusRevenue } = useQuery(
    [CACHE_KEYS.REVENUE_IMPACT_CATEGORIES_DATA, ...props.dateRange],
    () => IrisPortalService.getCategoryRevenueImpact(props.dateRange[0], props.dateRange[1]),
    {
      ...CACHE_TIME.get(CACHE_KEYS.REVENUE_IMPACT_CATEGORIES_DATA),
      refetchOnWindowFocus: false,
      onError: (e) => {
        Utils.showError(String(e));
      },
      onSuccess: () => {}
    }
  );
  useEffect(() => {
    if (revenueImpactData && statusRevenue === 'success') {
      revenueImpactData.impact.graph.sort((a: CategoryItemRange, b: CategoryItemRange) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime());
      const COLORS = ['#56CCF2', '#F2C94C', '#8AF1B9', '#FF808B', 'rgb(159, 114, 254)'];
      const newCategoriesBin: any = [];
      const newGraphOverallBin: any = [];
      const totalObj = {
        name: 'total_impact_by_category',
        value: 0,
        label: 'Total Impact by Category',
        type: 'currency',
        color: 'rgb(129, 129, 165)',
        hide: true
      };
      newGraphOverallBin.push(totalObj);
      let totalAmt = 0;
      revenueImpactData.impact.cumulative.map((top: CategoryItem, index: number) => {
        const obj: any = {};
        obj.name = top.item;
        obj.value = top.acv ? top.acv : 0;
        totalAmt += top.acv ? top.acv : 0;
        obj.label = top.item;
        obj.color = COLORS[index % 5];
        obj.type = 'currency';
        newGraphOverallBin.push(obj);
        return top;
      });
      newGraphOverallBin[0].value = totalAmt;
      revenueImpactData.impact.graph.map((imp: CategoryItemRange) => {
        const obj: any = {};
        obj['date'] = imp.startTime;
        let localTotal = 0;
        imp.categories.map((cat: any, index: number) => {
          obj[cat.item] = cat.acv;
          localTotal += cat.acv;
          return cat;
        });
        obj['total_impact_by_category'] = localTotal;
        newCategoriesBin.push(obj);
        return imp;
      });
      setGraphOverallBin(newGraphOverallBin);
      setCategoriesBin(newCategoriesBin);
    }
  }, [revenueImpactData, statusRevenue]);

  return (
    <Stack style={{ width: '100%' }} direction="horizontal">
      {statusRevenue === 'loading' ? (
        <Paper style={{ flex: 1, marginBottom: '40px' }}>
          <Loader center />
        </Paper>
      ) : (
        <Stack direction="vertical" gap={30} align="flex-start" style={{ width: '100%' }}>
          <Stack direction="horizontal">
            {graphOverallBin.map((item, index: number) => {
              return (
                <Stack direction="horizontal" style={{ marginLeft: '40px' }}>
                  <LegendItem value={Utils.getCurrencyValue(item.value)} label={item.label} color={item.color} />
                  {graphOverallBin.length > 1 && index === 0 && (
                    <div
                      style={{
                        width: '4px',
                        height: '47px',
                        background: '#5E81F4',
                        marginLeft: '40px'
                      }}
                    ></div>
                  )}
                </Stack>
              );
            })}
          </Stack>
          <StackedBarGraph customTooltip={true} title="" distribution={categoriesBin} bars={graphOverallBin} additionalTipOrder={0} />
        </Stack>
      )}
    </Stack>
  );
};
