import React from 'react';

import { TableCell as MUITableCell, TableRow as MUITableRow } from '@material-ui/core';

import { ReactComponent as EditIcon } from '../../../../../assets/Edit.svg';
import { IconButton } from '../../../../../components/IconButton';
import { ReactComponent as ActiveStatus } from '../../../../../assets/active-status.svg';
import { ReactComponent as InactiveStatus } from '../../../../../assets/inactive-status.svg';

import { DetectedIncidentsSetting, DetectedIncidentsSettingStatus } from '../types';

import './styles.scss';
import _ from 'lodash';
import { DetectedIncidentsInputFormHolder } from '../DetectedIncidentsSettingsHolder/DetectedIncidentsInputFormHolder';

interface IDetectedIncidentsManagementTableItemProps {
  item: DetectedIncidentsSetting;
  onSuccess: Function;
}

export const DetectedIncidentsManagementTableItem: React.FC<IDetectedIncidentsManagementTableItemProps> = ({ onSuccess, item }) => {
  return (
    <>
      <MUITableRow className="rules-triggers-table-item-container">
        <MUITableCell style={{ width: '30%' }} align="left" className="rules-triggers-table-item-title" component={'td'}>
          {item.name}
        </MUITableCell>
        <MUITableCell style={{ width: '50%' }} align="left">
          {item.settings.case_threshold}
        </MUITableCell>
        <MUITableCell style={{ width: '50%' }} align="left">
          {item.settings.time_interval}
        </MUITableCell>
        <MUITableCell style={{ width: '50%' }} align="left">
          {_.isEqual(item.status, DetectedIncidentsSettingStatus.ENABLED) ? <ActiveStatus /> : <InactiveStatus />}
        </MUITableCell>
        <MUITableCell style={{ width: '50%' }} align="left">
          {item.createdAt}
        </MUITableCell>
        <MUITableCell align="left" className="rules-triggers-table-item-actions">
          <React.Fragment>
            <DetectedIncidentsInputFormHolder
              item={item}
              buttonComponent={<IconButton size="sm" title={'Edit'} icon={EditIcon} onClick={() => {}} />}
              onSuccess={onSuccess}
            />
          </React.Fragment>
        </MUITableCell>
      </MUITableRow>
    </>
  );
};
