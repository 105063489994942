import React, { Component } from 'react';
import './ConnectJira.scss';
import IrisPortalService from '../../../services/IrisPortalService';
import { Button, Input } from '@material-ui/core';
import { logEventWithUserInfo } from '../../../utils/amplitude';
import Utils from '../../../utils/utils';

interface Props {
  onClick: any;
  updateConnectJira: any;
}

class ConnectJiraServer extends Component<Props, any> {
  constructor(props: Props) {
    super(props);
    this.state = { errors: {} };
  }

  connectJira = () => {
    // const { updateConnectJira } = this.props;
    let jiraTokenValue = this.state.jiraToken;
    let subdomain = this.state.subdomain;
    const onPrem = true; // This is jira server token entry page

    if (!jiraTokenValue && !subdomain) {
      this.setState({
        errors: { message: 'Jira token and Subdomain cannot be empty' }
      });
      return;
    }
    if (!jiraTokenValue) {
      this.setState({ errors: { message: 'Jira token cannot be empty' } });
      return;
    }
    if (!subdomain) {
      this.setState({ errors: { message: 'Jira base url cannot be empty' } });
      return;
    }

    try {
      new URL(`https://${subdomain}`);
    } catch (e) {
      this.setState({ errors: { message: 'Not a valid url' } });
      return;
    }

    const email = Utils.getUserEmail();

    IrisPortalService.createJiraToken(onPrem, jiraTokenValue, email, subdomain).catch((e: any) => {
      logEventWithUserInfo('Connect On-prem JSM Error', { error: e });
    });
  };

  handleChangeJiraToken = (e: any) => {
    this.setState({ errors: {} });
    this.setState({ jiraToken: e.target.value });
  };

  handleChangeSubdomain = (e: any) => {
    this.setState({ errors: {} });
    this.setState({ subdomain: e.target.value });
  };

  onKeyPress = (e: any) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      this.connectJira();
    }
  };

  render() {
    const { onClick } = this.props;
    let testUrlPreview = this.state.subdomain ? `https://${this.state.subdomain}/rest/api/2/search` : null;
    return (
      <div className="login-jira-container">
        <p className="instructions">
          Please provide <b>a token</b> to connect to your self hosted jira instance. Instructions to find/create your token can be found{' '}
          <a target="_blank" rel="noopener noreferrer" href="https://confluence.atlassian.com/enterprise/using-personal-access-tokens-1026032365.html">
            here.
          </a>
        </p>
        <Input fullWidth placeholder="Jira api Token" onKeyDown={this.onKeyPress} onChange={this.handleChangeJiraToken} required />
        <div className="subdomain-container">
          <h4 className="https-domain">https://</h4>
          <Input className="domain-input" placeholder="Base URL of your instance" onKeyDown={this.onKeyPress} onChange={this.handleChangeSubdomain} required />
        </div>
        <span className="error">{this.state.errors['message']}</span>
        <Button fullWidth onClick={this.connectJira} className="login-jira-button">
          Connect with Atlassian
        </Button>
        {'   '}
        <Button fullWidth onClick={onClick} className="cancel-button">
          Cancel
        </Button>
        {testUrlPreview && (
          <p>
            Test request will be sent to:
            <br /> <b>{testUrlPreview}</b>
          </p>
        )}
      </div>
    );
  }
}

export default ConnectJiraServer;
