import React, { FC } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Stack, Text } from '../../../components/common';
import { PageContainer } from '../../../components/common/blocks/PageContainer';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import { useQuery } from 'react-query';
import IrisPortalService from '../../../services/IrisPortalService';
import { Loader } from '../../../components/common/blocks/Loader';
import { ImportantStats, RevenueDetail, StatsGraphsCharts } from './ImportantStats';
import { RecentCasesTable } from './RecentCasesTable';
import { CACHE_KEYS, CACHE_TIME } from '../../../utils/cacheUtils';
import Constants from '../../../utils/constants';
import { useResetPage } from '../../../hooks/useResetPage';
import { LinkedJiraIssues } from './CaseMetrics';

export const AccountDetails: FC = (props) => {
  const location = useLocation();
  const history = useHistory();
  const accountName = new URLSearchParams(location.search).get(Constants.ACCOUNT_NAME_SEARCH_PARAM) || '';

  const { data: accountHealth, isLoading } = useQuery([CACHE_KEYS.ACCOUNT_HEALTH, accountName], () => IrisPortalService.getAccountHealth(accountName), {
    enabled: !!accountName,
    refetchOnWindowFocus: false,
    ...CACHE_TIME[CACHE_KEYS.ACCOUNT_HEALTH]
  });

  const { data: recentCases } = useQuery([CACHE_KEYS.ACCOUNT_RECENT_CASES, accountName], () => IrisPortalService.getRecentCasesForAccount(accountName), {
    enabled: !!accountName,
    refetchOnWindowFocus: false,
    ...CACHE_TIME[CACHE_KEYS.ACCOUNT_RECENT_CASES]
  });

  const { data: graphData } = useQuery([CACHE_KEYS.ACCOUNT_GRAPH_DATA, accountName], () => IrisPortalService.getGraphDataForAccount(accountName), {
    enabled: !!accountName,
    refetchOnWindowFocus: false,
    ...CACHE_TIME[CACHE_KEYS.ACCOUNT_GRAPH_DATA]
  });

  useResetPage();

  return (
    <PageContainer>
      <Stack gap={10} align="center" style={{ cursor: 'pointer' }} role="button" tabIndex={0} onClick={() => history.push('/customer-health')}>
        <div
          style={{
            position: 'relative',
            width: 48,
            height: 48,
            borderRadius: 4,
            backgroundColor: '#5E81F4'
          }}
        >
          <ArrowBackOutlinedIcon
            style={{
              color: 'white',
              transform: 'translate(0%, 50%)'
            }}
          />
        </div>
        <Text variant="p4" weight="semi-bolder" color="dark">
          Back to All Customers’ Health Overview
        </Text>
      </Stack>
      <Stack align="center" justify="space-between">
        <Text variant="p4" weight="semi-bolder" style={{ color: '#4F4F4F' }}>
          Showing details for&nbsp;
          <Text variant="p4" weight="semi-bolder" style={{ color: '#5D5FEF' }}>
            {accountName}
          </Text>
          &nbsp;in the last 90 days
        </Text>
        {isLoading || !accountHealth ? (
          ' '
        ) : (
          <>
            <RevenueDetail accountHealth={accountHealth} />
          </>
        )}
      </Stack>

      {isLoading || !accountHealth ? (
        <Loader center />
      ) : (
        <ImportantStats graphData={graphData} accountHealth={accountHealth} accountName={accountName}></ImportantStats>
      )}

      {isLoading || !accountHealth ? (
        ' '
      ) : (
        <>
          <StatsGraphsCharts graphData={graphData} accountName={accountName} />
          <LinkedJiraIssues jiraIssues={accountHealth.linkedJiraIssues !== undefined ? accountHealth.linkedJiraIssues : []} style={{ flexBasis: '100%' }} />
          {recentCases && <RecentCasesTable cases={recentCases.cases} />}
        </>
      )}
    </PageContainer>
  );
};
