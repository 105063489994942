import React, { Component } from 'react';
import './BadgeInformation.scss';
import BadgeCard from '../../../components/badge-card/BadgeCard';
import { Stack } from '../../../components/common';
import { formatDistance } from 'date-fns';
import { ReactComponent as TotalRevenueSVG } from '../../../assets/TotalRevenue.svg';
import { ReactComponent as AgentsSVG } from '../../../assets/Agents.svg';
import { ReactComponent as TimeSavedSVG } from '../../../assets/TimeSaved.svg';
import { ReactComponent as TicketsResolvedSVG } from '../../../assets/TicketResolved.svg';
import Utils from '../../../utils/utils';
import { JiraIssueCase } from '../../../services/IrisPortalService/jira';

interface Props {
  data: JiraIssueCase[];
  revenueData: any;
}

class BadgeInformation extends Component<Props, any> {
  render() {
    const { data, revenueData } = this.props;

    let uniqueAccountNames = data.map((item: any) => item.AccountName).filter((value: any, index: any, self: any) => self.indexOf(value) === index);
    let orderedCases = data.sort((a: JiraIssueCase, b: JiraIssueCase) => (a.timeOfCreation > b.timeOfCreation ? 1 : -1));

    const middleDate = orderedCases.length ? orderedCases[0].timeOfCreation : null;
    const lastDate = orderedCases.length ? orderedCases[orderedCases.length - 1].timeOfCreation : null;

    let durationValue = 0;

    if (lastDate) {
      let l = new Date(lastDate);
      let m = new Date(middleDate || lastDate);
      durationValue = Math.round(Math.abs(l.getTime() - m.getTime()) / 36e5);
    }

    let revenue = {
      value: revenueData ? Utils.nFormatter(revenueData, 2) : '-',
      text: 'Total Revenue Impacted',
      icon: TotalRevenueSVG
    };
    let customersImpacted = {
      value: uniqueAccountNames.length,
      text: 'Customers Impacted',
      icon: AgentsSVG
    };
    let ticketsCaused = {
      value: data.length,
      text: 'Cases Caused',
      icon: TicketsResolvedSVG
    };
    let duration = {
      value: durationValue ? `${formatDistance(0, durationValue * 60 * 60 * 1000)}` : 0,
      text: 'Impact Duration',
      icon: TimeSavedSVG
    };

    return (
      <Stack justify="space-between" gap={20}>
        <BadgeCard data={revenue} />
        <BadgeCard data={customersImpacted} />
        <BadgeCard data={ticketsCaused} />
        <BadgeCard data={duration} />
      </Stack>
    );
  }
}

export default BadgeInformation;
