import { createStyles, Paper as MUIPaper, withStyles } from '@material-ui/core';
import React, { FC } from 'react';
import { Stack, Text } from '../../../components/common';
import { CaseCategoriesType } from '../types';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { formatDistanceStrict } from 'date-fns';
import { buckets, getEmoji, getSentiment } from '../utils';
import _ from 'lodash';
import './KnowledgeArticlesAndBugsTable.scss';

type CategoryMetricsData = {
  categoryData: CaseCategoriesType;
  toSearch?: boolean;
  onlyText?: boolean;
  selected?: boolean;
  getOptionClick?: (option: string) => void;
};

export const CategoryMetrics: FC<CategoryMetricsData> = (props) => {
  const { categoryData, toSearch = false, onlyText = false, selected, getOptionClick = () => {} } = props;
  const [hoursSaved, hoursSavedUnit] = formatDistanceStrict(0, (categoryData?.mttr?.mttr || 0) * 60 * 60 * 1000).split(' ');
  const metricsConfig = [
    categoryData.count && {
      num: categoryData.count,
      title: toSearch ? 'Cases' : 'Total Cases',
      percentage: categoryData.casesChangePercent
    },
    categoryData.mttr?.mttr && {
      num: hoursSaved,
      title: toSearch ? 'MTTR' : 'Mean Time to Resolve',
      percentage: categoryData.mttrChangePercent
    },
    categoryData.mttr?.numberOfClosedCases &&
      !toSearch && {
        num: categoryData.mttr.numberOfClosedCases,
        title: 'Cases Closed',
        percentage: ''
      },
    categoryData.csat?.totalSurveyCount &&
      !toSearch && {
        num: categoryData.csat,
        title: 'CSAT Score',
        percentage: ''
      },
    !_.isNil(categoryData.averageSentimentScore) &&
      !toSearch && {
        num: categoryData.averageSentimentScore,
        title: 'Average Sentiment'
        // percentage: 'sentimentScoreChangePercent'
      }
  ].filter(Boolean);

  const redirectCategory = () => {
    getOptionClick(categoryData.name);
  };
  function getDivStyles(config: any, index: number) {
    if (config?.title === 'MTTR' || config?.title === 'Mean Time to Resolve') {
      return (
        <>
          <div
            style={{
              fontFamily: 'DM Sans',
              fontStyle: 'normal',
              fontSize: toSearch ? 14 : 24,
              fontWeight: 'bold'
            }}
          >
            {config?.num === null ? 'N/A' : config?.num} &nbsp;
          </div>
          <div
            style={{
              fontFamily: 'DM Sans',
              fontStyle: 'normal',
              fontSize: toSearch ? 8 : 16,
              fontWeight: 'bold'
            }}
          >
            {hoursSavedUnit}
          </div>
        </>
      );
    } else if (config?.title === 'Average Sentiment') {
      return (
        <div
          style={{
            fontFamily: 'DM Sans',
            fontStyle: 'normal',
            fontSize: toSearch ? 14 : 24,
            fontWeight: 'bold'
          }}
        >
          {/* <Text variant="p4" weight="semi-bolder" color="dark"> */}
          {config?.num === null ? 'N/A' : getEmoji(getSentiment(buckets, config?.num))}
          {/* </Text> */}
          <Text variant="p0" weight="regular" color={getSentiment(buckets, config?.num) === 'Positive' ? 'green' : 'red'}>
            {getSentiment(buckets, config?.num)}
          </Text>
        </div>
      );
    } else if (config?.title === 'CSAT Score') {
      return (
        <div
          style={{
            fontFamily: 'DM Sans',
            fontStyle: 'normal',
            fontSize: toSearch ? 14 : 24,
            fontWeight: 'bold',
            display: 'flex'
          }}
        >
          <p>{config?.num?.score === null ? 'N/A' : config?.num.score + '% '}</p>
          <Text variant="p0" weight="regular" color="green" style={{ marginTop: '4%' }}>
            {config?.num === null ? ' ' : config?.num.totalSurveyCount + ' Surveys'}
          </Text>
        </div>
      );
    } else {
      return (
        <div
          style={{
            fontFamily: 'DM Sans',
            fontStyle: 'normal',
            fontSize: toSearch ? 14 : 24,
            fontWeight: 'bold'
          }}
        >
          {config?.num === null ? 'N/A' : config?.num}
        </div>
      );
    }
  }

  function getDivStyles2(config: any, index: number) {
    if (config?.title !== 'CSAT Score') {
      return (
        <div
          style={{
            fontFamily: 'DM Sans',
            fontStyle: 'normal',
            fontSize: toSearch ? 8 : 10,
            fontWeight: 'normal',
            display: 'flex',
            marginLeft: '5%'
          }}
        >
          {getPercentChangeDivStyles(config, index)}
          {getArrowDivStyles(config, index)}
        </div>
      );
    }
  }

  function getPercentChangeDivStyles(config: any, index: number) {
    if (config?.title !== 'Average Sentiment') {
      return (
        config?.percentage !== '' &&
        (config?.percentage === null ? (
          <span style={{ color: 'gray' }}>N/A</span>
        ) : (
          <span
            style={{
              color: config?.percentage < 0 ? '#6FCF97' : '#FF808B'
            }}
          >{`${Math.abs(config?.percentage).toFixed(0)}%`}</span>
        ))
      );
    }
  }

  function getArrowDivStyles(config: any, index: number) {
    if (config?.title !== 'Average Sentiment') {
      return (
        config?.percentage !== '' &&
        config?.percentage !== null &&
        (config?.percentage >= 0 ? (
          <ArrowDropUpIcon style={{ color: '#FF808B' }} fontSize="small" />
        ) : (
          <ArrowDropDownIcon style={{ color: '#6FCF97' }} fontSize="small" />
        ))
      );
    }
  }

  return (
    <Stack direction="vertical" gap={15} align="flex-start" style={{ marginTop: toSearch ? 15 : 30 }}>
      <Stack direction="horizontal" gap={30}>
        <Paper
          style={{
            textAlign: 'left',
            cursor: toSearch ? 'pointer' : 'default',
            width: toSearch ? 260 : 'auto',
            boxShadow: 'none'
          }}
          className={toSearch && selected ? 'category-metrics' : ''}
          onClick={redirectCategory}
        >
          {toSearch && (
            <div
              style={{
                fontFamily: 'DM Sans',
                fontStyle: 'normal',
                fontSize: 16,
                fontWeight: 500,
                marginLeft: 10,
                marginTop: 5,
                wordBreak: 'break-word',
                padding: '5px',
                marginBottom: onlyText ? 10 : 0
              }}
            >
              {categoryData.name}
            </div>
          )}
          <div style={{ display: 'flex' }}>
            {metricsConfig.map((config: any, index) => (
              <div style={{ padding: toSearch ? 10 : 20 }} key={index}>
                <div style={{ display: 'flex', height: '67%' }}>
                  {getDivStyles(config, index)}
                  {getDivStyles2(config, index)}
                </div>
                <div
                  style={{
                    color: '#808080',
                    fontFamily: 'DM Sans',
                    fontStyle: 'normal',
                    fontSize: 12,
                    fontWeight: 400,
                    width: 'max-content'
                  }}
                >
                  {config?.title}
                </div>
              </div>
            ))}
          </div>
        </Paper>
      </Stack>
    </Stack>
  );
};
const Paper = withStyles(
  createStyles({
    root: {
      boxShadow: '-1px 2px 6px #EDEDED;',
      borderRadius: 3
    }
  })
)(MUIPaper);
