import { Button } from '@material-ui/core';

import React, { ReactNode } from 'react';
import './Tool.scss';
import { ReactComponent as Plus } from '../../../../assets/plus.svg';
import { ReactComponent as Error } from '../../../../assets/Error.svg';

import { IconButton } from '../../../../components/IconButton';
import './ToolItem.scss';
import { Option } from '../../types';
import { Tooltip } from '../../../../components/Tooltip';

type ToolItemProps = {
  toolItem: Option;
  getIcon: (iconName: string) => ReactNode;
  borderColor: string;
  conditionToolClicked: (condition: string) => void;
  type?: string;
};

export const ToolItem: React.FC<ToolItemProps> = (props) => {
  const onConditionToolClicked = () => {
    props.conditionToolClicked(props.toolItem.name);
  };
  return (
    <div className="parent">
      <IconButton className="icon-button" icon={Plus} size="sm" disabled={props.toolItem.disabled} onClick={onConditionToolClicked}></IconButton>
      <Button
        onClick={props.toolItem.disabled ? undefined : onConditionToolClicked}
        className="box"
        variant="outlined"
        startIcon={props.getIcon(props.toolItem.name)}
        style={{ borderColor: props.borderColor, textAlign: 'left' }}
      >
        {props.toolItem.label}
        {props.toolItem.disabled && (
          <Tooltip title={props.type ? props.type + ' cannot be clubbed.' : 'Cannot be clubbed'} placement="right" darkMode={true}>
            <div
              style={{
                position: 'absolute',
                right: '0',
                marginRight: '10px',
                marginTop: '5px'
              }}
            >
              <Error />
            </div>
          </Tooltip>
        )}
      </Button>
    </div>
  );
};
