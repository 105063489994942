import React, { FC, useState } from 'react';
import { Stack, TableCell, TableRow, Text, ViewDetailsButton } from '../../../components/common';
import { PriorityTooltip } from './types';
import queryString from 'query-string';
import Constants from '../../../utils/constants';
import { Link, useHistory } from 'react-router-dom';
import { logEventWithUserInfo } from '../../../utils/amplitude';
import Utils from '../../../utils/utils';
import './AiRecommendedCaseRow.scss';
import { PriorityCell } from './PriorityCell';
import { ReactComponent as ExternalLink } from '../../../assets/external-link.svg';
import { ReactComponent as ExternalLinkGrey } from '../../../assets/external-link-grey.svg';
import { AIRecommendationItem } from '../../../services/IrisPortalService/incidents';

export const AiRecommendedCaseRow: FC<{
  caseItem: AIRecommendationItem;
  alwaysShowViewDetails?: boolean;
  hideLinkedIcon?: boolean;
}> = (props) => {
  const { caseItem, alwaysShowViewDetails, hideLinkedIcon = false } = props;
  const [showViewDetailsButton, setShowViewDetailsButton] = useState(false);
  const priorityItem = CreatePriority(caseItem);
  const history = useHistory();
  const getDetailsPageLink = () => {
    const params = queryString.stringify({
      [Constants.CASES_TYPE_QUERY_PARAM]: Constants.SUGGESTED_JIRA_QUERY_PARAM
    });
    return `/incidents-details/Jira/${caseItem.issue.issueKey}?${params}`;
  };

  return (
    <TableRow
      onMouseEnter={() => setShowViewDetailsButton(true)}
      onMouseLeave={() => setShowViewDetailsButton(false)}
      onClick={() => history.push(getDetailsPageLink())}
      style={{ cursor: 'pointer' }}
    >
      <TableCell variant="body">
        <PriorityCell priorityTooltipItem={priorityItem} type="" />
      </TableCell>
      <TableCell variant="body">
        {caseItem.case.url ? (
          <a href={caseItem.case.url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }} onClick={(e) => e.stopPropagation()}>
            <CaseNumberCell Id={caseItem.case.caseNumber} />
          </a>
        ) : (
          <CaseNumberCell Id={caseItem.case.caseNumber} />
        )}
      </TableCell>
      <TableCell variant="body">
        <Stack direction="horizontal" gap={10} justify="space-between">
          <Text lineClamp={2} variant="p2" weight="semi-bold" color="light">
            {caseItem.case.subject}
          </Text>
        </Stack>
      </TableCell>
      <TableCell variant="body">
        <Text variant="p1" weight="medium" color="lighter">
          {Utils.formatDistanceToNowStrict(new Date(caseItem.case.timeOfCreation), {
            addSuffix: true
          })}
        </Text>
      </TableCell>
      <TableCell variant="body">
        <Stack direction="vertical">
          <Stack direction="horizontal" gap={10} align="start">
            <Text variant="p1" weight="regular" color="dark" lineClamp={2}>
              {caseItem.issue.summary}
            </Text>
            {!hideLinkedIcon && <img alt="" src="/images/link.png" width="20px" />}
          </Stack>
          <Stack direction="horizontal" gap={10}>
            <Text variant="p2" weight="medium" color="lighter">
              {'Jira'}
            </Text>
            {caseItem.issue.url ? (
              <a href={caseItem.issue.url} target="_blank" rel="noopener noreferrer" onClick={(e) => e.stopPropagation()}>
                <ExternalLink style={{ padding: 0 }} />
              </a>
            ) : (
              <ExternalLinkGrey />
            )}
          </Stack>
        </Stack>
      </TableCell>
      <TableCell align="right" padding="none" style={{ paddingRight: 10 }}>
        {alwaysShowViewDetails || showViewDetailsButton ? (
          <Link style={{ textDecoration: 'none' }} onClick={(e) => e.stopPropagation()} to={getDetailsPageLink()}>
            <ViewDetailsButton
              onClick={() => {
                logEventWithUserInfo('View Incident Details', {
                  incidentId: caseItem.issue.issueKey,
                  incidentType: 'Jira'
                });
              }}
            />
          </Link>
        ) : null}
      </TableCell>
    </TableRow>
  );
};

const CaseNumberCell: FC<{ Id: string }> = (props) => {
  return (
    <Text variant="p2" weight="semi-bold" style={{ color: '#30AABC' }}>
      {props.Id}
    </Text>
  );
};

export const CreatePriority = (caseItem: AIRecommendationItem) => {
  let priorityTooltipItem: PriorityTooltip = {
    Priority: caseItem.case.priority,
    priorityScore: caseItem.priorityScore || { score: 0, parameters: [] },
    caseSentimentBucket: caseItem.priorityScore?.caseSentimentBucket,
    openTimeSecs: caseItem.priorityScore?.openTimeSecs || 0,
    commentsLength: caseItem.priorityScore?.commentsLength || 0,
    annualContractValue: caseItem.priorityScore?.annualContractValue || 0
  };
  return priorityTooltipItem;
};
