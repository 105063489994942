import React, { useCallback } from 'react';

import { Box, Input, Tooltip } from '@material-ui/core';

import { Text } from '../../../../../components/common';

import { ReactComponent as ActiveStatus } from '../../../../../assets/active-status.svg';
import { ReactComponent as InactiveStatus } from '../../../../../assets/inactive-status.svg';

import { DetectedIncidentsSetting, DetectedIncidentsSettingStatus } from '../types';

import './styles.scss';
import _ from 'lodash';
import SearchWidget from '../../../../categories/components/SearchWidget';
import IrisPortalService from '../../../../../services/IrisPortalService';
import { Button } from '../../../../../components/home/Button';

interface IDetectedIncidentsManagementTableItemProps {
  item?: DetectedIncidentsSetting;
  onSuccess: Function;
}

export const DetectedIncidentsSettingsInputForm: React.FC<IDetectedIncidentsManagementTableItemProps> = ({ onSuccess, item }) => {
  const [inputCategoryState, setInputCategoryState] = React.useState(item ? item?.settings.category : '');
  const [inputTimeIntervalState, setInputTimeIntervalState] = React.useState(0);
  const [inputCaseThresholdState, setInputCaseThresholdState] = React.useState(0);

  const data = IrisPortalService.getDetectedIncidentsSettingsPrerequisites();

  const upsertDetectedIncidentSetting = useCallback(async () => {}, [item, inputCategoryState, inputTimeIntervalState, onSuccess, inputCaseThresholdState]);

  return (
    <React.Fragment>
      {
        <div>
          <Box bgcolor={`rgba(159, 161, 255, 0.05)`} padding={'20px'} marginBottom={'20px'}>
            <Text>Create a trending incident when the number of cases matching the below Category/Tag</Text>
            <Tooltip title={'detected-incidents=category-search'} placement="top">
              <div className={'search-selector'}>
                <SearchWidget
                  isCustom={false}
                  searchValue={inputCategoryState}
                  dataList={data.categories}
                  onInputChange={(input: string) => {
                    setInputCategoryState(input);
                  }}
                />
              </div>
            </Tooltip>
            <br />
            <br />
            <Text>exceed the threshold </Text>
            <br />
            <Input
              onChange={(e) => {
                setInputCaseThresholdState(parseInt(e.target.value));
              }}
              defaultValue={item?.settings.case_threshold}
              type="number"
            ></Input>
            <br />
            <br />
            <br />
            <Text>within a time interval of </Text>
            <br />
            <Input
              onChange={(e) => {
                setInputTimeIntervalState(parseFloat(e.target.value));
              }}
              defaultValue={item?.settings.time_interval}
              type="number"
            ></Input>
            <Text> days</Text>

            <div
              style={{
                marginTop: '2em'
              }}
            >
              {(_.isEqual(item?.status, DetectedIncidentsSettingStatus.ENABLED) && <ActiveStatus />) ||
                (_.isEqual(item?.status, DetectedIncidentsSettingStatus.DISABLED) && <InactiveStatus />)}
            </div>

            <div>
              <div className={'ml-auto width-fc'}>
                <Button className="button_details" variant="contained" onClick={() => upsertDetectedIncidentSetting()}>
                  {item ? 'Update Incident' : 'Create Incident'}
                </Button>
              </div>
            </div>
          </Box>
        </div>
      }
    </React.Fragment>
  );
};
