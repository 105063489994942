import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tabs,
  Tooltip
} from '@material-ui/core';
import React, { FC, useState } from 'react';
import { Stack, Text, useTableSorters } from '../../../components/common';
import TableHeadSorting from '../../../components/table-head-sorting/TableHeadSorting';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import './KnowledgeArticlesAndBugsTable.scss';

export enum DEFAULT_SORT {
  column = 'cases',
  orderAsc = 'asc'
}

export const KnowledgeArticlesAndBugsTable: FC<{
  dateRange: Date[];
  data: any[];
}> = (props) => {
  const [tabNumber, setTabNumber] = useState(0);
  let {
    onSort,
    filterData,
    sortColumn,
    sortOrder,
    onPageChange,
    currentPage,
    handleChangeRowsPerPage,
    labelDisplayedRows,
    pageSize,
    ROWS_PER_PAGE_OPTIONS
  } = useTableSorters({
    turnOffPagination: true,
    defaultSortColumn: DEFAULT_SORT.column,
    defaultSortOrder: DEFAULT_SORT.orderAsc
  });
  const [expanded, setExpanded] = React.useState<string | false>('');
  const handleChange = (panel: any) => (event: any, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <React.Fragment>
      <TableContainer style={{ maxHeight: 1000 }}>
        <Table size="small">
          <TableHeadSorting order={sortOrder} orderByColumn={sortColumn} onRequestSort={(_, column) => onSort(column)} headCells={TableHeaderCells} />
          <TableBody>
            {props.data &&
              filterData(props.data)
                .slice(currentPage * pageSize, currentPage * pageSize + pageSize)
                .map((item: any, index: number) => {
                  return (
                    <TableRow style={{ display: 'flex', flexDirection: 'column' }}>
                      <div style={{ marginRight: '-150%' }}>
                        <Accordion
                          className="kb-accordian"
                          // style={{ width: 532 }}
                          expanded={expanded === 'panel' + index}
                          onChange={handleChange('panel' + index)}
                        >
                          <AccordionSummary
                            style={{ display: 'flex', flexDirection: 'row' }}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <TableCell style={{ flexBasis: '40%' }}>
                              <Tooltip title={item.name}>
                                <Text variant="p1" weight="semi-bolder" lineClamp={2} style={{ overflowWrap: 'anywhere' }}>
                                  {item.name}
                                </Text>
                              </Tooltip>
                            </TableCell>
                            <TableCell style={{ flexBasis: '25%' }}>
                              <Text weight="semi-bolder">{item.count}</Text>
                            </TableCell>
                            <TableCell style={{ flexBasis: '22%' }}>
                              <Text weight="semi-bolder">{item.articlesCount}</Text>
                            </TableCell>
                            <TableCell style={{ flexBasis: '13%' }}>
                              <Text weight="semi-bolder">{item.jiraIssuesCount}</Text>
                            </TableCell>
                          </AccordionSummary>
                          <AccordionDetails
                            style={{
                              flexDirection: 'column',
                              border: '1px solid rgb(93, 95, 239,0.3)',
                              padding: 0
                            }}
                          >
                            <div style={{ height: 35 }}>
                              <Tabs
                                value={tabNumber}
                                onChange={(event: any, newValue: number) => {
                                  setTabNumber(newValue);
                                }}
                                className="kb-tabs"
                              >
                                <Tab
                                  className="kb-tab"
                                  label={
                                    <Stack direction="horizontal" gap={15} align="center">
                                      <span className="kb-tab-heading">Articles ({item.articlesCount})</span>
                                    </Stack>
                                  }
                                />
                                <Tab
                                  className="kb-tab"
                                  label={
                                    <Stack direction="horizontal" gap={15} align="center">
                                      <span className="kb-tab-heading">Bugs ({item.jiraIssuesCount})</span>
                                    </Stack>
                                  }
                                />
                              </Tabs>
                            </div>
                            <div style={{ marginLeft: 5, marginRight: 5 }}>
                              <TabPanel value={tabNumber} index={0}>
                                <List
                                  style={{
                                    width: '100%',
                                    maxHeight: 250,
                                    overflow: 'auto'
                                  }}
                                >
                                  {item.articles?.map((article: any, inx: number) => {
                                    return (
                                      <ListItem
                                        className="list-item"
                                        style={
                                          inx % 2
                                            ? {
                                                background: '#F9F9FE',
                                                width: '100%'
                                              }
                                            : {
                                                background: 'white',
                                                width: '100%'
                                              }
                                        }
                                      >
                                        <ListObject id={article.title} summary={article.summary} url={article.url} />
                                      </ListItem>
                                    );
                                  })}
                                </List>
                              </TabPanel>
                              <TabPanel value={tabNumber} index={1}>
                                <List
                                  style={{
                                    width: '100%',
                                    maxHeight: 250,
                                    overflow: 'auto',
                                    marginTop: 5,
                                    marginBottom: 5
                                  }}
                                >
                                  {item.jiraIssues?.map((jiraIssue: any, inx: number) => {
                                    return (
                                      <ListItem
                                        // style ={ index % 2? { background : "#fdffe0" }:{ background : "white" }}
                                        className="list-item"
                                        style={
                                          inx % 2
                                            ? {
                                                background: '#F9F9FE',
                                                width: '100%'
                                              }
                                            : {
                                                background: 'white',
                                                width: '100%'
                                              }
                                        }
                                      >
                                        <ListObject id={jiraIssue.issueKey} summary={jiraIssue.summary} url={jiraIssue.url} />
                                      </ListItem>
                                    );
                                  })}
                                </List>
                              </TabPanel>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </TableRow>
                  );
                })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
        component="div"
        count={props.data?.length}
        rowsPerPage={pageSize}
        page={currentPage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelDisplayedRows={labelDisplayedRows}
        onChangePage={(_, page) => onPageChange(page)}
      />
    </React.Fragment>
  );
};
const TableHeaderCells = [
  {
    id: 'name',
    label: 'Category Name',
    width: '40%',
    hasFilter: true,
    type: 'string'
  },
  {
    id: 'count',
    label: '#Cases',
    width: '20%',
    hasFilter: true,
    type: 'number'
  },
  {
    id: 'articlesCount',
    label: '#articles',
    width: '20%',
    hasFilter: true,
    type: 'number'
  },
  {
    id: 'jiraIssuesCount',
    label: '#Bugs',
    width: '20%',
    hasFilter: true,
    type: 'number'
  }
];
const ListObject = (props: any) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginLeft: '11px'
      }}
    >
      <a href={props.url} target="_blank" rel="noreferrer noopener">
        <Text className="item-id">{props.id}</Text>
      </a>
      <Text weight="semi-bolder">{props.summary}</Text>
    </div>
  );
};
const TabPanel = (props: { children?: React.ReactNode; index: number; value: number }) => {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <>{children}</>}
    </div>
  );
};
