import _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import IrisPortalService from '../../services/IrisPortalService';
import React from 'react';
import Utils from '../../utils/utils';
import { Card, CardContent, TablePagination } from '@material-ui/core';
import { CACHE_KEYS, CACHE_TIME } from '../../utils/cacheUtils';
import { ChatbotActivity, ChatbotConversation } from './types';
import { ChatbotActivityCard } from './ChatbotActivityCard';
import { Loader } from '../../components/common/blocks/Loader';
import { PageContainer } from '../../components/common/blocks/PageContainer';
import { Stack, Text, useTableSorters } from '../../components/common';
import { TablePaper } from '../../components/common/blocks/TablePaper';
import { useQuery } from 'react-query';

const DEFAULT_PAGE_SIZE = 8;
const ROWS_PER_PAGE_OPTIONS = [DEFAULT_PAGE_SIZE, 10, 25, 50, 100];
const MIN_HEIGHT = '97vh';

export default function ChatbotActivityDashboard() {
  const { onPageChange, currentPage, handleChangeRowsPerPage, labelDisplayedRows, pageSize } = useTableSorters({ pageSize: DEFAULT_PAGE_SIZE });

  const [currentConversationId, setCurrentConversationId] = React.useState<string | undefined>(undefined);

  const { data: chatbotActivityData, isLoading } = useQuery([CACHE_KEYS.CHATBOT_ACTIVITY], () => IrisPortalService.getChatbotActivity(), {
    ...CACHE_TIME[CACHE_KEYS.CHATBOT_ACTIVITY],
    onError: (error) => {
      console.error('Failed to fetch chatbot activity:', error);
    }
  });

  React.useEffect(() => {
    if (chatbotActivityData) {
      onPageChange(0);
    }
  }, [chatbotActivityData]);

  // Calculate paginated data
  const paginatedData = React.useMemo(() => {
    if (!chatbotActivityData) return [];
    const startIndex = currentPage * pageSize;
    return chatbotActivityData.slice(startIndex, startIndex + pageSize);
  }, [chatbotActivityData, currentPage, pageSize]);

  const selectedChatbotActivity = _.find(chatbotActivityData, (chatbotActivity) => _.isEqual(chatbotActivity.chatIdentifier, currentConversationId));

  const highlightedBgColor = `#F7F9FE`;
  const borderStyle = `1px solid #F6F6F6`;

  function showMessageDetail(): React.ReactNode {
    return (
      <Grid item lg={7} md={7} sm={12} xs={12} style={{ paddingLeft: '24px' }}>
        {!_.isNil(selectedChatbotActivity) &&
          selectedChatbotActivity.conversation.map((conversation) => {
            return (
              <div key={`${conversation.entity}-${conversation.createdAt}`}>
                <ChatbotActivityCard userName={conversation.entity} message={conversation.message} timestamp={conversation.createdAt} />
                <br />
              </div>
            );
          })}
      </Grid>
    );
  }

  function showMessages(chatbotActivity: ChatbotActivity) {
    const conversation: Array<ChatbotConversation> = chatbotActivity.conversation;
    const userName = _.first(conversation)?.entity;
    const shortMsg = _.first(conversation)?.message;
    return (
      <Card
        variant={'outlined'}
        onClick={() => setCurrentConversationId(chatbotActivity.chatIdentifier)}
        style={{
          border: borderStyle,
          borderRadius: '0px',
          backgroundColor: _.isEqual(currentConversationId, chatbotActivity.chatIdentifier) ? highlightedBgColor : '#FFFFFF'
        }}
      >
        <CardContent>
          <Stack direction="vertical" gap={10}>
            <Stack direction="horizontal" align={'center'}>
              <Text style={{ fontSize: 14 }} weight={'bold'}>
                {userName}
              </Text>
              <Text className={'ml-auto'}>{Utils.formatDate(new Date(chatbotActivity.createdAt), 'll')}</Text>
            </Stack>
            <Text style={{ fontSize: 14 }}>{shortMsg ? shortMsg : ''}</Text>
          </Stack>
        </CardContent>
      </Card>
    );
  }

  function showListOfMessages(): React.ReactNode {
    if (isLoading) {
      return <Loader />;
    }
    return (
      <Grid
        item
        lg={5}
        md={5}
        sm={12}
        xs={12}
        style={{
          borderLeft: borderStyle,
          borderRight: borderStyle,
          minHeight: MIN_HEIGHT,
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <div style={{ flex: 1 }}>
          {paginatedData.map((chatbotActivity) => {
            return <React.Fragment key={chatbotActivity.chatIdentifier}>{showMessages(chatbotActivity)}</React.Fragment>;
          })}
        </div>
        {chatbotActivityData?.length ? (
          <TablePagination
            aria-label="Chatbot conversations pagination"
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            component="div"
            count={chatbotActivityData.length}
            rowsPerPage={pageSize}
            page={currentPage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelDisplayedRows={labelDisplayedRows}
            onChangePage={(_, page) => onPageChange(page)}
          />
        ) : null}
      </Grid>
    );
  }

  if (isLoading) {
    return <Loader />;
  }

  if (_.isEmpty(chatbotActivityData)) {
    return (
      <React.Fragment>
        <PageContainer className="tab-container">
          <TablePaper style={{ fontFamily: 'inherit', minHeight: MIN_HEIGHT }}>
            <div style={{ textAlign: 'center', marginTop: '10%' }}>
              <Text weight={'bold'}>
                No conversations to show yet.
                <br />
                Please make sure that you have deployed IrisGPT bot on your website using steps on the "Configure" page.
              </Text>
            </div>
          </TablePaper>
        </PageContainer>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <PageContainer className="tab-container">
        <TablePaper style={{ fontFamily: 'inherit', minHeight: MIN_HEIGHT }}>
          <Grid container spacing={0} style={{ marginTop: '24px', minHeight: '100%' }}>
            {showListOfMessages()}
            {showMessageDetail()}
          </Grid>
        </TablePaper>
      </PageContainer>
    </React.Fragment>
  );
}
