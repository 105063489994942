import React, { FC } from 'react';

import { ReactComponent as DeleteOutlineIcon } from '../../../../../assets/delete.svg';

import { RulesRowManager } from '../../../utils';
import { ConditionContainer, HandleRowComponentType, HandleRowDeleteType, Option } from '../../../types';

import './styles.scss';
import { RulesAddButton } from '../../RulesAddButton';
import { NotImplementedRowManager } from '../../RowManagers/NotImplementedRowManager';

export class ActionRowWrapper {
  rowManager: RulesRowManager;
  options: Option[];
  handleActionsChange: (actions: ConditionContainer[]) => void;
  constructor(rowManager: RulesRowManager, options: Option[]) {
    this.rowManager = rowManager;
    this.options = options;
  }
  createRowComponent(
    handleRowComponent: HandleRowComponentType,
    handleRowDelete: HandleRowDeleteType,
    handleInnerConditionAdd: (row: ConditionContainer, index: number) => void,
    handleGroupRowDelete: HandleRowDeleteType,
    row: ConditionContainer,
    parentIndex: number,
    childIndex: number
  ) {
    return (
      <div
        style={{
          paddingBottom: '20px',
          // border: row.actions.length > 1 ? '2px solid rgb(165, 166, 246)' : '',
          borderTopWidth: row.actions.length > 1 ? '0px' : '',
          borderRightWidth: row.actions.length > 1 ? '0px' : '',
          borderBottomWidth: row.actions.length > 1 ? '0px' : ''
        }}
      >
        <ActionRow
          rowManager={this.rowManager}
          handleRowComponent={handleRowComponent}
          handleRowDelete={handleRowDelete}
          handleActionsChange={this.handleActionsChange}
          handleInnerConditionAdd={handleInnerConditionAdd}
          handleGroupRowDelete={handleGroupRowDelete}
          options={this.options}
          parentIndex={parentIndex}
          childIndex={childIndex}
          row={row}
        />
      </div>
    );
  }
}

export const ActionRow: FC<{
  rowManager: RulesRowManager;
  handleRowComponent: HandleRowComponentType;
  handleRowDelete: HandleRowDeleteType;
  handleActionsChange: (actions: ConditionContainer[]) => void;
  handleInnerConditionAdd: (row: ConditionContainer, index: number) => void;
  handleGroupRowDelete: HandleRowDeleteType;
  options: Option[];
  row: ConditionContainer;
  parentIndex: number;
  childIndex: number;
}> = ({
  rowManager,
  handleRowComponent,
  handleRowDelete,
  handleActionsChange,
  handleInnerConditionAdd,
  handleGroupRowDelete,
  options,
  row,
  parentIndex,
  childIndex
}) => {
  const handleRulesDropdownSelect = (actionName: string) => {
    //function to add a new operand under current operator.
    const rowManagerFactory = options[options.findIndex((value) => value.name === actionName)];
    if (rowManagerFactory === undefined) {
      return;
    }

    const rowManager =
      rowManagerFactory.rowFactoryFn === undefined ? new NotImplementedRowManager(options, undefined, undefined, '') : rowManagerFactory.rowFactoryFn(options);

    const newRow = new ActionRowWrapper(rowManager, options);
    row.actions.push(newRow);
    handleInnerConditionAdd(row, parentIndex);
  };

  const [s, ss] = React.useState({});

  return (
    <div>
      {row.actions.length > 1 && childIndex === 0 && (
        <div className="group-container">
          <DeleteOutlineIcon
            className="delete-button"
            onClick={() => {
              handleGroupRowDelete(rowManager.action);
            }}
          />
          <RulesAddButton onChange={handleRulesDropdownSelect} options={options}>
            <span
              style={{
                color: 'white',
                textTransform: 'none',
                fontSize: '10px'
              }}
            >
              Add condition
            </span>
          </RulesAddButton>
        </div>
      )}
      <div
        className="container"
        style={{
          borderColor: rowManager?.type === 'action' ? '#EF5DA8' : '#5D5FEF',
          borderRadius: '4px',
          background: row.actions.length > 1 ? '#F8FAFB' : 'white'
        }}
      >
        <div
          style={{
            display: 'flex',
            columnGap: 20,
            width: '100%'
          }}
        >
          {rowManager.createItems(handleRowComponent, s, ss)}
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            width: 'min-content',
            height: 'min-content'
          }}
        >
          {rowManager?.type !== 'action' && row.actions.length === 1 && (
            <RulesAddButton onChange={handleRulesDropdownSelect} options={options}>
              <span
                style={{
                  color: 'white',
                  textTransform: 'none',
                  fontSize: '10px'
                }}
              >
                Add condition
              </span>
            </RulesAddButton>
          )}
          <DeleteOutlineIcon
            className="delete-button"
            onClick={() => {
              handleRowDelete(rowManager.action);
            }}
          />
        </div>
      </div>
    </div>
  );
};
