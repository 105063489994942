import React, { FC, useState } from 'react';
import { CategoryTypeSelection } from '../../../../routes/categories/components/categoryTypeComponent/CategoryTypeSelection';
import { DateRangePicker } from '../../../../routes/incidents/date-picker-component/DatePickerComponent';
import { Stack, Text } from '../../../common';
import { OverallImpact } from './OverallImpact';
import './RevenueImpacted.scss';
import { Categories } from './Categories';
import { DEFAULT_END_DATE, DEFAULT_START_DATE } from '../../../../utils/constants';

export const RevenueImpacted: FC = (props) => {
  const [filterField, setFilterField] = useState('OVERALL');
  const [hasData, setHasData] = useState(true);
  const [dateRangeForRevenue, setDateRangeForRevenue] = useState<[Date, Date]>(() => {
    return [DEFAULT_START_DATE, DEFAULT_END_DATE];
  });

  enum FilterField {
    Overall = 'OVERALL',
    Categories = 'CATEGORIES'
  }

  const buttonLabels = [FilterField.Overall, FilterField.Categories];

  return (
    <>
      <Stack className={'w-100'}>
        <div className="revenue-container">
          {/* <div> */}
          <Stack direction="horizontal" justify="space-between" align="center" style={{ marginBottom: '16px' }}>
            <h3 className="heading">Revenue Impacted by</h3>
            <CategoryTypeSelection onChangeType={setFilterField} selectedType={filterField} buttonLabels={buttonLabels}></CategoryTypeSelection>
            <DateRangePicker value={dateRangeForRevenue} onChange={setDateRangeForRevenue} />
          </Stack>
          {hasData === true ? (
            <div>
              {filterField === FilterField.Overall && <OverallImpact dateRange={dateRangeForRevenue} dataAvailable={setHasData} />}
              {filterField === FilterField.Categories && <Categories dateRange={dateRangeForRevenue} />}
            </div>
          ) : (
            <NoCategoriesFoundCmp />
          )}
        </div>
      </Stack>
    </>
  );
};
export const NoCategoriesFoundCmp: FC = (props) => {
  return <Text style={{ textAlign: 'center' }}>Please connect with your CRM (Salesforce or Hubspot) from Manage Integrations on bottom left to see data</Text>;
};
