import React from 'react';
import { CategoryFilter, CategoryFilterType, FilterChecked } from '../categories';
import _ from 'lodash';
import { AdvancedFilter, SelectedItem } from '../categories/components/filterComponent/AdvancedFilter';
import { useQuery } from 'react-query';
import { CACHE_KEYS, CACHE_TIME } from '../../utils/cacheUtils';
import IrisPortalService from '../../services/IrisPortalService';
import { ReactComponent as Settings } from '../../assets/settings.svg';
import { AtlassianConfigUpdateRequest } from '../../services/types';
import Utils from '../../utils/utils';

enum Key {
  CONFLUENCE_SPACE = 'confluenceSpaces',
  JIRA_PROJECT = 'jiraProjects'
}

export const AtlassianConfig: React.FC = () => {
  const { data: atlassianConfig, isFetching, refetch } = useQuery(
    [CACHE_KEYS.ATLASSIAN_CONFIG],
    () => {
      return IrisPortalService.getAtlassianConfig();
    },
    {
      ...CACHE_TIME.get(CACHE_KEYS.ATLASSIAN_CONFIG)
    }
  );

  const isAdmin = Utils.amIAdmin();

  if (isFetching || !isAdmin) {
    return <React.Fragment />;
  }

  return (
    <AdvancedFilter
      onFiltersChange={onAtlassianConfigChange}
      classes={{
        root: 'top-right'
      }}
      filters={[
        {
          key: Key.CONFLUENCE_SPACE,
          label: 'Confluence Spaces',
          type: CategoryFilterType.SELECT,
          values: atlassianConfig?.config?.confluence?.spaces?.map((space) => {
            return {
              value: space.key,
              isChecked: _.includes(atlassianConfig?.config?.confluence?.selectedSpaces, space.key)
            } as FilterChecked;
          })
        } as CategoryFilter,
        {
          key: Key.JIRA_PROJECT,
          label: 'Jira Projects',
          type: CategoryFilterType.SELECT,
          values: atlassianConfig?.config?.jira?.projects?.map((project) => {
            return {
              value: project.key,
              isChecked: _.includes(atlassianConfig?.config?.jira?.selectedProjects, project.key)
            } as FilterChecked;
          })
        } as CategoryFilter
      ]}
    >
      <Settings />
    </AdvancedFilter>
  );

  async function onAtlassianConfigChange(items: SelectedItem[]) {
    const confluenceSpaces = _.chain(items).find({ key: Key.CONFLUENCE_SPACE }).get('values', []).value();

    const jiraProjects = _.chain(items).find({ key: Key.JIRA_PROJECT }).get('values', []).value();

    const fieldMask = _.map(items, 'key');

    const updateRequest: AtlassianConfigUpdateRequest = {
      fieldMask,
      confluenceSpaces,
      jiraProjects
    };

    try {
      await IrisPortalService.updateAtlassianConfig(updateRequest);
      await refetch();
    } catch (e) {
      console.error(e);
    }
  }
};
