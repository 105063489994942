import React, { Component } from 'react';
import './LoginZoho.scss';
import IrisPortalService from '../../services/IrisPortalService';
import Utils from '../../utils/utils';
import { FormControl } from '@material-ui/core';
import { logEvent } from '../../utils/amplitude';
import { IrisAgentTextField } from '../../components/input/IrisAgentTextField';
import { PlatformConnectButtons, PlatformConnectFormControlText } from '../PlatformConnect';
import _ from 'lodash';
import { Stack } from '../../components/common';
import { Provider } from '../../services/IrisPortalService/connect';

interface Props {
  onClick: any;
}

interface DataError {
  clientId?: string;
  clientSecret?: string;
}

interface State {
  error: DataError;
  clientId?: string;
  clientSecret?: string;
}

class LoginZoho extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { error: {} };
  }

  loginZoho = async () => {
    let clientId = this.state.clientId;
    let clientSecret = this.state.clientSecret;

    if (!clientId) {
      let errors = { clientId: 'Client ID cannot be empty' };
      this.setState({ error: errors });
      return;
    }

    if (!clientSecret) {
      let errors = { clientSecret: 'Client secret cannot be empty' };
      this.setState({ error: errors });
      return;
    }

    const isNew = Utils.IsNew();
    // At the moment that the user wants to login with google (First Time)
    logEvent('Connect with Zoho button', {
      email: Utils.getUserEmail(),
      isNewUser: isNew
    });

    await IrisPortalService.setupOAuth({
      isNew: isNew,
      provider: Provider.Zoho,
      clientId: clientId,
      clientSecret: clientSecret
    }).catch((e: any) => {
      logEvent('Zoho OAuth Error', { error: e });
    });
  };

  handleChangeClientId = (e: any) => {
    this.setState({ error: {} });
    this.setState({ clientId: e.target.value });
  };

  handleChangeClientSecret = (e: any) => {
    this.setState({ error: {} });
    this.setState({ clientSecret: e.target.value });
  };

  render() {
    const { onClick } = this.props;
    return (
      <div className="mt-1">
        <Stack direction={'vertical'} gap={8}>
          <FormControl
            classes={{
              root: 'w-100'
            }}
          >
            <IrisAgentTextField label="Client ID" color="secondary" variant="outlined" size={'small'} required onChange={this.handleChangeClientId} />
            <PlatformConnectFormControlText error={!_.isEmpty(this.state.error)} message={this.state.error?.clientId} />
          </FormControl>

          <FormControl
            classes={{
              root: 'w-100'
            }}
          >
            <IrisAgentTextField label="Client Secret" color="secondary" variant="outlined" size={'small'} required onChange={this.handleChangeClientSecret} />
            <PlatformConnectFormControlText error={!_.isEmpty(this.state.error)} message={this.state.error?.clientSecret} />
          </FormControl>

          <PlatformConnectButtons onCancel={onClick} onConnect={this.loginZoho} />
        </Stack>
      </div>
    );
  }
}

export default LoginZoho;
