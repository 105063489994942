import Utils from '../../utils/utils';
import MockData from '../../utils/mockData';
import { handleError, isMocKData } from './utils';
import { ChatbotActivity } from '../../routes/chatbot-activity/types';

export const getChatbotActivity = async (): Promise<ChatbotActivity[]> => {
  const tokenExpress = Utils.getObjectItem('token_express');
  if (isMocKData) {
    return MockData.getChatbotActivityMockData();
  }

  const response = await fetch(`${process.env.REACT_APP_APISERVER_URL}/v1/portal/chatbot`, {
    method: 'GET',
    headers: new Headers({
      Authorization: `Bearer ${tokenExpress}`
    })
  });
  handleError(response.status);
  if (response.status === 403) {
    return [];
  }
  if (!response.ok) {
    throw new Error(`getChatbotActivity: Unhandled error status code!: ${response.status}`);
  }
  return response.json();
};
