import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { Table, TableBody, TableCell, TableContainer, TablePagination, TableRow } from '@material-ui/core';
import IrisPortalService from '../../../services/IrisPortalService';
import Constants, { DEFAULT_END_DATE, DEFAULT_START_DATE } from '../../../utils/constants';
import { DateRangePicker } from '../date-picker-component/DatePickerComponent';
import { AiRecommendedCaseRow } from './AiRecommendedCaseRow';
import { CACHE_KEYS, CACHE_TIME } from '../../../utils/cacheUtils';
import { isValid } from 'date-fns';
import { Stack, Text, useTableSorters, useURLState } from '../../../components/common';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import TableHeadSorting from '../../../components/table-head-sorting/TableHeadSorting';

export const CasesWithLinkedJiraTable: FC = (props) => {
  const location = useLocation();
  const history = useHistory();
  const getDateFiltersFromURL = (params: string): [Date, Date] => {
    const [startDate, endDate] = params.split(',').map((param) => new Date(Number(param))) as [Date, Date];
    if (isValid(startDate) && isValid(endDate)) {
      return [startDate, endDate];
    } else {
      return [DEFAULT_START_DATE, DEFAULT_END_DATE];
    }
  };

  const [dateRange, setDateRange] = useURLState<[Date, Date]>(
    Constants.LINKED_JIRA_DATE_FILTER_SEARCH_KEY,
    [DEFAULT_START_DATE, DEFAULT_END_DATE],
    getDateFiltersFromURL
  );
  const { data: cases, isLoading } = useQuery(
    [CACHE_KEYS.AI_RECOMMENDED_CASES, ...dateRange],
    () => IrisPortalService.getAiRecommendedCases(dateRange[0], dateRange[1]),
    {
      ...CACHE_TIME[CACHE_KEYS.INCIDENT_SOURCES_DATA]
    }
  );

  const casesWithLinkedJira = cases?.data;

  const handleDateRangeUpdate = (dateRange: [Date, Date]) => {
    setDateRange(dateRange.map((date) => +date).join(','), {
      [Constants.LINKED_JIRA_PAGE_SEARCH_KEY]: '0'
    });
  };

  const handlePageChange = (page: number) => {
    const existingParams = queryString.parse(location.search);
    history.replace({
      search: queryString
        .stringify({
          ...existingParams,
          [Constants.LINKED_JIRA_PAGE_SEARCH_KEY]: page
        })
        .toString()
    });
  };

  const getCurrentPageFromURL = useCallback(() => {
    const page = queryString.parse(location.search)[Constants.LINKED_JIRA_PAGE_SEARCH_KEY]?.toString();
    if (page && Number(page)) {
      return Number(page);
    } else {
      return 0;
    }
  }, [location.search]);

  const {
    onSort,
    filterData,
    sortColumn,
    sortOrder,
    onPageChange,
    currentPage,
    handleChangeRowsPerPage,
    labelDisplayedRows,
    pageSize,
    ROWS_PER_PAGE_OPTIONS
  } = useTableSorters({
    defaultCurrentPage: getCurrentPageFromURL()
  });

  useEffect(() => {
    const currentPageFromURL = getCurrentPageFromURL();
    if (currentPage !== currentPageFromURL) {
      onPageChange(currentPageFromURL);
    }
  }, [currentPage, getCurrentPageFromURL, onPageChange]);

  const filteredData = useMemo(() => {
    if (casesWithLinkedJira) {
      return filterData(casesWithLinkedJira);
    } else {
      return [];
    }
  }, [casesWithLinkedJira, filterData]);

  return (
    <React.Fragment>
      <Stack direction="horizontal" gap={15} align="center" justify="space-between" style={{ float: 'right', marginTop: '-36px' }}>
        <DateRangePicker value={dateRange} onChange={handleDateRangeUpdate} />
      </Stack>
      {isLoading || !casesWithLinkedJira ? (
        <Stack align="center" justify="center" direction="vertical" gap={0} style={{ minHeight: 500 }}>
          <img className="loading" alt="" width="55px" src="/images/spinner.gif" style={{ margin: 0 }} />
        </Stack>
      ) : (
        <TableContainer>
          <Table aria-label="Linked Jira Table">
            <TableHeadSorting order={sortOrder} orderByColumn={sortColumn} onRequestSort={(_, column) => onSort(column)} headCells={TableHeaderCells} />
            <TableBody>
              {filteredData.length === 0 && (
                <TableRow>
                  <TableCell colSpan={100} align="center">
                    <Text>
                      No data. Please ensure you have <a href="/manage-accounts">integrated</a> with Jira. If so, change the date filter to see more results.
                    </Text>
                  </TableCell>
                </TableRow>
              )}
              {filteredData.map((caseItem, index) => (
                <AiRecommendedCaseRow key={caseItem.case.caseId} caseItem={caseItem} alwaysShowViewDetails={index === 0} hideLinkedIcon />
              ))}
            </TableBody>
          </Table>
          {casesWithLinkedJira.length ? (
            <TablePagination
              rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
              component="div"
              count={casesWithLinkedJira.length}
              rowsPerPage={pageSize}
              page={currentPage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              labelDisplayedRows={labelDisplayedRows}
              onChangePage={(_, page) => handlePageChange(page)}
            />
          ) : null}
        </TableContainer>
      )}
    </React.Fragment>
  );
};

export const TableHeaderCells = [
  {
    id: 'Priority',
    label: 'PRIORITY',
    width: '10%',
    hasFilter: true
  },
  {
    id: 'CaseNumber',
    label: 'CASE#',
    width: '15%',
    hasFilter: true
  },
  {
    id: 'caseSubject',
    label: 'SUBJECT',
    width: '19%',
    hasFilter: true
  },
  {
    id: 'caseTimeOfCreation',
    label: 'DATE CREATED',
    width: '13%',
    hasFilter: true
  },
  {
    id: 'incident',
    label: 'LINKED JIRA ISSUE',
    width: '29%',
    hasFilter: true
  },
  {
    id: 'viewDetails',
    label: '',
    width: '14%',
    hasFilter: false
  }
];
