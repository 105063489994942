import React, { FC } from 'react';
import { Stack, Text, useTableSorters } from '../../../components/common';
import { Cell, Pie, PieChart, ResponsiveContainer, Sector, Tooltip, TooltipProps } from 'recharts';
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import TableHeadSorting from '../../../components/table-head-sorting/TableHeadSorting';

const TableHeaderCells = [
  { id: 'colorDot', label: '', width: '10%' },
  { id: 'name', label: 'CATEGORY NAME', width: '30%', hasFilter: true },
  { id: 'value', label: 'PERCENTAGE', width: '20%', hasFilter: true }
];
// TODO: Confirm if we need to show uncategorized cases also?
export const CategoryDistributionChart: FC<{
  style?: React.CSSProperties;
  data: Array<{ name: string; value: number }>;
  totalCases: number;
}> = (props) => {
  const { style, data, totalCases } = props;
  const { onSort, sortColumn, sortOrder } = useTableSorters({
    turnOffPagination: true
  });
  const COLORS = ['#ACA4E3', '#58508D', '#2EECA8', '#C62EEC', '#602EEC'];
  return (
    <Stack style={{ ...style, marginTop: 16 }}>
      <Stack direction="vertical">
        <Text variant="p4" weight="bold" color="dark">
          Category distribution
        </Text>
        <ResponsiveContainer height={350} width="100%">
          <PieChart>
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              innerRadius={100}
              outerRadius={150}
              fill="#8884d8"
              paddingAngle={1}
              dataKey="value"
              activeIndex={0}
              activeShape={({ cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value }) => (
                <g>
                  <text x={cx} y={cy} dy={8} textAnchor="middle" fill="#383838" fontSize="36px">
                    {totalCases}
                  </text>
                  <text x={cx} y={cy} dy={36} textAnchor="middle" fill="rgba(56, 56, 56, 0.63)" fontSize="14px">
                    Total Cases
                  </text>
                  <Sector cx={cx} cy={cy} innerRadius={innerRadius} outerRadius={outerRadius} startAngle={startAngle} endAngle={endAngle} fill={fill} />
                </g>
              )}
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip content={<CustomTooltip />} />
          </PieChart>
        </ResponsiveContainer>
        <TableContainer style={{ maxHeight: 400 }}>
          <Table size="small">
            <TableHeadSorting order={sortOrder} orderByColumn={sortColumn} onRequestSort={(_, column) => onSort(column)} headCells={TableHeaderCells} />
            <TableBody>
              {data
                .sort((caseItem1, caseItem2) => caseItem2.value - caseItem1.value)
                .map((legend, index) => (
                  <TableRow key={legend.name}>
                    <TableCell>
                      <span
                        style={{
                          width: 18,
                          height: 18,
                          borderRadius: '50%',
                          backgroundColor: COLORS[index % COLORS.length],
                          display: 'inline-block'
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <Text variant="p1" weight="regular" lineClamp={2} style={{ textOverflow: 'ellipsis', display: 'block' }}>
                        {legend.name}
                      </Text>
                    </TableCell>
                    <TableCell>
                      <Text variant="p1" weight="semi-bold" style={{ color: COLORS[index % COLORS.length] }}>
                        {legend.value}%
                      </Text>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </Stack>
  );
};

const CustomTooltip: FC<TooltipProps<number, string>> = (props) => {
  const { active, payload } = props;
  if (active && payload && payload.length) {
    const categoryName = payload[0].name;
    const casesCount = payload[0].value;
    const color = payload[0].payload.fill;
    return (
      <Stack
        style={{
          backgroundColor: `white`,
          padding: '5px 10px',
          borderRadius: 100,
          border: `1px solid ${color}`
        }}
      >
        <Text variant="p0" weight="semi-bold" style={{ color }}>
          {categoryName}: {casesCount}
        </Text>
      </Stack>
    );
  }
  return null;
};
