import _ from 'lodash';
import {
  CategoryAccountName,
  CategoryCategories,
  CategoryStatus,
  CategorySubject,
  CategoryTags,
  CategoryTicketContent,
  CategoryTicketPriority,
  DropboxHasActiveSubscription,
  DropboxHasMultipleDevicesConnected,
  DropboxHasPremiumSupport,
  DropboxIsEligibleForRefund
} from '../constants';

export function validateTriggerData(triggerData: any, customSimpleTriggerFact: boolean = false): boolean {
  const possibleSimpleTriggerOperators = ['eq', 'ne', 'contains', 'doesNotContain', 'in', 'nin', 'geq', 'value_changed', 'value_changed_to'];
  const possibleCompoundTriggerOperators = ['and', 'or'];

  const possibleSimpleTriggerFacts = [
    CategoryAccountName,
    CategoryCategories,
    CategorySubject,
    CategoryStatus,
    CategoryTags,
    CategoryTicketContent,
    CategoryTicketPriority,
    DropboxHasActiveSubscription,
    DropboxHasMultipleDevicesConnected,
    DropboxHasPremiumSupport,
    DropboxIsEligibleForRefund
  ];

  const triggerOperatorsByTriggerFact: Record<string, any[]> = {
    [CategoryAccountName]: ['eq'],
    [CategoryCategories]: ['in'],
    [CategorySubject]: ['contains', 'doesNotContain'],
    [CategoryStatus]: ['in', 'nin'],
    [CategoryTags]: ['in', 'nin'],
    [CategoryTicketContent]: ['contains', 'doesNotContain'],
    [CategoryTicketPriority]: ['in', 'nin'],
    [DropboxHasActiveSubscription]: ['eq'],
    [DropboxHasMultipleDevicesConnected]: ['eq'],
    [DropboxHasPremiumSupport]: ['eq'],
    [DropboxIsEligibleForRefund]: ['eq']
  };

  if (_.isEqual(triggerData.type, 'simple')) {
    if (!possibleSimpleTriggerOperators.includes(triggerData.operator)) {
      return false;
    }

    if (!customSimpleTriggerFact && !possibleSimpleTriggerFacts.includes(triggerData.fact)) {
      return false;
    }

    if (!customSimpleTriggerFact && !triggerOperatorsByTriggerFact[triggerData.fact].includes(triggerData.operator)) {
      return false;
    }

    return !(_.isEqual(triggerData.fact, 'tags') && !Array.isArray(triggerData.value));
  }

  if (_.isEqual(triggerData.type, 'compound')) {
    if (!possibleCompoundTriggerOperators.includes(triggerData.operator)) {
      return false;
    }

    if (!Array.isArray(triggerData.operands)) {
      return false;
    }

    for (const triggerOperand of triggerData.operands) {
      if (!validateTriggerData(triggerOperand)) {
        return false;
      }
    }

    return true;
  }

  if (_.isEqual(triggerData.type, 'complex')) {
    return validateTriggerData(triggerData.value, true);
  }

  return false;
}
