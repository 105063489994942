import Utils from '../../utils/utils';
import MockData from '../../utils/mockData';
import { doRequestWithCache, handleError } from './utils';

export type ChatbotSetting = {
  chatbotSetting: {
    bannerColor: string;
    brandLogo: string;
    welcomeMessage: string;
  };

  externalIdentifier: string;
};

export const getChatbotSetting = async (): Promise<ChatbotSetting> => {
  return await doRequestWithCache('/v1/portal/chatbot/settings', MockData.getChatbotSettingsMockData);
};

export const createChatbotSetting = async (brandLogo: File, bannerColor: string): Promise<void> => {
  const tokenExpress = Utils.getObjectItem('token_express');

  const formData = new FormData();
  formData.append('brandLogo', brandLogo);
  formData.append('bannerColor', bannerColor);

  const requestOptions = {
    method: 'PUT',
    headers: new Headers({ Authorization: `Bearer ${tokenExpress}` }),
    body: formData
  };
  const response = await fetch(`${process.env.REACT_APP_APISERVER_URL}/v1/portal/chatbot/settings`, requestOptions);

  handleError(response.status);

  if (!response.ok) {
    throw new Error('Some error occurred');
  }

  return;
};
