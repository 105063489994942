import React, { FC } from 'react';
import { Stack, Text } from '../../../components/common';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { Tooltip, withStyles } from '@material-ui/core';
import { format } from 'date-fns';

const WIDTH = 45;
export const ChangeIndicator: FC<{
  value: number | null;
  dateRange?: [Date, Date];
  // If growth is increment
  // positive values will be shown in green
  // else negative values will be show in green
  // NOTE: Default value of growth is `decrement`
  growth?: 'increment' | 'decrement';
}> = (props) => {
  const { value, dateRange, growth = 'decrement' } = props;
  const dateFormatter = (date: Date) => format(date, 'dd-MM-yyyy');
  const color = (function () {
    // Invert the value, if the growth === 'increment'
    // Since the whole function was written with the default
    // value of growth as 'decrement'
    const effectiveValue = value && growth === 'increment' ? value * -1 : value;
    if (effectiveValue === null) {
      return `lightgray`;
    } else if (effectiveValue > 0) {
      return '#FF6361';
    } else {
      return '#02BC6B';
    }
  })();

  if (value === null) {
    return (
      <Text variant="p0" weight="semi-bold" style={{ color, textAlign: 'right', width: WIDTH }}>
        N/A
      </Text>
    );
  }

  const displayValue = Math.abs(value).toFixed(0) + '%';
  const valueSuffix = value === 0 ? 'change' : value > 0 ? 'increase' : 'decrease';
  return (
    <LightTooltip
      placement="top"
      arrow
      title={
        dateRange ? (
          <Text variant="p0">
            {displayValue} {valueSuffix} compared to previous duration {dateFormatter(dateRange[0])} to {dateFormatter(dateRange[1])}
          </Text>
        ) : (
          false
        )
      }
    >
      <Stack direction="horizontal" align="center" gap={1} justify="space-between" style={{ width: WIDTH, flexBasis: WIDTH }} className={'align-self-center'}>
        <Text variant="p0" weight="semi-bold" style={{ color, textAlign: 'right', flex: 1 }}>
          {displayValue}
        </Text>
        {/* Do not show the arrow when the value is zero */}
        {value === 0 ? null : value > 0 ? <ArrowDropUpIcon style={{ color: color }} /> : <ArrowDropDownIcon style={{ color: color }} />}
      </Stack>
    </LightTooltip>
  );
};

const LightTooltip = withStyles(() => ({
  tooltip: {
    background: 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    maxWidth: 250
  },
  arrow: {
    color: 'white'
  }
}))(Tooltip);
