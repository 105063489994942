import { VisualBucket } from '../../services/types';

export type CustomerSentimentScores = {
  _id: {
    AccountName: string;
  };
  sentimentScore: number;
  numCases: number;
};

export enum HealthParameter {
  TTR = 'ttr',
  CSAT = 'csat',
  NUM_CASES = 'numCases',
  CASE_SENTIMENT_SCORE = 'caseSentimentScore',
  PRIORITY = 'priority',
  ACV = 'acv',
  NUM_ESCALATED_CASES = 'numEscalatedCases',
  CONTRACT_EXPIRY = 'contractExpiry'
}

export enum PriorityScoreParameter {
  CASE_SENTIMENT_SCORE = 'caseSentimentScore',
  OPEN_TIME_SCORE = 'openTime',
  COMMENTS_SCORE = 'comments',
  SOURCE_PRIORITY_SCORE = 'sourcePriority',
  ACV_SCORE = 'acv'
}

export type Score = {
  score: number;
};

export type ParameterScore = {
  score: number;
};

export type ParameterHealthScore = ParameterScore & {
  type: HealthParameter;
  value?: number;
  subtitle: string;
  healthBucket?: VisualBucket;
};

export type HealthScore = Score & {
  parameters?: ParameterHealthScore[];
};

export type ParameterCasePriorityScore = ParameterScore & {
  type: PriorityScoreParameter;
  scoreBucket: VisualBucket;
};

export type CasePriorityScore = Score & {
  parameters: ParameterCasePriorityScore[];
};

export type CustomerAccount = {
  name: string;
  annualContractValue?: number;
  health: HealthScore;
  healthBucket?: VisualBucket;
  currency?: string;
};

export type CustomerAccountFormat = {
  name: string;
  annualContractValue?: number;
  healthScore: number;
};

export type CustomerWithNumCases = {
  _id: {
    AccountName: string;
  };
  numCases: number;
};

export type CaseSentiment = {
  score: number;
  impactfulSentence?: string;
};

export type Case = {
  _id: string;
  Id: string;
  CaseNumber: string;
  priority: string;
  AccountName: string;
  Subject: string;
  caseSentiment: CaseSentiment;
  InstanceURL: string;
  timeOfCreation: string;
  Category: string[];
  agentNames?: string[];
  teamNames?: string[];
  teamAgentName?: string;
};

export type CaseWithStatus = Case & {
  Status: string;
  sentimentCategory: string[];
  categories: string[];
};

export type PriorityExtras = {
  annualContractValue?: number;
  commentsLength?: number;
  caseSentimentBucket?: VisualBucket;
  openTimeSecs: number;
};

export type CaseWithPriority = Case &
  PriorityExtras & {
    Status: string;
    priorityScore: CasePriorityScore;
  };

export type HighPriorityCase = CaseWithPriority & {
  agentNames: string[];
};

export type CustomerHealthDataV2 = {
  customerHealth: {
    customerLowestHealthScores: CustomerAccount[];
    customerPositiveSentimentScores: CustomerSentimentScores[];
    negativeCaseList: CaseWithStatus[];
    customerWithNumCases: CustomerWithNumCases[];
    [key: string]: any;
  };
};

export type CustomerHealthDataExtras = {
  customerHealth: {
    highPriorityCaseList: CasePriorityScore[];
    binnedSentimentCategory: Record<string, any>;
    perDayCustomerHealthCounts: Record<string, any>;
    [key: string]: any;
  };
};

export type RecentCasesForAccount = {
  cases: Case[];
};

export type AccountHealth = {
  categoryCaseDistribution: Record<string, number>;
  caseTimeDistribution: Record<
    string,
    {
      cases: Array<Pick<Case, '_id' | 'CaseNumber' | 'timeOfCreation' | 'Category'>>;
      totalSentimentScore?: number;
      casesWithSentimentScore?: number;
    }
  >;
  averageSentimentScore: number;
  totalCases: number;
  closedCases: number;
  mttr: number;
  accountDetails: CustomerAccount;
  satisfactionRatingsData: {
    score: number;
    surveyCount: number;
  };
  linkedJiraIssues: LinkedJiraIssue[];
};

export type LinkedJiraIssue = {
  issueKey: string;
  url: string;
  summary: string;
  priority: string;
  issueStatus: string;
  assignee?: string;
};
