import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react';

import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@material-ui/core';
import { Text } from '../../components/common/blocks/Text';

import './table-with-tabs.scss';
import { useTableSorters } from '../common';
import SearchWidget from '../../routes/categories/components/SearchWidget';
import { RuleTriggersTableHeadButton } from '../../routes/rules/components/RulesTriggersTable/RulesTriggersTableHeadButton';
import { TableWithTabsRow } from './TableWithTabsRow';
import _ from 'lodash';

export interface ITableStateFilter<T> {
  (item: T): boolean;
}

export interface TableHeader {
  name: string;
  width: string;
}

interface ITableWithTabsProps<T> {
  header: string;
  tabs: string[];
  tableHeaders: TableHeader[];
  data: T[];
  getTableStatePredicate: (state: ITableStateFilter<T>, action: string) => ITableStateFilter<T>;
  getSearchableValue: (item: T) => string;
  renderDatum: (item: T) => React.ReactNode;
}

export function TableWithTabs<T>(props: ITableWithTabsProps<T>) {
  const { data, tabs, getTableStatePredicate, getSearchableValue, renderDatum, tableHeaders } = props;

  // Table State
  const [tabState, setTabState] = useState<string>(props.tabs[0]);
  const [tablePredicate, dispatchTablePredicate] = useReducer(props.getTableStatePredicate, (_rule) => true);
  const handleChangeTabState = useCallback((newState: string) => {
    setTabState(newState);
    dispatchTablePredicate(newState);
  }, []);
  const [filteredData, setFilteredData] = useState(data.filter(tablePredicate));

  // Pagination state
  const [searchValue, setSearchValue] = useState<string>('');
  const { filterData, onPageChange, currentPage } = useTableSorters({
    pageSize: 10
  });
  const { current: tablePaginationOptions } = useRef({
    component: 'div' as React.ElementType,
    rowsPerPage: 10,
    rowsPerPageOptions: [10]
  });

  // Search Widget
  const searchableValues = React.useMemo(() => filteredData.map(getSearchableValue), [filteredData, getSearchableValue]);

  // Composite Hook with Composite State
  useEffect(() => {
    if (_.isEmpty(searchValue)) {
      setFilteredData(data.filter(tablePredicate));
    } else {
      const wordsInput = searchValue.split(/_|-| /).filter((val: any) => val);
      const newData = data.filter(tablePredicate).filter((item) => {
        return (
          wordsInput.filter((word: any) => getSearchableValue(item).split('_').join('').toLowerCase()?.indexOf(word.toLowerCase().trim()) > -1).length ===
          wordsInput.length
        );
      });
      setFilteredData(newData);
    }
  }, [searchValue, data, tablePredicate, getSearchableValue]);

  return (
    <>
      <div className="table-with-tabs-table-head-title" style={{ display: 'flex', flexDirection: 'row' }}>
        <Text variant="p4" weight="semi-bolder" color="dark">
          {props.header}
        </Text>
        <div
          style={{
            float: 'right',
            marginLeft: 'auto'
          }}
        >
          <div
            style={{
              width: '300px',
              height: '40px',
              marginRight: '16px'
            }}
          >
            <SearchWidget
              showDropdown={false}
              placeholder="Search"
              isCustom={true}
              searchValue={searchValue}
              dataList={searchableValues}
              onInputChange={(input: string) => {
                setSearchValue(input);
              }}
            />
          </div>
        </div>
      </div>
      <TableContainer className="table-with-tabs-table-container">
        <Table>
          <colgroup>
            {tableHeaders.map((tableHeader) => (
              <col style={{ width: tableHeader.width }} />
            ))}
          </colgroup>
          <TableHead>
            <div className="table-with-tabs-table-head-content">
              <div className="table-with-tabs-table-head-buttons">
                {tabs.map((tab, index) => {
                  return (
                    <RuleTriggersTableHeadButton
                      key={index}
                      subTitle={tab}
                      onClick={() => handleChangeTabState(tab)}
                      isSelected={tabState === tab}
                      title={`${data.filter(getTableStatePredicate(() => true, tab)).length}`}
                    />
                  );
                })}
              </div>
            </div>
            <TableRow>
              {tableHeaders.map((tableHeader, index) => {
                return (
                  <TableCell align="left" key={index}>
                    {tableHeader.name}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {filterData(filteredData).map((item, index) => {
              return <TableWithTabsRow key={index}>{renderDatum(item)}</TableWithTabsRow>;
            })}
          </TableBody>
        </Table>
        <TablePagination count={filteredData.length} onChangePage={(_, page) => onPageChange(page)} page={currentPage} {...tablePaginationOptions} />
      </TableContainer>
    </>
  );
}
