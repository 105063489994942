import React, { useRef } from 'react';

import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@material-ui/core';

import { Text } from '../../../../components/common/blocks/Text';
import { DetectedIncidentsManagementTableItem } from './DetectedIncidentsManagementTableItem';

import './styles.scss';
import { useTableSorters } from '../../../../components/common';
import { DetectedIncidentsSetting } from './types';
import _ from 'lodash';
import AddIcon from '@material-ui/icons/Add';
import { DetectedIncidentsInputFormHolder } from './DetectedIncidentsSettingsHolder/DetectedIncidentsInputFormHolder';
import IrisPortalService from '../../../../services/IrisPortalService';

export type DetectedIncidentsManagementTableData = Array<DetectedIncidentsSetting>;

export const DetectedIncidentsManagementTable: React.FC = () => {
  const data = IrisPortalService.getDetectedIncidentSettings();

  const { onPageChange, currentPage } = useTableSorters({
    pageSize: 10
  });

  const { current: tablePaginationOptions } = useRef({
    component: 'div' as React.ElementType,
    rowsPerPage: 10,
    rowsPerPageOptions: [10]
  });

  return (
    <React.Fragment>
      {
        <>
          <DetectedIncidentsInputFormHolder
            onSuccess={() => () => {}}
            item={undefined}
            buttonComponent={
              <div
                className="create-button"
                style={{
                  backgroundColor: `rgb(93, 95, 239)`,
                  width: '300px',
                  height: '50px',
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'row'
                }}
              >
                <div className={'create-button-icon-detected-incidents-settings'}>
                  <AddIcon
                    style={{
                      width: '30px',
                      height: '30px',
                      color: 'white'
                    }}
                  />
                </div>
                <p style={{ color: 'white' }}> Add a new configuration </p>
              </div>
            }
          />
          <br />
          <Text variant="p4" weight="semi-bolder" color="dark">
            List of configurations
            {!_.isEmpty(data) && ` (${data.length})`}
          </Text>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left">TAG</TableCell>
                  <TableCell align="left">CASE THRESHOLD</TableCell>
                  <TableCell align="left">TIME INTERVAL (DAYS)</TableCell>
                  <TableCell align="left">STATUS</TableCell>
                  <TableCell align="left">CREATED ON</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((item: any, index: any) => (
                  <DetectedIncidentsManagementTableItem
                    onSuccess={() => () => {}}
                    key={`${item._id}.${index}`}
                    item={item}
                    // getTypeActionItem={getTypeActionItem}
                  />
                ))}
              </TableBody>
            </Table>
            <TablePagination count={data.length} onChangePage={(_, page) => onPageChange(page)} page={currentPage} {...tablePaginationOptions} />
          </TableContainer>
        </>
      }
    </React.Fragment>
  );
};
