import { ReactElement } from 'react';

import { Action, HandleRowComponentType, Option } from '../types';

export abstract class RulesRowManager {
  action: Action;
  options: Option[];
  type?: string;
  constructor(options: Option[], actionValue: any, actionOperator: any, type?: string) {
    this.action = {
      id: Math.random() * 1000,
      value: actionValue,
      type: '',
      operator: actionOperator
    };
    this.options = options;
    this.type = type;
    if (this.action.value === undefined) this.setDefaultActionValue();
  }
  /**
   * Function to set a default value for the action value
   */
  abstract setDefaultActionValue(): void;
  /**
   * Function for creating items into a Rule/Trigger row (selects, textareas, etc)
   * @param handleRowComponent parent handler function when something changes
   */
  abstract createItems(handleRowComponent: HandleRowComponentType, s?: any, ss?: any): ReactElement;
}
