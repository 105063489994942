import React, { ReactElement } from 'react';
import { RulesRowManager } from '../../../utils';
import { HandleRowComponentType, Option } from '../../../types';
import '../styles.scss';
import { ActionWriteCaseTagsCustom } from '../../../constants';
import { TextField, Typography } from '@material-ui/core';
import Constants from '../../../../../utils/constants';
import { ReactComponent as TagSvg } from '../../../../../assets/tag.svg';

type RestrictedChar = ' ' | '(' | ')';
const RESTRICTED_CHARS: readonly RestrictedChar[] = [' ', '(', ')'] as const;

const isRestrictedChar = (key: string): key is RestrictedChar => RESTRICTED_CHARS.includes(key as RestrictedChar);

export class WriteCaseTagsCustomRowManager extends RulesRowManager {
  constructor(options: Option[], actionValue: any, actionOperator: any, type: string) {
    super(options, actionValue, actionOperator, type);
    this.action.type = ActionWriteCaseTagsCustom;
  }

  setDefaultActionValue(): void {
    this.action.value = { content: '' };
  }

  createItems(handleRowComponent: HandleRowComponentType): ReactElement {
    return (
      <div className={'d-flex flex-column'}>
        <div className={'d-flex flex-row'}>
          <TagSvg />
          <p className="title">Write custom tag</p>
        </div>
        <Typography variant="caption" display="block" gutterBottom align={'left'}>
          {Constants.TRIGGER_FORMAT_TAG_NUDGE}
        </Typography>
        <div className={'d-flex flex-row'} style={{ marginTop: 8 }}>
          <p className="operator-text">Enter custom tag</p>
          <div style={{ width: '20em' }}>
            <TextField
              required
              size="medium"
              style={{ width: '100%' }}
              id="outlined-error-helper-text"
              placeholder="Enter tag"
              value={this.action.value.content}
              variant="outlined"
              inputProps={{
                maxLength: 50,
                style: { height: '14px' },
                // Prevent space, newline, and parentheses
                pattern: '[^\\s()]*'
              }}
              onChange={(e) => {
                // Remove spaces, newlines, and parentheses from input
                const sanitizedValue = e.target.value.replace(/[\s()]/g, '');
                this.action.value.content = sanitizedValue;
                handleRowComponent(this.action);
              }}
              // Prevent space and parentheses on keydown
              onKeyDown={(e) => {
                if (isRestrictedChar(e.key)) {
                  e.preventDefault();
                }
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
