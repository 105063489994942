import Utils from './utils/utils';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import React from 'react';
import Constants, { Source } from './utils/constants';
import _ from 'lodash';
import { Loader } from './components/common/blocks/Loader';

interface ProtectedRouteProps extends RouteProps {
  backfillProtected?: boolean;
}

const IRIS_GPT_PATHS = [
  Constants.routes.TRIGGERS,
  Constants.routes.TRIGGERS_DETAILS,
  Constants.routes.AUTOMATION_IMPACT,
  Constants.routes.LOGIN,
  Constants.routes.IRIS_GPT,
  Constants.routes.MANAGE_ACCOUNTS,
  Constants.routes.MANAGE_CHATBOT,
  Constants.routes.MANAGE_INTEGRATION_USER,
  Constants.routes.MANAGE_ORGANIZATIONS,
  Constants.routes.MANAGE_USERS,
  Constants.routes.CHATBOT_ACTIVITY
];

export default function ProtectedRoute(props: ProtectedRouteProps) {
  const { backfillProtected = true, ...rest } = props;

  if (Utils.isDemo()) {
    return <Route {...rest} />;
  }

  const isLoggedIn = Utils.isLoggedIn();
  if (!isLoggedIn) {
    return (
      <Redirect
        to={{
          pathname: Constants.routes.LOGIN,
          state: { from: props.location }
        }}
      />
    );
  }

  const { data: customer, isLoading } = Utils.useCustomerQuery(true);
  if (isLoading) {
    return (
      <div className={'d-flex align-items-center justify-content-center'} style={{ minHeight: '90vh' }}>
        <Loader />
      </div>
    );
  }

  if (_.isEqual(customer?.sourceName, Source.IRIS_GPT) && !_.includes(IRIS_GPT_PATHS, props.path)) {
    return (
      <Redirect
        to={{
          pathname: Constants.routes.LOGIN,
          state: { from: props.location }
        }}
      />
    );
  }

  if (backfillProtected && !customer?.backfillDone) {
    return (
      <Redirect
        to={{
          pathname: Constants.routes.DASHBOARD,
          state: { from: props.location }
        }}
      />
    );
  }

  return <Route {...rest} />;
}
