import React, { forwardRef, useCallback, useImperativeHandle, useState } from 'react';

import { TextField } from '@material-ui/core';

interface ITriggerNameInputProps {
  triggerId: any;
  triggersData: any;
  nameValue: any;
  updateValue(newValue: any): void;
}

export interface ITriggerNameInputRef {
  setError(message: string): void;
  hasError: boolean;
}

const TriggerNameInputComponent: React.ForwardRefRenderFunction<ITriggerNameInputRef, ITriggerNameInputProps> = (
  { triggerId, triggersData, nameValue, updateValue },
  ref
) => {
  const [hasError, setHasError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const setError = useCallback((message: string) => {
    setHasError(!!message);
    setErrorMessage(message);
  }, []);

  const validateNameTrigger = useCallback(
    (value: string) => {
      if (hasError) {
        setHasError(false);
        setErrorMessage('');
      }

      const ruleFound = triggersData?.triggers.findIndex((rule: any) => (triggerId ? triggerId !== rule.id && rule.name === value : rule.name === value));

      if (ruleFound >= 0) {
        setErrorMessage('This trigger name already being used. Try choosing another name.');
        setHasError(true);
      }

      updateValue(value);
    },
    [hasError, triggerId, triggersData, updateValue]
  );

  const handleOnFocus = useCallback<React.FocusEventHandler<HTMLInputElement>>(
    (_event) => {
      const focusRelatedErrors = ['This field is required.'];

      if (focusRelatedErrors.includes(errorMessage)) {
        setHasError(false);
        setErrorMessage('');
      }
    },
    [errorMessage]
  );

  const handleOnBlur = useCallback<React.FocusEventHandler<HTMLInputElement>>((event) => {
    if (!event.target.value.length) {
      setHasError(true);
      setErrorMessage('This field is required.');
    }
  }, []);

  useImperativeHandle(ref, () => ({
    setError,
    hasError
  }));

  return (
    <TextField
      required
      size="small"
      onFocus={handleOnFocus}
      onBlur={handleOnBlur}
      style={{ width: '30%', height: '32px' }}
      error={!!hasError}
      id="outlined-error-helper-text"
      placeholder="Enter Trigger Name"
      value={nameValue}
      helperText={errorMessage}
      variant="outlined"
      onChange={(e) => validateNameTrigger(e.target.value)}
    />
  );
};

export const TriggerNameInput = forwardRef(TriggerNameInputComponent);
