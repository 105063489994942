import Utils from '../../utils/utils';
import MockData from '../../utils/mockData';
import { doRequest, doRequestWithCache } from './utils';
import { Trigger, TriggersOverallImpactData, TriggersOverallImpactTrendData, TriggerStatus } from '../../routes/rules/types';
import { TriggerActionCasesArray } from '../../routes/rules/components/TriggerActionCases';

export const getTrigger = async (triggerId: any) => {
  return await doRequest(`/v1/automation/triggers/${triggerId}`, MockData.getTriggersMockData);
};

export const getTriggers = async () => {
  return await doRequestWithCache('/v1/automation/triggers', MockData.getTriggersMockData);
};

export const getTriggersPrerequisites = async () => {
  return await doRequestWithCache('/v1/automation/triggers/prerequisites', MockData.getTriggersPrerequisitesMockData);
};

export type TriggerResponse = { id: string };

export const createTrigger = async (trigger: Trigger): Promise<TriggerResponse> => {
  return await doRequest('/v1/automation/triggers', () => {}, 'POST', { trigger: trigger });
};

export const editTrigger = async (trigger: Trigger): Promise<TriggerResponse> => {
  return await doRequest(`/v1/automation/triggers/${trigger.id}`, () => {}, 'PUT', { trigger: trigger });
};

export const updateTriggerStatus = async (triggerId: string, status: TriggerStatus): Promise<TriggerResponse> => {
  return await doRequest(`/v1/automation/triggers/${triggerId}/status`, () => {}, 'PUT', { status: status });
};

export const removeTrigger = async (triggerId: string): Promise<TriggerResponse> => {
  if (!triggerId || triggerId === '') {
    return { id: '' };
  }

  return await doRequest(`/v1/automation/triggers/${triggerId}`, () => {}, 'DELETE');
};

export const getTriggersOverallImpactData = async (): Promise<TriggersOverallImpactTrendData> => {
  const url = `/v1/automation/triggers/impact`;
  return await doRequest(url, MockData.getTriggersOverallImpactData);
};

export const getTriggersOverallImpactTrendData = async (startTime: Date, endTime: Date): Promise<TriggersOverallImpactTrendData> => {
  const params = new URLSearchParams({
    startTime: Utils.formatDateToUTC(startTime),
    endTime: Utils.formatDateToUTC(endTime)
  });

  const url = `/v1/automation/triggers/impact/trend?${params.toString()}`;
  return await doRequest(url, MockData.getTriggersOverallImpactTrendData);
};

export const getTriggerImpactData = async (triggerId: string, startTime: Date, endTime: Date): Promise<TriggersOverallImpactData> => {
  const params = new URLSearchParams({
    startTime: Utils.formatDateToUTC(startTime),
    endTime: Utils.formatDateToUTC(endTime)
  });

  const url = `/v1/automation/triggers/${triggerId}/impact?${params.toString()}`;
  return await doRequest(url, MockData.getTriggersOverallImpactData);
};

export const getTriggerActionCases = async (triggerId: string): Promise<TriggerActionCasesArray> => {
  return await doRequest(`/v1/automation/triggers/${triggerId}/cases`, MockData.getTriggerActionCases);
};

export const toggleSelfDeflection = async (status: TriggerStatus): Promise<TriggerResponse> => {
  return await doRequest(`/v1/automation/triggers/case-deflection`, () => {}, 'POST', { status: status });
};

export type MacroDetails = {
  id: string;
  name: string;
  url: string;
};

export const GetMacroNameByID = async (macroID: string): Promise<MacroDetails> => {
  return doRequestWithCache(`/v1/automation/triggers/macros/${macroID}`, function () {
    return {
      id: macroID,
      name: `Sample Macro: ${macroID}`,
      url: 'https://irisagent.com/'
    };
  });
};
