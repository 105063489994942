import { format } from 'date-fns';
import React from 'react';

import Utils from '../../../../utils/utils';

import './Graph.scss';

const AdditionalDataTooltip = ({ active, payload, label, lines, additionalTipOrder }: any) => {
  if (active && payload && payload.length) {
    const line = lines.filter((line: any) => {
      return line.name === payload[0].dataKey;
    })[0];

    const getAdditionalTips = () => {
      const otherLines = lines.filter((line: any) => {
        return line.name !== payload[0].dataKey;
      });
      return otherLines.map((ol: any) => {
        // console.log('addi', payload[0].payload[ol.name]);
        return (
          <p style={{ color: ol.color }}>
            {ol.label}:{' '}
            {ol.type === 'currency' ? Utils.getCurrencyValue(payload[0].payload[ol.name] ? payload[0].payload[ol.name] : 0) : payload[0].payload[ol.name]}
          </p>
        );
      });
    };
    return (
      <div className="customTooltip">
        <div className="tooltipDetails">
          <span style={{ textAlign: 'center' }}>{format(new Date(label), 'dd MMM')}</span>
          {additionalTipOrder === 0 && getAdditionalTips()}
          <p className="label" style={{ color: line.color }}>
            {line.label}:{' '}
            {line.type === 'currency'
              ? Utils.getCurrencyValue(payload[0].payload[line.name] ? payload[0].payload[line.name] : 0)
              : payload[0].payload[line.name] + line.unit}
          </p>
          {additionalTipOrder === 1 && getAdditionalTips()}
        </div>
      </div>
    );
  }

  return null;
};
export default AdditionalDataTooltip;
