export enum DetectedIncidentsSettingStatus {
  ENABLED = 'enabled',
  DISABLED = 'disabled'
}

export type DetectedIncidentsSetting = {
  _id?: string;
  name: string;
  status: DetectedIncidentsSettingStatus.ENABLED | DetectedIncidentsSettingStatus.DISABLED;
  createdAt?: string;
  settings: {
    category: string;
    case_threshold: number;
    time_interval: number;
  };
};

export type DetectedIncidentsSettingsPrerequisites = {
  categories: string[];
};
