import React, { ReactElement } from 'react';

import { RulesRowManager } from '../../../utils';
import CKEditorIris from '../../../../../CKEditorIris';

import { HandleRowComponentType, Option } from '../../../types';
import { ReactComponent as AutoRespond } from '../../../../../assets/auto_respond.svg';
import '../styles.scss';
import Constants, { Source } from '../../../../../utils/constants';
import { Checkbox, FormControlLabel, TextField, Typography } from '@material-ui/core';
import Utils from '../../../../../utils/utils';
import _ from 'lodash';
import { ActionReplyToCase } from '../../../constants';

/**
 * Row manager for Auto Respond
 */
export class AutoRespondRowManager extends RulesRowManager {
  constructor(options: Option[], actionValue: any, actionOperator: any, type: string, actionName: string) {
    super(options, actionValue, actionOperator, type);
    this.action.type = actionName;
  }

  setDefaultActionValue(): void {
    this.action.value = {
      deferIfAgentReplied: true
    };
  }

  createItems(handleRowComponent: HandleRowComponentType): ReactElement {
    let user = Utils.getObjectItem('user');
    const label = _.isEqual(this.action.type, ActionReplyToCase) ? 'Auto-respond to customer' : 'Private Note';

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', flexDirection: 'row', height: '2em' }} className={'align-items-center relative'}>
          <AutoRespond />
          <p className="title">{label}</p>
          {_.isEqual(this.action.type, ActionReplyToCase) ? (
            <FormControlLabel
              style={{}}
              className={'absolute right-0 title'}
              control={
                <Checkbox
                  color="default"
                  name={'Do not auto-respond in case a reply is present'}
                  size="small"
                  onChange={(e, checked) => {
                    this.action.value.deferIfAgentReplied = checked;
                  }}
                  defaultChecked={this.action.value.deferIfAgentReplied}
                />
              }
              label={'Do not auto-respond in case a reply is present'}
            />
          ) : null}
        </div>
        <Typography variant="caption" display="block" gutterBottom align={'left'}>
          {Constants.TRIGGER_FORMAT_NUDGE}
        </Typography>
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 8 }}>
          <div style={{ fontWeight: 'normal', width: '99%' }}>
            {/*Show plaintext editor for SF customers as SF auto-respond does not support html formatting*/}
            {_.isEqual(user.sourceName, Source.SALESFORCE) ? (
              <TextField
                required
                size="medium"
                style={{
                  width: '100%'
                }}
                id="outlined-error-helper-text"
                placeholder="Enter Auto-respond message"
                value={this.action.value.content}
                multiline={true}
                variant="outlined"
                onChange={(e) => {
                  this.action.value.content = e.target.value;
                  handleRowComponent(this.action);
                }}
              />
            ) : (
              <CKEditorIris
                defaultValue={this.action.value.content}
                onChange={(event: any, editor: { getData: () => any }) => {
                  const data = editor.getData();
                  this.action.value.content = data;
                  handleRowComponent(this.action);
                }}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}
