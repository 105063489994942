import React, { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Heading, Stack, Text } from '../../../../components/common';
import { PageContainer } from '../../../../components/common/blocks/PageContainer';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import { useQuery } from 'react-query';
import IrisPortalService from '../../../../services/IrisPortalService';
import { Loader } from '../../../../components/common/blocks/Loader';
import { ImportantStats, RevenueDetail, StatsGraphsCharts } from '../ImportantStats';
// import { RecentCasesTable } from '../RecentCasesTable';
import { CACHE_KEYS, CACHE_TIME } from '../../../../utils/cacheUtils';
import Constants from '../../../../utils/constants';
import Utils from '../../../../utils/utils';
import utils from '../../../../utils/utils';
import AccountSearch from '../AccountSearch';
import { queryClient } from '../../../../utils/queryCache';
import './HealthAccount.scss';
import { useResetPage } from '../../../../hooks/useResetPage';
import { LinkedJiraIssues } from '../CaseMetrics';
import { CustomerEngagementGraph } from './CustomerEngagementGraph';
import { TablePaper } from '../../../../components/common/blocks/TablePaper';
import { isMocKData } from '../../../../services/IrisPortalService/utils';

export const HealthAccount: FC = (props) => {
  const location = useLocation();
  const history = useHistory();

  const checkCacheAllAccountNames = (keyCheck: string) => {
    const categoriesDataCache = queryClient.getQueryCache();
    return categoriesDataCache.getAll().find((categoryCache) => JSON.stringify(categoryCache.queryKey) === keyCheck);
  };

  const allAccountNames = () => {
    const accountCacheData = checkCacheAllAccountNames(JSON.stringify([CACHE_KEYS.CUSTOMERS]));
    if (accountCacheData) {
      return accountCacheData?.state.data;
    }
    return undefined;
  };

  const accountName = new URLSearchParams(location.search).get(Constants.ACCOUNT_NAME_SEARCH_PARAM) || '';

  const { data: accountHealth, isLoading } = useQuery([CACHE_KEYS.ACCOUNT_HEALTH, accountName], () => IrisPortalService.getAccountHealth(accountName), {
    enabled: !!accountName,
    refetchOnWindowFocus: false,
    onError: (e) => {
      Utils.showError(String(e));
    },
    onSuccess: () => {},
    ...CACHE_TIME.get(CACHE_KEYS.ACCOUNT_HEALTH)
  });

  const { data: recentCases } = useQuery([CACHE_KEYS.ACCOUNT_RECENT_CASES, accountName], () => IrisPortalService.getRecentCasesForAccount(accountName), {
    enabled: !!accountName,
    onError: (e) => {
      Utils.showError(String(e));
    },
    onSuccess: () => {},
    ...CACHE_TIME.get(CACHE_KEYS.ACCOUNT_RECENT_CASES)
  });

  const { data: graphData } = useQuery([CACHE_KEYS.ACCOUNT_GRAPH_DATA, accountName], () => IrisPortalService.getGraphDataForAccount(accountName), {
    enabled: !!accountName,
    refetchOnWindowFocus: false,
    ...CACHE_TIME[CACHE_KEYS.ACCOUNT_GRAPH_DATA]
  });

  const totalCasesHealth = graphData?.stats.count || 0;

  const [customers, setCustomers] = useState<any>(allAccountNames);

  const datFormatCustomers = customers?.allAccountNames?.map((nameCustomer: any) => ({ name: nameCustomer }));

  const handleEventClickCardMetrics = (optionSelected: string) => {
    history.replace(utils.getAccountHealthRoute(optionSelected));
  };

  useResetPage();

  useEffect(() => {
    try {
      queryClient
        .fetchQuery([CACHE_KEYS.CUSTOMERS], () => IrisPortalService.getAllAccountNames(), {
          ...CACHE_TIME.get(CACHE_KEYS.CUSTOMERS)
        })
        .then((response) => {
          setCustomers((response as any) as {});
        });
    } catch (error) {
      console.log('Fetching account data', error);
    }
  }, []);

  const BackPage = () => {
    return (
      <Stack gap={10} align="center" role="button" tabIndex={0}>
        <div className="health-account-back-container" onClick={() => history.push(Constants.routes.HEALTH_OVERVIEW)}>
          <ArrowBackOutlinedIcon className="health-account-button" />
        </div>
        <Text variant="p4" weight="semi-bolder" color="dark">
          Back to All Customers’ Health Overview
        </Text>
      </Stack>
    );
  };
  const WithAccount = () => {
    return (
      <>
        <Stack align="center" justify="space-between">
          <Text variant="p4" weight="semi-bolder" style={{ color: '#4F4F4F' }}>
            Showing details for&nbsp;
            <Text variant="p4" weight="semi-bolder" style={{ color: '#5D5FEF' }}>
              {accountName}
            </Text>
            &nbsp;in the last 90 days
          </Text>
          {accountHealth?.accountDetails?.annualContractValue && <RevenueDetail accountHealth={accountHealth} />}
        </Stack>
        {isLoading || !accountHealth ? (
          <Loader center />
        ) : (
          <>
            <ImportantStats accountHealth={accountHealth} graphData={graphData} accountName={accountName} />
            {totalCasesHealth > 0 && <StatsGraphsCharts  graphData={graphData} accountName={accountName} />}
            {isMocKData && (
              <TablePaper
                style={{
                  paddingTop: '16px'
                }}
              >
                <Heading style={{ float: 'left', marginTop: '16px', marginBottom: 8 }}>Customer Engagement and Trend</Heading>
                <Text style={{ float: 'right', marginTop: '16px', marginBottom: 8 }}>Last 1 month data</Text>
                <CustomerEngagementGraph />
              </TablePaper>
            )}

            <LinkedJiraIssues
              jiraIssues={accountHealth.linkedJiraIssues !== undefined ? accountHealth.linkedJiraIssues : []}
              cases={recentCases?.cases}
              style={{ flexBasis: '100%' }}
            />
          </>
        )}
      </>
    );
  };

  const WithoutAccount = () => {
    const messageFirst = 'Currently, you haven’t selected any customer to see details.';
    const messageLast = 'Please search and select from right side a customer to view.';
    return (
      <div className="customer-view-container">
        <div className="customer-view-img"></div>
        <div className="container-customer-message-text">
          <div className="customer-message-text">{messageFirst}</div>
          <div className="customer-message-text">{messageLast}</div>
        </div>
      </div>
    );
  };

  return (
    <div className="health-account-container">
      <div className="health-account-content">
        <PageContainer>
          <BackPage />
          {accountName !== '' ? (
            <>
              <WithAccount />
            </>
          ) : (
            <WithoutAccount />
          )}
        </PageContainer>
      </div>
      <div className="health-account-searchBar">
        <div className="health-account-searchBar-content">
          {!datFormatCustomers ? (
            <Loader center />
          ) : (
            <AccountSearch
              isCustom={true}
              deactivateDropDown={true}
              placeholder="Search by Customer"
              deactivateTitle={true}
              withoutBorder={true}
              dataListSearch={datFormatCustomers}
              showListSearch={true}
              onlyTextItems={true}
              getClickCardMetrics={handleEventClickCardMetrics}
            />
          )}
        </div>
      </div>
    </div>
  );
};
