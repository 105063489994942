import { Paper } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import IrisPortalService from '../../../../services/IrisPortalService';
import { CACHE_KEYS, CACHE_TIME } from '../../../../utils/cacheUtils';
import Utils from '../../../../utils/utils';
// import './RevenueImpacted.scss';
import '../../../../components/home/components/Graphs/Graph.scss';
import { Stack } from '../../../../components/common';
import { Loader } from '../../../../components/common/blocks/Loader';
import LegendItem from '../../../../components/home/components/Graphs/LegendItem';
import { StackedBarGraph } from '../../../../components/home/components/Graphs/StackedBarGraph';

export const CustomerEngagementGraph: FC<any> = (props) => {
  const [caseStatusBin, setCaseStatusBin] = useState<any[]>([]);
  const [cumulativeBin, setCumulativeBin] = useState<any[]>([]);
  const bars = [
    {
      name: 'total',
      label: 'Events/Day',
      color: '#8181A5',
      type: 'number',
      hide: true
    },
    {
      name: 'subscribe',
      label: 'Subscribe',
      color: '#219653',
      type: 'number'
    },
    {
      name: 'login',
      label: 'Login',
      color: ' #F2C94C',
      type: 'number'
    }
  ];

  const { data: customerEngagementData, status: statusRevenue } = useQuery(
    [CACHE_KEYS.REVENUE_IMPACT_CASE_STATUS_DATA],
    () => IrisPortalService.getCustomerEngagement(),
    {
      ...CACHE_TIME.get(CACHE_KEYS.REVENUE_IMPACT_CASE_STATUS_DATA),
      refetchOnWindowFocus: false,
      onError: (e) => {
        Utils.showError(String(e));
      },
      onSuccess: (CaseStatusData) => {}
    }
  );
  useEffect(() => {
    if (customerEngagementData && statusRevenue === 'success') {
      customerEngagementData.impact.graph.sort((a: any, b: any) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime());
      let login =
        customerEngagementData?.impact.cumulative.topAcvStatuses[0]?.item === 'login'
          ? customerEngagementData?.impact.cumulative.topAcvStatuses[0]?.acv
          : customerEngagementData?.impact.cumulative.topAcvStatuses[1]?.acv;
      let subscribe =
        customerEngagementData?.impact.cumulative.topAcvStatuses[0]?.item === 'subscribe'
          ? customerEngagementData?.impact.cumulative.topAcvStatuses[0]?.acv
          : customerEngagementData?.impact.cumulative.topAcvStatuses[1]?.acv;
      if (!login) {
        login = 0;
      }
      if (!subscribe) {
        subscribe = 0;
      }
      let totalTop = Utils.getCurrencyValue(customerEngagementData?.impact.cumulative.topAcvStatuses[0]?.acv);
      let loginTop = Utils.getCurrencyValue(customerEngagementData?.impact.cumulative.topAcvStatuses[1]?.acv);
      let subscribeTop = Utils.getCurrencyValue(customerEngagementData?.impact.cumulative.topAcvStatuses[2]?.acv);
      const newCumulativeBin = [
        {
          name: 'total',
          value: totalTop ? totalTop.substring(1) : totalTop
        },
        {
          name: 'login',
          value: loginTop ? loginTop.substring(1) : loginTop
        },
        {
          name: 'subscribe',
          value: subscribeTop ? subscribeTop.substring(1) : subscribeTop
        }
      ];

      const newCaseStatusBin: any = [];
      customerEngagementData.impact.graph.map((imp: any) => {
        let login = imp.data.topAcvStatuses[0]?.item === 'login' ? imp.data.topAcvStatuses[0]?.acv : imp.data.topAcvStatuses[1]?.acv;
        let subscribe = imp.data.topAcvStatuses[0]?.item === 'subscribe' ? imp.data.topAcvStatuses[0]?.acv : imp.data.topAcvStatuses[1]?.acv;
        if (!login) {
          login = 0;
        }
        if (!subscribe) {
          subscribe = 0;
        }
        newCaseStatusBin.push({
          date: imp.startTime,
          login: login,

          subscribe: subscribe,

          total: login + subscribe
        });
        return imp;
      });
      // console.log(newCaseStatusBin);
      setCumulativeBin(newCumulativeBin);
      setCaseStatusBin(newCaseStatusBin);
    }
  }, [customerEngagementData, statusRevenue]);

  return (
    <Stack style={{ width: '100%' }} direction="horizontal">
      {statusRevenue === 'loading' ? (
        <Paper style={{ flex: 1, marginBottom: '40px' }}>
          <Loader center />
        </Paper>
      ) : (
        <>
          <Stack direction="vertical" gap={30} align="flex-start" style={{ width: '100%' }}>
            <Stack direction="horizontal">
              {cumulativeBin.map((item, index: number) => {
                return (
                  <Stack direction="horizontal" style={{ marginLeft: '40px' }}>
                    <LegendItem
                      value={
                        item.value
                        // Utils.getCurrencyValue(item.value)
                        //   ? Utils.getCurrencyValue(item.value).substring(1)
                        //   : Utils.getCurrencyValue(item.value)
                      }
                      label={bars.filter((bar) => bar.name === item.name)[0].label}
                      color={bars.filter((bar) => bar.name === item.name)[0].color}
                    />
                    {cumulativeBin.length > 1 && index === 0 && (
                      <div
                        style={{
                          width: '4px',
                          height: '47px',
                          background: '#5E81F4',
                          marginLeft: '40px'
                        }}
                      ></div>
                    )}
                  </Stack>
                );
              })}
            </Stack>
            <StackedBarGraph
              //   customTooltip={true}
              title=""
              distribution={caseStatusBin}
              additionalTipOrder={0}
              bars={bars}
            />
          </Stack>
        </>
      )}
    </Stack>
  );
};
