export type IncidentSourcesData = {
  title: string;
  incidentNumber: string | number | null;
  timeOfOccurrence: string;
  incidentId: string;
  incidentSource: string;
  cases: Array<{
    caseNumber: string;
    Id: string;
  }>;
};

export type VisualBucket = {
  id: number | string;
  title: string;
};

export type CustomerPrivateCommentSidebar = {
  enabled: boolean;
  sections?: string[];
};

export type CustomerAgentAssist = {
  sections?: string[];
};

export type CustomerPrivateCommentSidebarSettings = {
  show: boolean;
  showSections: boolean;
  allSections: Array<{ key: string; label: string }>;
};

export type CustomerAgentAssistSettings = {
  show: boolean;
  allSections: Array<{ key: string; label: string }>;
};

export type Customer = {
  id: string;
  issueTrackingProvider?: string;
  selfServe?: CustomerSelfServe;
  sidebarConnected?: boolean;
  createdAt: string;
  irisGptEnabled?: boolean;
  backfillDone: boolean;
  sourceName: string;
  privateCommentSidebar?: CustomerPrivateCommentSidebar;
  agentAssist?: CustomerAgentAssist;
  privateCommentSidebarSettings: CustomerPrivateCommentSidebarSettings;
  agentAssistSettings: CustomerAgentAssistSettings;
  businessId: string;
  externalIdentifier: string;
  hideChatbotPricing: boolean;
};

export enum Role {
  ADMIN = 'admin',
  VIEWER = 'viewer'
}

export type UserRole = Role.ADMIN | Role.VIEWER;

export type User = {
  roles: UserRole[];
  isSubscribedToDetectedIncidents: boolean;
};

export type CustomerSelfServe = {
  enabled: boolean;
};

export type ConfluenceSpace = {
  name: string;
  key: string;
};

export type AtlassianConfluenceConfig = {
  spaces: ConfluenceSpace[];
  selectedSpaces: string[];
};

export type JiraProject = {
  name: string;
  key: string;
};

export type AtlassianJiraConfig = {
  projects: JiraProject[];
  selectedProjects: string[];
};

export type AtlassianConfig = {
  confluence: AtlassianConfluenceConfig;
  jira: AtlassianJiraConfig;
};

export type AtlassianConfigResponse = {
  config: AtlassianConfig;
};

export type AtlassianConfigUpdateRequest = {
  confluenceSpaces?: string[];
  jiraProjects?: string[];
  fieldMask: string[];
};

export type WorkingHours = {
  start: string;
  end: string;
};

export type AgentForRouting = {
  id: string;
  sourceAgentId: string;
  name: string;
  email: string;
  isEnabled: boolean;
  isOnboarded: boolean;
  timeZone: string;
  workingWeekDays: number[];
  workingHours: WorkingHours;
  outOfOfficePeriod: string;
  skills: string;
};

export type AgentsForRoutingResponse = {
  agents: AgentForRouting[];
};

export type AgentForRoutingUpdateRequest = {
  isEnabled: boolean;
  timeZone: string;
  workingWeekDays: number[];
  workingHours: WorkingHours;
  outOfOfficePeriod: string;
  skills: string;
};

export type CustomerProvidedTag = {
  tag: string;
  description: string;
  examples: string[];
  status: string;
};

export type CreateCustomerProvidedTagRequest = {
  tag: string;
  description: string;
  examples: string[];
};
