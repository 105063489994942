import { PriorityScoreParameter } from '../../routes/customerHealth/types';

export const aiRecommendedCases = [
  {
    caseDatabaseId: '6115e4e2084486d74a1718ef',
    CaseNumber: '00025333',
    Id: '00025333',
    Priority: 'High',
    caseTimeOfCreation: '2021-08-13T03:17:18.000Z',
    caseSubject: 'Fwd: IrisAgent error, 1GB file transfer/upload',
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sYDRGAA4',
    incidentId: 'TD-57152',
    incidentNumber: 'TD-57152',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-57152',
    incidentTitle: 'Transformation Engine Crash error importing AVRO files on HDFS to IrisAgent',
    incidentTimeOfCreation: '2020-11-09T16:20:41.000Z',
    incidentSource: 'Jira',
    suggestedByMl: true,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '61156381084486d74afba619',
    CaseNumber: '00025330',
    Id: '00025330',
    Priority: 'High',
    caseTimeOfCreation: '2021-08-12T18:05:59.000Z',
    caseSubject: 'Run Flow API Error',
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sY8d6AAC',
    incidentId: 'TD-54331',
    incidentNumber: 'TD-54331',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-54331',
    incidentTitle: 'Token error while running "/v4/flows/<id>/runAsUser" when there is no token in the keyvault',
    incidentTimeOfCreation: '2020-09-13T20:07:01.000Z',
    incidentSource: 'Jira',
    suggestedByMl: true,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '611550c1084486d74af7c137',
    CaseNumber: '00025329',
    Id: '00025329',
    Priority: 'High',
    caseTimeOfCreation: '2021-08-12T16:44:11.000Z',
    caseSubject: 'RE: [External] Re: Dataprep(Project lisle_pbps) not working ??or turned off internally? --  Case#28156312',
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sY7SJAA0',
    incidentId: 'TD-57152',
    incidentNumber: 'TD-57152',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-57152',
    incidentTitle: 'Dataprep workspace unavailable "Contract has expired" - Accenture',
    incidentTimeOfCreation: '2021-08-12T16:44:11.000Z',
    incidentSource: 'Jira',
    suggestedByMl: false,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '611542b1084486d74af4da48',
    CaseNumber: '00025328',
    Id: '00025328',
    Priority: 'Normal',
    caseTimeOfCreation: '2021-08-12T15:45:36.000Z',
    caseSubject: 'IrisAgent not failing to write to PostgreSQL table',
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sY6a2AAC',
    incidentId: 'TD-51712',
    incidentNumber: 'TD-51712',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-51712',
    incidentTitle: 'Job failing postgresql.util.PSQLException(Connection error is still in use)',
    incidentTimeOfCreation: '2020-06-25T19:58:29.000Z',
    incidentSource: 'Jira',
    suggestedByMl: true,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '61153772084486d74af29238',
    CaseNumber: '00025325',
    Id: '00025325',
    Priority: 'High',
    caseTimeOfCreation: '2021-08-12T14:58:46.000Z',
    caseSubject: 'Majority of Dataprep jobs fail due to insufficient workers in Dataflow',
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sY5ukAAC',
    incidentId: 'TD-60423',
    incidentNumber: 'TD-60423',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-60423',
    incidentTitle: 'Dataprep job failure with no message, no Dataflow job',
    incidentTimeOfCreation: '2021-04-01T19:57:56.000Z',
    incidentSource: 'Jira',
    suggestedByMl: true,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '61152962084486d74aefc15a',
    CaseNumber: '00025320',
    Id: '00025320',
    Priority: 'Low',
    caseTimeOfCreation: '2021-08-12T13:57:02.000Z',
    caseSubject: 'Customer ticket - jobs fail at spark',
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sY57nAAC',
    incidentId: 'TD-47222',
    incidentNumber: 'TD-47222',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-47222',
    incidentTitle: 'Scheduled Spark jobs are failing',
    incidentTimeOfCreation: '2020-02-02T17:44:09.000Z',
    incidentSource: 'Jira',
    suggestedByMl: true,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '611520f2084486d74aee2169',
    CaseNumber: '00025319',
    Id: '00025319',
    Priority: 'High',
    caseTimeOfCreation: '2021-08-12T13:21:57.000Z',
    caseSubject: 'Missing Records through IrisAgent',
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sY4hQAAS',
    incidentId: 'TD-59681',
    incidentNumber: 'TD-59681',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-59681',
    incidentTitle: 'Job output encoding unexpected when input is CSV.gz file',
    incidentTimeOfCreation: '2021-02-24T13:43:27.000Z',
    incidentSource: 'Jira',
    suggestedByMl: true,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '6114bca2084486d74ad90c0f',
    CaseNumber: '00025314',
    Id: '00025314',
    Priority: 'High',
    caseTimeOfCreation: '2021-08-12T06:15:56.000Z',
    caseSubject: 'IrisAgent LOD UI is not coming up',
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sY3NGAA0',
    incidentId: 'TD-54331',
    incidentNumber: 'TD-54331',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-54331',
    incidentTitle: 'UI unavailable',
    incidentTimeOfCreation: '2021-08-12T06:15:56.000Z',
    incidentSource: 'Jira',
    suggestedByMl: false,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '61144e0c084486d74ac1f6d2',
    CaseNumber: '00025311',
    Id: '00025311',
    Priority: 'High',
    caseTimeOfCreation: '2021-08-11T22:12:19.000Z',
    caseSubject: 'Flows for Published Content Library WP are failing in IrisAgent with 403 forbidden error',
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sY1JQAA0',
    incidentId: 'TD-50694',
    incidentNumber: 'TD-50694',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-50694',
    incidentTitle: 'File Writer Publish Jobs are failing on IrisAgent 6.8 Azure Instance',
    incidentTimeOfCreation: '2020-06-03T09:46:28.000Z',
    incidentSource: 'Jira',
    suggestedByMl: true,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '61140e41084486d74ab446f4',
    CaseNumber: '00025306',
    Id: '00025306',
    Priority: 'High',
    caseTimeOfCreation: '2021-08-11T17:51:58.000Z',
    caseSubject: 'Data not found exception',
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sXy3AAAS',
    incidentId: 'TD-55382',
    incidentNumber: 'TD-55382',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-55382',
    incidentTitle: 'Data not found exception on existing file.',
    incidentTimeOfCreation: '2020-10-01T20:35:22.000Z',
    incidentSource: 'Jira',
    suggestedByMl: true,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '61140c62084486d74ab3c1c8',
    CaseNumber: '00025305',
    Id: '00025305',
    Priority: 'High',
    caseTimeOfCreation: '2021-08-11T17:40:18.000Z',
    caseSubject: 'Error Running Job',
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sXxnSAAS',
    incidentId: 'TD-37386',
    incidentNumber: 'TD-37386',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-37386',
    incidentTitle: 'Error running a job on AWS EMR',
    incidentTimeOfCreation: '2019-01-08T21:14:36.000Z',
    incidentSource: 'Jira',
    suggestedByMl: true,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '6113f4f2084486d74aaf0c7a',
    CaseNumber: '00025304',
    Id: '00025304',
    Priority: 'High',
    caseTimeOfCreation: '2021-08-11T16:02:21.000Z',
    caseSubject: 'Stratified Samples',
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sXvxBAAS',
    incidentId: 'TD-54331',
    incidentNumber: 'TD-54331',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-54331',
    incidentTitle: 'Stratified Samples',
    incidentTimeOfCreation: '2021-08-11T16:02:21.000Z',
    incidentSource: 'Jira',
    suggestedByMl: false,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '6113e9b2084486d74aacc552',
    CaseNumber: '00025302',
    Id: '00025302',
    Priority: 'High',
    caseTimeOfCreation: '2021-08-11T15:13:14.000Z',
    caseSubject: 'Error at triggering Jobs at IrisAgent API from GDMPM Project',
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sXvcNAAS',
    incidentId: 'TD-58869',
    incidentNumber: 'TD-58869',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-58869',
    incidentTitle: 'table_c32ae5412ebf8f05e56ad7be58ce8c90a753111b: not found - Input validation failed While performing lookup',
    incidentTimeOfCreation: '2021-03-25T19:38:49.000Z',
    incidentSource: 'Jira',
    suggestedByMl: true,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '6113e231084486d74aab3aca',
    CaseNumber: '00025301',
    Id: '00025301',
    Priority: 'High',
    caseTimeOfCreation: '2021-08-11T14:42:52.000Z',
    caseSubject: 'Accessing Existing Flows created by another user',
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sXuxzAAC',
    incidentId: 'TD-54331',
    incidentNumber: 'TD-54331',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-54331',
    incidentTitle: 'Asset Transfer in GCP SaaS legacy dataprep - no admin',
    incidentTimeOfCreation: '2021-08-11T14:42:52.000Z',
    incidentSource: 'Jira',
    suggestedByMl: false,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '61127582084486d74a5f4afa',
    CaseNumber: '00025291',
    Id: '00025291',
    Priority: 'High',
    caseTimeOfCreation: '2021-08-10T12:45:24.000Z',
    caseSubject: 'Multiple Jobs outputting to postgres fails randomly',
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sXkCbAAK',
    incidentId: 'TD-64018',
    incidentNumber: 'TD-64018',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-64018',
    incidentTitle: 'Multiple scheduled jobs with postgres publications intermittently fail',
    incidentTimeOfCreation: '2021-08-10T12:45:24.000Z',
    incidentSource: 'Jira',
    suggestedByMl: false,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '6111baf2084486d74a38235a',
    CaseNumber: '00025287',
    Id: '00025287',
    Priority: 'High',
    caseTimeOfCreation: '2021-08-09T23:31:33.000Z',
    caseSubject: 'Job fails but no log',
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sXhwaAAC',
    incidentId: 'TD-36973',
    incidentNumber: 'TD-36973',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-36973',
    incidentTitle: 'Hadoop jobs fail with jackson error (job.log), java.io.IOException (spark-job.log)',
    incidentTimeOfCreation: '2018-11-26T11:00:41.000Z',
    incidentSource: 'Jira',
    suggestedByMl: true,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '61115f12084486d74a25c3ed',
    CaseNumber: '00025282',
    Id: '00025282',
    Priority: 'High',
    caseTimeOfCreation: '2021-08-09T16:59:12.000Z',
    caseSubject: 'MySQL publish failure: max row size too large',
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sXdUOAA0',
    incidentId: 'TD-63169',
    incidentNumber: 'TD-63169',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-63169',
    incidentTitle: 'PublishException: A system error occurred: Row size too large" for MySQL DB - SaaS',
    incidentTimeOfCreation: '2021-07-08T15:59:17.000Z',
    incidentSource: 'Jira',
    suggestedByMl: true,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '61115e21084486d74a25a552',
    CaseNumber: '00025281',
    Id: '00025281',
    Priority: 'High',
    caseTimeOfCreation: '2021-08-09T16:55:02.000Z',
    caseSubject: 'AWS SaaS - Job failure when connecting to Google Sheets',
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sXdRPAA0',
    incidentId: 'TD-59889',
    incidentNumber: 'TD-59889',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-59889',
    incidentTitle: 'AWS SaaS - Job Failure (Job ID: 708417)',
    incidentTimeOfCreation: '2021-03-15T16:41:29.000Z',
    incidentSource: 'Jira',
    suggestedByMl: true,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '610daa51084486d74a7428f2',
    CaseNumber: '00025278',
    Id: '00025278',
    Priority: 'High',
    caseTimeOfCreation: '2021-08-06T21:29:26.000Z',
    caseSubject: 'Two issues in Prod-E',
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sXOGqAAO',
    incidentId: 'TD-64054',
    incidentNumber: 'TD-64054',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-64054',
    incidentTitle: 'Jobs Hanging on UI(cancellation) due 2 ingest jobs running at the same time, one of them failed which caused us to cancel the other',
    incidentTimeOfCreation: '2021-08-06T21:29:26.000Z',
    incidentSource: 'Jira',
    suggestedByMl: false,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '610d96a1084486d74a6fecbf',
    CaseNumber: '00025277',
    Id: '00025277',
    Priority: 'High',
    caseTimeOfCreation: '2021-08-06T20:07:25.000Z',
    caseSubject: 'Unable to import datasets from existing Oracle connection !',
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sXNNvAAO',
    incidentId: 'TD-54766',
    incidentNumber: 'TD-54766',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-54766',
    incidentTitle:
      'Importing dataset (SQL server connectivity) - java.lang.NullPointerException: null uri host. This can be caused by unencoded / in the password string',
    incidentTimeOfCreation: '2020-10-06T23:39:42.000Z',
    incidentSource: 'Jira',
    suggestedByMl: true,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '610d6042084486d74a669b33',
    CaseNumber: '00025276',
    Id: '00025276',
    Priority: 'Low',
    caseTimeOfCreation: '2021-08-06T16:15:32.000Z',
    caseSubject: 'Slowness when opening recipe, add new step or modify existing steps',
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sXKX9AAO',
    incidentId: 'TD-42170',
    incidentNumber: 'TD-42170',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-42170',
    incidentTitle: 'Error while adding standardize step to recipe',
    incidentTimeOfCreation: '2019-07-04T06:06:13.000Z',
    incidentSource: 'Jira',
    suggestedByMl: true,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '610d4c91084486d74a632cd6',
    CaseNumber: '00025275',
    Id: '00025275',
    Priority: 'High',
    caseTimeOfCreation: '2021-08-06T14:49:57.000Z',
    caseSubject: 'Failed Scheduled Job',
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sXJLhAAO',
    incidentId: 'TD-64065',
    incidentNumber: 'TD-64065',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-64065',
    incidentTitle: 'Job failure when scheduled / Success-full when run via UI',
    incidentTimeOfCreation: '2021-08-06T14:49:57.000Z',
    incidentSource: 'Jira',
    suggestedByMl: false,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '610c2452084486d74a3006ce',
    CaseNumber: '00025261',
    Id: '00025261',
    Priority: 'High',
    caseTimeOfCreation: '2021-08-05T17:44:07.000Z',
    caseSubject: 'Re: Validation failure when',
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sXCGTAA4',
    incidentId: 'TD-38533',
    incidentNumber: 'TD-38533',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-38533',
    incidentTitle: 'Importing a IrisAgent flow with parameters fails with input validation error',
    incidentTimeOfCreation: '2019-02-13T20:38:36.000Z',
    incidentSource: 'Jira',
    suggestedByMl: true,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '610c1731084486d74a2dabbc',
    CaseNumber: '00025257',
    Id: '00025257',
    Priority: 'High',
    caseTimeOfCreation: '2021-08-05T16:48:21.000Z',
    caseSubject: 'RE:   Inner join works different between photon and spark excecution',
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sXBWkAAO',
    incidentId: 'TD-63957',
    incidentNumber: 'TD-63957',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-63957',
    incidentTitle: 'Inner join returns zero rows for Hive Table in Spark Job or Sample runs using Spark',
    incidentTimeOfCreation: '2021-08-05T16:48:21.000Z',
    incidentSource: 'Jira',
    suggestedByMl: false,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '610c1641084486d74a2d7fd4',
    CaseNumber: '00025255',
    Id: '00025255',
    Priority: 'High',
    caseTimeOfCreation: '2021-08-05T16:44:09.000Z',
    caseSubject: 'Inner join works different between photon and spark excecution',
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sXBSxAAO',
    incidentId: 'TD-63957',
    incidentNumber: 'TD-63957',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-63957',
    incidentTitle: 'Inner join returns zero rows for Hive Table in Spark Job or Sample runs using Spark',
    incidentTimeOfCreation: '2021-08-05T16:48:21.000Z',
    incidentSource: 'Jira',
    suggestedByMl: true,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '610b6061084486d74a0be536',
    CaseNumber: '00025243',
    Id: '00025243',
    Priority: 'High',
    caseTimeOfCreation: '2021-08-05T03:50:03.000Z',
    caseSubject: 'Unable to trigger jobs for parameterized dataset with size = 0 after upgrading to 8.2',
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sX7NbAAK',
    incidentId: 'TD-63867',
    incidentNumber: 'TD-63867',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-63867',
    incidentTitle: 'Jobs are not triggering for a parameterized datasets with file size is zero',
    incidentTimeOfCreation: '2021-08-05T03:50:03.000Z',
    incidentSource: 'Jira',
    suggestedByMl: false,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '610b5434084486d74a099f4c',
    CaseNumber: '00025240',
    Id: '00025240',
    Priority: 'High',
    caseTimeOfCreation: '2021-08-05T02:58:51.000Z',
    caseSubject: 'IrisAgent SystemWebhook is missing triggers',
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sX7IlAAK',
    incidentId: 'TD-64024',
    incidentNumber: 'TD-64024',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-64024',
    incidentTitle: 'SystemWebhook Failures due to SSL error and timeout',
    incidentTimeOfCreation: '2021-08-05T02:58:51.000Z',
    incidentSource: 'Jira',
    suggestedByMl: false,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '610b3902084486d74a046654',
    CaseNumber: '00025238',
    Id: '00025238',
    Priority: 'High',
    caseTimeOfCreation: '2021-08-05T01:02:47.000Z',
    caseSubject: 'Job unable to cancel',
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sX71GAAS',
    incidentId: 'TD-56421',
    incidentNumber: 'TD-56421',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-56421',
    incidentTitle: "Cannot cancel jobs that are stuck 'In Progress'",
    incidentTimeOfCreation: '2020-11-19T13:11:16.000Z',
    incidentSource: 'Jira',
    suggestedByMl: true,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '610b2af1084486d74a021900',
    CaseNumber: '00025237',
    Id: '00025237',
    Priority: 'Normal',
    caseTimeOfCreation: '2021-08-05T00:01:55.000Z',
    caseSubject: 'BAML has been experiencing instability',
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sX6kAAAS',
    incidentId: 'TD-63732',
    incidentNumber: 'TD-63732',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-63732',
    incidentTitle: 'BAML: Prod-S environment down, restart needed to stabilize environment',
    incidentTimeOfCreation: '2021-06-25T18:27:18.000Z',
    incidentSource: 'Jira',
    suggestedByMl: false,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '610ad4b1084486d74af2d975',
    CaseNumber: '00025231',
    Id: '00025231',
    Priority: 'High',
    caseTimeOfCreation: '2021-08-04T17:52:03.000Z',
    caseSubject: 'IrisAgent flow not getting copied',
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sX2tPAAS',
    incidentId: 'TD-61456',
    incidentNumber: 'TD-61456',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-61456',
    incidentTitle: 'Flow owner, collaborators cannot copy a specific flow',
    incidentTimeOfCreation: '2021-04-21T12:11:39.000Z',
    incidentSource: 'Jira',
    suggestedByMl: false,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '610a5802084486d74adcb16d',
    CaseNumber: '00025226',
    Id: '00025226',
    Priority: 'High',
    caseTimeOfCreation: '2021-08-04T09:03:08.000Z',
    caseSubject: 'Job failed to run via GUI "request entity too large"',
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sWxn4AAC',
    incidentId: 'TD-63788',
    incidentNumber: 'TD-63788',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-63788',
    incidentTitle: 'Trying to \'Run Job\' in Run Job Page fails: "The chosen settings are invalid. request entity too large - Input validation failed"',
    incidentTimeOfCreation: '2021-08-04T09:03:08.000Z',
    incidentSource: 'Jira',
    suggestedByMl: false,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '61096da1084486d74ab13928',
    CaseNumber: '00025216',
    Id: '00025216',
    Priority: 'High',
    caseTimeOfCreation: '2021-08-03T16:22:34.000Z',
    caseSubject: 'Unable to execute same job two times',
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sWqH6AAK',
    incidentId: 'TD-63956',
    incidentNumber: 'TD-63956',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-63956',
    incidentTitle: 'Job failure on second run',
    incidentTimeOfCreation: '2021-08-03T16:22:34.000Z',
    incidentSource: 'Jira',
    suggestedByMl: false,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '61095ae2084486d74aadb4f1',
    CaseNumber: '00025215',
    Id: '00025215',
    Priority: 'High',
    caseTimeOfCreation: '2021-08-03T15:03:20.000Z',
    caseSubject: 'Have 3 flow no problem in Photon but failing in Spark',
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sWp8eAAC',
    incidentId: 'TD-46895',
    incidentNumber: 'TD-46895',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-46895',
    incidentTitle: 'Discrepancy in the expected results, when using Spark. Working fine in Photon and Preview mode',
    incidentTimeOfCreation: '2020-01-21T18:50:08.000Z',
    incidentSource: 'Jira',
    suggestedByMl: true,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '61090fe3084486d74a9fb39f',
    CaseNumber: '00025212',
    Id: '00025212',
    Priority: 'High',
    caseTimeOfCreation: '2021-08-03T09:43:34.000Z',
    caseSubject: 'Multiple flows are failing',
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sWn43AAC',
    incidentId: 'TD-63760',
    incidentNumber: 'TD-63760',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-63760',
    incidentTitle: 'Datasource not found for wildcard parameterized input',
    incidentTimeOfCreation: '2021-08-03T09:43:34.000Z',
    incidentSource: 'Jira',
    suggestedByMl: false,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '6108ad72084486d74a8d09aa',
    CaseNumber: '00025209',
    Id: '00025209',
    Priority: 'High',
    caseTimeOfCreation: '2021-08-03T02:41:33.000Z',
    caseSubject: 'Transfer flow owner',
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sHEdeAAG',
    incidentId: 'DP-663',
    incidentNumber: 'DP-663',
    incidentLink: 'https://jira.IrisAgent.net//browse/DP-663',
    incidentTitle: 'Asset Transfer',
    incidentTimeOfCreation: '2021-03-23T22:22:42.000Z',
    incidentSource: 'Jira',
    suggestedByMl: true,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '61084831084486d74a7aaec0',
    CaseNumber: '00025204',
    Id: '00025204',
    Priority: 'High',
    caseTimeOfCreation: '2021-08-02T19:31:48.000Z',
    caseSubject: 'Error in IrisAgent job run',
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sHAx9AAG',
    incidentId: 'TD-63652',
    incidentNumber: 'TD-63652',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-63652',
    incidentTitle: 'Ignore unknown properties in databricks REST API responses',
    incidentTimeOfCreation: '2021-07-30T16:25:35.000Z',
    incidentSource: 'Jira',
    suggestedByMl: false,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '6107d992084486d74a66007d',
    CaseNumber: '00025179',
    Id: '00025179',
    Priority: 'High',
    caseTimeOfCreation: '2021-08-02T11:39:08.000Z',
    caseSubject: 'Setting the Admin Settings property webapp.maxQueryResultsSize to 0 causes preview to be disabled',
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sH542AAC/5003j00001sH542AAC',
    incidentId: 'TD-63345',
    incidentNumber: 'TD-63345',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-63345',
    incidentTitle:
      'Cust want to disable the ability to download the output of a job. Set the Admin Settings property webapp.maxQueryResultsSize to 0; however this had the undesired effect of also causing the preview feature to be disabled',
    incidentTimeOfCreation: '2021-08-02T11:39:08.000Z',
    incidentSource: 'Jira',
    suggestedByMl: false,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '6107d992084486d74a66007d',
    CaseNumber: '00025179',
    Id: '00025179',
    Priority: 'High',
    caseTimeOfCreation: '2021-08-02T11:39:08.000Z',
    caseSubject: 'Setting the Admin Settings property webapp.maxQueryResultsSize to 0 causes preview to be disabled',
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sH542AAC',
    incidentId:
      'Cust want to disable the ability to download the output of a job. Set the Admin Settings property webapp.maxQueryResultsSize to 0; however this had the undesired effect of also causing the preview feature to be disabled',
    incidentNumber:
      'Cust want to disable the ability to download the output of a job. Set the Admin Settings property webapp.maxQueryResultsSize to 0; however this had the undesired effect of also causing the preview feature to be disabled',
    incidentLink: 'https://jira.IrisAgent.net/browse/TD-63345',
    incidentTitle:
      'Cust want to disable the ability to download the output of a job. Set the Admin Settings property webapp.maxQueryResultsSize to 0; however this had the undesired effect of also causing the preview feature to be disabled',
    incidentTimeOfCreation: '2021-08-02T11:39:08.000Z',
    incidentSource: 'Jira',
    suggestedByMl: false,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '610480b125682c596dd98073',
    CaseNumber: '00025158',
    Id: '00025158',
    Priority: 'High',
    caseTimeOfCreation: '2021-07-30T22:43:45.000Z',
    caseSubject: 'Data issue with MySQL query in IrisAgent',
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sGqaLAAS',
    incidentId: 'TD-59924',
    incidentNumber: 'TD-59924',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-59924',
    incidentTitle: 'Issue with SQL Query Execution on Oracle',
    incidentTimeOfCreation: '2021-03-12T22:12:57.000Z',
    incidentSource: 'Jira',
    suggestedByMl: true,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '6104298325682c596dc9a37f',
    CaseNumber: '00025148',
    Id: '00025148',
    Priority: 'High',
    caseTimeOfCreation: '2021-07-30T16:31:44.000Z',
    caseSubject: 'RE: Production Issue......!!!!!',
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sGmA9AAK',
    incidentId: 'TD-61900',
    incidentNumber: 'TD-61900',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-61900',
    incidentTitle: 'Dataprep sampling issue: [QM] Batch failed. Job status(es): - ["Failed","Failed"] - {"count":1} at trilogger._log',
    incidentTimeOfCreation: '2021-05-17T18:59:54.000Z',
    incidentSource: 'Jira',
    suggestedByMl: true,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '6104298325682c596dc9a37d',
    CaseNumber: '00025147',
    Id: '00025147',
    Priority: 'High',
    caseTimeOfCreation: '2021-07-30T16:29:32.000Z',
    caseSubject: 'RE: Production Issue......!!!!!',
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sGm8XAAS',
    incidentId: 'TD-47849',
    incidentNumber: 'TD-47849',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-47849',
    incidentTitle: "Manually executed jobs failing => Download logs gives 'Input validation error' JSON",
    incidentTimeOfCreation: '2020-03-03T14:06:02.000Z',
    incidentSource: 'Jira',
    suggestedByMl: true,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '610415d225682c596dc5dfb4',
    CaseNumber: '00025139',
    Id: '00025139',
    Priority: 'High',
    caseTimeOfCreation: '2021-07-30T15:04:54.000Z',
    caseSubject: 'Production Issue......!!!!!',
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sGkAHAA0',
    incidentId: 'TD-47450',
    incidentNumber: 'TD-47450',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-47450',
    incidentTitle:
      'IrisAgent jobs are failing with temp table not found/already present when multiple different flows are publishing to the same hive table in parallel',
    incidentTimeOfCreation: '2020-02-12T07:57:14.000Z',
    incidentSource: 'Jira',
    suggestedByMl: true,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '610305f125682c596d92b1f5',
    CaseNumber: '00025132',
    Id: '00025132',
    Priority: 'High',
    caseTimeOfCreation: '2021-07-29T19:45:59.000Z',
    caseSubject: 'PRD: IrisAgent is incorrectly detecting/changing the data type(s) (Cortex# 1591687)',
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sGdlHAAS',
    incidentId: 'TD-63757',
    incidentNumber: 'TD-63757',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-63757',
    incidentTitle: 'IrisAgent is incorrectly detecting/changing the data type',
    incidentTimeOfCreation: '2021-07-29T19:45:59.000Z',
    incidentSource: 'Jira',
    suggestedByMl: false,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '6102ccc225682c596d88cb44',
    CaseNumber: '00025129',
    Id: '00025129',
    Priority: 'High',
    caseTimeOfCreation: '2021-07-29T15:40:44.000Z',
    caseSubject: 'Can not Remove Leading and Trailing quotes or White Spaces',
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sGaPcAAK',
    incidentId: 'TD-63852',
    incidentNumber: 'TD-63852',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-63852',
    incidentTitle: 'Transform  not working for Removing Leading and Trailing quotes or White Spaces',
    incidentTimeOfCreation: '2021-07-29T15:40:44.000Z',
    incidentSource: 'Jira',
    suggestedByMl: false,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '61024f2125682c596d66d0fd',
    CaseNumber: '00025121',
    Id: '00025121',
    Priority: 'High',
    caseTimeOfCreation: '2021-07-29T06:47:14.000Z',
    caseSubject: 'Dataflow job failed',
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sGXdEAAW',
    incidentId: 'TD-43828',
    incidentNumber: 'TD-43828',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-43828',
    incidentTitle: 'Dataflow job failure',
    incidentTimeOfCreation: '2019-09-13T23:19:50.000Z',
    incidentSource: 'Jira',
    suggestedByMl: true,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '6101949125682c596d291062',
    CaseNumber: '00025115',
    Id: '00025115',
    Priority: 'High',
    caseTimeOfCreation: '2021-07-28T17:30:36.000Z',
    caseSubject: 'IrisAgent 7.6 Authentication Issues on CDP 7.3.1',
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sGRyCAAW',
    incidentId: 'TD-63644',
    incidentNumber: 'TD-63644',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-63644',
    incidentTitle: 'Unable to read/write data from HDFS non encrypted to encrypted zones & vice versa on CDP 7.1.6',
    incidentTimeOfCreation: '2021-07-28T17:30:36.000Z',
    incidentSource: 'Jira',
    suggestedByMl: false,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '6101868125682c596d26758f',
    CaseNumber: '00025114',
    Id: '00025114',
    Priority: 'High',
    caseTimeOfCreation: '2021-07-28T16:31:07.000Z',
    caseSubject: 'IrisAgent Related Issue',
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sGRBxAAO',
    incidentId: 'TD-35617',
    incidentNumber: 'TD-35617',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-35617',
    incidentTitle: 'IrisAgent 5.0 HW - Teradata - Ingestion and partial publication issue',
    incidentTimeOfCreation: '2018-09-14T20:43:56.000Z',
    incidentSource: 'Jira',
    suggestedByMl: true,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '610182c125682c596d25bf9d',
    CaseNumber: '00025113',
    Id: '00025113',
    Priority: 'High',
    caseTimeOfCreation: '2021-07-28T16:13:15.000Z',
    caseSubject: 'ScriptMalformedException     400 - <html> <head><title>502 Bad Gateway - error message',
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sGQr6AAG',
    incidentId: 'TD-63794',
    incidentNumber: 'TD-63794',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-63794',
    incidentTitle: 'Systems down: Script malformed exception -  IO exception: java.nio.file.AccessDeniedException',
    incidentTimeOfCreation: '2021-07-28T16:13:15.000Z',
    incidentSource: 'Jira',
    suggestedByMl: false,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '61016c4125682c596d22022c',
    CaseNumber: '00025110',
    Id: '00025110',
    Priority: 'High',
    caseTimeOfCreation: '2021-07-28T14:36:48.000Z',
    caseSubject: 'Unable to save Custom SQL... ERROR :Could not connect to host: com.IrisAgent.dataservice.connect.exception.JdbcConnectionException',
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sGPhcAAG',
    incidentId: 'TD-43424',
    incidentNumber: 'TD-43424',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-43424',
    incidentTitle: 'Modifying existing custom SQL dataset throwing Invalid SQL :500-I/O Error',
    incidentTimeOfCreation: '2020-03-12T18:41:55.000Z',
    incidentSource: 'Jira',
    suggestedByMl: true,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '6101142125682c596d135ae5',
    CaseNumber: '00025107',
    Id: '00025107',
    Priority: 'High',
    caseTimeOfCreation: '2021-07-28T08:21:38.000Z',
    caseSubject: 'FTP connectivity',
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sGNxVAAW',
    incidentId: 'TD-55714',
    incidentNumber: 'TD-55714',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-55714',
    incidentTitle: 'Creating New SFTP connection',
    incidentTimeOfCreation: '2020-10-27T16:21:05.000Z',
    incidentSource: 'Jira',
    suggestedByMl: true,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '610054e125682c596df101a6',
    CaseNumber: '00025105',
    Id: '00025105',
    Priority: 'High',
    caseTimeOfCreation: '2021-07-27T18:44:45.000Z',
    caseSubject: 'create dataset with sql - issue',
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sGJFJAA4',
    incidentId: 'TD-45622',
    incidentNumber: 'TD-45622',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-45622',
    incidentTitle: 'Issue with Creating dataset with SQL',
    incidentTimeOfCreation: '2019-11-22T17:16:09.000Z',
    incidentSource: 'Jira',
    suggestedByMl: true,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '61004b8225682c596def67fb',
    CaseNumber: '00025103',
    Id: '00025103',
    Priority: 'High',
    caseTimeOfCreation: '2021-07-27T18:07:58.000Z',
    caseSubject: 'Dataflow Job Creation Failed Error',
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sGIryAAG/5003j00001sGIryAAG',
    incidentId: 'TD-63692',
    incidentNumber: 'TD-63692',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-63692',
    incidentTitle: 'Job Failure: Some users encountering "run settings can\'t be found"',
    incidentTimeOfCreation: '2021-07-27T18:07:58.000Z',
    incidentSource: 'Jira',
    suggestedByMl: false,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '61004b8225682c596def67fb',
    CaseNumber: '00025103',
    Id: '00025103',
    Priority: 'High',
    caseTimeOfCreation: '2021-07-27T18:07:58.000Z',
    caseSubject: 'Dataflow Job Creation Failed Error',
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sGIryAAG',
    incidentId: 'TD-63658',
    incidentNumber: 'TD-63658',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-63658',
    incidentTitle: "Task in Plan generated output but was marked as 'Failed', so Plan failed also",
    incidentTimeOfCreation: '2021-07-29T07:04:40.000Z',
    incidentSource: 'Jira',
    suggestedByMl: false,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '60ffebe125682c596dde2d5b',
    CaseNumber: '00025099',
    Id: '00025099',
    Priority: 'High',
    caseTimeOfCreation: '2021-07-27T11:17:21.000Z',
    caseSubject: 'Java UDF issues',
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sGEUbAAO',
    incidentId: 'TD-63729',
    incidentNumber: 'TD-63729',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-63729',
    incidentTitle: 'UDF not found',
    incidentTimeOfCreation: '2021-07-27T11:17:21.000Z',
    incidentSource: 'Jira',
    suggestedByMl: false,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '60ffd38225682c596dda08ba',
    CaseNumber: '00025098',
    Id: '00025098',
    Priority: 'High',
    caseTimeOfCreation: '2021-07-27T09:32:30.000Z',
    caseSubject: 'IrisAgent bug - Numformat autocast Decimal into Integer',
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sGEJ8AAO',
    incidentId: 'TD-64015',
    incidentNumber: 'TD-64015',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-64015',
    incidentTitle: 'Numformat autocast Decimal into Integer',
    incidentTimeOfCreation: '2021-07-27T09:32:30.000Z',
    incidentSource: 'Jira',
    suggestedByMl: false,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '60fb544125682c596d18be20',
    CaseNumber: '00025087',
    Id: '00025087',
    Priority: 'High',
    caseTimeOfCreation: '2021-07-23T23:40:28.000Z',
    caseSubject: 'Bad Gateway Error when moving files from Deleted user',
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sFuG7AAK',
    incidentId: 'TD-63598',
    incidentNumber: 'TD-63598',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-63598',
    incidentTitle: 'Bad Gateway error while removing user',
    incidentTimeOfCreation: '2021-07-23T23:40:28.000Z',
    incidentSource: 'Jira',
    suggestedByMl: false,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '60fb067225682c596d0c59ec',
    CaseNumber: '00025085',
    Id: '00025085',
    Priority: 'High',
    caseTimeOfCreation: '2021-07-23T18:08:55.000Z',
    caseSubject: 'Job failing - java.PERSON_NAME.AssertionError: Invalid decRef when refCount is 0: 0x286ab0d5(0)',
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sFqvBAAS',
    incidentId: 'TD-51712',
    incidentNumber: 'TD-51712',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-51712',
    incidentTitle: 'Job failing postgresql.util.PSQLException(Connection error is still in use)',
    incidentTimeOfCreation: '2020-06-25T19:58:29.000Z',
    incidentSource: 'Jira',
    suggestedByMl: true,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '60faf68225682c596d09b85d',
    CaseNumber: '00025084',
    Id: '00025084',
    Priority: 'High',
    caseTimeOfCreation: '2021-07-23T17:01:38.000Z',
    caseSubject: 'Last Login not working',
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sFq7SAAS',
    incidentId: 'TD-49123',
    incidentNumber: 'TD-49123',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-49123',
    incidentTitle: 'SAML logging not working for non-admin users',
    incidentTimeOfCreation: '2020-04-13T05:25:08.000Z',
    incidentSource: 'Jira',
    suggestedByMl: true,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '60fa9c8125682c596dfb1c91',
    CaseNumber: '00025078',
    Id: '00025078',
    Priority: 'High',
    caseTimeOfCreation: '2021-07-23T10:36:08.000Z',
    caseSubject: 'Snowflake Connectivity',
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sFmtmAAC',
    incidentId: 'TD-63547',
    incidentNumber: 'TD-63547',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-63547',
    incidentTitle: 'Unexpected error when opening Snowflake connection',
    incidentTimeOfCreation: '2021-07-23T10:36:08.000Z',
    incidentSource: 'Jira',
    suggestedByMl: false,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '60fa833325682c596df6c008',
    CaseNumber: '00025076',
    Id: '00025076',
    Priority: 'High',
    caseTimeOfCreation: '2021-07-23T08:50:20.000Z',
    caseSubject: 'OutOfMemory Error in IrisAgent',
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sFmjIAAS',
    incidentId: 'TD-63416',
    incidentNumber: 'TD-63416',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-63416',
    incidentTitle: 'Getting error while exporting a flow in IrisAgent',
    incidentTimeOfCreation: '2021-05-21T13:31:11.000Z',
    incidentSource: 'Jira',
    suggestedByMl: true,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '60fa437125682c596deaea39',
    CaseNumber: '00025073',
    Id: '00025073',
    Priority: 'High',
    caseTimeOfCreation: '2021-07-23T04:17:49.000Z',
    caseSubject: 'Job always fail when select multiple excel files with parameter',
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sFm6GAAS',
    incidentId: 'TD-61166',
    incidentNumber: 'TD-61166',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-61166',
    incidentTitle: 'Job fails with parameterized excel where not all files have matching sheet name for selection',
    incidentTimeOfCreation: '2021-04-09T14:00:02.000Z',
    incidentSource: 'Jira',
    suggestedByMl: false,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '60f98f7125682c596dcc4336',
    CaseNumber: '00025064',
    Id: '00025064',
    Priority: 'High',
    caseTimeOfCreation: '2021-07-22T15:30:38.000Z',
    caseSubject: 'Jobs running longer with profiling enabled',
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sFfwAAAS',
    incidentId: 'TD-63465',
    incidentNumber: 'TD-63465',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-63465',
    incidentTitle: 'Profiling job takes longer time to run - Deloitte',
    incidentTimeOfCreation: '2021-07-22T15:30:38.000Z',
    incidentSource: 'Jira',
    suggestedByMl: false,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '60f9780125682c596dc85a4f',
    CaseNumber: '00025061',
    Id: '00025061',
    Priority: 'High',
    caseTimeOfCreation: '2021-07-22T13:51:11.000Z',
    caseSubject: 'IrisAgent PROD job failed',
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sFemnAAC',
    incidentId: 'TD-62781',
    incidentNumber: 'TD-62781',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-62781',
    incidentTitle: 'Too many open files by job-metadata-service',
    incidentTimeOfCreation: '2021-06-14T16:46:01.000Z',
    incidentSource: 'Jira',
    suggestedByMl: false,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '60f9780125682c596dc85a27',
    CaseNumber: '00025060',
    Id: '00025060',
    Priority: 'Low',
    caseTimeOfCreation: '2021-07-22T13:49:20.000Z',
    caseSubject: 'Date data types mapping issues',
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sFeJ1AAK',
    incidentId: 'TD-63761',
    incidentNumber: 'TD-63761',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-63761',
    incidentTitle: 'Date format published to Datetime instead of Date via Snowflake',
    incidentTimeOfCreation: '2021-07-22T13:49:20.000Z',
    incidentSource: 'Jira',
    suggestedByMl: false,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '60f914a125682c596db69334',
    CaseNumber: '00025058',
    Id: '00025058',
    Priority: 'High',
    caseTimeOfCreation: '2021-07-22T06:45:33.000Z',
    caseSubject: "can't cancel queued job which is causing a production plan run to fail",
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sFdLsAAK/5003j00001sFdLsAAK/5003j00001sFdLsAAK',
    incidentId: 'TD-63746',
    incidentNumber: 'TD-63746',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-63746',
    incidentTitle: 'Dataprep job fails while run via plan',
    incidentTimeOfCreation: '2021-07-22T06:45:33.000Z',
    incidentSource: 'Jira',
    suggestedByMl: false,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '60f914a125682c596db69334',
    CaseNumber: '00025058',
    Id: '00025058',
    Priority: 'High',
    caseTimeOfCreation: '2021-07-22T06:45:33.000Z',
    caseSubject: "can't cancel queued job which is causing a production plan run to fail",
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sFdLsAAK/5003j00001sFdLsAAK',
    incidentId: 'TD-63589',
    incidentNumber: 'TD-63589',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-63589',
    incidentTitle: 'Dataprep job fails while run via plan',
    incidentTimeOfCreation: '2021-07-22T06:45:33.000Z',
    incidentSource: 'Jira',
    suggestedByMl: false,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '60f914a125682c596db69334',
    CaseNumber: '00025058',
    Id: '00025058',
    Priority: 'High',
    caseTimeOfCreation: '2021-07-22T06:45:33.000Z',
    caseSubject: "can't cancel queued job which is causing a production plan run to fail",
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sFdLsAAK',
    incidentId: 'TD-63493',
    incidentNumber: 'TD-63493',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-63493',
    incidentTitle: 'Unable to cancel a plan run',
    incidentTimeOfCreation: '2021-07-22T06:45:33.000Z',
    incidentSource: 'Jira',
    suggestedByMl: false,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '60f8e98125682c596daeffce',
    CaseNumber: '00025056',
    Id: '00025056',
    Priority: 'High',
    caseTimeOfCreation: '2021-07-22T03:41:48.000Z',
    caseSubject: 'Publish is failing',
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sFck3AAC',
    incidentId: 'TD-63454',
    incidentNumber: 'TD-63454',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-63454',
    incidentTitle: 'Publish to S3 with Append-pqt fails with error "Append is not currently supported as an operation"',
    incidentTimeOfCreation: '2021-07-22T03:41:48.000Z',
    incidentSource: 'Jira',
    suggestedByMl: false,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '60f8871125682c596d9ea7fa',
    CaseNumber: '00025055',
    Id: '00025055',
    Priority: 'High',
    caseTimeOfCreation: '2021-07-21T20:24:58.000Z',
    caseSubject: 'Lock down LOCAL storage directories',
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sFa8RAAS/5003j00001sFa8RAAS',
    incidentId: 'TD-63808',
    incidentNumber: 'TD-63808',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-63808',
    incidentTitle: 'Local storage does not allow path other than "/"',
    incidentTimeOfCreation: '2021-07-21T20:24:58.000Z',
    incidentSource: 'Jira',
    suggestedByMl: false,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '60f8871125682c596d9ea7fa',
    CaseNumber: '00025055',
    Id: '00025055',
    Priority: 'High',
    caseTimeOfCreation: '2021-07-21T20:24:58.000Z',
    caseSubject: 'Lock down LOCAL storage directories',
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sFa8RAAS',
    incidentId: 'TD-62553',
    incidentNumber: 'TD-62553',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-62553',
    incidentTitle: 'IrisAgent on Openshift localFileStorage properties are incorrect',
    incidentTimeOfCreation: '2021-07-21T20:24:58.000Z',
    incidentSource: 'Jira',
    suggestedByMl: false,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '60f86dc225682c596d9ac677',
    CaseNumber: '00025052',
    Id: '00025052',
    Priority: 'High',
    caseTimeOfCreation: '2021-07-21T18:52:24.000Z',
    caseSubject: 'Photon vs emrSpark',
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sFYs9AAG',
    incidentId: 'TD-63517',
    incidentNumber: 'TD-63517',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-63517',
    incidentTitle: 'Customer looking for Scope of the issue and RCA - Data Discrepancy in Job Output for Photon vs EMR Spark',
    incidentTimeOfCreation: '2021-07-21T18:52:24.000Z',
    incidentSource: 'Jira',
    suggestedByMl: false,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '60f85fb225682c596d987000',
    CaseNumber: '00025050',
    Id: '00025050',
    Priority: 'High',
    caseTimeOfCreation: '2021-07-21T17:52:25.000Z',
    caseSubject: 'False error on Dataprep job 10031873',
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sFYESAA4',
    incidentId: 'TD-62553',
    incidentNumber: 'TD-62553',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-62553',
    incidentTitle: 'Discrepancy in Job reports Dataprep vs Dataflow. Dataprep shows job as failure while Dataflow shows job as completed.',
    incidentTimeOfCreation: '2021-07-21T17:52:25.000Z',
    incidentSource: 'Jira',
    suggestedByMl: false,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '60f5b53125682c596df44a25',
    CaseNumber: '00025023',
    Id: '00025023',
    Priority: 'High',
    caseTimeOfCreation: '2021-07-19T17:20:19.000Z',
    caseSubject: 'IrisAgent Related Issue',
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sFEA3AAO',
    incidentId: 'TD-35617',
    incidentNumber: 'TD-35617',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-35617',
    incidentTitle: 'IrisAgent 5.0 HW - Teradata - Ingestion and partial publication issue',
    incidentTimeOfCreation: '2018-09-14T20:43:56.000Z',
    incidentSource: 'Jira',
    suggestedByMl: true,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  },
  {
    caseDatabaseId: '60f57b1525682c596deaea3b',
    CaseNumber: '00025014',
    Id: '00025014',
    Priority: 'High',
    caseTimeOfCreation: '2021-07-19T13:15:51.000Z',
    caseSubject: 'Fundsquare error Spark Job',
    caseLink: 'https://IrisAgent.my.salesforce.com/5003j00001sFAsVAAW',
    incidentId: 'TD-62554',
    incidentNumber: 'TD-62554',
    incidentLink: 'https://jira.IrisAgent.net//browse/TD-62554',
    incidentTitle: 'Spark job error - Spark SQL exception',
    incidentTimeOfCreation: '2021-04-13T15:10:44.000Z',
    incidentSource: 'Jira',
    suggestedByMl: true,
    caseSentimentBucket: {
      id: 5,
      title: 'Neutral',
      color: '#E8E36E'
    },
    annualContractValue: 857230000,
    commentsLength: 10,
    openTimeSecs: 518400,
    priorityScore: {
      score: 81,
      parameters: [
        {
          type: PriorityScoreParameter.OPEN_TIME_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.SOURCE_PRIORITY_SCORE,
          score: 40,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.CASE_SENTIMENT_SCORE,
          score: 70,
          scoreBucket: {
            id: 'good',
            title: 'Good'
          }
        },
        {
          type: PriorityScoreParameter.COMMENTS_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        },
        {
          type: PriorityScoreParameter.ACV_SCORE,
          score: 99,
          scoreBucket: {
            id: 'bad',
            title: 'Bad'
          }
        }
      ]
    }
  }
];

export const aiRecommendedCasesResponse = {
  data: aiRecommendedCases.map((item) => ({
    case: {
      caseId: item.Id,
      caseNumber: item.CaseNumber,
      priority: item.Priority,
      sourceCaseId: item.caseDatabaseId,
      url: item.caseLink,
      subject: item.caseSubject,
      timeOfCreation: item.caseTimeOfCreation
    },
    issue: {
      issueKey: item.incidentNumber,
      url: item.incidentLink,
      summary: item.incidentTitle || ''
    },
    priorityScore: {
      score: item.priorityScore.score,
      caseSentimentBucket: item.caseSentimentBucket,
      openTimeSecs: item.openTimeSecs,
      commentsLength: item.commentsLength,
      annualContractValue: item.annualContractValue,
      parameters: item.priorityScore.parameters.map((param) => ({
        score: param.score,
        type: param.type,
        scoreBucket: param.scoreBucket
      }))
    }
  }))
};
