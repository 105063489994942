import React, { FC } from 'react';
import { Text } from '../../../components/common';
import { PriorityTooltip } from './types';
import './AiRecommendedCaseRow.scss';
import { PriorityScoreParameter } from '../../customerHealth/types';
import { PriorityScoreGranularData } from '../../customerHealth/components/PriorityScoreGranularData';
import { HtmlTooltip } from '../../../components/common/blocks/Tooltip';
import Grid from '@material-ui/core/Grid';
import Utils from '../../../utils/utils';
import Constants from '../../../utils/constants';

import _ from 'lodash';
import moment from 'moment';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

export const PriorityCell: FC<{
  priorityTooltipItem: PriorityTooltip;
  type: String;
  placement?:
    | 'left'
    | 'right'
    | 'top'
    | 'bottom'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'right-end'
    | 'right-start'
    | 'top-end'
    | 'top-start';
}> = (props) => {
  return (
    <div className="priority-box-container ">
      <HtmlTooltip
        classes={{
          tooltip: 'width-mc max-width-mc'
        }}
        arrow
        placement={props.placement ? props.placement : 'top'}
        title={
          <React.Fragment>
            <div
              style={{
                textAlign: 'left',
                marginLeft: '2%',
                marginTop: '3%'
              }}
            >
              <Text variant="p1" weight="bold" style={{ color: '#FFFFFF' }}>
                Case Priority based on
              </Text>
            </div>
            <Grid
              container
              style={{
                width: '500px'
                // padding: '2%'
              }}
            >
              {getParameterElements(props.priorityTooltipItem).map((x, i) => (
                <React.Fragment key={i}>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    {x}
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </React.Fragment>
        }
      >
        <div style={{ width: 'fit-content' }}>
          {props.type !== 'info' && (
            <span
              className={props.priorityTooltipItem.Priority}
              style={{
                borderRadius: '4px'
              }}
            >
              {props.priorityTooltipItem.Priority}
            </span>
          )}
          {props.type === 'info' && <InfoOutlinedIcon style={{ color: '#5D5FEF' }} />}
        </div>
      </HtmlTooltip>
    </div>
  );
};
export const getParameterElements = (priorityTooltipItem: PriorityTooltip) => {
  const scoreHeader = new Map<PriorityScoreParameter, string>([
    [PriorityScoreParameter.CASE_SENTIMENT_SCORE, 'Case Sentiment Score'],
    [PriorityScoreParameter.SOURCE_PRIORITY_SCORE, 'Case Priority'],
    [PriorityScoreParameter.ACV_SCORE, 'Customer Annual Contract Value'],
    [PriorityScoreParameter.OPEN_TIME_SCORE, 'Customer wait time'],
    [PriorityScoreParameter.COMMENTS_SCORE, 'Number of case comments']
  ]);
  const scoreInfos = new Map<PriorityScoreParameter, string>([
    [PriorityScoreParameter.SOURCE_PRIORITY_SCORE, `${priorityTooltipItem.Priority || 'Moderate'} Priority`]
  ]);

  if (!_.isNil(priorityTooltipItem.caseSentimentBucket)) {
    scoreInfos.set(PriorityScoreParameter.CASE_SENTIMENT_SCORE, priorityTooltipItem.caseSentimentBucket.title);
  }

  if (!_.isNil(priorityTooltipItem.openTimeSecs)) {
    scoreInfos.set(PriorityScoreParameter.OPEN_TIME_SCORE, `${Utils.durationFormatReadable(moment.duration(priorityTooltipItem.openTimeSecs, 'seconds'))}`);
  }

  if (!_.isNil(priorityTooltipItem.commentsLength)) {
    scoreInfos.set(PriorityScoreParameter.COMMENTS_SCORE, `${priorityTooltipItem.commentsLength} comments`);
  }

  if (!_.isNil(priorityTooltipItem.annualContractValue)) {
    scoreInfos.set(PriorityScoreParameter.ACV_SCORE, Utils.nFormatter(priorityTooltipItem.annualContractValue, 1));
  }

  const checkDash = (len: number, index: number) => {
    if (len <= 2) {
      return false;
    }
    if (len === 3 && index === 0) {
      return true;
    }
    if (len > 3) {
      if (index <= Math.ceil(len / 2 - 1)) {
        return true;
      }
    }
    return false;
  };

  const parameters = priorityTooltipItem.priorityScore.parameters;
  return _.orderBy(parameters, ['score'], ['desc']).map((granularScore, index) => (
    <PriorityScoreGranularData
      key={index}
      scoreType={scoreHeader.get(granularScore.type)}
      scoreValue={_.floor(granularScore.score)}
      color={Constants.ColorRating[granularScore.scoreBucket.id]}
      rating={granularScore.scoreBucket.id}
      enabledDashLine={checkDash(parameters.length, index)}
      scoreInfo={scoreInfos.get(granularScore.type) || ''}
    />
  ));
};
