import React, { ReactElement } from 'react';
import { MultiSelectInput } from '../../../../../components/MultiSelectInput';
import { RulesNativeSelect } from '../../RulesNativeSelect';
import { formatPrerequisitesData, RulesRowManager, useTriggersPrerequisites } from '../../../utils';
import { HandleRowComponentType, Option } from '../../../types';
import { ReactComponent as Tag } from '../../../../../assets/tag.svg';
import '../styles.scss';
import { CategoryStatus, CategoryTags } from '../../../constants';

/**
 * Row manager for Tags,Statuses
 */
export class TagsRowManager extends RulesRowManager {
  tagsOptionsOperator: Option[] = [
    { name: 'in', label: 'Contains at least one of the following' },
    { name: 'nin', label: 'Contains none of the following' }
  ];

  private placeholderText: string;
  private placeholderOption: {
    name: any;
    label: any;
  }[];

  constructor(options: Option[], actionValue: any, actionOperator: any, actionType: string) {
    super(options, actionValue, actionOperator, actionType);
    this.action.type = actionType;
    this.action.operator = actionOperator === undefined ? 'in' : actionOperator;
  }

  setDefaultActionValue(): void {
    this.action.value = [];
  }

  tagsSelected = this.action.value.map((val: any) => {
    return { name: val, label: val };
  });

  createItems(handleRowComponent: HandleRowComponentType): ReactElement {
    if (this.action.value === undefined) this.setDefaultActionValue();
    const { triggersPrerequesites } = useTriggersPrerequisites();

    switch (this.action.type) {
      case CategoryTags:
        this.placeholderText = 'Search Tags';
        this.placeholderOption = formatPrerequisitesData(triggersPrerequesites?.tags || []);
        break;
      case CategoryStatus:
        this.placeholderText = 'Search Status';
        // TODO: Zendesk hardcoded statuses
        this.placeholderOption = [
          { name: 'new', label: 'new' },
          { name: 'open', label: 'open' },
          { name: 'pending', label: 'pending' },
          { name: 'hold', label: 'hold' },
          { name: 'solved', label: 'solved' }
        ];
        break;
    }

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Tag />
          <p className="title">Tags</p>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 8 }}>
          <RulesNativeSelect
            options={this.tagsOptionsOperator}
            defaultValue={this.action.operator}
            onChange={(e) => {
              this.action.operator = e.target.value;
              handleRowComponent(this.action);
            }}
          />
          <div>
            <MultiSelectInput
              displayValue="label"
              placeholder={this.placeholderText}
              onKeyPressFn={function noRefCheck() {}}
              onRemove={(e) => {
                this.action.value = e.map((val: any) => val.name) || [];
                handleRowComponent(this.action);
              }}
              onSearch={function noRefCheck() {}}
              valuesSelected={this.tagsSelected}
              onChange={(e) => {
                this.action.value = e.map((val) => val.name);
                handleRowComponent(this.action);
              }}
              options={this.placeholderOption}
            />
          </div>
        </div>
      </div>
    );
  }
}
