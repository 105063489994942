import React, { FC } from 'react';
import { Card, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import TableHeadSorting from '../../../components/table-head-sorting/TableHeadSorting';
import { Stack, StackProps, Text, useTableSorters } from '../../../components/common';
import { CustomerAccount, CustomerAccountFormat } from '../types';
import { useHistory } from 'react-router-dom';
import { DarkTooltip } from '../../incidents/incident-list/DarkTooltip';
import { ReactComponent as HelpSvg } from '../../../assets/Help.svg';
import utils from '../../../utils/utils';
import { Button } from '../../../components/home/Button';
import { TablePaper } from '../../../components/common/blocks/TablePaper';

const TableHeaderCells = [
  {
    id: 'accountName',
    label: 'CUSTOMER',
    width: '25%',
    hasFilter: true
  },
  {
    id: 'healthScore',
    label: 'HEALTH SCORE',
    width: '20%',
    hasFilter: true
  },
  {
    id: 'cases',
    label: '#CASES',
    width: '20%',
    hasFilter: true
  },
  {
    id: 'numEscalatedCases',
    label: '#ESCALATED CASES',
    width: '20%',
    hasFilter: true
  }
];

type Props = {
  data: CustomerAccount[];
  isDashboard?: boolean;
} & StackProps;

export const CustomerAccountHealthScoreTable: FC<Props> = (props) => {
  const { data, isDashboard, ...stackProps } = props;
  const formatDataCustomerLower = data?.map((customerLower: any) => {
    const escalations = customerLower.health.parameters.filter((item: any) => item.type === 'numEscalatedCases');
    return {
      name: customerLower.name,
      healthScore: customerLower.health.score,
      cases: customerLower.health.parameters.filter((item: any) => item.type === 'numCases')[0].value,
      numEscalatedCases: escalations.length > 0 ? escalations[0].value : 0
    };
  });
  const history = useHistory();
  const { onSort, filterData, sortColumn, sortOrder } = useTableSorters({
    turnOffPagination: true
  });
  const handleRowClick = (rowData: CustomerAccountFormat) => {
    history.push(utils.getAccountHealthRoute(rowData.name));
  };
  return (
    <TablePaper
      style={{
        minHeight: '100%'
      }}
    >
      <Stack gap={20} direction="vertical" {...stackProps}>
        <Text variant="p4" weight="bold" color="dark" style={{ marginTop: '16px', marginBottom: '0px' }}>
          {'Customers with low health score'} &nbsp;
          <DarkTooltip title="Score between 0 and 100 that indicates customer health based on signals like MTTR, sentiment, escalations, number of cases, etc.">
            <HelpSvg />
          </DarkTooltip>
        </Text>
        <Card
          style={{
            // padding: '30px 15px',
            borderRadius: 0,
            boxShadow: 'none'
          }}
        >
          <TableContainer>
            <Table size="small" aria-label="Low Customer Account Health Table">
              <TableHeadSorting order={sortOrder} orderByColumn={sortColumn} onRequestSort={(_, column) => onSort(column)} headCells={TableHeaderCells} />
              <TableBody>
                {formatDataCustomerLower.length ? (
                  filterData(formatDataCustomerLower)
                    .slice(0, isDashboard ? 5 : formatDataCustomerLower.length)
                    .map((row, index) => (
                      <TableRow key={index} onClick={() => handleRowClick(row)}>
                        <TableCell variant="body">
                          <Text lineClamp={3} variant="p1" weight="semi-bolder" style={{ color: '#5E81F4' }}>
                            {row.name}
                          </Text>
                        </TableCell>
                        <TableCell variant="body">
                          <Text variant="p1" weight="semi-bold" color="dark">
                            {renderScore(row.healthScore)}
                          </Text>
                        </TableCell>
                        <TableCell variant="body">
                          <Text variant="p1" weight="semi-bold" color="dark">
                            {row.cases}
                          </Text>
                        </TableCell>
                        <TableCell variant="body">
                          <Text variant="p1" weight="semi-bold" color="dark">
                            {row.numEscalatedCases}
                          </Text>
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableCell colSpan={100} align="center">
                    <Text>No Data</Text>
                  </TableCell>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
        {isDashboard && (
          <Stack style={{ position: 'absolute', bottom: '30px', width: '100%' }} direction="horizontal" align="center" justify="center">
            <Button href="/health/overview" variant="outlined">
              View Customer Health Overview
            </Button>
          </Stack>
        )}
      </Stack>
    </TablePaper>
  );
};

const renderScore = (score: number) => {
  var color = '',
    background = '';
  if (score < 40) {
    color = '#EB5757';
    background = 'rgba(235, 87, 87, 0.1)';
  } else if (score < 40) {
    color = '#F2994A';
    background = 'rgba(242, 201, 76, 0.1)';
  } else {
    color = '#219653';
    background = 'rgba(3, 187, 115, 0.1)';
  }

  return (
    <div
      style={{
        color: color,
        background: background,
        width: '60px',
        paddingLeft: '11.5px',
        paddingRight: '11.5',
        paddingTop: '8px',
        paddingBottom: '8px',
        fontSize: '8px',
        lineHeight: '16px',
        height: '32px'
      }}
    >
      <div style={{ fontSize: '16px', display: 'inline' }}>{score}</div>/100
    </div>
  );
};
