import { useQuery } from 'react-query';

import { CACHE_KEYS, CACHE_TIME } from '../../../utils/cacheUtils';
import IrisPortalService from '../../../services/IrisPortalService';

import type { CustomerHealthDataExtras } from '../types';

export function useCustomerHealthDataExtras() {
  const { data: dataCustomerHealthExtras, isLoading: isLoadingCustomerHealthExtras, isFetching: isFetchingCustomerHealthExtras } = useQuery(
    CACHE_KEYS.CUSTOMER_HEALTH_EXTRAS,
    () => IrisPortalService.getCustomerHealthDataExtras(),
    {
      ...CACHE_TIME.get(CACHE_KEYS.CUSTOMER_HEALTH_EXTRAS),
      placeholderData: {
        customerHealth: {
          highPriorityCaseList: [],
          binnedSentimentCategory: {},
          perDayCustomerHealthCounts: {}
        }
      } as CustomerHealthDataExtras
    }
  );

  return {
    dataCustomerHealthExtras,
    isLoadingCustomerHealthExtras,
    isFetchingCustomerHealthExtras
  };
}
