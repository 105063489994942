import { useQuery } from 'react-query';

import IrisPortalService from '../../../services/IrisPortalService';
import { CACHE_KEYS, CACHE_TIME } from '../../../utils/cacheUtils';
import Utils from '../../../utils/utils';

export const useTriggersPrerequisites = () => {
  const { data: triggersPrerequesites, isLoading } = useQuery([CACHE_KEYS.TRIGGERS_PREREQUISITES], () => IrisPortalService.getTriggersPrerequisites(), {
    refetchOnWindowFocus: false,
    onError: (e) => {
      Utils.showError(String(e));
    },
    onSuccess: () => {},
    ...CACHE_TIME.get(CACHE_KEYS.TRIGGERS_PREREQUISITES)
  });

  return {
    triggersPrerequesites,
    isLoading
  };
};
