import React, { FC } from 'react';
import { Stack } from './Stack';
import './Loader.scss';

export const Loader: FC<{
  center?: boolean;
  width?: React.ImgHTMLAttributes<HTMLImageElement>['width'];
  imgClassName?: string;
}> = (props) => {
  const { center, width = '55px', imgClassName = '' } = props;

  const img = <img alt="loader" width={width} src="/images/spinner.gif" className={imgClassName} />;

  if (center) {
    return (
      <Stack align="center" gap={0} direction="vertical" style={{ marginTop: '10%' }}>
        {img}
      </Stack>
    );
  }

  return img;
};
