import React, { Component } from 'react';
import { Stack, Text } from '../common';

import './BadgeCard.scss';

interface Props {
  data: any;
}

class BadgeCard extends Component<Props, any> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    const { data } = this.props;
    return (
      <Stack
        style={{
          width: '271px',
          padding: '20px 20px',
          borderRadius: 10,
          flex: 1,
          background: 'linear-gradient(0deg, rgba(94, 129, 244, 0.05), rgba(94, 129, 244, 0.05)), #FFFFFF'
        }}
        align="center"
        justify="space-between"
      >
        <Stack direction="vertical" gap={10}>
          <Text variant="p4" weight="semi-bolder" style={{ color: '#1C1D21' }}>
            {data.value}
          </Text>
          <Text variant="p1" weight="regular" style={{ color: '#8181A5' }}>
            {data.text}
          </Text>
        </Stack>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '56px',
            height: '56px',
            borderRadius: '10px'
          }}
        >
          <data.icon></data.icon>
        </div>
      </Stack>
    );
  }
}

export default BadgeCard;
