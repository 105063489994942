import 'react-datepicker/dist/react-datepicker.css';
import React, { FC, useEffect, useState } from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { endOfDay, startOfDay, subDays } from 'date-fns';
import { DateDisplay } from './DateDisplay';
import { DateTuple } from './utils';
import moment from 'moment';
import './DateDisplay.scss';

const MAX_DATE = new Date();
const MIN_DATE = subDays(MAX_DATE, 90);

type DateRangePickerProps = Omit<ReactDatePickerProps, 'onChange' | 'value'> & {
  onChange: (dateRange: DateTuple) => void;
  value: DateTuple | undefined;
  style?: React.CSSProperties;
  compact?: boolean;
  invertStyle?: boolean;
};

export const DateRangePicker: FC<DateRangePickerProps> = (props) => {
  const { value, maxDate = MAX_DATE, minDate = MIN_DATE, style, onChange, compact, invertStyle, ...datePickerProps } = props;
  const getNum = (val: any) => {
    const now = moment(val?.[0]);
    const end = moment(val?.[1]);
    const duration = moment.duration(now.diff(end));
    const days = duration.asDays() * -1 - 1;
    switch (days.toFixed(0).toString()) {
      case '1':
        return 0;
      case '7':
        return 1;
      case '14':
        return 2;
      default:
        return 3;
    }
  };
  const [dateSelected, setDateSelected] = useState<number | null>(getNum(value));
  const [customDateRange, setCustomDateRange] = useState<any | null>(getNum(value) >= 3 ? value : null);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [datePickerVisible, setDatePickerVisible] = useState(false);

  useEffect(() => {
    if (compact) {
      setCustomDateRange(value);
    }
  }, [compact, value]);

  const handleDateChange = (dateRange: DateTuple) => {
    const [start, end] = dateRange;
    setStartDate(start);
    setEndDate(end);
    if (start !== null && end !== null) {
      props.onChange([startOfDay(start), endOfDay(end)]);
      setCustomDateRange(dateRange);
    }
  };
  const handleDateChangeFromPicker = (dateRange: DateTuple) => {
    const [start, end] = dateRange;
    setStartDate(start);
    setEndDate(end);
    if (start !== null && end !== null) {
      props.onChange([startOfDay(start), endOfDay(end)]);
      setCustomDateRange(dateRange);
      setDatePickerVisible(false);
      setDateSelected(-1);
    }
  };

  const handleCalendarClose = () => {
    if (startDate === null || endDate === null) {
      setStartDate(value?.[0] || null);
      setEndDate(value?.[1] || null);
    }
  };

  const handleCustomClick = () => {
    setDatePickerVisible(true);
  };

  const ButtonGroup = (data: any) => {
    const { buttons, doSomethingAfterClick, dateSelected } = data;
    const [clickedId, setClickedId] = useState(dateSelected);
    const handleClick = (event: any, id: any) => {
      setClickedId(id);
      setDateSelected(id);
      doSomethingAfterClick(event);
      setCustomDateRange(null);
    };

    return (
      <div className={'d-flex align-items-center'} style={{ paddingRight: 5 }}>
        {buttons.map((buttonLabel: any, i: any) => (
          <button
            key={i}
            name={i}
            onClick={(event) => handleClick(event, i)}
            style={{
              backgroundColor: invertStyle ? (i !== clickedId ? 'transparent' : 'white') : 'white',
              color: i !== clickedId ? '#8181A5' : 'black',
              width: 70,
              fontWeight: 600,
              fontSize: 14,
              height: 40,
              font: 'DM Sans',
              // fontFamily: `'DM Sans', sans-serif`,
              cursor: 'pointer',
              border: i === clickedId ? '2px solid #ECECF2' : 'none',
              boxSizing: i !== clickedId ? 'border-box' : undefined,
              borderRadius: 8
            }}
          >
            {buttonLabel}
          </button>
        ))}
      </div>
    );
  };

  const printButtonLabel = (event: any) => {
    switch (event.target.name.toString()) {
      case '0':
        handleDateChange([subDays(new Date(), 1), new Date()]);
        break;
      case '1':
        handleDateChange([subDays(new Date(), 7), new Date()]);
        break;
      case '2':
        handleDateChange([subDays(new Date(), 14), new Date()]);
        break;
    }
  };

  return (
    <div className={'d-flex ml-auto'} style={{ zIndex: 9, ...style }}>
      {!compact && <ButtonGroup buttons={['1 Day', '7 Days', '14 Days']} dateSelected={dateSelected} doSomethingAfterClick={printButtonLabel} />}
      <DatePicker
        maxDate={maxDate}
        minDate={minDate}
        onChange={handleDateChangeFromPicker}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        open={datePickerVisible}
        onClickOutside={() => setDatePickerVisible(false)}
        onCalendarClose={handleCalendarClose}
        onInputClick={handleCustomClick}
        customInput={
          <div>{customDateRange ? <DateDisplay dateRange={[customDateRange[0], customDateRange[1]]} onClick={() => {}} /> : <DateDisplay></DateDisplay>}</div>
        }
        {...datePickerProps}
      />
    </div>
  );
};
