import 'react-datepicker/dist/react-datepicker.css';
import React, { FC, useState } from 'react';
import './CategoryTypeSelectionDisplay.scss';

type CategoryTypeSelectionProps = {
  value?: string;
  style?: string;
  onChangeType: (type: string) => void;
  selectedType: string;
  buttonLabels: string[];
};

export const CategoryTypeSelection: FC<CategoryTypeSelectionProps> = (props) => {
  const { selectedType, onChangeType, buttonLabels } = props;
  let selectedId = 0;
  buttonLabels.map((buttonLabel: any, i: any) => {
    if (buttonLabel === selectedType) {
      selectedId = i;
    }
    return buttonLabel;
  });

  const ButtonGroup = (data: any) => {
    const { buttons } = data;
    const [clickedId, setClickedId] = useState(selectedId);
    const handleClick = (id: any) => {
      setClickedId(id);
      onChangeType(buttonLabels[id]);
    };

    return (
      <div style={{ paddingRight: 5 }}>
        {buttons.map((buttonLabel: any, i: any) => (
          <button
            key={i}
            name={i}
            onClick={() => handleClick(i)}
            className="category-button"
            style={
              clickedId === i
                ? { background: '#5E81F4' }
                : {
                    background: 'linear-gradient(0deg, rgba(94, 129, 244, 0.1), rgba(94, 129, 244, 0.1)), #FFFFFF'
                  }
            }
          >
            <p style={clickedId === i ? { color: 'white' } : { color: '#5E81F4' }} className="category-button-text">
              {buttonLabel}
            </p>
          </button>
        ))}
      </div>
    );
  };

  return (
    <div className="category-class">
      <ButtonGroup buttons={buttonLabels} />
    </div>
  );
};
