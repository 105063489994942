import Utils from '../../utils/utils';
import MockData from '../../utils/mockData';
import { CategoryDataWithFilter } from '../../routes/categories';
import { handleError, isMocKData } from './utils';

export interface CaseCategoriesInfo {
  filter: string;
  caseCategoriesData: any[];
}

export const getCaseCategoriesData = async (startTime: any, endTime: any, attributeFilter?: {}): Promise<CaseCategoriesInfo> => {
  /**
   * Converts caseCategories into an array and sort max cases first
   * CAUTION: Do not export this function or reuse it anywhere outside of this service fn!
   * @param data Record<string, CategoryData>
   */
  function responseInterceptor(data: CategoryDataWithFilter): CaseCategoriesInfo {
    const cats = data.caseCategoriesData;

    const sortedCats = Object.keys(cats)
      .map((category) => ({
        name: category,
        ...cats[category]
      }))
      .slice()
      .sort((category1, category2) => (category1.count - category2.count > 0 ? -1 : 1));
    return { filter: data.filter, caseCategoriesData: sortedCats };
  }

  const tokenExpress = Utils.getObjectItem('token_express');
  const startDate = Utils.formatDate(startTime, 'YYYY-MM-DD');
  const endDate = Utils.formatDate(endTime, 'YYYY-MM-DD');
  const endpoint = `${process.env.REACT_APP_APISERVER_URL}/v1/customer/insights/categories?startTime=${startDate}&endTime=${endDate}`;

  if (isMocKData) {
    return responseInterceptor(
      // @ts-expect-error
      MockData.getCaseCategoriesMockData() as CategoryDataWithFilter
    );
  }

  const response = await fetch(endpoint, {
    method: 'POST',
    headers: new Headers({
      Authorization: `Bearer ${tokenExpress}`,
      'Content-Type': 'application/json'
    }),
    body: JSON.stringify({
      attributeFilter
    })
  });
  handleError(response.status);
  if (!response.ok) {
    throw new Error(`Unhandled error status code!: ${response.status}`);
  }

  const body: CategoryDataWithFilter = await response.json();
  return responseInterceptor(body);
};
