import React, { FC } from 'react';
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { Stack, Text } from '../../../components/common';
import { format } from 'date-fns';

export const CaseDistribution: FC<{
  distribution: Record<string, number>;
  categoryName: string;
}> = (props) => {
  const { distribution, categoryName } = props;
  const orderedData: any = [];
  Object.keys(distribution).forEach((key: any) => {
    orderedData.push({ date: key, casesCount: distribution[key] });
  });
  orderedData.sort((a: any, b: any) => new Date(a.date).getTime() - new Date(b.date).getTime());

  return (
    <Stack direction="vertical" gap={30} align="flex-start" style={{ width: '100%' }}>
      <Text variant="p3" weight="medium">
        Case Distribution for {categoryName}
      </Text>

      <Text weight="semi-bold">NUMBER OF CASES</Text>
      <ResponsiveContainer height={300} width="100%">
        <AreaChart data={orderedData}>
          <defs>
            <linearGradient id="prefix__a" x1={0.5} x2={0.5} y2={1}>
              <stop stopColor="#5F83FA" stopOpacity={0.3} />
              <stop offset={1} stopColor="#FFFFFF" stopOpacity={0.01} />
            </linearGradient>
          </defs>
          <XAxis dataKey="date" tickFormatter={(value: string) => format(new Date(value), 'MMM dd, yyyy')} tickSize={12} />
          <YAxis tickSize={0} tickMargin={20} />
          <CartesianGrid strokeDasharray="3 3" />
          <Area type="monotone" dataKey="casesCount" stroke="#5F83FA" fill="url(#prefix__a)" />
          <Tooltip
            labelFormatter={(label) => {
              return <span>{format(new Date(label), 'MMM dd, yyyy')}</span>;
            }}
            formatter={(value: string) => {
              return [value, 'Cases'];
            }}
          />
        </AreaChart>
      </ResponsiveContainer>
    </Stack>
  );
};
