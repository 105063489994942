import React, { Component } from 'react';
import './NegativeScoreTable.scss';
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip } from '@material-ui/core';

import Utils from '../../../utils/utils';
import Constants from '../../../utils/constants';
import TableHeadSorting from '../../../components/table-head-sorting/TableHeadSorting';
import { Heading, IssueId, Stack, Text } from '../../../components/common';
import { TablePaper } from '../../../components/common/blocks/TablePaper';
import { JiraIssueCase } from '../../../services/IrisPortalService/jira';

interface Props {
  data: JiraIssueCase[];
}

class NegativeScoreTable extends Component<Props, any> {
  constructor(props: Props) {
    super(props);
    this.state = {
      order: Constants.DESCENDENT_SORTING,
      orderByColumn: 'timeOfCreation'
    };
  }

  handleRequestSort = (event: any, column: string) => {
    const isAsc = this.state.orderByColumn === column && this.state.order === Constants.ASCENDING_SORTING;
    this.setState({
      order: isAsc ? Constants.DESCENDENT_SORTING : Constants.ASCENDING_SORTING,
      orderByColumn: column
    });
  };

  render() {
    const { data } = this.props;

    const headCells = [
      { id: 'caseNumber', label: 'Case#', width: '10%', hasFilter: true },
      { id: 'subject', label: 'Subject', width: '20%', hasFilter: true },
      {
        id: 'description',
        label: 'Description',
        width: '23%',
        hasFilter: true
      },
      { id: 'AccountName', label: 'Customer', width: '14%', hasFilter: true },
      { id: 'priority', label: 'Priority', width: '10%', hasFilter: true },
      { id: 'status', label: 'Status', width: '8%', hasFilter: true },
      {
        id: 'timeOfCreation',
        label: 'Created on',
        width: '15%',
        hasFilter: true
      }
    ];

    return (
      <TablePaper>
        <Stack direction="vertical" gap={20}>
          <Heading style={{ marginTop: 16 }}>Cases caused by this incident</Heading>
          <TableContainer component={Paper} elevation={0}>
            <Table stickyHeader>
              <TableHeadSorting
                order={this.state.order}
                orderByColumn={this.state.orderByColumn}
                onRequestSort={this.handleRequestSort}
                headCells={headCells}
              />
              <TableBody>
                {Utils.sortTableData(data || [], this.state.order, this.state.orderByColumn).map((row: JiraIssueCase, index: number) => (
                  <TableRow key={index}>
                    <TableCell>
                      <a href={row.url} target="_blank" rel="noopener noreferrer">
                        <IssueId color="#58508D">{row.caseNumber}</IssueId>
                      </a>
                    </TableCell>
                    <Tooltip title={row.subject} placement="top">
                      <TableCell>
                        <Text color="dark" variant="p1" lineClamp={2}>
                          {row.subject}
                        </Text>
                      </TableCell>
                    </Tooltip>
                    <Tooltip title={row.description} placement="top">
                      <TableCell className="dont-break-out">
                        <Text color="dark" variant="p1" lineClamp={2}>
                          {row.description}
                        </Text>
                      </TableCell>
                    </Tooltip>
                    <TableCell>{row.accountName}</TableCell>
                    <Tooltip title={row.priority} placement="top">
                      <TableCell>
                        <Text color="dark" variant="p1" lineClamp={2}>
                          {row.priority}
                        </Text>
                      </TableCell>
                    </Tooltip>
                    <TableCell>
                      <Text color="dark" variant="p1" lineClamp={2}>
                        {row.status}
                      </Text>
                    </TableCell>
                    <TableCell>{Utils.formatDate(row.timeOfCreation, 'MMM D, Y')}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </TablePaper>
    );
  }
}

export default NegativeScoreTable;
