import React from 'react';
import { AgentForRouting, AgentForRoutingUpdateRequest } from '../../services/types';
import { Button, Switch, TableCell as MUITableCell } from '@material-ui/core';
import _ from 'lodash';
import { IconButton } from '../../components/IconButton';
import { Stack } from '../../components/common';
import { Loader } from '../../components/common/blocks/Loader';
import produce from 'immer';
import FrontendApiServer from '../../services/IrisPortalService/frontendApiServer';
import Utils from '../../utils/utils';
import MomentTimezone from 'moment-timezone';
import Moment from 'moment/moment';
import { ReactComponent as EditIcon } from '../../assets/Edit.svg';
import { MultiSelectInput, MultiSelectInputOption } from '../../components/MultiSelectInput';
import SearchWidget from '../categories/components/SearchWidget';
import { TextArea } from '../../components/TextArea';

const WEEK_DAY_OPTIONS: Array<MultiSelectInputOption> = _.range(0, 7).map((day: number) => {
  return { item: day, label: Moment.weekdays(day) };
});
const WORKING_HOURS_ERROR_MESSAGE = 'Working hours should be in the format hh:mm AM - hh:mm AM';

// Most people are not aware that single location could have multiple timezones, keep only location names.
const timezoneNames = MomentTimezone.tz.names().filter((name) => name.includes('/'));
const timezones: Array<MomentTimezone.MomentZone> = [];
for (const timezoneName of timezoneNames) {
  const zone = MomentTimezone.tz.zone(timezoneName);
  if (_.isNil(zone)) {
    continue;
  }

  timezones.push(zone);
}

export interface IRoutingAgentRowProps {
  agent: AgentForRouting;
  canModify: boolean;
  onUpdateSuccessful: () => void;
  onEdit: () => void;
  onCancel: () => void;
}

export const RoutingAgentRow: React.FC<IRoutingAgentRowProps> = (props) => {
  const { agent, canModify, onUpdateSuccessful, onEdit, onCancel } = props;

  const [isEditing, setEditing] = React.useState<boolean>(false);
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const [updateRequest, setUpdateRequest] = React.useState<AgentForRoutingUpdateRequest>(getUpdateRequestFromAgent());
  const [error, setError] = React.useState<string | null>(null);

  const isOnboarded = agent.isOnboarded;

  return (
    <React.Fragment>
      <MUITableCell style={{ width: '20%' }} align="left" className={'routing-agent-name'} component={'td'} classes={{ root: 'word-break' }}>
        {agent.name}
      </MUITableCell>
      <MUITableCell style={{ width: '10%' }} align="left" component={'td'}>
        {getTimezoneColumn()}
      </MUITableCell>
      <MUITableCell style={{ width: '10%' }} align="left" component={'td'}>
        {getWorkingDaysColumn()}
      </MUITableCell>
      <MUITableCell style={{ width: '10%' }} align="left" component={'td'}>
        {getWorkingHoursColumn()}
      </MUITableCell>
      <MUITableCell style={{ width: '9%' }} align="left" component={'td'}>
        {getOutOfOfficeDaysColumn()}
      </MUITableCell>
      <MUITableCell style={{ width: '13%' }} align="left" component={'td'}>
        {getSkillsColumn()}
      </MUITableCell>
      <MUITableCell style={{ width: '9%' }} align="left" component={'td'}>
        <Switch
          checked={isEditing && !_.isNil(updateRequest?.isEnabled) ? updateRequest.isEnabled : agent.isEnabled}
          disabled={!isOnboarded || !isEditing}
          onChange={onAgentEnabledOrDisabled}
          color="primary"
          name="checkedB"
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
      </MUITableCell>
      <MUITableCell style={{ width: '7%' }} align="left" component={'td'}>
        {getEditColumn()}
      </MUITableCell>
    </React.Fragment>
  );

  function getTimezoneColumn() {
    if (!agent.isOnboarded) {
      return '';
    }

    const moment = MomentTimezone.tz(agent.timeZone);
    if (!isEditing) {
      return `(UTC${moment.format('Z')}) ${agent.timeZone}`;
    }

    const timeZone = MomentTimezone.tz.zone(agent.timeZone);
    if (_.isNil(timeZone)) {
      return '';
    }

    return (
      <SearchWidget
        isCustom={false}
        searchValue={updateRequest?.timeZone ? updateRequest?.timeZone : timeZone.name}
        dataList={timezoneNames}
        onInputChange={onTimezoneChange}
        classes={{ root: 'w-100' }}
      />
    );
  }

  function getWorkingDaysColumn() {
    if (!agent.isOnboarded) {
      return '';
    }

    if (!isEditing) {
      return getWorkingDays(agent);
    }

    const selectedOptions: MultiSelectInputOption[] = agent.workingWeekDays.map((day: number) => {
      return { item: day, label: Moment.weekdays(day) };
    });

    return (
      <MultiSelectInput
        displayValue="label"
        placeholder=""
        onKeyPressFn={() => {}}
        onRemove={onWorkingWeekDaysChange}
        onSearch={() => {}}
        valuesSelected={selectedOptions}
        onChange={onWorkingWeekDaysChange}
        options={WEEK_DAY_OPTIONS}
      />
    );
  }

  function getSkillsColumn() {
    if (!agent.isOnboarded) {
      return '';
    }

    if (!isEditing) {
      return agent.skills;
    }

    return <TextArea onChange={onSkillsChange} placeholder="" defaultValue={agent.skills} rowsMin={1} className={'width-unset'} />;
  }

  function getWorkingHoursColumn() {
    if (!agent.isOnboarded) {
      return '';
    }

    const agentWorkingHours = getAgentWorkingHours();
    if (!isEditing) {
      return agentWorkingHours;
    }

    return <input defaultValue={agentWorkingHours} onChange={onWorkingHoursChange} />;
  }

  function getOutOfOfficeDaysColumn() {
    if (!agent.isOnboarded) {
      return '';
    }

    if (!isEditing) {
      return agent.outOfOfficePeriod;
    }

    return (
      <TextArea
        onChange={onOOOPeriodChange}
        placeholder="Mar 12 2024, Nov 09 2023 - May 05 2024"
        defaultValue={agent.outOfOfficePeriod}
        rowsMin={1}
        className={'width-unset'}
      />
    );
  }

  function getEditColumn() {
    if (!agent.isOnboarded) {
      return '';
    }

    if (isEditing) {
      return (
        <Stack direction={'horizontal'}>
          <Button onClick={cancelAgentEditing} color={'secondary'}>
            Cancel
          </Button>
          {isLoading ? (
            <Loader imgClassName={'small-img-loader'} />
          ) : (
            <Button className="cancel-button" onClick={updateAgent} disabled={_.isEmpty(updateRequest)}>
              Update
            </Button>
          )}
        </Stack>
      );
    }

    if (canModify) {
      return <IconButton size="sm" title={'Edit'} icon={EditIcon} onClick={editAgent} />;
    }

    return '';
  }

  function getTimeFromDuration(startOfTheDay: Moment.Moment, durationString: string): string {
    const duration = Moment.duration(`PT${durationString.toUpperCase()}`);
    const datetime = startOfTheDay.clone().add(duration);
    return datetime.format('hh:mm A');
  }

  function getAgentWorkingHours(): string {
    const now = Moment();
    const startOfTheDay = now.startOf('day');
    const start = getTimeFromDuration(startOfTheDay, agent.workingHours.start);
    const end = getTimeFromDuration(startOfTheDay, agent.workingHours.end);
    return `${start} - ${end}`;
  }

  function getWorkingDays(agent: AgentForRouting): string {
    if (_.isEmpty(agent.workingWeekDays)) {
      return '';
    }

    const weekDays = agent.workingWeekDays.map((intDay) => Moment.weekdaysShort(intDay));
    return weekDays.join(', ');
  }

  function editAgent() {
    setEditing(true);
    onEdit();
  }

  function resetState() {
    // Reset the state
    setEditing(false);
    setUpdateRequest(getUpdateRequestFromAgent());
  }

  function cancelAgentEditing() {
    resetState();
    onCancel();
  }

  async function updateAgent() {
    if (_.isNil(updateRequest)) {
      return;
    }

    if (!_.isNil(error)) {
      Utils.showNotify(error);
      return;
    }

    setLoading(true);
    try {
      await FrontendApiServer.putAgentForRouting(agent.id, updateRequest);
      _.assign(agent, updateRequest);

      resetState();

      // The below call may unmount the component. So ensure that any state changes are done before
      await onUpdateSuccessful();
    } catch (e) {
      Utils.showError(String(e));
    } finally {
      setLoading(false);
    }
  }

  function onTimezoneChange(timezoneName: string) {
    if (_.isEmpty(timezoneName)) {
      return;
    }

    setUpdateRequest(
      produce((draftUpdateRequest) => {
        draftUpdateRequest.timeZone = timezoneName;
      })
    );
  }

  function onSkillsChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    const value = _.trim(event.target.value);
    // Skills should be in the format skill-1,skill-2,skill-3
    // TODO: add validation logic

    setUpdateRequest(
      produce((draftUpdateRequest) => {
        draftUpdateRequest.skills = value;
      })
    );
  }

  function onWorkingWeekDaysChange(selectedOptions: Array<MultiSelectInputOption>) {
    setUpdateRequest(
      produce((draftUpdateRequest) => {
        draftUpdateRequest.workingWeekDays = _.sortBy(selectedOptions.map((option) => option.item));
      })
    );
  }

  function onWorkingHoursChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = _.trim(event.target.value);
    if (_.isEmpty(value)) {
      setError('Working hours cannot be empty');
      return;
    }

    if (!_.includes(value, '-')) {
      setError(WORKING_HOURS_ERROR_MESSAGE);
      return;
    }

    const splits = _.chain(value).split('-').map(_.trim).reject(_.isEmpty).value();
    if (_.size(splits) !== 2) {
      setError(WORKING_HOURS_ERROR_MESSAGE);
      return;
    }

    const [startTime, endTime] = splits;

    const startOfTheDay = Moment().startOf('day');
    const startMoment = getMomentFromTime(startOfTheDay, startTime);
    if (!startMoment.isValid()) {
      setError(WORKING_HOURS_ERROR_MESSAGE);
      return;
    }

    const endMoment = getMomentFromTime(startOfTheDay, endTime);
    if (!endMoment.isValid()) {
      setError(WORKING_HOURS_ERROR_MESSAGE);
      return;
    }

    if (!endMoment.isAfter(startMoment)) {
      setError('End time should be after start time');
      return;
    }

    const start = getDurationFromTime(startOfTheDay, startMoment);
    const end = getDurationFromTime(startOfTheDay, endMoment);

    setUpdateRequest(
      produce((draftUpdateRequest) => {
        draftUpdateRequest.workingHours = {
          start: start,
          end: end
        };
      })
    );
    setError(null);
    return;
  }

  function onOOOPeriodChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    const value = _.trim(event.target.value);

    const periods = _.split(value, ',');
    for (const periodStr of periods) {
      const period = _.trim(periodStr);
      if (_.isEmpty(period)) {
        setError('Out of office period should be in the format MMM DD YYYY or MMM DD YYYY - MMM DD YYYY');
        return;
      }

      if (!period.includes('-')) {
        const periodMoment = Moment(period, 'MMM DD YYYY', true);
        if (!periodMoment.isValid()) {
          setError('Out of office period should be in the format MMM DD YYYY or MMM DD YYYY - MMM DD YYYY');
          return;
        }
        continue;
      }

      const splits = _.chain(period).split('-').map(_.trim).reject(_.isEmpty).value();
      if (_.size(splits) !== 2) {
        setError('Out of office period should be in the format MMM DD YYYY or MMM DD YYYY - MMM DD YYYY');
        return;
      }

      const [start, end] = splits;
      const startMoment = Moment(start, 'MMM DD YYYY', true);
      if (!startMoment.isValid()) {
        setError('Out of office period should be in the format MMM DD YYYY or MMM DD YYYY - MMM DD YYYY');
        return;
      }

      const endMoment = Moment(end, 'MMM DD YYYY', true);
      if (!endMoment.isValid()) {
        setError('Out of office period should be in the format MMM DD YYYY or MMM DD YYYY - MMM DD YYYY');
        return;
      }
    }

    setUpdateRequest(
      produce((draftUpdateRequest) => {
        draftUpdateRequest.outOfOfficePeriod = value;
      })
    );
    setError(null);
    return;
  }

  function onAgentEnabledOrDisabled(event: any) {
    const isEnabled = event.target.checked;
    setUpdateRequest(
      produce((draftUpdateRequest) => {
        draftUpdateRequest.isEnabled = isEnabled;
      })
    );
  }

  function getMomentFromTime(startOfTheDay: Moment.Moment, time: string): Moment.Moment {
    const nowDate = startOfTheDay.format('YYYY-MM-DD');
    return Moment(`${nowDate} ${time}`, 'YYYY-MM-DD hh:mm A', true);
  }

  function getDurationFromTime(startOfTheDay: Moment.Moment, durationMoment: Moment.Moment): string {
    const duration = Moment.duration(durationMoment.diff(startOfTheDay, 'minutes'), 'minutes');
    return `${duration.hours()}h${duration.minutes()}m`;
  }

  function getUpdateRequestFromAgent(): AgentForRoutingUpdateRequest {
    return {
      isEnabled: agent.isEnabled,
      workingWeekDays: agent.workingWeekDays,
      workingHours: agent.workingHours,
      outOfOfficePeriod: agent.outOfOfficePeriod,
      timeZone: agent.timeZone,
      skills: agent.skills
    } as AgentForRoutingUpdateRequest;
  }
};
