import React, { Component } from 'react';
import './LoginFreshdesk.scss';
import IrisPortalService from '../../services/IrisPortalService';
import Utils from '../../utils/utils';
import { FormControl } from '@material-ui/core';
import { logEvent } from '../../utils/amplitude';
import { IrisAgentTextField } from '../../components/input/IrisAgentTextField';
import { PlatformConnectButtons, PlatformConnectFormControlText } from '../PlatformConnect';
import _ from 'lodash';
import { Stack } from '../../components/common';

interface Props {
  onClick: any;
}

interface DataError {
  subdomain?: string;
  accessToken?: string;
}

interface State {
  error: DataError;
  subdomainValue?: string;
  accessTokenValue?: string;
}

class LoginFreshdesk extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { error: {} };
  }

  loginFreshdesk = async () => {
    const subdomainValue = this.state.subdomainValue;
    const accessTokenValue = this.state.accessTokenValue;

    console.log(this.state);

    if (!subdomainValue) {
      const errors = { subdomain: 'Cannot be empty' };
      this.setState({ error: errors });
      return;
    }

    if (!accessTokenValue) {
      const errors = { accessToken: 'Cannot be empty' };
      this.setState({ error: errors });
      return;
    }

    const user = Utils.getUserItem();
    const adminEmail = Utils.getUserEmail();

    // At the moment that the user wants to login with google (First Time)
    logEvent('Connect with Freshdesk button', {
      email: adminEmail,
      isNewUser: user.isNewUser,
      subdomainValue: subdomainValue
    });

    const isNew = Utils.IsNew();
    Utils.saveObjectItem('freshdeskSubdomain', subdomainValue);

    const adminName = Utils.getUserName();

    await IrisPortalService.createFreshdeskToken({
      adminEmail: adminEmail,
      adminName: adminName,
      hd: user?.data?.hd || '',
      isNew: isNew,
      source: Utils.getOAuthSource(),
      token: accessTokenValue,
      subdomain: subdomainValue
    });
  };

  handleChangeSubdomain = (e: any) => {
    this.setState({ error: {} });
    this.setState({ subdomainValue: e.target.value });
  };

  handleChangeAccessToken = (e: any) => {
    this.setState({ error: {} });
    this.setState({ accessTokenValue: e.target.value });
  };

  render() {
    const { onClick } = this.props;
    return (
      <div className="mt-1">
        <Stack direction={'vertical'} gap={8}>
          <FormControl
            classes={{
              root: 'w-100'
            }}
          >
            <IrisAgentTextField
              label="Subdomain"
              color="secondary"
              variant="outlined"
              size={'small'}
              required
              onChange={this.handleChangeSubdomain}
              InputProps={{
                startAdornment: <strong>https://</strong>,
                endAdornment: <strong>.freshdesk.com</strong>
              }}
            />
            <PlatformConnectFormControlText error={!_.isEmpty(this.state.error)} message={this.state.error?.subdomain} />
          </FormControl>

          <FormControl
            classes={{
              root: 'w-100'
            }}
          >
            <IrisAgentTextField label="API Key" color="secondary" variant="outlined" size={'small'} required onChange={this.handleChangeAccessToken} />
            <PlatformConnectFormControlText error={!_.isEmpty(this.state.error)} message={this.state.error?.accessToken} />
          </FormControl>

          <PlatformConnectButtons onCancel={onClick} onConnect={this.loginFreshdesk} />
        </Stack>
      </div>
    );
  }
}

export default LoginFreshdesk;
