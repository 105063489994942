import {
  getAgentAnalyticsData,
  getChangeIrisportalviewUserData,
  getFirstTimeSetupIrisPortal,
  getIrisPortalInvitedUsers,
  getSingleAgentAnalytics,
  saveIrisPortalUser
} from './common';
import { handleError } from './utils';
import { getJIRAIssueDetails } from './jira';
import { getCaseCategoriesData } from './categories';
import {
  getAccountHealth,
  getAllAccountNames,
  getAtlassianConfig,
  getCustomer,
  getCustomerEngagement,
  getCustomerHealthDataExtras,
  getCustomerHealthDataV2,
  getRecentCasesForAccount,
  getGraphDataForAccount,
  updateAgentAssistSettings,
  updateAtlassianConfig,
  updatePrivateCommentSidebarSettings,
  UploadArticles
} from './customer';
import { getAiRecommendedCases, getIncidentSourcesData } from './incidents';
import {
  createFreshdeskToken,
  createJiraToken,
  createNewJiraToken,
  isJiraTokenAvailable,
  setupOAuth,
  signupIrisGpt,
  verifySalesforceCrmToken
} from './connect';
import { getUserInfo, onLoginGoogle, onLoginMicrosoft, onLoginSalesforce } from './auth';
import { getCategoryRevenueImpact, getCSATMetrics, getOverallRevenueImpact, getSentimentMetrics } from './home';
import {
  createTrigger,
  editTrigger,
  getTrigger,
  getTriggerActionCases,
  getTriggerImpactData,
  getTriggers,
  getTriggersOverallImpactData,
  getTriggersOverallImpactTrendData,
  getTriggersPrerequisites,
  removeTrigger,
  toggleSelfDeflection,
  updateTriggerStatus
} from './triggers';
import { sendIrisGPTMessage } from './irisGPT';
import { getDetectedIncidentSettings, getDetectedIncidentsSettingsPrerequisites } from './detectedIncidentsSettings';
import { createChatbotSetting, getChatbotSetting } from './chatbotSettings';
import { getArticleMetrics } from './automation';
import { getChatbotActivity } from './chatbotActivity';
import { getUserData } from './user';

export const IrisPortalService = {
  createChatbotSetting,
  createFreshdeskToken,
  createJiraToken,
  createNewJiraToken,
  createTrigger,
  editTrigger,
  getAccountHealth,
  getAgentAnalyticsData,
  getAiRecommendedCases,
  getAllAccountNames,
  getArticleMetrics,
  getAtlassianConfig,
  getCaseCategoriesData,
  getCategoryRevenueImpact,
  getChangeIrisportalviewUserData,
  getChatbotActivity,
  getChatbotSetting,
  getGraphDataForAccount,
  getCSATMetrics,
  getCustomer,
  getCustomerEngagement,
  getCustomerHealthDataExtras,
  getCustomerHealthDataV2,
  getDetectedIncidentSettings,
  getDetectedIncidentsSettingsPrerequisites,
  getFirstTimeSetupIrisPortal,
  getIncidentSourcesData,
  getIrisPortalInvitedUsers,
  getJIRAIssueDetails,
  getOverallRevenueImpact,
  getRecentCasesForAccount,
  getSentimentMetrics,
  getSingleAgentAnalytics,
  getTrigger,
  getTriggerActionCases,
  getTriggerImpactData,
  getTriggers,
  getTriggersOverallImpactData,
  getTriggersOverallImpactTrendData,
  getTriggersPrerequisites,
  getUserData,
  getUserInfo,
  handleError,
  isJiraTokenAvailable,
  onLoginGoogle,
  onLoginMicrosoft,
  onLoginSalesforce,
  removeTrigger,
  saveIrisPortalUser,
  sendIrisGPTMessage,
  setupOAuth,
  signupIrisGpt,
  toggleSelfDeflection,
  updateAgentAssistSettings,
  updateAtlassianConfig,
  updatePrivateCommentSidebarSettings,
  updateTriggerStatus,
  UploadArticles,
  verifySalesforceCrmToken
};
export default IrisPortalService;
