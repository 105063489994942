import React from 'react';

import { Tooltip as MuiTooltip } from '@material-ui/core';

import './styles.scss';

type ToolTipPlacement =
  | 'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'left-end'
  | 'left-start'
  | 'left'
  | 'right-end'
  | 'right-start'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top';

interface TooltipProps {
  title: string;
  children: React.ReactElement;
  placement?: ToolTipPlacement;
  containerStyles?: React.CSSProperties;
  darkMode?: boolean;
}

export const Tooltip: React.FC<TooltipProps> = ({ title = '', children, placement = 'top', containerStyles = {}, darkMode = false }) => (
  <MuiTooltip
    arrow
    style={containerStyles}
    classes={{
      tooltip: ['tooltip-span', darkMode && 'tooltip-span-dark'].filter(Boolean).join(' '),
      arrow: ['tooltip-arrow', darkMode && 'tooltip-arrow-dark'].filter(Boolean).join(' ')
    }}
    placement={placement}
    title={title}
  >
    {children}
  </MuiTooltip>
);
