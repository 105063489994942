import { Table, TableBody, TableCell, TableContainer, TablePagination, TableRow } from '@material-ui/core';
import React, { FC } from 'react';
import { formatDistance } from 'date-fns';
import { Stack, Text, useTableSorters } from '../../../components/common';
import TableHeadSorting from '../../../components/table-head-sorting/TableHeadSorting';
import Constants from '../../../utils/constants';
import { useHistory } from 'react-router';
import './Agent.scss';
import { Loader } from '../../../components/common/blocks/Loader';
import { TablePaper } from '../../../components/common/blocks/TablePaper';

export const AgentAnalyticsTable: FC<{
  agentsData: any;
  footerText?: string;
  isDashboard?: boolean;
}> = (props) => {
  const { agentsData, isDashboard } = props;
  agentsData?.map((agent: any) => {
    agent.cases = agent.numOpenCases + agent.numClosedCases;

    return agent;
  });
  agentsData?.sort((a: any, b: any) => b.cases - a.cases);
  const {
    onSort,
    filterData,
    sortColumn,
    sortOrder,
    onPageChange,
    currentPage,
    handleChangeRowsPerPage,
    labelDisplayedRows,
    pageSize,
    ROWS_PER_PAGE_OPTIONS
  } = useTableSorters({});

  agentsData?.map((data: any) => {
    data['csatAverageScore'] = data.csat.averageScore;
    data['csatSurveyCount'] = data.csat.surveyCount;
    return data;
  });
  const history = useHistory();

  const handleRowClick = (agentId: any) => {
    history.push(`${Constants.routes.AGENT_DETAILS}?${Constants.AGENT_ID_SEARCH_PARAM}=${agentId}`);
  };

  if (agentsData === undefined) {
    return (
      <div className="center-loader">
        <Loader />
      </div>
    );
  }

  return (
    <TablePaper style={{ marginTop: '20px' }}>
      <TableContainer style={{ maxHeight: 1000 }}>
        <Table size="small">
          <TableHeadSorting order={sortOrder} orderByColumn={sortColumn} onRequestSort={(_, column) => onSort(column)} headCells={headCells} />
          <TableBody>
            {filterData(agentsData).length === 0 && (
              <TableRow>
                <TableCell colSpan={100} align="center" style={{ border: 'none' }}>
                  <Stack align="center" direction="vertical" style={{ paddingTop: 50 }} gap={40}>
                    <Text variant="p3" weight="semi-bolder" color="dark">
                      No data
                    </Text>
                  </Stack>
                </TableCell>
              </TableRow>
            )}
            {filterData(agentsData)
              .slice(0, isDashboard ? 5 : agentsData.length)
              .map((agent: any, index: any) => (
                <TableRow key={index} className="agent-row" onClick={() => handleRowClick(agent.id)}>
                  <TableCell variant="body" style={{ borderBottom: 'none' }}>
                    <Text variant="p1" weight="semi-bolder" style={{ color: '#5E81F4' }}>
                      {agent.name === null ? '' : agent.name}
                    </Text>
                  </TableCell>
                  <TableCell variant="body" style={{ borderBottom: 'none' }}>
                    <Text variant="p1" weight="semi-bolder" color="dark">
                      {agent.cases}
                    </Text>
                  </TableCell>
                  <TableCell variant="body" style={{ borderBottom: 'none' }}>
                    <Text variant="p1" weight="semi-bolder" color="dark">
                      {agent.mttrInSecs ? `${formatDistance(0, agent.mttrInSecs * 1000)}` : '-'}
                    </Text>
                  </TableCell>
                  {/* <TableCell variant="body" style={{ borderBottom: 'none' }}>
                    <Text variant="p1" weight="semi-bolder" color="dark">
                      {agent.numOpenCases}
                    </Text>
                  </TableCell>
                  <TableCell variant="body" style={{ borderBottom: 'none' }}>
                    <Text variant="p1" weight="semi-bolder" color="dark">
                      {agent.numClosedCases}
                    </Text>
                  </TableCell> */}

                  <TableCell variant="body" style={{ borderBottom: 'none' }}>
                    <Stack align="center" justify="space-between">
                      {agent.csat.averageScore ? (
                        <Stack direction="horizontal" align="center" gap={5}>
                          <Text variant="p1" weight="semi-bolder" color="dark">
                            {agent.csatAverageScore}%
                          </Text>
                          <Text variant="p0" weight="regular" color="green">
                            {agent.csatSurveyCount} Surveys
                          </Text>
                        </Stack>
                      ) : (
                        '-'
                      )}
                      <img
                        onClick={() => handleRowClick(agent.id)}
                        alt=""
                        src="/images/goRedirect.png"
                        width="auto"
                        height="40px"
                        className="incidents-summary-go"
                      />
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {agentsData?.length && !isDashboard ? (
          <TablePagination
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            component="div"
            count={agentsData.length}
            rowsPerPage={pageSize}
            page={currentPage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelDisplayedRows={labelDisplayedRows}
            onChangePage={(_, page) => onPageChange(page)}
          />
        ) : null}
      </TableContainer>
    </TablePaper>
  );
};

const headCells = [
  {
    id: 'name',
    label: 'AGENT',
    width: '20%',
    hasFilter: true
  },
  {
    id: 'cases',
    label: 'CASES',
    width: '13%',
    hasFilter: true
  },

  {
    id: 'mttrInSecs',
    label: 'MTTR',
    width: '17%',
    hasFilter: true
  },
  {
    id: 'csatAverageScore',
    label: 'CSAT',
    width: '20%',
    hasFilter: true
  }
];
