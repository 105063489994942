import React, { useCallback, useState } from 'react';

import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';

import { IconButton } from '../IconButton';
import { PortalSidebarItem } from './PortalSidebarItem';
import { PortalUserMenu } from './PortalUserMenu';

import { ReactComponent as DashboardSvg } from '../../assets/Dashboard.svg';
import { ReactComponent as CustomerHealthSvg } from '../../assets/CustomerHealth.svg';
import { ReactComponent as IrisGPT } from '../../assets/IrisGPT.svg';
import { ReactComponent as RuleSvg } from '../../assets/Rule.svg';
import { ReactComponent as ManageUsersSvg } from '../../assets/ManageUsers.svg';
import { ReactComponent as ManageIntegrations } from '../../assets/ManageIntegrations.svg';

import Constants, { Source } from '../../utils/constants';

import './styles.scss';
import Utils from '../../utils/utils';
import _ from 'lodash';

const Line = () => {
  return (
    <div style={{ margin: '20px 0', paddingLeft: '20px' }}>
      <hr style={{ width: '60px', backgroundColor: '#8181A5' }} />
    </div>
  );
};

export const PortalSidebar: React.FC = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isHover, setIsHover] = useState(false);

  const handleToggleCollapseMode = useCallback(() => {
    setIsCollapsed((oldState) => !oldState);
  }, []);

  const toggleSidebar = function () {
    console.log(isCollapsed, isHover);
    if (isCollapsed === true || (isCollapsed === false && isHover === true)) {
      setIsCollapsed((oldState) => !oldState);
      setIsHover((oldState) => !oldState);
    }
  };

  const [showIrisGPT, setShowIrisGPT] = React.useState<boolean>(false);

  const callAPI = Utils.isLoggedIn() || Utils.isDemo();

  const isAdmin = Utils.amIAdmin();

  const { data: customer, status, isLoading } = Utils.useCustomerQuery(callAPI);

  React.useEffect(() => {
    if (_.isEqual(status, 'success')) {
      setShowIrisGPT(!!customer?.irisGptEnabled);
    }
  }, [status, customer]);

  return (
    <div className={['app-portal-sidebar', isCollapsed ? 'portal-sidebar-collapsed' : ''].filter(Boolean).join(' ')}>
      <IconButton onClick={handleToggleCollapseMode} icon={isCollapsed ? KeyboardArrowRightIcon : KeyboardArrowLeftIcon} />

      <img src={isCollapsed ? '/images/irisagent_logo.svg' : '/images/irisagent.svg'} alt="iris agent logo" />

      <nav className="app-portal-sidebar-content">
        <div onMouseEnter={() => toggleSidebar()} onMouseLeave={() => toggleSidebar()}>
          <PortalSidebarItem
            title="IrisGPT"
            to={Constants.routes.IRIS_GPT}
            icon={IrisGPT}
            isSidebarCollapsed={isCollapsed}
            paths={[Constants.routes.IRIS_GPT, Constants.routes.MANAGE_CHATBOT, Constants.routes.CHATBOT_ACTIVITY]}
            haveSubItems
          >
            {showIrisGPT && (
              <React.Fragment>
                <PortalSidebarItem title={Constants.menuItem.IRIS_GPT} to={Constants.routes.IRIS_GPT} isSidebarCollapsed={isCollapsed} />
                <PortalSidebarItem title={Constants.menuItem.MANAGE_CHATBOT} to={Constants.routes.MANAGE_CHATBOT} isSidebarCollapsed={isCollapsed} />
              </React.Fragment>
            )}
            <PortalSidebarItem title={Constants.menuItem.CHATBOT_ACTIVITY} to={Constants.routes.CHATBOT_ACTIVITY} isSidebarCollapsed={isCollapsed} />
          </PortalSidebarItem>

          {!isLoading && !_.isEqual(customer?.sourceName, Source.IRIS_GPT) && (
            <React.Fragment>
              <PortalSidebarItem title="Needs Attention" to={Constants.routes.NEEDS_ATTENTION} icon={DashboardSvg} isSidebarCollapsed={isCollapsed} />

              <PortalSidebarItem
                title={Constants.menuItem.INSIGHTS}
                to={Constants.routes.CATEGORIES_OVERVIEW}
                icon={CustomerHealthSvg}
                isSidebarCollapsed={isCollapsed}
                paths={[
                  Constants.routes.HEALTH_OVERVIEW,
                  Constants.routes.ESCALATIONS,
                  Constants.routes.CATEGORIES_OVERVIEW,
                  Constants.routes.HEALTH_ACCOUNT,
                  Constants.routes.DETAILED_VIEW,
                  Constants.routes.AGENT_OVERVIEW,
                  Constants.routes.AGENT_DETAILS
                ]}
                haveSubItems
              >
                <PortalSidebarItem
                  title={Constants.menuItem.CATEGORIES}
                  to={Constants.routes.CATEGORIES_OVERVIEW}
                  isSidebarCollapsed={isCollapsed}
                  paths={[Constants.routes.DETAILED_VIEW]}
                />
                <PortalSidebarItem
                  title={Constants.menuItem.HEALTH_OVERVIEW}
                  to={Constants.routes.HEALTH_OVERVIEW}
                  isSidebarCollapsed={isCollapsed}
                  paths={[Constants.routes.HEALTH_ACCOUNT]}
                />
                <PortalSidebarItem title={Constants.menuItem.ESCALATIONS} to={Constants.routes.ESCALATIONS} isSidebarCollapsed={isCollapsed} />
                <PortalSidebarItem
                  title={Constants.menuItem.AGENT_ANALYTICS}
                  to={Constants.routes.AGENT_OVERVIEW}
                  isSidebarCollapsed={isCollapsed}
                  paths={[Constants.routes.AGENT_OVERVIEW, Constants.routes.AGENT_DETAILS]}
                />
              </PortalSidebarItem>
            </React.Fragment>
          )}

          <PortalSidebarItem
            title={Constants.menuItem.AUTOMATION}
            to={Constants.routes.TRIGGERS}
            icon={RuleSvg}
            isSidebarCollapsed={isCollapsed}
            paths={[
              Constants.routes.TRIGGERS,
              Constants.routes.TRIGGERS_DETAILS,
              Constants.routes.AUTOMATION_IMPACT,
              Constants.routes.AUTOMATION_ROUTING,
              Constants.routes.AUTOMATION_CATEGORIES
            ]}
            haveSubItems
          >
            <PortalSidebarItem
              title={Constants.menuItem.TRIGGERS}
              to={Constants.routes.TRIGGERS}
              isSidebarCollapsed={isCollapsed}
              paths={[Constants.routes.TRIGGERS_DETAILS]}
            />

            <PortalSidebarItem title={Constants.menuItem.AUTOMATION_IMPACT} to={Constants.routes.AUTOMATION_IMPACT} isSidebarCollapsed={isCollapsed} />

            {!isLoading && !_.isEqual(customer?.sourceName, Source.IRIS_GPT) && (
              <PortalSidebarItem title={Constants.menuItem.CATEGORIES} to={Constants.routes.AUTOMATION_CATEGORIES} isSidebarCollapsed={isCollapsed} />
            )}
            {!isLoading && _.isEqual(customer?.sourceName, Source.ZENDESK) && (
              <PortalSidebarItem title={Constants.menuItem.ROUTING} to={Constants.routes.AUTOMATION_ROUTING} isSidebarCollapsed={isCollapsed} />
            )}
          </PortalSidebarItem>

          <Line />
        </div>
        <div
          style={{
            justifyContent: 'flex-end'
          }}
          onMouseEnter={() => toggleSidebar()}
          onMouseLeave={() => toggleSidebar()}
        >
          <PortalSidebarItem title="Manage Users" to={Constants.routes.MANAGE_USERS} icon={ManageUsersSvg} isSidebarCollapsed={isCollapsed} />
          {isAdmin && (
            <PortalSidebarItem
              title="Change Integration User"
              to={Constants.routes.MANAGE_INTEGRATION_USER}
              icon={ManageIntegrations}
              isSidebarCollapsed={isCollapsed}
            />
          )}
          <PortalSidebarItem title="Manage Integrations" to={Constants.routes.MANAGE_ACCOUNTS} icon={ManageIntegrations} isSidebarCollapsed={isCollapsed} />
          <Line />
          <PortalUserMenu isSidebarCollapsed={isCollapsed} />
        </div>
      </nav>
    </div>
  );
};
