import { TextField } from '@material-ui/core';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';
import { AutocompleteProps } from '@material-ui/lab/Autocomplete/Autocomplete';

interface HighlightedOption {
  toString(): string;
}

interface DropdownSearchProps<
  T extends HighlightedOption,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends Omit<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'renderInput'> {
  placeholder: string;
}

export function DropdownSearch<
  T extends HighlightedOption,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>(props: DropdownSearchProps<T, Multiple, DisableClearable, FreeSolo>) {
  const { placeholder, ...rest } = props;
  return (
    <Autocomplete
      {...rest}
      size="small"
      style={{
        width: 300
      }}
      autoHighlight={true}
      renderInput={(params) => {
        return <TextField {...params} placeholder={placeholder} variant="outlined" />;
      }}
      renderOption={(option: T, { inputValue }) => {
        const matches = match(option.toString(), inputValue);
        const parts = parse(option.toString(), matches);

        return (
          <div>
            {parts.map((part, index) => (
              <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                {part.text}
              </span>
            ))}
          </div>
        );
      }}
    />
  );
}
