import React, { FC, useMemo } from 'react';
import { Stack, Text } from '../../../components/common';
import { DetailedAccountMetrics } from './CaseMetrics';
import { CategoryDistributionChart } from './CategoryDistributionChart';
import { CaseTimeDistribution } from './CaseTimeDistribution';
import { SentimentDistribution } from './SentimentDistribution';
import { AccountHealth, HealthScore } from '../types';
import { makeStyles } from '@material-ui/core';
import _ from 'lodash';
import Utils from '../../../utils/utils';
import { TablePaper } from '../../../components/common/blocks/TablePaper';
import classNames from 'classnames';
import { GraphData } from '../../../services/IrisPortalService/customer';

export const RevenueDetail: FC<{
  accountHealth: AccountHealth;
}> = (props) => {
  const { accountHealth } = props;

  const useStyles = makeStyles((theme) => ({
    box: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 'minmax(256px, 1fr)',
      height: '52px',
      borderRadius: '10px',
      backgroundColor: '#FFFFFF'
    }
  }));

  const classes = useStyles();

  return (
    <div className={classes.box}>
      <Stack align="center" style={{ padding: 10 }}>
        {accountHealth.accountDetails !== null && !_.isNil(accountHealth.accountDetails.annualContractValue) ? (
          <Text
            variant="p2"
            weight="regular"
            style={{
              padding: 15,
              color: '#8181A5'
            }}
          >
            Annual Revenue{' '}
            <Text variant="p3" weight="bold" color="dark">
              {Utils.nFormatter(accountHealth.accountDetails.annualContractValue, 1, accountHealth.accountDetails.currency)}
            </Text>
          </Text>
        ) : (
          ''
        )}
      </Stack>
    </div>
  );
};

export const ImportantStats: FC<{
  accountHealth: AccountHealth;
  graphData: GraphData | undefined;
  accountName: string;
}> = (props) => {
  const { accountHealth, graphData } = props;

  const totalCases = graphData?.stats.count || 0;
  let emptyHealthData = {} as HealthScore;
  const healthData = accountHealth.accountDetails && accountHealth.accountDetails.health ? accountHealth.accountDetails.health : emptyHealthData;

  return (
    <Stack direction="vertical" gap={20}>
      <DetailedAccountMetrics
        granularDetails={healthData}
        closedCases={accountHealth.closedCases}
        mttr={accountHealth.mttr}
        totalCases={totalCases}
        satisfactionRatingScore={accountHealth.satisfactionRatingsData.score}
        satisfactionRatingSurveyCount={accountHealth.satisfactionRatingsData.surveyCount}
        averageSentiment={accountHealth.averageSentimentScore}
        healthScore={accountHealth.accountDetails?.health?.score}
        healthBucket={accountHealth.accountDetails?.healthBucket}
        annualContractValue={accountHealth.accountDetails?.annualContractValue}
        currency={accountHealth.accountDetails?.currency}
        style={{ flexBasis: '100%' }}
      />
    </Stack>
  );
};

export const StatsGraphsCharts: FC<{
  graphData: GraphData | undefined;
  accountName: string;
}> = (props) => {
  const { graphData } = props;
  const sentiments = graphData?.sentiments || [];
  const cases = graphData?.cases || [];
  const category = graphData?.category || ({} as Record<string, number>);
  const totalCases = graphData?.stats.count || 0;

  const categoryDistribution = useMemo(() => {
    if (!category) {
      return [];
    }

    return Object.keys(category)
      .map((name) => ({
        name: name,
        value: Math.round((category[name] / totalCases) * 100)
      }))
      .filter((data) => data.value !== 0);
  }, [category]);

  const rhsClassName = classNames({ 'w-50': categoryDistribution.length, 'w-100': !categoryDistribution.length });
  const rhsItemClassName = classNames({ 'w-50': !categoryDistribution.length, 'w-100': categoryDistribution.length });

  return (
    <Stack direction="vertical" gap={15}>
      <Stack direction="vertical" gap={20}>
        <Stack direction="vertical" gap={40}>
          <Stack gap={30}>
            {categoryDistribution.length ? (
              <TablePaper style={{ width: '50%' }}>
                <CategoryDistributionChart data={categoryDistribution} totalCases={totalCases} />
              </TablePaper>
            ) : null}
            {cases.length ? (
              <Stack direction={categoryDistribution.length ? 'vertical' : 'horizontal'} gap={20} className={rhsClassName}>
                <SentimentDistribution data={sentiments} classes={{ root: rhsItemClassName }} />
                <CaseTimeDistribution cases={cases} classes={{ root: rhsItemClassName }} />
              </Stack>
            ) : null}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
