import Utils from '../../utils/utils';
import MockData from '../../utils/mockData';
import { AccountHealth, CustomerHealthDataExtras, CustomerHealthDataV2, RecentCasesForAccount } from '../../routes/customerHealth/types';
import { doMultipartRequest, doRequest, handleError, isMocKData } from './utils';
import { AtlassianConfigResponse, AtlassianConfigUpdateRequest, Customer } from '../types';

export type GetAllAccountNamesResponse = {
  allAccountNames: string[];
};

export const getAllAccountNames = async (): Promise<GetAllAccountNamesResponse> => {
  return await doRequest(
    `/v1/customer/health/accounts`,
    (): GetAllAccountNamesResponse => {
      return { allAccountNames: ['Iris', 'IrisAgent'] };
    }
  );
};

export const getRecentCasesForAccount = async (accountName: string): Promise<RecentCasesForAccount> => {
  const url = `/v1/customer/health/accounts/cases?name=${accountName}`;
  return await doRequest(url, MockData.getDetailsAccountDashboardMockData);
};

export interface SentimentEntry {
  id: string;
  avgSentiment: number;
  count: number;
}

export interface CaseEntry {
  id: string;
  count: number;
}

export interface GraphData {
  sentiments: SentimentEntry[];
  cases: CaseEntry[];
  category: Record<string, number>;
  stats: {
    count: number;
  };
}

export const getGraphDataForAccount = async (accountName: string): Promise<GraphData> => {
  const url = `/v1/customer/health/accounts/graph?name=${accountName}`;
  return await doRequest(url, MockData.getGraphData);
};

export const getAccountHealth = async (accountName: string) => {
  const user = Utils.getObjectItem('user');
  const tokenExpress = Utils.getObjectItem('token_express');

  if (isMocKData) {
    return MockData.getAccountHealth() as AccountHealth;
  }

  const url =
    `${process.env.REACT_APP_BACKEND_URL}/account/health/?` +
    new URLSearchParams({
      required: 'accountDetails',
      accountName: accountName.toString(),
      customerId: user.customerId
    });
  const response = await fetch(url, {
    headers: new Headers({
      Authorization: `Bearer ${tokenExpress}`
    })
  });
  handleError(response.status);
  if (response.status === 403) {
    return;
  }
  if (!response.ok) {
    throw new Error('Unhandled status code');
  }

  const body: AccountHealth = await response.json();
  return body;
};

export const getCustomerHealthDataV2 = async (): Promise<CustomerHealthDataV2> => {
  return await doRequest(`/v1/customer/health`, MockData.getCustomerHealthsMockData);
};

export const getCustomerHealthDataExtras = async (): Promise<CustomerHealthDataExtras> => {
  return await doRequest(`/v1/customer/healthExtras`, MockData.getCustomerHealthsMockData);
};

export const getCustomer = async (): Promise<Customer> => {
  return await doRequest(`/v1/customer`, MockData.getCustomerMockData);
};

export const getCustomerEngagement = async () => {
  return MockData.getCustomerEngagementMockData();
};

export const updatePrivateCommentSidebarSettings = async (data: any): Promise<void> => {
  let user = Utils.getObjectItem('user');
  let token_express = Utils.getObjectItem('token_express');

  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/privateCommentSidebar?customerId=${user.customerId}`, {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: new Headers({
      Authorization: `Bearer ${token_express}`,
      'Content-Type': 'application/json'
    })
  } as RequestInit);
  handleError(response.status);

  if (!response.ok) {
    throw new Error(`Unhandled error status code!: ${response.status}`);
  }
};

export const updateAgentAssistSettings = async (sections: string[]): Promise<void> => {
  let user = Utils.getObjectItem('user');
  let token_express = Utils.getObjectItem('token_express');

  const body = {
    sections
  };

  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/agentAssist?customerId=${user.customerId}`, {
    method: 'PUT',
    body: JSON.stringify(body),
    headers: new Headers({
      Authorization: `Bearer ${token_express}`,
      'Content-Type': 'application/json'
    })
  } as RequestInit);
  handleError(response.status);

  if (!response.ok) {
    throw new Error(`Unhandled error status code!: ${response.status}`);
  }
};

export const getAtlassianConfig = async (): Promise<AtlassianConfigResponse> => {
  if (isMocKData) {
    return MockData.getAtlassianConfigMockData();
  }

  let user = Utils.getObjectItem('user');
  let token_express = Utils.getObjectItem('token_express');

  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/atlassian/config?customerId=${user.customerId}`, {
    method: 'GET',
    headers: new Headers({
      Authorization: `Bearer ${token_express}`,
      'Content-Type': 'application/json'
    })
  } as RequestInit);
  handleError(response.status);

  if (!response.ok) {
    throw new Error(`Unhandled error status code!: ${response.status}`);
  }

  return await response.json();
};

export const updateAtlassianConfig = async (request: AtlassianConfigUpdateRequest): Promise<void> => {
  let user = Utils.getObjectItem('user');
  let token_express = Utils.getObjectItem('token_express');

  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/atlassian/config?customerId=${user.customerId}`, {
    method: 'PATCH',
    body: JSON.stringify(request),
    headers: new Headers({
      Authorization: `Bearer ${token_express}`,
      'Content-Type': 'application/json'
    })
  } as RequestInit);
  handleError(response.status);

  if (!response.ok) {
    throw new Error(`Unhandled error status code!: ${response.status}`);
  }
};

export const UploadArticles = async (file: File): Promise<string> => {
  if (!file || !file.name.toLowerCase().endsWith('.csv')) {
    throw new Error('Please provide a valid CSV file');
  }

  const formData = new FormData();
  formData.append('file', file);

  const resp = await doMultipartRequest('/v1/articles/upload', formData);
  if (!resp) {
    throw new Error('Error while uploading articles');
  }
  handleError(resp.status);
  if (resp.status === 204) {
    return 'Articles uploaded successfully';
  }
  return 'Errors while uploading articles';
};
