import React from 'react';
import { useQuery } from 'react-query';
import { CACHE_KEYS, CACHE_TIME } from '../../../../utils/cacheUtils';
import FrontendApiServer from '../../../../services/IrisPortalService/frontendApiServer';
import Utils from '../../../../utils/utils';
import _ from 'lodash';
import { Loader } from '../../../../components/common/blocks/Loader';
import { ITableStateFilter, TableHeader, TableWithTabs } from '../../../../components/table-with-tabs/TableWithTabs';
import { CustomerProvidedTag } from '../../../../services/types';
import { TableCell as MUITableCell } from '@material-ui/core';
import './CustomerProvidedTagTable.scss';

const TABS = ['All'];
const TABLE_HEADERS: TableHeader[] = [
  {
    name: 'CATEGORY',
    width: '15%'
  },
  {
    name: 'DESCRIPTION',
    width: '25%'
  },
  {
    name: 'EXAMPLES',
    width: '45%'
  },
  {
    name: 'STATUS',
    width: '15%'
  }
];

export interface CustomerProvidedTagTableRef {
  refetch: () => void;
}

// Wrap the component in a forwardRef call
export const CustomerProvidedTagTable = React.forwardRef<CustomerProvidedTagTableRef, any>((props, ref) => {
  const { isLoading, data, refetch } = useQuery([CACHE_KEYS.CUSTOMER_PROVIDED_TAGS], () => FrontendApiServer.getCustomerProvidedTags(), {
    refetchOnWindowFocus: false,
    onError: (e) => {
      Utils.showError(String(e));
    },
    ...CACHE_TIME.get(CACHE_KEYS.CUSTOMER_PROVIDED_TAGS)
  });

  // Pass the ref to the useImperativeHandle hook
  // This is required for the parent component to be able to call the refetch method (parent calling child. e.g.
  // ref.current?.refetch())
  React.useImperativeHandle(ref, () => ({
    refetch
  }));

  if (isLoading || _.isNil(data)) {
    return <Loader />;
  }

  return (
    <React.Fragment>
      <div className={'customer-provided-tag-table'}>
        <TableWithTabs
          data={data}
          getSearchableValue={getCustomerProvidedTagSearchableValue}
          getTableStatePredicate={getTabFilter}
          header={'Categories'}
          tableHeaders={TABLE_HEADERS}
          tabs={TABS}
          renderDatum={getCustomerProvidedTagRow}
        />
      </div>
    </React.Fragment>
  );

  function getCustomerProvidedTagSearchableValue(customerProvidedTag: CustomerProvidedTag): string {
    return customerProvidedTag.tag;
  }

  function getTabFilter(state: ITableStateFilter<CustomerProvidedTag>, action: string): ITableStateFilter<CustomerProvidedTag> {
    switch (action) {
      default:
      case 'All':
        return (customerProvidedTag) => true;
    }
  }

  function getCustomerProvidedTagRow(customerProvidedTag: CustomerProvidedTag) {
    return (
      <React.Fragment>
        <MUITableCell align="left" className={'routing-agent-name'} component={'td'} classes={{ root: 'word-break' }}>
          {customerProvidedTag.tag}
        </MUITableCell>
        <MUITableCell align="left" component={'td'} classes={{ root: 'word-break' }}>
          {customerProvidedTag.description}
        </MUITableCell>
        <MUITableCell align="left" component={'td'} classes={{ root: 'word-break' }}>
          {customerProvidedTag.examples.join(', ')}
        </MUITableCell>
        <MUITableCell align="left" component={'td'} classes={{ root: 'word-break' }}>
          {getVisibleStatus(customerProvidedTag)}
        </MUITableCell>
      </React.Fragment>
    );
  }

  function getVisibleStatus(customerProvidedTag: CustomerProvidedTag): string {
    switch (customerProvidedTag.status) {
      case 'under_review':
        return 'Under Review';
      default:
        return customerProvidedTag.status;
    }
  }
});
