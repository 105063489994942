import React from 'react';
import './CustomerProvidedTagForm.scss';
import { useFieldArray, useForm } from 'react-hook-form';
import { Stack, Text } from '../../../../components/common';
import { FormControl } from '@material-ui/core';
import { IrisAgentTextField } from '../../../../components/input/IrisAgentTextField';
import _ from 'lodash';
import { PlatformConnectFormControlText } from '../../../PlatformConnect';
import { IconButton } from '../../../../components/IconButton';
import { IrisAgentButton } from '../../../../components/button/IrisAgentButton';
import { Loader } from '../../../../components/common/blocks/Loader';
import { CreateCustomerProvidedTagRequest } from '../../../../services/types';
import FrontendApiServer from '../../../../services/IrisPortalService/frontendApiServer';
import Utils from '../../../../utils/utils';
import { ReactComponent as Plus } from '../../../../assets/plus.svg';
import { ReactComponent as DeleteOutlineIcon } from '../../../../assets/delete.svg';

export interface CustomerProvidedTagFormProps {
  onCreate: () => void;
}

// Notes:
//
// type="button" is required for any non-submit buttons in the component, otherwise type="submit" is assumed and messes
// up the state of the form
//
// ---
//
// Always supply defaultValue to the html elements that are being rendered by useFieldArray. This is because the
// useFieldArray hook is not able to maintain the state of the fields correctly if the fields are not initialized with
// default values

export const CustomerProvidedTagForm: React.FC<CustomerProvidedTagFormProps> = (props) => {
  const { onCreate } = props;

  const [isLoading, setLoading] = React.useState(false);
  // initialise the hook
  // Add an empty example as default, so that the user can start adding examples
  const defaultValues = {
    tag: '',
    description: '',
    examples: [{ example: '' }]
  };
  const { register, handleSubmit, errors, control, reset } = useForm({
    defaultValues
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'examples'
  });

  const addExample = React.useCallback(() => {
    // append an empty example for the user. It's essential to have a default values for the input field that
    // is being appended. otherwise useFieldArray may behave unexpectedly
    append({ example: '' });
  }, [append]);

  return (
    <React.Fragment>
      <div className={'customer-provided-tag-form w-100'}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack direction={'vertical'} gap={8}>
            <Text variant="p4" weight="semi-bolder" color="dark" style={{ marginBottom: '8px' }}>
              Add ML Category
            </Text>
            <FormControl
              classes={{
                root: 'w-50'
              }}
            >
              <IrisAgentTextField
                label="Category"
                color="secondary"
                variant="outlined"
                size={'small'}
                InputProps={{
                  name: 'tag'
                }}
                inputRef={register({
                  required: 'Category is required',
                  validate: (value) => !_.isEmpty(value?.trim()) || 'Valid category is required'
                })}
              />
              <PlatformConnectFormControlText error={_.has(errors, 'tag')} message={errors.tag?.message} />
            </FormControl>

            <FormControl
              classes={{
                root: 'w-50'
              }}
            >
              <IrisAgentTextField
                label="Description"
                color="secondary"
                variant="outlined"
                size={'small'}
                InputProps={{
                  name: 'description'
                }}
                inputRef={register({
                  required: 'Description is required',
                  validate: (value) => !_.isEmpty(value?.trim()) || 'Valid description is required'
                })}
                rows={3}
                rowsMax={5}
                multiline={true}
              />
              <PlatformConnectFormControlText error={_.has(errors, 'description')} message={errors.description?.message} />
            </FormControl>

            {fields.map((field, index) => {
              // use the `field.id` as a key instead of index such that the state of the fields is
              // maintained correctly when the user adds or removes an example
              return (
                <Stack direction={'horizontal'} gap={8} key={field.id}>
                  <FormControl
                    classes={{
                      root: 'w-50'
                    }}
                  >
                    <IrisAgentTextField
                      label="Example query text or ticket id"
                      color="secondary"
                      variant="outlined"
                      size={'small'}
                      InputProps={{
                        name: `examples[${index}].example`
                      }}
                      inputRef={register({
                        required: 'Example text is required',
                        validate: (value) => !_.isEmpty(value?.trim()) || 'Valid example text is required'
                      })}
                      defaultValue={field.example}
                    />
                    <PlatformConnectFormControlText
                      error={_.has(errors, `examples[${index}].example.message`)}
                      message={errors.examples?.[index]?.example?.message}
                    />
                  </FormControl>

                  {_.isEqual(index, 0) ? (
                    <IconButton className="icon-button" icon={Plus} size="sm" disabled={false} onClick={addExample} type="button" />
                  ) : (
                    <IconButton className="" icon={DeleteOutlineIcon} size="sm" disabled={false} onClick={(event) => remove(index)} type="button" />
                  )}
                </Stack>
              );
            })}

            <IrisAgentButton size={'medium'} type={'submit'} style={{ width: '83px' }} className={'ml-auto'}>
              {isLoading ? <Loader imgClassName={'small-img-loader'} /> : 'Create'}
            </IrisAgentButton>
          </Stack>
        </form>
      </div>
    </React.Fragment>
  );

  async function onSubmit(formData: any) {
    const examples: Array<Record<string, string>> = formData.examples;
    const createCustomerProvidedTagRequest: CreateCustomerProvidedTagRequest = {
      tag: _.trim(formData.tag),
      description: _.trim(formData.description),
      examples: _.chain(examples).map('example').map(_.trim).value()
    };

    try {
      setLoading(true);
      await FrontendApiServer.createCustomerProvidedTag(createCustomerProvidedTagRequest);
      reset();
      onCreate();
    } catch (e) {
      Utils.showUnknownError(e);
    } finally {
      setLoading(false);
    }
  }
};
