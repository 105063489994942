import { Source } from '../../utils/constants';
import _ from 'lodash';
import { Action, Option } from './types';

import { AccountNameRowManager } from './components/RowManagers/AccountNameRowManager';
import { ApplyMacroRowManager } from './components/RowManagers/ApplyMacroRowManager';
import { AssignAgentRowManager } from './components/RowManagers/AssignAgentRowManager';
import { AssignTeamRowManager } from './components/RowManagers/AssignTeamRowManager';
import { AutoRespondRowManager } from './components/RowManagers/AutoRespondRowManager';
import { CategoriesRowManager } from './components/RowManagers/CategoriesRowManager';
import { ChangeCaseStatusRowManager } from './components/RowManagers/ChangeCaseStatusRowManager';
import { CustomFieldRowManager } from './components/RowManagers/CustomFieldRowManager';
import { CustomFlagRowManager } from './components/RowManagers/CustomFlagRowManager';
import { PriorityRowManager } from './components/RowManagers/PrioritiesRowManager';
import { SubjectRowManager } from './components/RowManagers/SubjectRowManager';
import { TagsRowManager } from './components/RowManagers/TagsRowManager';
import { TicketContentRowManager } from './components/RowManagers/TicketContentRowManager';
import { WriteCaseCustomFieldRowManager } from './components/RowManagers/WriteCaseCustomFieldRowManager';
import { WriteCaseTagsCustomRowManager } from './components/RowManagers/WriteCaseTagsCustomRowManager';
import { WriteCaseTagsRowManager } from './components/RowManagers/WriteCaseTagsRowManager';

export const operators: string[] = ['and', 'or'];

export const CategoryAccountName = 'account_name';
export const CategoryCategories = 'categories';
export const CategoryCustomField = 'custom_field';
export const CategoryStatus = 'status';
export const CategorySubject = 'subject';
export const CategoryTags = 'tags';
export const CategoryTicketContent = 'ticket_content';
export const CategoryTicketPriority = 'ticket_priority';

export const DropboxHasActiveSubscription = 'has_active_subscription';
export const DropboxHasMultipleDevicesConnected = 'has_multiple_devices_connected';
export const DropboxHasPremiumSupport = 'has_premium_support';
export const DropboxIsEligibleForRefund = 'is_eligible_for_refund';

export const ActionApplyMacro = 'apply_macro';
export const ActionChangeCaseAssignee = 'change_case_assignee';
export const ActionChangeCaseAssigneeTeam = 'change_case_assignee_team';
export const ActionChangeCaseStatus = 'change_case_status';
export const ActionReplyToCase = 'reply_to_case';
export const ActionWriteCaseCustomField = 'write_case_custom_field';
export const ActionWriteCaseTags = 'write_case_tags';
export const ActionWriteCaseTagsCustom = 'write_case_tags_custom';
export const ActionWritePrivateComments = 'write_private_comments';

export const DropboxCustomerID = '6669c767644f8f001440035f';

/**
 * Returns filtered condition rules based on source name
 */
export const GetFilteredConditionRules = (sourceName: Source, customerID: string): Option[] => {
  const isSalesforceSource = sourceName === Source.SALESFORCE;
  const isZendeskSource = sourceName === Source.ZENDESK;

  if (isSalesforceSource) {
    return GetConditionRules().filter((conditionRule) => {
      return ![CategoryTags, CategoryTicketPriority].includes(conditionRule.name);
    });
  } else if (isZendeskSource) {
    const zendeskConditions = [...GetConditionRules(), StatusConditionOption];

    if (customerID === DropboxCustomerID) {
      return [...zendeskConditions, ...GetDropboxConditionRules()];
    }

    return zendeskConditions;
  }

  return GetConditionRules();
};

export const GetFilteredActionRules = (sourceName: Source, customerID: string): Option[] => {
  const isSalesforceSource = sourceName === Source.SALESFORCE;
  const isZendeskSource = sourceName === Source.ZENDESK;

  if (isSalesforceSource) {
    return _.cloneDeep(
      GetActionRules().filter(
        (action) => action.name !== ActionWriteCaseTags && action.name !== ActionChangeCaseAssigneeTeam && action.name !== ActionWritePrivateComments
      )
    );
  } else if (isZendeskSource) {
    return _.cloneDeep([
      ...GetActionRules(),
      {
        name: ActionApplyMacro,
        label: 'Apply Macro',
        keyName: ActionApplyMacro,
        rowFactoryFn: (options: Option[], value?: Action, operatorValue?: string) => new ApplyMacroRowManager(options, value, operatorValue, 'action')
      }
    ]);
  }

  return GetActionRules();
};

export const GetConditionRules = (): Option[] => {
  return [
    {
      name: CategoryCategories,
      label: 'Categories',
      keyName: 'category',
      rowFactoryFn: (options: Option[], action?: Action, operatorValue?: string) => new CategoriesRowManager(options, action, operatorValue)
    },
    {
      name: CategoryTags,
      label: 'Tags',
      keyName: CategoryTags,
      rowFactoryFn: (options: Option[], action?: Action, operatorValue?: string) => new TagsRowManager(options, action, operatorValue, CategoryTags)
    },
    {
      name: CategoryCustomField,
      label: 'Custom Field',
      keyName: CategoryCustomField,
      rowFactoryFn: (options: Option[], action?: Action, operatorValue?: string) => new CustomFieldRowManager(options, action, operatorValue)
    },
    {
      name: CategorySubject,
      label: 'Subject',
      keyName: CategorySubject,
      rowFactoryFn: (options: Option[], action?: Action, operatorValue?: string) => new SubjectRowManager(options, action, operatorValue)
    },
    {
      name: CategoryTicketContent,
      label: 'Ticket Content',
      keyName: CategoryTicketContent,
      rowFactoryFn: (options: Option[], action?: Action, operatorValue?: string) => new TicketContentRowManager(options, action, operatorValue)
    },
    {
      name: CategoryTicketPriority,
      label: 'Ticket Priority',
      keyName: CategoryTicketPriority,
      rowFactoryFn: (options: Option[], action?: Action, operatorValue?: string) => new PriorityRowManager(options, action, operatorValue)
    },
    {
      name: CategoryAccountName,
      label: 'Account Name',
      keyName: CategoryAccountName,
      rowFactoryFn: (options: Option[], action?: Action, operatorValue?: string) => new AccountNameRowManager(options, action, operatorValue)
    }
  ];
};

export const GetActionRules = (): Option[] => {
  return [
    {
      name: ActionChangeCaseAssignee,
      label: 'Assign agent',
      keyName: ActionChangeCaseAssignee,
      rowFactoryFn: (options: Option[], value?: Action, operatorValue?: string) => new AssignAgentRowManager(options, value, operatorValue, 'action')
    },
    {
      name: ActionChangeCaseAssigneeTeam,
      label: 'Assign team',
      keyName: ActionChangeCaseAssigneeTeam,
      rowFactoryFn: (options: Option[], value?: Action, operatorValue?: string) => new AssignTeamRowManager(options, value, operatorValue, 'action')
    },
    {
      name: ActionChangeCaseStatus,
      label: 'Change case status',
      keyName: ActionChangeCaseStatus,
      rowFactoryFn: (options: Option[], value?: Action, operatorValue?: string) => new ChangeCaseStatusRowManager(options, value, operatorValue, 'action')
    },
    {
      name: ActionReplyToCase,
      label: 'Auto-respond to customer',
      keyName: ActionReplyToCase,
      rowFactoryFn: (options: Option[], value?: Action, operatorValue?: string) =>
        new AutoRespondRowManager(options, value, operatorValue, 'action', ActionReplyToCase)
    },
    {
      name: ActionWriteCaseTags,
      label: 'Write tags to case',
      keyName: ActionWriteCaseTags,
      rowFactoryFn: (options: Option[], value?: Action, operatorValue?: string) => new WriteCaseTagsRowManager(options, value, operatorValue, 'action')
    },
    {
      name: ActionWriteCaseTagsCustom,
      label: 'Write custom tags to case',
      keyName: ActionWriteCaseTagsCustom,
      rowFactoryFn: (options: Option[], value?: Action, operatorValue?: string) => new WriteCaseTagsCustomRowManager(options, value, operatorValue, 'action')
    },
    {
      name: ActionWriteCaseCustomField,
      label: 'Write custom field to case',
      keyName: ActionWriteCaseCustomField,
      rowFactoryFn: (options: Option[], value?: Action, operatorValue?: string) => new WriteCaseCustomFieldRowManager(options, value, operatorValue, 'action')
    },
    {
      name: ActionWritePrivateComments,
      label: 'Create Private Note',
      keyName: ActionWritePrivateComments,
      rowFactoryFn: (options: Option[], value?: Action, operatorValue?: string) =>
        new AutoRespondRowManager(options, value, operatorValue, 'action', ActionWritePrivateComments)
    }
  ];
};

export const GetDropboxConditionRules = (): Option[] => {
  return [
    {
      name: DropboxHasActiveSubscription,
      label: 'Check Active Subscription',
      keyName: DropboxHasActiveSubscription,
      rowFactoryFn: (options: Option[], action?: Action, operatorValue?: string) =>
        new CustomFlagRowManager(DropboxHasActiveSubscription, options, action, operatorValue)
    },
    {
      name: DropboxHasMultipleDevicesConnected,
      label: 'Check Multiple Devices Connected',
      keyName: DropboxHasMultipleDevicesConnected,
      rowFactoryFn: (options: Option[], action?: Action, operatorValue?: string) =>
        new CustomFlagRowManager(DropboxHasMultipleDevicesConnected, options, action, operatorValue)
    },
    {
      name: DropboxHasPremiumSupport,
      label: 'Check Premium Support',
      keyName: DropboxHasPremiumSupport,
      rowFactoryFn: (options: Option[], action?: Action, operatorValue?: string) =>
        new CustomFlagRowManager(DropboxHasPremiumSupport, options, action, operatorValue)
    },
    {
      name: DropboxIsEligibleForRefund,
      label: 'Check Eligible For Refund',
      keyName: DropboxIsEligibleForRefund,
      rowFactoryFn: (options: Option[], action?: Action, operatorValue?: string) =>
        new CustomFlagRowManager(DropboxIsEligibleForRefund, options, action, operatorValue)
    }
  ];
};

export const StatusConditionOption: Option = {
  name: CategoryStatus,
  label: 'Status',
  keyName: CategoryStatus,
  rowFactoryFn: (options: Option[], action?: Action, operatorValue?: string) => new TagsRowManager(options, action, operatorValue, CategoryStatus)
};
