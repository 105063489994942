import React from 'react';
import { useLocation } from 'react-router-dom';
import Utils from '../../utils/utils';

import { PortalSidebar } from '../PortalSidebar';

import { isMocKData } from '../../services/IrisPortalService/utils';

import './styles.scss';

export const PortalLayout: React.FC = ({ children }) => {
  const { pathname } = useLocation();

  if (!['/overview', '/detailed-view'].includes(pathname)) {
    Utils.removeObjectItem('filtersCategories');
  }

  const accessEnabled = Utils.getObjectItem('accessEnabled').current;

  return (
    <>
      <div className="app-portal-layout">
        {(accessEnabled || isMocKData) && <PortalSidebar />}

        {accessEnabled ? <div className="app-portal-content">{children}</div> : <>{children}</>}
      </div>
    </>
  );
};
