import { Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import React, { FC } from 'react';
import { Heading, Stack, Text, useTableSorters } from '../../../components/common';
import { Loader } from '../../../components/common/blocks/Loader';
import TableHeadSorting from '../../../components/table-head-sorting/TableHeadSorting';
import { AgentCaseData } from '../types';
import { useSingleAgentQuery } from '../utils';
import { makeStyles } from '@material-ui/core/styles';
import { FeedbackCol } from './FeedbackCol';
import { Tooltip } from '../../../components/Tooltip';

const useStyles = makeStyles({
  ellipsis: {
    maxWidth: 100, // percentage also works
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
});

function formatDescription(text: string): string {
  return `${text?.slice(0, 108)}...`;
}

export const AgentTable: FC<{
  heading: string;
  agentId: string;
  waitFor: any;
}> = (props) => {
  const { agentId, heading, waitFor } = props;

  const {
    onSort,
    filterData,
    sortColumn,
    sortOrder,
    onPageChange,
    currentPage,
    handleChangeRowsPerPage,
    labelDisplayedRows,
    pageSize,
    ROWS_PER_PAGE_OPTIONS
  } = useTableSorters({});

  const { data: agentSingleAnalyticsData } = useSingleAgentQuery(agentId);

  const data: AgentCaseData[] = agentSingleAnalyticsData !== undefined ? agentSingleAnalyticsData.casesWithinTimeRange : [];

  const isMockData = () => {
    const mock = process.env.REACT_APP_MOCKDATA === 'true' ? true : false;
    return mock;
  };

  const classes = useStyles();

  return (
    <Stack gap={20} direction="vertical" align="flex-start">
      <div>
        <Heading>{heading}</Heading>
      </div>
      {agentSingleAnalyticsData === undefined || waitFor === undefined ? (
        <div style={{ width: '100%' }}>
          <Loader center />
        </div>
      ) : (
        <React.Fragment>
          <Typography variant="caption" display="block" gutterBottom align={'left'} className={'mt-0'}>
            {`A maximum of ${agentSingleAnalyticsData.maxCasesShown} cases are displayed here`}
          </Typography>

          <TableContainer className="agent-cases-table-container">
            <Table>
              <TableHeadSorting
                order={sortOrder}
                orderByColumn={sortColumn}
                onRequestSort={(_, column) => onSort(column)}
                headCells={isMockData() ? TableHeaderCells : TableHeaderCells.slice(0, TableHeaderCells.length - 1)}
              />
              <TableBody>
                {filterData(data).length === 0 && (
                  <TableRow>
                    <TableCell colSpan={100} align="center">
                      <Text>No Data</Text>
                    </TableCell>
                  </TableRow>
                )}
                {filterData(data).map((value, index) => (
                  <TableRow key={index} style={{ cursor: 'auto' }}>
                    <TableCell style={{ textAlignLast: 'center' }}>
                      <Text variant="p1" weight="semi-bolder" style={{ color: '#5E81F4' }}>
                        {value.CaseNumber === null ? '' : value.CaseNumber}
                      </Text>
                    </TableCell>
                    <TableCell>
                      <Tooltip title={value.Subject} placement="top">
                        <Text variant="p1" weight="semi-bold" color="dark" lineClamp={1}>
                          {value.Subject}
                        </Text>
                      </Tooltip>
                      <Tooltip title={value.Description} placement="top">
                        <Text variant="p1" color="dark" lineClamp={2}>
                          {formatDescription(value.Description)}
                        </Text>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Text variant="p1" weight="medium">
                        {format(new Date(value.timeOfCreation), 'MMM dd, yyyy')}
                      </Text>
                    </TableCell>
                    {/* <TableCell style={{ maxWidth: 200 }}>
                    <Text variant="p2" color="dark" lineClamp={4}>
                      {formatDescription(value.Description)}
                    </Text>
                  </TableCell> */}
                    <TableCell className={classes.ellipsis}>
                      <Text variant="p1" weight="semi-bold" color="dark">
                        {value.category.join(', ')}
                      </Text>
                    </TableCell>
                    <TableCell>
                      <Text variant="p1" weight="medium">
                        {value.Status}
                      </Text>
                    </TableCell>
                    {isMockData() && (
                      <TableCell>
                        <Text variant="p1" weight="medium">
                          <FeedbackCol caseData={value} />
                        </Text>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {data.length ? (
              <TablePagination
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                component="div"
                count={data.length}
                rowsPerPage={pageSize}
                page={currentPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelDisplayedRows={labelDisplayedRows}
                onChangePage={(_, page) => onPageChange(page)}
              />
            ) : null}
          </TableContainer>
        </React.Fragment>
      )}
    </Stack>
  );
};

const TableHeaderCells = [
  {
    id: 'CaseNumber',
    label: 'CASE #',
    width: '11%',
    hasFilter: true
  },
  {
    id: 'Subject',
    label: 'SUBJECT / Description',
    width: '30%',
    hasFilter: true
  },
  {
    id: 'timeOfCreation',
    label: 'DATE CREATED',
    width: '15%',
    hasFilter: true
  },
  // {
  //   id: 'Description',
  //   label: 'DESCRIPTION',
  //   width: '20%',
  //   hasFilter: false
  // },
  {
    id: 'Category',
    label: 'CATEGORY',
    width: '15%',
    hasFilter: true
  },
  {
    id: 'Status',
    label: 'STATUS',
    width: '9%',
    hasFilter: true
  },
  {
    id: 'Feedback',
    label: 'FEEDBACK',
    width: '20%',
    hasFilter: true
  }
];
