import React, { Component } from 'react';
import './AlertComponent.scss';
import { Collapse, IconButton } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';

interface Props {
  alertType: any;
  onClick: any;
  message: string;
}

class AlertComponent extends Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    const { alertType, onClick, message } = this.props;

    return (
      <Collapse className="alert-container" in={true}>
        <Alert
          className="center-content"
          severity={alertType}
          action={
            <IconButton aria-label="close" color="inherit" size="small" onClick={onClick}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {message}
        </Alert>
      </Collapse>
    );
  }
}

export default AlertComponent;
