import { Button as MUIButton, createStyles, withStyles } from '@material-ui/core';

export const Button = withStyles(
  createStyles({
    root: {
      textTransform: 'none'
    },
    outlined: {
      border: '1px solid #ECECF2'
    }
  })
)(MUIButton);
