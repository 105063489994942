import React, { FC } from 'react';
import { Stack } from '../../../components/common';
import { CaseCategoriesType } from '../types';
import { RECENT_CASE_LIMIT, useCurrentCategory } from '../utils';
import { CaseDistribution } from './CaseDistribution';
import { CategoryMetrics } from './CategoryMetrics';
import { CasesTable } from './CasesTable';
import { SecondaryCategoryDistribution } from './SecondaryCategoryDistribution';
import Utils from '../../../utils/utils';

const CTM_CAT_FILTERS = [
  {
    cat: 'Model_',
    sub_cat: 'Issue_'
  },
  {
    cat: 'Dealer_',
    sub_cat: 'Issue_Damage upon delivery'
  }
];

const CTM_ID = '62ceeabc39f64720f9f382ce';
export const CategoriesTabs: FC<{ caseCategories: CaseCategoriesType[] }> = (props) => {
  const { caseCategories } = props;
  const currentCategory = useCurrentCategory();

  return (
    <Stack gap={0} direction="vertical">
      {caseCategories.map((category: any) => (
        <TabPanel value={currentCategory} category={category} key={category.name}>
          Panel
        </TabPanel>
      ))}
    </Stack>
  );
};

const TabPanel: FC<{
  category: CaseCategoriesType;
  value: string;
}> = (props) => {
  const { value, category } = props;

  let user = Utils.getObjectItem('user');
  if (user && user.customerId === CTM_ID) {
    const ctmFilter = CTM_CAT_FILTERS.filter((filter) => category.name.indexOf(filter.cat) === 0);
    if (ctmFilter.length !== 0) {
      const sub_cat = ctmFilter[0].sub_cat;
      Object.keys(category.secondaryCategoryDistribution).map((key) => {
        if (key.indexOf(sub_cat) === -1) {
          delete category.secondaryCategoryDistribution[key];
        }
        return key;
      });
    }
  }
  return (
    <Stack gap={0} role="tabpanel" hidden={value !== category.name} id={category.name} aria-labelledby={`category`} direction="vertical">
      <Stack style={{ display: value === category.name ? 'block' : 'none' }} gap={30} direction="vertical">
        <CategoryMetrics categoryData={category} />
        <Stack style={{ width: '100%' }}>
          <CaseDistribution distribution={category.bins} categoryName={category.name} />
        </Stack>
        {category.secondaryCategoryDistribution && Object.keys(category.secondaryCategoryDistribution).length > 0 && (
          <Stack>
            <SecondaryCategoryDistribution data={category.secondaryCategoryDistribution} />
          </Stack>
        )}
        <Stack style={{ width: '100%' }}>
          <CasesTable
            title="Cases"
            cases={category.recentCases}
            footerText={category.count > RECENT_CASE_LIMIT ? `Showing ${RECENT_CASE_LIMIT} most recent cases` : ''}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
