import React, { FC } from 'react';
import { Stack, StackProps } from './Stack';

/**
 * Main page container for all route level components
 * Responsible for standardized margin, background color & other layout CSS
 * Accepts all the StackProps
 * which gets passed to the internal Stack component
 */
export const PageContainer: FC<StackProps> = (props) => {
  return (
    <Stack
      direction="vertical"
      gap={0}
      align="center"
      style={{
        paddingTop: '30px',
        // paddingBottom: '30px',
        backgroundColor: '#F7F7F7',
        minHeight: '100vh'
      }}
    >
      <Stack direction="vertical" gap={30} {...props} style={{ width: '95%', margin: '0px', ...props.style }}>
        {props.children}
      </Stack>
    </Stack>
  );
};
