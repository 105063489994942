import React, { ReactElement } from 'react';
import { RulesNativeSelect } from '../../RulesNativeSelect';
import { RulesRowManager } from '../../../utils/RulesRowManager';
import { HandleRowComponentType, Option } from '../../../types';
import { ReactComponent as BookOpen } from '../../../../../assets/bookOpen.svg';
import '../styles.scss';

enum _ValidValues {
  True = 'true',
  False = 'false'
}

export class CustomFlagRowManager extends RulesRowManager {
  constructor(action: string, options: Option[], actionValue: any, actionOperator: any) {
    super(options, actionValue, actionOperator);
    this.action.type = action;
    this.action.operator = 'eq';
  }

  setDefaultActionValue(): void {
    this.action.value = true;
  }

  createItems(handleRowComponent: HandleRowComponentType): ReactElement {
    if (this.action.value === undefined) this.setDefaultActionValue();
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <BookOpen />
          <p className="title">Expected Value: {this.action.type}</p>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 8 }}>
          <div className={'fact-selector'}>
            <RulesNativeSelect
              options={[
                { name: _ValidValues.True, label: 'True' },
                { name: _ValidValues.False, label: 'False' }
              ]}
              defaultValue={_ValidValues.True}
              onChange={(e) => {
                this.action.value = String(this.action.value).toLowerCase() === 'true';
                handleRowComponent(this.action);
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
