import React, { FC } from 'react';
import { Stack, Text } from '../../../components/common';
import './GranularData.scss';

export const PriorityScoreGranularData: FC<{
  scoreType: any;
  scoreValue: any;
  color: any;
  rating: any;
  enabledDashLine?: boolean;
  scoreInfo: string;
}> = (props) => {
  const { scoreType, scoreValue, color, rating, enabledDashLine = true, scoreInfo } = props;

  return (
    <Stack className="granularDataContainer" direction="vertical">
      <Stack direction="vertical">
        <div>
          <Text
            variant="p2"
            weight="bold"
            style={{
              backgroundColor: color,
              color: '#FFFFFF',
              fontSize: '14px',
              padding: 6,
              borderRadius: 4,
              textTransform: 'capitalize'
            }}
          >
            {rating}
          </Text>
          <Text variant="p4" weight="bold" style={{ color: 'rgba(255, 255, 255, 0.87)', paddingLeft: 8 }}>
            {scoreValue}
            <Text style={{ color: 'rgba(255, 255, 255, 0.87)' }}>/100</Text>
          </Text>
        </div>
        <Stack direction="vertical" justify="space-between" style={{ paddingTop: 8 }}>
          <Text variant="p1" weight="regular" style={{ color: 'rgba(223, 223, 223, 0.76)' }}>
            {scoreType}
          </Text>
          <Text variant="p1" weight="regular" style={{ color: 'rgba(223, 223, 223, 0.76)' }}>
            {scoreInfo}
          </Text>
        </Stack>
      </Stack>
      {enabledDashLine && <div className="dashLine" />}
    </Stack>
  );
};
