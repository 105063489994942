import React, { useCallback, useRef } from 'react';
import { useHistory } from 'react-router';

import { TableCell as MUITableCell, TableRow as MUITableRow } from '@material-ui/core';
import { ArrowForward, MoreVertOutlined } from '@material-ui/icons';

import { ReactComponent as EditIcon } from '../../../../../assets/Edit.svg';
import { ReactComponent as TriggerTableIcon1 } from '../../../../../assets/triggerTableIcon1.svg';
import { ContextMenu, IContextMenuRef } from '../../../../../components/ContextMenu';
import { ContextMenuItem } from '../../../../../components/ContextMenu/ContextMenuItem';
import { IconButton } from '../../../../../components/IconButton';

import Constants from '../../../../../utils/constants';

import { Trigger, TriggerStatus } from '../../../types';

import './styles.scss';
import Routing from '../../../../../utils/routing';
import Utils from '../../../../../utils/utils';

export interface IRulesTriggersTableItemData {
  id: string;
  name: string;
  createdBy: string;
  status: string;
  raw: Trigger;
}

interface IRulesTriggersTableItemProps {
  item: IRulesTriggersTableItemData;
  getTypeActionItem: (action: string, item: any) => void;
}

export const RulesTriggersTableItem: React.FC<IRulesTriggersTableItemProps> = ({ item, getTypeActionItem = () => {} }) => {
  const history = useHistory();
  const moreOptionsContextMenuRef = useRef<IContextMenuRef>(null);
  const moreOptionsContainerRef = useRef<HTMLDivElement>(null);

  /**
   * =========================================================================
   * Replace this code with the one to navigate to edit the item.
   * =========================================================================
   */
  const handleEditItem = useCallback(() => {
    history.push(Routing.getTriggerDetailsPageUrl(item.id));
  }, [history, item.id]);

  /**
   * =========================================================================
   * Replace this code to handle more options.
   * =========================================================================
   */
  const handleMoreOptions = useCallback<React.MouseEventHandler<HTMLButtonElement>>((evt) => {
    moreOptionsContextMenuRef.current?.openContextMenu(evt.clientX, evt.clientY);
  }, []);

  /**
   * =========================================================================
   * Replace this code with the one to navigate to the item details.
   * =========================================================================
   */
  const handleInspectItem = (itemId: any) => {
    history.push(`${Constants.routes.TRIGGERS_DETAILS}?${Constants.TRIGGER_ID_SEARCH_PARAM}=${itemId}`);
  };

  const isAdmin = Utils.amIAdmin();

  return (
    <>
      <MUITableRow className="rules-triggers-table-item-container">
        <MUITableCell style={{ width: '30%' }} align="left" className="rules-triggers-table-item-title" component={'td'}>
          {item.name}
        </MUITableCell>
        <MUITableCell style={{ width: '50%' }} align="left">
          {item.createdBy}
        </MUITableCell>
        <MUITableCell style={{ width: '50%' }} align="left">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {item.raw.aiResolutions !== '0' && (
              <>
                <IconButton size="sm" icon={TriggerTableIcon1} onClick={() => {}} />
                {item.raw.aiResolutions}
              </>
            )}
            {item.raw.aiResolutions === '0' && <div style={{ marginLeft: 8 }}>NA</div>}
          </div>
        </MUITableCell>
        <MUITableCell align="left" className="rules-triggers-table-item-actions">
          {!item.raw.isSystem && isAdmin && (
            <React.Fragment>
              <IconButton size="sm" title={'Edit'} icon={EditIcon} onClick={handleEditItem} />
              <div ref={moreOptionsContainerRef}>
                <IconButton size="sm" title={'More options'} icon={MoreVertOutlined} onClick={handleMoreOptions} />

                <ContextMenu className="rules-triggers-table-context-menu" ref={moreOptionsContextMenuRef} itemRef={moreOptionsContainerRef}>
                  <ContextMenuItem onClick={() => getTypeActionItem('clone', item.raw)}>Clone</ContextMenuItem>
                  <ContextMenuItem onClick={() => getTypeActionItem(TriggerStatus.ENABLED ? 'disabled' : 'enabled', item.raw)}>
                    {item.raw.status === TriggerStatus.ENABLED ? 'Disable' : 'Enable'}
                  </ContextMenuItem>
                  <ContextMenuItem onClick={() => getTypeActionItem('delete', item.raw)}>Delete</ContextMenuItem>
                </ContextMenu>
              </div>
            </React.Fragment>
          )}

          <IconButton title={'Inspect'} icon={ArrowForward} onClick={() => handleInspectItem(item.id)} backgroundColor="rgba(94, 129, 244, 0.2)" />
        </MUITableCell>
      </MUITableRow>
    </>
  );
};
