import { Stack, Text } from '../../../components/common/blocks';
import React, { FC } from 'react';
import _ from 'lodash';

export const SentimentNumberToText = (sentimentScore: number) => {
  switch (true) {
    case sentimentScore < -0.6:
      return 'Negative';
    case sentimentScore >= -0.6 && sentimentScore < -0.1:
      return 'Moderate Negative';
    case sentimentScore >= -0.1 && sentimentScore <= 0.1:
      return 'Neutral';
    case sentimentScore > 0.1 && sentimentScore <= 0.6:
      return 'Moderate Positive';
    case sentimentScore > 0.6:
      return 'Positive';
  }
};

export const SentimentLabel: FC<{
  sentimentScore: number;
}> = (props) => {
  const { sentimentScore } = props;
  const colors = {
    red: {
      bgColor: `rgba(255, 99, 97, 0.1)`,
      textColor: '#bd4a49'
    },

    lightred: {
      bgColor: `rgba(255, 99, 97, 0.1)`,
      textColor: '#ff6361'
    },

    white: {
      bgColor: `rgba(0, 0, 0, 0.05)`,
      textColor: '#111010'
    },

    lightgreen: {
      bgColor: `rgba(99, 255, 97, 0.2)`,
      textColor: '#1aaf59'
    },

    green: {
      bgColor: `rgba(99, 255, 97, 0.2)`,
      textColor: '#0d5c2e'
    }
  };

  let selectedColor = colors.white;
  let selectedText = '';
  if (!_.isNil(sentimentScore)) {
    selectedText = 'Neutral';
  }
  selectedText = SentimentNumberToText(sentimentScore) || '';
  switch (true) {
    case sentimentScore < -0.6:
      selectedColor = colors.red;
      break;
    case sentimentScore >= -0.6 && sentimentScore < -0.1:
      selectedColor = colors.lightred;
      break;
    case sentimentScore >= -0.1 && sentimentScore <= 0.1:
      selectedColor = colors.white;
      break;
    case sentimentScore > 0.1 && sentimentScore <= 0.6:
      selectedColor = colors.lightgreen;
      break;
    case sentimentScore > 0.6:
      selectedColor = colors.green;
      break;
  }

  if (_.isNil(sentimentScore)) {
    return null;
  }

  return (
    <Stack
      style={{
        paddingLeft: 13,
        paddingRight: 13,
        paddingTop: 4,
        paddingBottom: 4,
        backgroundColor: selectedColor.bgColor,
        borderRadius: 100,
        width: 'fit-content'
      }}
    >
      <Text variant="p0" weight="semi-bold" style={{ color: selectedColor.textColor }} align="center">
        {selectedText}
      </Text>
    </Stack>
  );
};
