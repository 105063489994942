import React, { FC } from 'react';

export const TablePaper: FC<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>> = (props) => {
  return (
    <div
      {...props}
      style={{
        ...props.style,
        padding: '0px',
        paddingBottom: '16px',
        paddingLeft: '16px',
        paddingRight: '16px',
        background: 'white',
        borderRadius: '10px'
      }}
    />
  );
};
