import React, { useState } from 'react';
import { Widget } from '../../components/common/Widget';
import { useQuery } from 'react-query';
import { CACHE_KEYS, CACHE_TIME } from '../../utils/cacheUtils';
import IrisPortalService from '../../services/IrisPortalService';
import { ArticleMetricEntityType } from '../../types/automation';
import { Loader } from '../../components/common/blocks/Loader';
import { CategoryTypeSelection } from '../categories/components/categoryTypeComponent/CategoryTypeSelection';
import _ from 'lodash';
import { MultiSelectInput } from '../../components/MultiSelectInput';
import { Stack } from '../../components/common';
import { IrisCard, IrisCardHighlightText } from '../../components/IrisCard';
import Grid from '@material-ui/core/Grid';
import { TopPerformingArticles } from './TopPerformingArticles';
import { EntitiesWithNoMatchingArticles } from './EntitiesWithNoMatchingArticles';

const labelToEntityType: Record<string, ArticleMetricEntityType> = {
  CASES: 'cases',
  IRISGPT: 'iris_gpt'
};

export const ArticleMetrics: React.FC = () => {
  const [dateRange, setDateRange] = useState<[Date, Date]>([new Date(), new Date()]);
  const [enabled, setEnabled] = React.useState<boolean>(false);

  const [entityType, setEntityType] = React.useState<string>('CASES');
  const [selectedCategories, setSelectedCategories] = React.useState<any[]>([]);

  const { data: metricsData, isFetching, isLoading } = useQuery(
    [CACHE_KEYS.ARTICLE_METRICS, ...dateRange, entityType, selectedCategories],
    () => {
      return IrisPortalService.getArticleMetrics(dateRange[0], dateRange[1], labelToEntityType[entityType], _.map(selectedCategories, 'name'));
    },
    {
      enabled,
      ...CACHE_TIME.get(CACHE_KEYS.ARTICLE_METRICS)
    }
  );

  const categoryOptions = React.useMemo(() => {
    if (isLoading) {
      return [];
    }

    return _.map(metricsData?.metrics?.categories, (category) => {
      return {
        name: category,
        label: category
      };
    });
  }, [isLoading, metricsData]);

  return (
    <React.Fragment>
      <Widget
        title={'Article-level metrics'}
        hasDateRange={true}
        onDateRangeChange={onDateRangeChange}
        left={
          <React.Fragment>
            {!isFetching && (
              <React.Fragment>
                <CategoryTypeSelection
                  onChangeType={setEntityType}
                  selectedType={entityType}
                  buttonLabels={
                    metricsData?.metrics?.isIrisGptEnabled
                      ? _.keys(labelToEntityType)
                      : _.chain(labelToEntityType)
                          .keys()
                          .reject((label) => _.isEqual(label, 'IRISGPT'))
                          .value()
                  }
                />
              </React.Fragment>
            )}
          </React.Fragment>
        }
        right={
          <React.Fragment>
            {!isLoading && !_.isEmpty(categoryOptions) && (
              <MultiSelectInput
                displayValue="label"
                placeholder="Categories"
                onKeyPressFn={function noRefCheck() {}}
                onSearch={function noRefCheck() {}}
                valuesSelected={selectedCategories}
                onRemove={onCategoriesChange}
                onChange={onCategoriesChange}
                options={categoryOptions}
              />
            )}
          </React.Fragment>
        }
      >
        {isFetching ? (
          <Loader />
        ) : (
          <React.Fragment>
            <Stack direction={'vertical'} gap={20}>
              <Stack direction={'horizontal'} gap={10}>
                <IrisCard description={'Queries without articles'}>
                  <IrisCardHighlightText text={`${_.ceil(metricsData?.metrics?.entityPercentageWithoutArticles || 0)}%`} />
                </IrisCard>

                <IrisCard description={'Matched articles'}>
                  <IrisCardHighlightText text={_.ceil(metricsData?.metrics?.matchedArticleCount || 0)} />
                </IrisCard>
              </Stack>

              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={6} lg={6} className={'m-0'}>
                  <div
                    className={'line-graph-container h-100 p-0o5'}
                    style={{
                      width: '98%'
                    }}
                  >
                    <TopPerformingArticles topPerformingArticles={metricsData?.metrics?.topPerformingArticles || []} />
                  </div>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} className={'m-0'}>
                  <div
                    className={'line-graph-container h-100 p-0o5'}
                    style={{
                      width: '98%'
                    }}
                  >
                    <EntitiesWithNoMatchingArticles entitiesWithNoMatchingArticles={metricsData?.metrics?.entitiesWithNoMatchingArticles || []} />
                  </div>
                </Grid>
              </Grid>
            </Stack>
          </React.Fragment>
        )}
      </Widget>
    </React.Fragment>
  );

  function onDateRangeChange(dateRange: [Date, Date]) {
    setEnabled(true);
    setDateRange(dateRange);
  }

  function onCategoriesChange(options: any[]) {
    setSelectedCategories(
      options.map((option: any) => {
        return {
          name: option.name,
          label: option.label
        };
      })
    );
  }
};
