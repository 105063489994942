import { ActionRowWrapper } from './components/RulesManager/ActionRow';
import { RulesRowManager } from './utils';

export type Option = {
  name: string;
  label: string;
  keyName?: string; // key name for data from BE (eg. alerts)
  disabled?: boolean;
  hidden?: string[];
  rowFactoryFn?: (options: Option[], action?: Action, operatorValue?: string) => RulesRowManager;
};

export type ActionValue = {
  content: string;
  deferIfAgentReplied: boolean;
};

export type Action = {
  id: number;
  value: any; // TODO: enforce type here. Macro and reply use ActionValue
  type: string;
  operator: string;
  expressionType?: string;
};

export type ConditionContainer = {
  operator: string;
  actions: ActionRowWrapper[];
};
export type Condition = {
  id: number;
  value: any;
  type: string;
};

export type HandleRowComponentType = (action: Action) => void;

export type HandleRowDeleteType = (deletedAction: Action) => void;

export enum TriggerStatus {
  ENABLED = 'enabled',
  DISABLED = 'disabled'
}

export enum TriggerOperator {
  AND = 'and',
  OR = 'or',
  CONTAINS = '',
  EQ = 'eq',
  IN = 'in',
  NIN = 'nin',
  DOES_NOT_CONTAIN = 'doesNotContain',
  VALUE_CHANGED = 'value_changed',
  VALUE_CHANGED_TO = 'value_changed_to'
}

export enum TriggerType {
  COMPOUND = 'compound',
  SIMPLE = 'simple'
}

export type TriggerUser = {
  name: string;
  email: string;
};

export type TriggerOperand = {
  type: TriggerType.COMPOUND | TriggerType.SIMPLE;
  operator:
    | TriggerOperator.AND
    | TriggerOperator.CONTAINS
    | TriggerOperator.DOES_NOT_CONTAIN
    | TriggerOperator.EQ
    | TriggerOperator.IN
    | TriggerOperator.NIN
    | TriggerOperator.OR
    | TriggerOperator.VALUE_CHANGED
    | TriggerOperator.VALUE_CHANGED_TO;
  fact: string;
  value: string | string[];
};

export type TriggerRuleDetail = {
  type: TriggerType.COMPOUND | TriggerType.SIMPLE;
  operator:
    | TriggerOperator.AND
    | TriggerOperator.CONTAINS
    | TriggerOperator.DOES_NOT_CONTAIN
    | TriggerOperator.EQ
    | TriggerOperator.IN
    | TriggerOperator.NIN
    | TriggerOperator.OR
    | TriggerOperator.VALUE_CHANGED
    | TriggerOperator.VALUE_CHANGED_TO;
  operands: TriggerOperand[];
};

export type TriggerRule = {
  type: TriggerType.COMPOUND | TriggerType.SIMPLE;
  operator:
    | TriggerOperator.AND
    | TriggerOperator.CONTAINS
    | TriggerOperator.DOES_NOT_CONTAIN
    | TriggerOperator.EQ
    | TriggerOperator.IN
    | TriggerOperator.NIN
    | TriggerOperator.OR;
  operands: TriggerRuleDetail[];
};

export type TriggerAction = {
  _id: string;
  type: TriggerType.COMPOUND | TriggerType.SIMPLE;
  data: { value: string };
};

export enum TriggerSystemType {
  CASE_DEFLECTION = 'case_deflection'
}

export type TriggerSystem = {
  type: TriggerSystemType.CASE_DEFLECTION;
};

export type Trigger = {
  id?: string;
  name: string;
  status: TriggerStatus.ENABLED | TriggerStatus.DISABLED;
  creatorUser: TriggerUser;
  createdAt: string;
  lastEdited: {
    at: string;
    by: TriggerUser;
  };
  rule?: TriggerRule;
  actions?: Array<TriggerAction>;
  aiResolutions?: string;
  isSystem?: boolean;
  system?: TriggerSystem;
};

export type TriggersOverallImpactData = {
  impactData: any;
};

export type TrendTimeRange = {
  startTime: string;
  endTime: string;
};

export type TriggersOverallImpactTrendTimeRange = {
  timeRange: TrendTimeRange;
  data: any[][];
};

export type TriggersOverallImpactTrendData = {
  impactData: TriggersOverallImpactTrendTimeRange[];
};
