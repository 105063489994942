import React, { Component } from 'react';
import './Platforms.scss';
import LoginButton from '../../components/login-button/LoginButton';
import LoginZendesk from '../login-zendesk/LoginZendesk';
import LoginFreshdesk from '../login-freshdesk/LoginFreshdesk';
import IrisPortalService from '../../services/IrisPortalService';
import Utils from '../../utils/utils';
import LoginZoho from '../login-zoho/LoginZoho';
import ConnectJira from '../manage-accounts/connect-jira/ConnectJira';
import { ReactComponent as ScheduleWidget } from '../../assets/scheduleWidget.svg';
import { Button } from '../../components/home/Button';
import { Source } from '../../utils/constants';
import LoginIrisGPT from '../login-irisgpt/LoginIrisGPT';
import { logout } from '../../services/IrisPortalService/utils';
import { logEventWithUserInfo } from '../../utils/amplitude';
import { Provider } from '../../services/IrisPortalService/connect';

interface FreshdeskFormProps {
  handleClick: (platform: string) => void;
}

const FreshdeskForm: React.FC<FreshdeskFormProps> = ({ handleClick }) => (
  <div>
    <h1 className="login-title"> Sign in to Freshdesk</h1>
    <div className={'common-container'}>
      <p>
        Please enter your API key as per the steps listed{' '}
        <a rel="noopener noreferrer" target="_blank" href="https://support.freshdesk.com/support/solutions/articles/215517-how-to-find-your-api-key">
          here
        </a>
        . Make sure that you have global access in{' '}
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://support.freshdesk.com/support/solutions/articles/196886-i-want-my-agents-access-to-be-altered-while-working-on-the-tickets-how-do-i-go-about-this-"
        >
          ticket scope
        </a>
        .
      </p>
      <p>
        Please enter your Freshdesk subdomain ensuring it doesn't include <b>https://</b> in the beginning and <b>.freshdesk.com</b> at the end
      </p>
    </div>
    <LoginFreshdesk onClick={handleClick} />
  </div>
);

interface ZohoFormProps {
  handleClick: (platform: string) => void;
}

const ZohoInstructions: React.FC = () => (
  <ul>
    <li>
      Go to{' '}
      <a rel="noopener noreferrer" target="_blank" href="https://api-console.zoho.com/">
        https://api-console.zoho.com/
      </a>
    </li>
    <li>
      Select "<strong>Add Client</strong>"
    </li>
    <li>
      Select "<strong>Server Based Applications</strong>"
    </li>
    <li>A popup will open up asking for details about the application.</li>
    <li>
      In the Client Details tab, enter the following details:
      <br />
      Client Name: <b>IrisAgent</b>
      <br />
      Homepage URL: <b>https://irisagent.com</b>
      <br />
      Authorized Redirect URIs: <b>https://frontend-api-server-v2.api.irisagent.com/v1/oauth/zoho/callback</b>
    </li>
    <li>
      Click on "<strong>Create</strong>"
    </li>
    <li>A new application will be created and a client id and client secret will be generated.</li>
    <li>Go to the Settings tab, and enable the Use the same OAuth credentials for all data centers option.</li>
    <li>Go to the Client Secret tab, and paste the Client ID and Client Secret in the form below.</li>
  </ul>
);

const ZohoForm: React.FC<ZohoFormProps> = ({ handleClick }) => (
  <div>
    <h1 className="login-title">Connect with Zoho</h1>
    <p className="common-container" style={{ marginLeft: '10%' }}>
      <ZohoInstructions />
    </p>
    <LoginZoho onClick={handleClick} />
  </div>
);

class Platforms extends Component<{}, any> {
  constructor(props: any) {
    super(props);
    this.state = { showConnect: true, platform: null };
  }

  handleClick = (platformName: string) => {
    this.setState({
      showConnect: !this.state.showConnect,
      platform: platformName
    });
  };

  handleOAuthIntercom = () => {
    this.handleClick('Intercom');
    IrisPortalService.setupOAuth({
      isNew: Utils.IsNew(),
      provider: Provider.Intercom
    }).catch((e: any) => {
      logEventWithUserInfo('New Intercom OAuth Error', { error: e });
    });
  };

  renderForms() {
    if (this.state.platform === Source.ZENDESK) {
      return (
        <div>
          <h1 className="login-title"> Sign in to Zendesk</h1>
          <LoginZendesk onClick={this.handleClick} />
        </div>
      );
    } else if (this.state.platform === Source.IRIS_GPT) {
      return (
        <div>
          <h1 className="login-title">Integrate with Help Center</h1>
          <LoginIrisGPT onClick={this.handleClick} />
        </div>
      );
    } else if (this.state.platform === Source.FRESHDESK) {
      return <FreshdeskForm handleClick={this.handleClick} />;
    } else if (this.state.platform === Source.ZOHO) {
      return <ZohoForm handleClick={this.handleClick} />;
    } else if (this.state.platform === Source.JIRA) {
      return <ConnectJira isPrimaryConnection={true} onClick={this.handleClick} updateConnectJira={null} />;
    }
  }

  componentDidMount() {
    let user = Utils.getObjectItem('user');
    if (!Object.keys(user).length) {
      IrisPortalService.handleError(403); // Unauthorized user (User not logged)
    }
  }

  render() {
    return (
      <>
        <Button className="logout-button" onClick={() => logout(false)}>
          Logout
        </Button>
        <div className="signin-content platform-login">
          <div className="signin-row platform-row">
            <div className="signin-form platform-form">
              <form>
                {this.state.showConnect ? (
                  <div>
                    <h1 className="login-main-title">Three Easy Options to Begin Your Journey</h1>
                    <br />
                    <div className="login-title">A. Try IrisGPT for free</div>
                    <div className="login-para">Enter your public help center URL to activate a custom Generative AI bot tailored just for you.</div>
                    <br />
                    <div className="signin-form platform-div">
                      <LoginButton
                        svgIcon="/images/help-circle.svg"
                        account={Source.IRIS_GPT}
                        title="Connect with Help Center Url"
                        onClick={() => this.handleClick(Source.IRIS_GPT)}
                      />
                    </div>
                    <br />

                    <div className="login-title">B. Implement the complete solution</div>
                    <div className="login-para">Connect IrisAgent to your customer support platform</div>
                    <br />
                    <div className="signin-form platform-div">
                      <LoginButton svgIcon="/images/zendesk.svg" account="Zendesk" title="Connect with Zendesk" onClick={() => this.handleClick('Zendesk')} />
                      <LoginButton svgIcon="/images/jira.png" account="Jira" title="Connect with Jira Service Desk" onClick={() => this.handleClick('Jira')} />
                    </div>
                    <div className="signin-form platform-div">
                      <LoginButton svgIcon="/images/intercom.svg" account="Intercom" title="Connect with Intercom" onClick={() => this.handleOAuthIntercom()} />
                      <LoginButton
                        svgIcon="/images/freshdesk.svg"
                        account="Freshdesk"
                        title="Connect with Freshdesk"
                        onClick={() => this.handleClick('Freshdesk')}
                      />
                    </div>
                    <div className="signin-form platform-div">
                      <LoginButton svgIcon="/images/zoho.svg" account="Zoho" title="Connect with Zoho" onClick={() => this.handleClick('Zoho')} />
                    </div>
                    <br />
                    <div className="login-title">C. Schedule an onboarding session</div>
                    <div className="login-para">If you're not quite ready, set up a consultation with our product specialists</div>
                  </div>
                ) : (
                  <div>{this.renderForms()}</div>
                )}
              </form>
            </div>
          </div>
        </div>
        {this.state.showConnect && <ScheduleDemo />}
      </>
    );
  }
}

const ScheduleDemo = () => {
  return (
    <div className="schedule-demo-box">
      <div className="inner-box">
        <h1>Schedule a demo today</h1>
        <h4 style={{ marginTop: 15 }}>Discover how IrisAgent can improve your customer support operations.</h4>
        <div style={{ marginTop: 'auto' }}>
          <Button href="https://calendly.com/palak-iris/demo-30mins" variant="contained" style={{ backgroundColor: 'white', marginRight: 20 }}>
            BOOK A DEMO
          </Button>
          <Button href="https://irisagent.com/resources/" variant="outlined" style={{ borderColor: 'white', color: 'white' }}>
            LEARN MORE
          </Button>
        </div>
      </div>
      <div className="image">
        <ScheduleWidget />
      </div>
      {/* <img alt="" className="icon" src={ScheduleWidget} /> */}
      {/* https://irisagent.com/about */}
    </div>
  );
};

export default Platforms;
