import React, { Component } from 'react';
import './SimpleTimeline.scss';
import Utils from '../../utils/utils';
import { Stack, Text } from '../common';
import { JiraIssue, JiraIssueCase } from '../../services/IrisPortalService/jira';

interface Props {
  data: JiraIssue;
}

class SimpleTimeline extends Component<Props, any> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  getTimelineData(data: JiraIssue) {
    let orderedCases = data.cases.filter(Boolean).sort((a: JiraIssueCase, b: JiraIssueCase) => (a.timeOfCreation > b.timeOfCreation ? 1 : -1));

    let firstCaseDate = orderedCases.length ? new Date(orderedCases[0].timeOfCreation) : null;
    let lastCaseDate = orderedCases.length ? new Date(orderedCases[orderedCases.length - 1].timeOfCreation) : null;
    let incidentDate = data.issue.createdAt ? new Date(data.issue.createdAt) : null;

    const dates = [
      {
        date: incidentDate,
        value: 'Incident Created',
        formattedDate: incidentDate ? Utils.formatDate(incidentDate, 'Do MMM') : ''
      },
      {
        date: firstCaseDate,
        value: 'First ticket added',
        formattedDate: firstCaseDate ? Utils.formatDate(firstCaseDate, 'Do MMM') : ''
      },
      {
        date: lastCaseDate,
        value: 'Last ticket added',
        formattedDate: lastCaseDate ? Utils.formatDate(lastCaseDate, 'Do MMM') : ''
      }
    ]
      .filter((item): item is { date: Date; value: string; formattedDate: string } => item.date !== null)
      .sort((a, b) => a.date.getTime() - b.date.getTime());

    return {
      first: { value: dates[0]?.value || '', date: dates[0]?.formattedDate || '' },
      middle: { value: dates[1]?.value || '', date: dates[1]?.formattedDate || '' },
      last: { value: dates[2]?.value || '', date: dates[2]?.formattedDate || '' }
    };
  }

  render() {
    const { data } = this.props;
    let timelineData = this.getTimelineData(data);

    return (
      <Stack className="simple-timeline" gap={0}>
        <Stack direction="vertical">
          <div className="timeline-node">
            <div className="timeline-dot-container"></div>
            <div className="timeline-connector-container"></div>
          </div>
          <Text variant="p1" weight="regular" color="gray">
            {timelineData.first.value}
          </Text>
          <Text variant="p1" weight="regular" color="gray">
            {timelineData.first.date}
          </Text>
        </Stack>
        <Stack direction="vertical">
          <div className="timeline-node">
            <div className="timeline-dot-container"></div>
            <div className="timeline-connector-container"></div>
          </div>
          <Text variant="p1" weight="regular" color="gray">
            {timelineData.middle.value}
          </Text>
          <Text variant="p1" weight="regular" color="gray">
            {timelineData.middle.date}
          </Text>
        </Stack>
        <Stack direction="vertical">
          <div className="timeline-node">
            <div className="timeline-dot-container"></div>
          </div>
          <Text variant="p1" weight="regular" color="gray">
            {timelineData.last.value}
          </Text>
          <Text variant="p1" weight="regular" color="gray">
            {timelineData.last.date}
          </Text>
        </Stack>
      </Stack>
    );
  }
}

export default SimpleTimeline;
