import MockData from '../../utils/mockData';
import { isMocKData } from './utils';
import {
  DetectedIncidentsSetting,
  DetectedIncidentsSettingsPrerequisites
} from '../../routes/incidents/trending-incidents-management/DetectedIncidentsManagementTable/types';

// getDetectedIncidentSettings has been deprecated and only used for the demo.
export const getDetectedIncidentSettings = (): Array<DetectedIncidentsSetting> => {
  if (isMocKData) {
    return MockData.geDetectedIncidentSettingsMockData();
  }

  return [];
};

// getDetectedIncidentsSettingsPrerequisites has been deprecated and only used for the demo.
export const getDetectedIncidentsSettingsPrerequisites = (): DetectedIncidentsSettingsPrerequisites => {
  if (isMocKData) {
    return MockData.getDetectedIncidentsSettingsPrerequisitesMockData();
  }

  return { categories: [] } as DetectedIncidentsSettingsPrerequisites;
};
