import React, { Component } from 'react';
import './ConnectJira.scss';
import IrisPortalService from '../../../services/IrisPortalService';
import { Button, Input } from '@material-ui/core';
import { Stack } from '../../../components/common';
import { logEventWithUserInfo } from '../../../utils/amplitude';
import Utils from '../../../utils/utils';

interface Props {
  isPrimaryConnection: boolean;
  onClick: any;
  updateConnectJira: any;
}

class ConnectJira extends Component<Props, any> {
  constructor(props: Props) {
    super(props);
    this.state = { errors: {} };
  }

  connectJira = () => {
    const { isPrimaryConnection } = this.props;
    let jiraTokenValue = this.state.jiraToken;
    let email = this.state.email;
    let subdomain = this.state.subdomain;
    const onPrem = false; // This is jira cloud token entry page

    if (!jiraTokenValue && !email && !subdomain) {
      this.setState({
        errors: { message: 'Jira token, Email and Subdomain cannot be empty' }
      });
      return;
    }
    if (!email) {
      this.setState({ errors: { message: 'Email cannot be empty' } });
      return;
    }
    if (!jiraTokenValue) {
      this.setState({ errors: { message: 'Jira token cannot be empty' } });
      return;
    }
    if (!subdomain) {
      this.setState({ errors: { message: 'Jira subdomain cannot be empty' } });
      return;
    }

    try {
      new URL(`https://${subdomain}.atlassian.net`);
    } catch (e) {
      this.setState({ errors: { message: 'Not a valid url' } });
      return;
    }

    if (isPrimaryConnection) {
      const userData = Utils.getUserItem();
      const adminName = userData.data.name || '';
      const adminEmail = Utils.getUserEmail();
      const hd = userData.data.hd || '';
      const sourceName = Utils.getOAuthSource();

      IrisPortalService.createNewJiraToken(jiraTokenValue, adminName, adminEmail, sourceName, email, hd, subdomain).catch((e: any) => {
        logEventWithUserInfo('Connect New JSM Error', { error: e });
      });
    } else {
      IrisPortalService.createJiraToken(onPrem, jiraTokenValue, email, subdomain).catch((e: any) => {
        logEventWithUserInfo('Connect JSM Error', { error: e });
      });
    }
  };

  handleChangeJiraToken = (e: any) => {
    this.setState({ errors: {} });
    this.setState({ jiraToken: e.target.value });
  };

  handleChangeEmail = (e: any) => {
    this.setState({ errors: {} });
    this.setState({ email: e.target.value });
  };

  handleChangeSubdomain = (e: any) => {
    this.setState({ errors: {} });
    this.setState({ subdomain: e.target.value });
  };

  onKeyPress = (e: any) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      this.connectJira();
    }
  };

  render() {
    const { onClick } = this.props;

    return (
      <Stack direction="vertical" gap={15} className="login-jira-container common-container">
        <p className="instructions">
          Please provide <b>a token</b> to connect to your Jira instance. Instructions to find/create your <b>API token</b> can be found{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://support.atlassian.com/atlassian-account/docs/manage-api-tokens-for-your-atlassian-account/"
          >
            here.
          </a>
        </p>
        <Input fullWidth placeholder="Jira api Token" onKeyDown={this.onKeyPress} onChange={this.handleChangeJiraToken} required />
        <Input fullWidth placeholder="Email (The one that CREATED the api token)" onKeyDown={this.onKeyPress} onChange={this.handleChangeEmail} required />
        <div className="subdomain-container">
          <h4 className="https-domain">https://</h4>
          <Input className="domain-input" placeholder="Subdomain" onKeyDown={this.onKeyPress} onChange={this.handleChangeSubdomain} required />
          <h4 className="jira-domain">.atlassian.net</h4>
        </div>
        <div style={{ minHeight: '20px', display: 'flex', alignItems: 'center' }}>
          {this.state.errors['message'] && <span className="error">{this.state.errors['message']}</span>}
        </div>
        <Stack direction="vertical" gap={10}>
          <Button fullWidth onClick={this.connectJira} className="login-jira-button">
            Connect with Atlassian
          </Button>
          <Button fullWidth onClick={onClick} className="cancel-button">
            Cancel
          </Button>
        </Stack>
      </Stack>
    );
  }
}

export default ConnectJira;
