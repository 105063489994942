import accountHealth from './mock-data-files/accountHealth.json';
import agentAnalyticsData from './mock-data-files/agentAnalyticsData.json';
import agentsForRoutingResponse from './mock-data-files/agentsForRoutingResponse.json';
import articleMetrics from './mock-data-files/articleMetrics.json';
import atlassianConfig from './mock-data-files/atlassianConfig.json';
import caseCategoriesMockData from './mock-data-files/caseCategoriesMockData.json';
import csatMetrics from './mock-data-files/csatMetrics.json';
import customer from './mock-data-files/customer.json';
import customerEngagement from './mock-data-files/customerEngagement.json';
import customerProvidedTags from './mock-data-files/customerProvidedTags.json';
import detailsAccountMockData from './mock-data-files/detailsAccountMockData.json';
import geDetectedIncidentSettingsMockData from './mock-data-files/geDetectedIncidentSettingsMockData.json';
import getChatbotActivityMockData from './mock-data-files/getChatbotActivityMockData.json';
import getChatbotSettingsMockData from './mock-data-files/getChatbotSettingsMockData.json';
import getDetectedIncidentsSettingsPrerequisitesMockData from './mock-data-files/getDetectedIncidentsSettingsPrerequisitesMockData.json';
import getTriggers from './mock-data-files/getTriggersMockData.json';
import getTriggersPrerequisitesMockData from './mock-data-files/getTriggersPrerequisitesMockData.json';
import incidentSourcesMockData from './mock-data-files/incidentSourcesMockData.json';
import invitedUsersMockData from './mock-data-files/invitedUsersMockData.json';
import irisPortalUser from './mock-data-files/irisPortalUser';
import jiraIssuesMockData from './mock-data-files/jiraIssuesMockData.json';
import revenueImpactCategories from './mock-data-files/revenueImpactCategories.json';
import revenueImpactOverall from './mock-data-files/revenueImpactOverall.json';
import ruleDetails from './mock-data-files/ruleDetailsMockData.json';
import sentimentMetric from './mock-data-files/sentimentMetric.json';
import singleAgentAnalytics from './mock-data-files/getSingleAgentAnalytics.json';
import graphData from './mock-data-files/graph-data.json';
import triggerActionCases from './mock-data-files/triggerActionCases.json';
import triggersOverallImpactData from './mock-data-files/triggersOverallImpactData.json';
import triggersOverallImpactTrendData from './mock-data-files/triggersOverallImpactTrend.json';
import userMockData from './mock-data-files/userMockData.json';
import { AgentsForRoutingResponse, AtlassianConfigResponse, CustomerProvidedTag, User } from '../services/types';
import { ArticleMetricsData } from '../types/automation';
import { ChatbotActivity } from '../routes/chatbot-activity/types';
import { customerHealthMockData } from './mock-data-files/customerHealthMockData';
import { DetectedIncidentsManagementTableData } from '../routes/incidents/trending-incidents-management/DetectedIncidentsManagementTable';
import { DetectedIncidentsSettingsPrerequisites } from '../routes/incidents/trending-incidents-management/DetectedIncidentsManagementTable/types';
import { TriggerActionCasesArray } from '../routes/rules/components/TriggerActionCases';
import { TriggersOverallImpactData, TriggersOverallImpactTrendData } from '../routes/rules/types';
import { RevenueImpactCategories } from '../services/IrisPortalService/home';
import { AIRecommendationResponse } from '../services/IrisPortalService/incidents';
import { aiRecommendedCasesResponse } from './mock-data-files/aiRecommendedCases';
import { JiraIssue } from '../services/IrisPortalService/jira';
import { GraphData } from '../services/IrisPortalService/customer';

class MockData {
  public static getDetailsAccountDashboardMockData() {
    return detailsAccountMockData;
  }

  public static getGraphData(): GraphData {
    return graphData;
  }

  public static getUserMockData() {
    return userMockData;
  }

  public static isGenericTokenAvailableMockData() {
    return { isToken: false };
  }

  public static getSaveTokenMockData() {
    return { codeMessage: 'tokenInserted' };
  }

  public static getSaveIrisPortalUserMockData() {
    return { inserted: true, messageCode: 'userInserted' };
  }

  public static getIrisPortalInvitedUsersMockData() {
    return invitedUsersMockData;
  }

  public static getCustomerHealthsMockData() {
    return customerHealthMockData;
  }

  public static getIncidentSourcesMockData() {
    return incidentSourcesMockData;
  }

  public static getCaseCategoriesMockData() {
    return caseCategoriesMockData;
  }

  public static getJiraIssuesMockData(): JiraIssue {
    const data = jiraIssuesMockData;
    return {
      ...data,
      cases: data.cases.map((caseItem) => ({
        ...caseItem,
        timeOfCreation: new Date(caseItem.timeOfCreation)
      })),
      issue: {
        ...data.issue,
        createdAt: new Date(data.issue.createdAt)
      }
    };
  }

  public static getAccountHealth() {
    return accountHealth;
  }

  public static getAiRecommendedCases(): AIRecommendationResponse {
    return aiRecommendedCasesResponse;
  }

  public static getAgentAnalyticsMockData() {
    return agentAnalyticsData;
  }

  public static getSingleAgentAnalyticsMockData() {
    return singleAgentAnalytics;
  }

  public static getTriggersMockData() {
    return getTriggers;
  }

  public static getTriggersPrerequisitesMockData() {
    return getTriggersPrerequisitesMockData;
  }

  public static getRuleDetailsMockData() {
    return ruleDetails;
  }

  static getCustomerMockData() {
    return customer;
  }

  static getSentimentMetricData() {
    return sentimentMetric;
  }

  static getCSATMetrics() {
    return csatMetrics;
  }

  static getRevenueImpactMockData(): RevenueImpactCategories {
    return revenueImpactCategories;
  }

  static getRevenueImpactOverall() {
    return revenueImpactOverall;
  }

  static getTriggersOverallImpactData() {
    return triggersOverallImpactData as TriggersOverallImpactData;
  }

  static getArticleMetricsData() {
    return articleMetrics as ArticleMetricsData;
  }

  static getTriggersOverallImpactTrendData() {
    return triggersOverallImpactTrendData as TriggersOverallImpactTrendData;
  }

  static getCustomerEngagementMockData() {
    return customerEngagement;
  }

  static getTriggerActionCases() {
    return triggerActionCases as TriggerActionCasesArray;
  }

  static getDetectedIncidentsSettingsPrerequisitesMockData() {
    return getDetectedIncidentsSettingsPrerequisitesMockData as DetectedIncidentsSettingsPrerequisites;
  }

  static geDetectedIncidentSettingsMockData() {
    return geDetectedIncidentSettingsMockData as DetectedIncidentsManagementTableData;
  }

  static getChatbotSettingsMockData() {
    return getChatbotSettingsMockData;
  }

  static getChatbotActivityMockData() {
    return getChatbotActivityMockData as ChatbotActivity[];
  }

  static getAtlassianConfigMockData(): AtlassianConfigResponse {
    return atlassianConfig as AtlassianConfigResponse;
  }

  static getIrisPortalUserMockData(): User {
    return irisPortalUser as User;
  }

  static getAgentsForRouting(): AgentsForRoutingResponse {
    return agentsForRoutingResponse as AgentsForRoutingResponse;
  }

  static getCustomerProvidedTags(): CustomerProvidedTag[] {
    return customerProvidedTags as CustomerProvidedTag[];
  }
}

export default MockData;
