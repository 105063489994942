import React, { FC, useState } from 'react';
import { Cell, Label, Pie, PieChart, ResponsiveContainer, Sector } from 'recharts';
import Utils from '../../../../utils/utils';
import { Stack } from '../../../common';
import './Graph.scss';
import LegendItem from './LegendItem';

const COLORS = ['#EF5DA8', '#F2C94C', '#9F72FE', '#F2994A', '#219653', '#2E5BFF'];
export const CustomPieChart: FC<{
  distribution: any[];
  title: string;
  pies: any[];
  dataType?: string; //count or currrency
  customColors?: string[];
  hideLegend?: boolean;
}> = (props) => {
  const { distribution, title, pies, dataType, customColors, hideLegend } = props;
  const [activeIndex, setActiveIndex] = useState(-1);

  const onPieEnter = (_: any, index: number) => {
    setActiveIndex(index);
  };
  const onPieLeave = (_: any) => {
    setActiveIndex(-1);
  };
  const getPayloadLabel = (name: string) => {
    return pies.filter((pie) => pie.name === name)[0]?.label;
  };

  const RenderLegend = () => {
    return (
      <div className="grid-container" style={{ justifyItems: 'center' }}>
        {distribution
          .filter((dist) => dist.value > 0 && !dist.hide)
          .map((item, index) => (
            <div
              // className="grid-item"
              onMouseEnter={() => setActiveIndex(index)}
              onMouseLeave={() => setActiveIndex(-1)}
            >
              <LegendItem
                value={dataType != null && dataType === 'count' ? Utils.getCurrencyValue(item.value).substring(1) : Utils.getCurrencyValue(item.value)}
                label={getPayloadLabel(item.name)}
                color={customColors ? customColors[index % COLORS.length] : COLORS[index % COLORS.length]}
              />
            </div>
          ))}
      </div>
    );
  };

  const renderActiveShape = (props: any) => {
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, payload, value } = props;
    return (
      <g>
        <text x={cx} y={cy} textAnchor="middle" fill={fill} style={{ fontSize: '32px' }}>
          {dataType != null && dataType === 'count' ? Utils.getCurrencyValue(value).substring(1) : Utils.getCurrencyValue(value)}
        </text>
        <text x={cx} y={cy} dy={25} textAnchor="middle" fill="#A0A0A0" style={{ fontSize: '18px', fontFamily: 'DM Sans' }}>
          {getPayloadLabel(payload.name)}
        </text>
        <Sector cx={cx} cy={cy} innerRadius={innerRadius} outerRadius={outerRadius + 10} startAngle={startAngle} endAngle={endAngle} fill={fill} />
      </g>
    );
  };

  return (
    <Stack direction="vertical">
      {title && (
        <h2
          style={{
            marginTop: '25px',
            marginLeft: '25px',
            textAlign: 'left',
            height: 'fit-content'
          }}
        >
          {title}
        </h2>
      )}
      <ResponsiveContainer className="graph-container" height={350} width={329}>
        <PieChart>
          <Pie
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            data={distribution.filter((ent) => ent.hide !== true)}
            cx="50%"
            cy="50%"
            innerRadius={100}
            outerRadius={140}
            fill="#8884d8"
            dataKey="value"
            onMouseEnter={onPieEnter}
            onMouseLeave={onPieLeave}
          >
            {activeIndex === -1 && (
              <>
                <Label
                  // offset={0}
                  dy={-12}
                  position="center"
                  style={{ fontSize: '32px' }}
                >
                  {dataType != null && dataType === 'count'
                    ? Utils.getCurrencyValue(distribution?.filter((item) => item.name === 'total')[0]?.value).substring(1)
                    : Utils.getCurrencyValue(distribution?.filter((item) => item.name === 'total')[0]?.value)}
                </Label>
                <Label dy={20} position="center" fill="#A0A0A0" style={{ fontSize: '18px', fontFamily: 'DM Sans' }}>
                  {getPayloadLabel('total')}
                </Label>
              </>
            )}
            {distribution
              .filter((ent) => ent.hide !== true)
              .map((entry, index) => (
                <Cell key={`cell-${index}`} fill={customColors ? customColors[index % COLORS.length] : COLORS[index % COLORS.length]} />
              ))}
          </Pie>
          {/* <Tooltip content={<AdditionalDataTooltip lines={pies} />} /> */}
        </PieChart>
      </ResponsiveContainer>
      {!hideLegend && <RenderLegend />}
    </Stack>
  );
};
