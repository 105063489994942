import React, { FC } from 'react';
import { RouteComponentProps, useHistory } from 'react-router';
import Utils from '../../../utils/utils';
import { Stack, Text } from '../../../components/common';
import { PageContainer } from '../../../components/common/blocks/PageContainer';
import { Loader } from '../../../components/common/blocks/Loader';
import { CategoriesByMostMttr } from '../../../components/home/CategoriesByMostMttr';
import { useResetPage } from '../../../hooks/useResetPage';
import { KnowledgeArticlesAndBugs } from './KnowledgeArticlesAndBugs';
import Grid from '@material-ui/core/Grid';
import AccountSearch from '../../customerHealth/components/AccountSearch';
import { CategoriesOverview } from './CategoriesOverview';

export const Overview: FC<RouteComponentProps> = () => {
  const history = useHistory();

  useResetPage();

  const [caseCategoriesData, setCaseCategoriesData] = React.useState<any[]>();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const handleSelectTextSearch = (category: string) => {
    Utils.removeObjectItem('categoryDetailedView');
    // setSelectedCategory(category);
    Utils.saveObjectItem('categoryDetailedView', { current: category });
    history.replace(`/detailed-view#${category}`);
  };

  const searchBarEntries = caseCategoriesData?.map((c) => c.name) || [];
  return (
    <div>
      <PageContainer>
        {isLoading ? (
          <Loader center />
        ) : (
          <div>
            <Grid>
              <Stack
                direction="horizontal"
                // justify="space-between"
                align="center"
                style={{ backgroundColor: 'white', padding: '16px' }}
              >
                <Text variant="p4" weight="bold" color="dark" style={{ marginRight: '10px' }}>
                  Search By Category
                </Text>
                <AccountSearch
                  withoutBorder={true}
                  dataList={searchBarEntries}
                  value={undefined}
                  deactivateTitle={true}
                  withoutBorderInput={true}
                  getOptionSelected={handleSelectTextSearch}
                />
              </Stack>
            </Grid>
          </div>
        )}

        <Grid container spacing={2}>
          <Grid item lg={6} md={12} sm={12} xs={12}>
            <CategoriesByMostMttr inDashboardTab={false} />
          </Grid>

          <Grid item lg={6} md={12} sm={12} xs={12}>
            <KnowledgeArticlesAndBugs />
          </Grid>
        </Grid>

        <CategoriesOverview onCaseCategoriesInfoChange={onCaseCategoriesInfoChange} />
      </PageContainer>
    </div>
  );

  function onCaseCategoriesInfoChange(isLoading: boolean, data: any[] | undefined) {
    setIsLoading(isLoading);
    setCaseCategoriesData(data);
  }
};
