import React, { FC, useMemo } from 'react';
import { Stack, Text } from '../../../components/common';
import { Area, AreaChart, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis } from 'recharts';
import { format, parseISO } from 'date-fns';
import { TablePaper } from '../../../components/common/blocks/TablePaper';
import { CaseEntry } from '../../../services/IrisPortalService/customer';

export const CaseTimeDistribution: FC<{
  style?: React.CSSProperties;
  cases: CaseEntry[];
  classes?: { root?: string };
}> = (props) => {
  const { cases, classes: { root = '' } = {} } = props;

  const transformedData = useMemo(() => {
    return cases.map((caseEntry) => ({
      title: format(parseISO(caseEntry.id), 'MMM dd, yyyy'),
      casesCount: caseEntry.count
    }));
  }, [cases]);

  return (
    <TablePaper className={root}>
      <Stack direction="vertical" gap={20} style={{ marginTop: 16 }}>
        <Text variant="p4" weight="bold" color="dark">
          Total cases
        </Text>
        <ResponsiveContainer height={300} width="100%">
          <AreaChart data={transformedData}>
            <defs>
              <linearGradient id="prefix__b" x1=".5" x2=".5" y2="1">
                <stop stop-color="#58508D" />
                <stop offset="1" stop-color="#58508d" stop-opacity=".67" />
              </linearGradient>
            </defs>
            <XAxis
              dataKey="title"
              tickFormatter={(value: string) => format(new Date(value), 'MMM dd, yyyy')}
              tick={{ fontSize: 14, fontWeight: 500 }}
              tickMargin={10}
            />
            <YAxis tickSize={0} tickMargin={20} tick={{ fontSize: 14, fontWeight: 600 }} allowDecimals={false} />
            <Area dataKey="casesCount" stroke="rgba(88, 80, 141, 0.5)" strokeWidth="2px" fill="url(#prefix__b)" />
            <Tooltip content={<CustomTooltip />} />
          </AreaChart>
        </ResponsiveContainer>
      </Stack>
    </TablePaper>
  );
};

const CustomTooltip: FC<TooltipProps<string, number>> = (props) => {
  const { active, payload, label } = props;
  if (active && payload && payload.length) {
    //console.log(props);
    const date = label;
    const casesCount = Number(payload[0].value) || 0;
    return (
      <Stack
        direction="vertical"
        gap={10}
        style={{
          backgroundColor: '#fff',
          boxShadow: '0px 4px 4px rgba(216, 216, 216, 0.25)',
          borderRadius: 1,
          padding: '15px 20px'
        }}
      >
        <Text variant="p1" weight="semi-bold">
          {date}
        </Text>
        <Text variant="p1" weight="medium">
          Cases: {casesCount}
        </Text>
      </Stack>
    );
  }
  return null;
};
