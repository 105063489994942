import React, { Component } from 'react';
import { Button } from '@material-ui/core';
import './ErrorPage.scss';

interface ErrorPageProps {
  message?: string;
  email?: string;
}

export const DefaultMessage = 'Something went wrong. Please contact us to resolve the issue.';
export const DefaultEmail = 'contact@irisagent.com';

export const NotMemberMessage = 'You are not a member of this organization. Please contact your administrator.';

class ErrorPage extends Component<ErrorPageProps> {
  static defaultProps = {
    message: DefaultMessage,
    email: DefaultEmail
  };

  componentDidMount() {}

  render() {
    const { message, email } = this.props;

    return (
      <div style={{ height: '100%', width: '100%', paddingTop: '5%' }}>
        <div style={{ width: '40%', marginLeft: 'auto', marginRight: 'auto', padding: 10 }}>
          <div style={{ color: '#8181A5', fontWeight: 'bold', paddingBottom: '3%' }}>
            {message}
            <div style={{ marginTop: '10px', fontSize: '14px' }}>
              You can reach us at:{' '}
              <a href={`mailto:${email}`} style={{ color: '#0fb2a9', textDecoration: 'none' }}>
                {email}
              </a>
            </div>
          </div>
          <Button href="/" variant="outlined" style={{ backgroundColor: '#0fb2a9', color: 'white', marginTop: '20px' }}>
            Return to Main Page
          </Button>
        </div>
      </div>
    );
  }
}

export default ErrorPage;
