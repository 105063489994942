import React from 'react';
import { useQuery } from 'react-query';
import FrontendApiServer from '../services/IrisPortalService/frontendApiServer';
import { Stack } from '../components/common';
import { Loader } from '../components/common/blocks/Loader';
import Utils from '../utils/utils';
import Constants from '../utils/constants';
import { Redirect } from 'react-router-dom';

export function AuthRedirect() {
  const { data: tokenAfterOktaAuth, isLoading, isSuccess } = useQuery([], () => FrontendApiServer.getTokenAfterOktaAuth(), {
    onError: (e) => {
      Utils.showUnknownError(e);
    }
  });

  React.useEffect(() => {
    if (!isSuccess) {
      return;
    }

    const source = Utils.getOAuthSource();

    window.location.href = `${process.env.REACT_APP_BACKEND_URL}/auth/redirect?token=${tokenAfterOktaAuth}&source=${source}`;
  }, [isSuccess]);

  if (isLoading) {
    return getLoader();
  }

  if (!isSuccess) {
    return <Redirect to={{ pathname: Constants.routes.LOGIN }} />;
  }

  function getLoader() {
    return (
      <div className={'d-flex align-items-center minh-100'} style={{ minHeight: '90vh' }}>
        <Stack direction={'vertical'} gap={20} className={'my-0 mx-auto'}>
          <Loader center={true} />
        </Stack>
      </div>
    );
  }

  return <></>;
}
