import { useQuery } from 'react-query';

import { CACHE_KEYS, CACHE_TIME } from '../../../utils/cacheUtils';
import IrisPortalService from '../../../services/IrisPortalService';

import type { CustomerHealthDataV2 } from '../types';

export function useCustomerHealthData() {
  const { data: dataCustomerHealth, isLoading: isLoadingCustomerHealth, isFetching: isFetchingCustomerHealth } = useQuery(
    [CACHE_KEYS.CUSTOMER_HEALTH_V2],
    () => IrisPortalService.getCustomerHealthDataV2(),
    {
      ...CACHE_TIME.get(CACHE_KEYS.CUSTOMER_HEALTH_V2),
      placeholderData: {
        customerHealth: {
          customerLowestHealthScores: [],
          customerPositiveSentimentScores: [],
          negativeCaseList: [],
          customerWithNumCases: []
        }
      } as CustomerHealthDataV2
    }
  );

  return {
    dataCustomerHealth,
    isLoadingCustomerHealth,
    isFetchingCustomerHealth
  };
}
