import React, { ReactElement } from 'react';

import { MultiSelectInput } from '../../../../../components/MultiSelectInput';
import { RulesNativeSelect } from '../../RulesNativeSelect';

import { formatPrerequisitesData, RulesRowManager, useTriggersPrerequisites } from '../../../utils';

import { HandleRowComponentType, Option } from '../../../types';
import { ReactComponent as CustomField } from '../../../../../assets/CustomField.svg';
import '../styles.scss';
import SearchWidget from '../../../../categories/components/SearchWidget';
import _ from 'lodash';

/**
 * Row manager for Tags
 */
export class CustomFieldRowManager extends RulesRowManager {
  tagsOptionsOperator: Option[] = [
    { name: 'in', label: 'Contains at least one of the following' },
    { name: 'nin', label: 'Contains none of the following' }
  ];
  private currentCustomFieldSelection: any;

  constructor(options: Option[], actionValue: any, actionOperator: any) {
    super(options, actionValue, actionOperator);

    // @ts-ignore
    this.action.type = 'custom_field';
    this.action.expressionType = 'complex';
    this.action.operator = actionOperator === undefined ? 'in' : actionOperator;
    this.currentCustomFieldSelection = null;
  }

  setDefaultActionValue(): void {
    this.action.value = {
      type: 'simple',
      operator: this.action.operator === undefined ? 'in' : this.action.operator,
      fact: null,
      value: []
    };
  }

  getListWithNameAndLabel(stringArr: string[]): object[] {
    // Convert a string array to array of {name: x, label: x}
    if (_.isEmpty(stringArr)) {
      return [];
    }
    return stringArr.map((x: string) => {
      return { name: x, label: x };
    });
  }

  createItems(handleRowComponent: HandleRowComponentType, s: any, ss: any): ReactElement {
    if (this.action.value === undefined) this.setDefaultActionValue();

    const { triggersPrerequesites } = useTriggersPrerequisites();
    const customFieldDetails = triggersPrerequesites?.customFieldDetails || {};
    const customFieldNames = Object.keys(customFieldDetails);
    let customFieldValues;

    if (!s.loadedExistingData && !_.isEmpty(triggersPrerequesites) && !_.isEmpty(this.action.value.value)) {
      ss({
        loadedExistingData: true,
        valuesSelected: this.getListWithNameAndLabel(this.action.value.value),
        cfValues: this.getListWithNameAndLabel(customFieldDetails[this.action.value.fact])
      });
    }

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <CustomField />
          <p className="title">Custom Field</p>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 8 }}>
          <div
            style={{
              flexBasis: '55%'
            }}
          >
            <div style={{ width: '100%' }}>
              <SearchWidget
                isCustom={false}
                searchValue={this.action.value.fact}
                dataList={customFieldNames}
                onInputChange={(input: string) => {
                  this.action.value.fact = input;
                  this.currentCustomFieldSelection = input;
                  customFieldValues = this.currentCustomFieldSelection ? formatPrerequisitesData(customFieldDetails[this.currentCustomFieldSelection]) : [];
                  ss({
                    cfValues: customFieldValues,
                    valuesSelected: []
                  });
                  handleRowComponent(this.action);
                }}
              />
            </div>
          </div>
          <RulesNativeSelect
            options={this.tagsOptionsOperator}
            defaultValue={this.action.operator}
            onChange={(e) => {
              this.action.operator = e.target.value;
              this.action.value.operator = e.target.value;
              handleRowComponent(this.action);
            }}
          />
          <div>
            <MultiSelectInput
              displayValue="label"
              placeholder="Search Field Value"
              onKeyPressFn={function noRefCheck() {}}
              onRemove={(e) => {
                this.action.value.value = e || [];
                handleRowComponent(this.action);
              }}
              onSearch={function noRefCheck() {}}
              valuesSelected={s.valuesSelected}
              onChange={(e) => {
                this.action.value.value = e.map((val) => val.name);
                handleRowComponent(this.action);
              }}
              options={s.cfValues} // TODO: Use different options here
            />
          </div>
        </div>
      </div>
    );
  }
}
