import React from 'react';

import './styles.scss';

interface IContextMenuItemProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

export const ContextMenuItem: React.FC<IContextMenuItemProps> = ({ children, onClick, ...rest }) => {
  return (
    <button className="app-context-menu-item" onClick={onClick} {...rest}>
      {children}
    </button>
  );
};
